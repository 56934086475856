import { getServerId, getTenantId, isDeveloperApp } from "../api/paths";

export const APP_LOCALSTORAGE_PREFIX = `${getTenantId()}_${
  isDeveloperApp() ? "developer_" : ""
}${getServerId()}`;

export const getFromLocalStorage = key => {
  try {
    return localStorage.getItem(`${APP_LOCALSTORAGE_PREFIX}_${key}`) || undefined;
  } catch (e) {
    return undefined;
  }
};

export const setInLocalStorage = (key, value) => {
  try {
    localStorage.setItem(`${APP_LOCALSTORAGE_PREFIX}_${key}`, value);
  } catch (e) {
    //
  }
};

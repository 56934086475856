import React from "react";

import { PoolAuthenticationMechanismsEnum } from "@cloudentity/acp-identity";

import Alert from "../../../../../common/components/Alert";
import FormInputLabel from "../../../../../common/components/FormInputLabel";
import { FormFactory } from "../../../../../common/utils/forms/formFactory";
import { validators } from "../../../../../common/utils/forms/validation";
import { useFeature } from "../../../../../common/utils/hooks/useFeature";
import IdentityPoolsAuthnSelect from "../identityPoolCreate/IdentityPoolsAuthnSelect";

interface Props {
  formFactory: FormFactory;
  progress: boolean;
  noManagePermission: boolean;
  settings?: {
    [key in PoolAuthenticationMechanismsEnum]?: () => void;
  };
}
export default function IdentityPoolAuthenticationMechanisms({
  formFactory,
  progress,
  noManagePermission,
  settings,
}: Props) {
  const isIdentityPoolMFAEnabled = useFeature("identity_pool_mfa");
  const isIdentityPoolTOTPEnabled = useFeature("identity_pool_totp");
  const isArculixEnabled = useFeature("arculix");
  const isRiskEngineEnabled = useFeature("risk_engine");
  const authenticationMechanisms = formFactory.watch("authentication_mechanisms") || [];
  const secondFactorAuthenticationMechanisms =
    formFactory.watch("second_factor_authentication_mechanisms") || [];
  const secondFactorThreshold = formFactory.watch("second_factor_threshold");

  return (
    <>
      <IdentityPoolsAuthnSelect
        id="first-factor-authentication-methods"
        label={
          <FormInputLabel
            label={
              isIdentityPoolMFAEnabled
                ? "First-Factor Authentication Methods"
                : "Authentication Methods"
            }
            id="first-factor-authn-method-label"
            tooltip="The Authentication method depends on the level of security required, the type of system or device being accessed, and the user's needs and preferences."
          />
        }
        authenticationMechanismsKeyName="authentication_mechanisms"
        preferredAuthenticationMechanismsKeyName="preferred_authentication_mechanism"
        options={Object.values(PoolAuthenticationMechanismsEnum).filter(
          v =>
            !authenticationMechanisms.includes(v) &&
            (isIdentityPoolTOTPEnabled || v !== PoolAuthenticationMechanismsEnum.Totp) &&
            (isIdentityPoolMFAEnabled ? !secondFactorAuthenticationMechanisms.includes(v) : true) &&
            v !== PoolAuthenticationMechanismsEnum.Arculix
        )}
        formFactory={formFactory}
        progress={progress}
        noManagePermission={noManagePermission}
        settings={settings}
        onMoveToOtherFactor={method => {
          formFactory.setValue("second_factor_authentication_mechanisms", [
            ...secondFactorAuthenticationMechanisms,
            method,
          ]);
          formFactory.setValue(
            "authentication_mechanisms",
            authenticationMechanisms.filter(am => am !== method)
          );
        }}
      />

      {authenticationMechanisms.length === 0 && (
        <Alert severity="warning" title="Warning">
          If there are any First-Factor Authentication Methods set users won't be able to log in.
        </Alert>
      )}

      {isIdentityPoolMFAEnabled && (
        <>
          <IdentityPoolsAuthnSelect
            id="second-factor-authentication-methods"
            label={
              <FormInputLabel
                label="Second-Factor Authentication Methods"
                id="Second-factor-authn-method-label"
              />
            }
            authenticationMechanismsKeyName="second_factor_authentication_mechanisms"
            preferredAuthenticationMechanismsKeyName="second_factor_preferred_authentication_mechanism"
            options={Object.values(PoolAuthenticationMechanismsEnum).filter(
              v =>
                (isIdentityPoolTOTPEnabled || v !== PoolAuthenticationMechanismsEnum.Totp) &&
                !authenticationMechanisms.includes(v) &&
                !secondFactorAuthenticationMechanisms.includes(v) &&
                (isArculixEnabled || v !== PoolAuthenticationMechanismsEnum.Arculix)
            )}
            formFactory={formFactory}
            progress={progress}
            noManagePermission={noManagePermission}
            disabled={authenticationMechanisms.length === 0}
            settings={settings}
            onMoveToOtherFactor={method => {
              formFactory.setValue("authentication_mechanisms", [
                ...authenticationMechanisms,
                method,
              ]);
              formFactory.setValue(
                "second_factor_authentication_mechanisms",
                secondFactorAuthenticationMechanisms.filter(am => am !== method)
              );
            }}
          />
          {formFactory.createDurationField({
            id: "second-factor-mfa-session-ttl",
            name: "mfa_session_ttl",
            label: "Reduce 2FA verification on same device",
            description: (
              <span>
                Users would not be asked again for 2FA for defined period of time on same device. To
                disable set to <code>0s</code>.
              </span>
            ),
            validate: {
              inRangeDuration: validators.inRangeDuration({
                label: "",
                min: "0h0m00s",
                max: "722h0m0s",
              }),
            },
          })}

          {isRiskEngineEnabled && (
            <div style={{ display: "flex", alignItems: "center" }}>
              {formFactory.createSliderField({
                name: "second_factor_threshold",
                title: "Level of assurance threshold",
                helperText:
                  "Users will be prompted for a second factor if the real-time level of assurance falls below the required threshold.",
                withValueIndicator: true,
                sliderProps: {
                  value: Number(parseFloat((secondFactorThreshold * 100).toFixed(2))),
                  onChange: (_, value) =>
                    formFactory.setValue(
                      "second_factor_threshold",
                      Number(((value as number) / 100).toFixed(2))
                    ),
                  step: 1,
                  size: "small",
                },
              })}
            </div>
          )}
        </>
      )}
    </>
  );
}

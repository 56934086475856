import { ReactNode } from "react";

import axios from "axios";

import { allowedMessagesFor401 } from "../../api/unauthorized-redirect.interceptor";
import getStore from "../../store";
import { showNotification } from "./actions/actions";

export const handleErrorWithNotify = (message: string) => (err: unknown) => {
  if (
    axios.isAxiosError(err) &&
    (err.response?.status !== 401 || allowedMessagesFor401.includes(err.response?.data?.error))
  ) {
    const extendedMessage =
      err.message === "Network Error"
        ? `${message}: Network Error - Check your internet connection or turn off ad blocking plugin`
        : message;

    getStore().dispatch(
      showNotification({
        message: extendedMessage,
        options: {
          variant: "error",
          autoHideDuration: 6000,
        },
      })
    );
  } else {
    console.error(message, err);
  }
  return err as any;
};

export const notifyErrorOrDefaultTo = (message: string) => (err: unknown) => {
  if (axios.isAxiosError(err)) {
    const errorMessage = err.response?.data?.error ?? message;
    return handleErrorWithNotify(errorMessage)(err);
  }
  return err as any;
};

export const notifySuccess = (message: string | ReactNode) => {
  getStore().dispatch(
    showNotification({
      message,
      options: { variant: "success" },
    })
  );
};

export const notifyError = (message: string) => {
  getStore().dispatch(
    showNotification({
      message,
      options: { variant: "error" },
    })
  );
};

export const notifyWarning = (message: string) => {
  getStore().dispatch(
    showNotification({
      message,
      options: { variant: "warning" },
    })
  );
};

export const notifyInfo = (message: string) => {
  getStore().dispatch(
    showNotification({
      message,
      options: { variant: "info" },
    })
  );
};

import React from "react";

import Dialog from "../../../../common/components/Dialog";
import Progress from "../../../../common/components/Progress";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { useOrganizationMutation } from "../../../services/adminOrganizationsMutations";
import { useGetOrganization } from "../../../services/adminOrganizationsQuery";

interface Props {
  serverID: string;
  onClose: () => void;
  isOrganization?: boolean;
}

export default function EditOrganizationDialog({ serverID, onClose }: Props) {
  const getOrganizationQuery = useGetOrganization(serverID);
  const organizationMutation = useOrganizationMutation({ wid: serverID });

  const formFactory = useFormFactory({
    id: "edit-organization",
    data: getOrganizationQuery.data,
    progress: organizationMutation.isPending,
  });

  const handleUpdate = data => {
    const d = { ...getOrganizationQuery.data, ...data };

    organizationMutation
      .mutateAsync({ wid: serverID, org: d })
      .then(() => {
        notifySuccess("Organization settings saved successfully");
        onClose();
      })
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to update organization settings"));
  };

  return (
    <Dialog onClose={onClose} id="edit-organization-dialog" title="General Settings">
      <FormContext.Provider value={formFactory.context}>
        {getOrganizationQuery.isLoading ? (
          <div style={{ height: 169 }}>
            <Progress />
          </div>
        ) : (
          <>
            {formFactory.createRequiredField({
              name: "name",
              label: "Organization name",
            })}
            {formFactory.createFormFooter({
              onCancel: onClose,
              onSubmit: handleUpdate,
            })}
          </>
        )}
      </FormContext.Provider>
    </Dialog>
  );
}

import forge from "node-forge";
import isAscii from "validator/lib/isAscii";
import isBase64 from "validator/lib/isBase64";
import isEmail, { IsEmailOptions } from "validator/lib/isEmail";
import isFQDN from "validator/lib/isFQDN";
import isNumeric from "validator/lib/isNumeric";
import isURL from "validator/lib/isURL";
import zxcvbn from "zxcvbn";

import { ttlValueToModel } from "../../../admin/components/common/TextFieldTTLEditor";

const ID_PATTERN = /^[a-z0-9=._-]+$/;
const ALPHANUMERIC = /^[a-zA-Z0-9]+$/;
const ALPHANUMERIC_WITH_WHITESPACES = /^[a-zA-Z0-9\s]+$/;
const ALPHANUMERIC_WITH_WHITESPACES_DASH_UNDERSCORE = /^[a-zA-Z0-9-_\s]+$/;
const UPPERCASE_ALPHANUMERIC = /^[A-Z0-9]+$/;

export function parseDuration(value: string) {
  const parsed = ttlValueToModel(value);
  return parsed.sec + parsed.min * 60 + parsed.hours * 60 * 60 + parsed.milliseconds / 1000;
}

const trim = (v: string | number | undefined) => {
  if (v === undefined) {
    return "";
  }
  if (typeof v === "number") {
    return v.toString();
  }

  return v.trim();
};

export const validators = {
  minLength:
    ({ label, min = 1 }) =>
    v =>
      trim(v).length >= min || `${label} minimum length is ${min} characters`,
  maxLength:
    ({ label, max = 255 }) =>
    v =>
      trim(v).length <= max || `${label} maximum length is ${max} characters`,
  validURL:
    ({ label, options = {} }) =>
    v =>
      !v ||
      isURL(trim(v), {
        protocols: ["http", "https"],
        require_protocol: true,
        require_tld: false,
        ...options,
      }) ||
      `${label} is not valid url`,
  validHttpsURL:
    ({ label }) =>
    v =>
      !v ||
      isURL(trim(v), { protocols: ["https"], require_protocol: true, require_tld: false }) ||
      `${label} is not valid url with https protocol`,
  isUrlSafe:
    ({ label }) =>
    v =>
      !v || isBase64(trim(v), { urlSafe: true }) || `${label} contains not URL safe characters`,
  validEmail:
    ({
      label,
      customErrorMsg,
      options,
    }: {
      label: string;
      customErrorMsg?: string;
      options?: IsEmailOptions;
    }) =>
    v =>
      !v || isEmail(trim(v), options) || customErrorMsg || `${label} is not valid email`,
  validPhone:
    ({ label, required, selector }) =>
    () => {
      const input = document.querySelector(selector);
      const iti = (window as any).intlTelInputGlobals.getInstance(input);
      if (!iti) return true;
      const number = iti.getNumber();
      return (
        (required && !number ? "Phone value is required" : !number) ||
        iti.isValidNumber() ||
        `${label} is not valid phone number`
      );
    },
  validID:
    ({ label }) =>
    v =>
      ID_PATTERN.test(trim(v)) ||
      `${label} is not valid ID. Only following characters are allowed a-z0-9=._-`,
  isJSON:
    ({ label }) =>
    v => {
      try {
        JSON.parse(v);
        return true;
      } catch (e) {
        return `${label} is not valid JSON object`;
      }
    },
  notUniq:
    ({ label, options }) =>
    v =>
      options.indexOf(trim(v)) === -1 || `${label} with given value already exists`,
  oneOf:
    ({ label, options }) =>
    v =>
      options.indexOf(v) > -1 || `${label} must be one of: ${options.join(", ")}`,
  validURLExtension:
    ({ type, extensions }) =>
    v =>
      !v ||
      extensions.some(ext => v.toLowerCase().endsWith(`.${ext.toLowerCase()}`)) ||
      `Supported ${type} formats: ${extensions.map(v => v.toUpperCase()).join(", ")}.`,
  validDomain:
    ({ label, require_tld = true }) =>
    v =>
      isFQDN(trim(v), { require_tld }) ||
      `${label} is not a valid domain${
        /^http(s?):\/\//.test(trim(v)) ? " - http(s):// prefix not allowed" : ""
      }`,
  validDomains:
    ({ label, require_tld = true }) =>
    v =>
      !v ||
      v.every(domain => isFQDN((domain.value || domain).trim(), { require_tld })) ||
      `${label} contain not a valid domain`,
  noDuplicatedElements:
    ({ label }) =>
    v => {
      const values = (v || []).map(v => v.value || v);
      return new Set(values).size === values.length || `${label} has duplicated elements`;
    },
  onlyAlphanumeric:
    ({ label }) =>
    v =>
      ALPHANUMERIC.test(trim(v)) ||
      `${label} is not valid. Only alphanumeric characters are allowed a-zA-Z0-9`,
  onlyAscii:
    ({ label }) =>
    v =>
      isAscii(trim(v)) || `${label} is not valid. Only ASCII characters are allowed`,
  onlyAsciiWithoutWhitespaces:
    ({ label }) =>
    v =>
      (isAscii(trim(v)) && !/\s/g.test(trim(v))) ||
      `${label} is not valid. Only ASCII (without whitespaces) characters are allowed`,
  onlyAlphanumericWithWhitespaces:
    ({ label }) =>
    v =>
      ALPHANUMERIC_WITH_WHITESPACES.test(trim(v)) ||
      `${label} is not valid. Only alphanumeric characters a-zA-Z0-9 and whitespaces are allowed`,
  onlyAlphanumericWithWhitespacesDashUnderscore:
    ({ label }) =>
    v =>
      ALPHANUMERIC_WITH_WHITESPACES_DASH_UNDERSCORE.test(trim(v)) ||
      `${label} is not valid. Only alphanumeric characters a-zA-Z0-9, whitespaces, dash and underscore are allowed`,
  onlyUppercaseAlphanumeric:
    ({ label }) =>
    v =>
      UPPERCASE_ALPHANUMERIC.test(trim(v)) ||
      `${label} is not valid. Only uppercase alphanumeric characters are allowed A-Z0-9`,
  notEmpty:
    ({ label }) =>
    v => {
      return (v && v.length !== 0) || `At least one value in the ${label} field is required`;
    },
  validTTL:
    ({ label }) =>
    v =>
      !["0s", "0h0m0s"].includes(v) || `Non-zero ${label} is required`,
  inRangeDuration:
    ({ label, min, max }) =>
    v => {
      const seconds = parseDuration(v);
      const minSeconds = parseDuration(min);
      const maxSeconds = parseDuration(max);

      if (seconds < minSeconds) {
        return `${label} must be greater than ${min}`;
      } else if (seconds > maxSeconds) {
        return `${label} must be less than ${max}`;
      }
      return true;
    },
  inRange:
    ({ label, min, max }) =>
    v =>
      (v >= min && v <= max) || `${label} must be a value between ${min} and ${max}`,
  validNumeric:
    ({ label, min = undefined, max = undefined }) =>
    v => {
      if (!v) {
        return true;
      } else if (isNumeric(trim(v))) {
        const num = parseInt(v);
        if (min !== undefined && num < min) {
          return `${label} min value is ${min}`;
        }
        if (max !== undefined && num > max) {
          return `${label} max value is ${max}`;
        }
        return true;
      } else {
        return `${label} is not a valid number`;
      }
    },
  validCertificate:
    ({ label }) =>
    v => {
      try {
        forge.pki.certificateFromPem(v);
      } catch {
        return `${label} is not valid`;
      }
      return true;
    },
  validPrivateKey:
    ({ label }) =>
    v => {
      try {
        forge.pki.privateKeyFromPem(v);
      } catch {
        return `${label} is not valid`;
      }
      return true;
    },
  validXML:
    ({ label }) =>
    v => {
      try {
        const domParser = new DOMParser();
        const dom = domParser.parseFromString(v, "text/xml");
        if (dom.getElementsByTagName("parsererror").length) {
          return `${label} is not valid`;
        }
      } catch {
        return `${label} is not valid`;
      }
      return true;
    },
  validPasswordStrength:
    ({ minScore }: { minScore: number }) =>
    v => {
      if (v) {
        const result = zxcvbn(v);
        if (result.score < minScore) {
          return "Password does not meet password policy. Provide stronger password.";
        }
      }
      return true;
    },
  minNumOfDigits:
    ({ label, min = 0 }) =>
    v => {
      const value = trim(v);
      const length = (value.match(/\d/g) || []).length;
      return length >= min || `${label} minimum number of digits is ${min}`;
    },
  minNumOfCapitalLetters:
    ({ label, min = 0 }) =>
    v => {
      const value = trim(v);
      const length = (value.match(/[A-Z]/g) || []).length;
      return length >= min || `${label} minimum number of capital letters is ${min}`;
    },
  minNumOfLowercaseLetters:
    ({ label, min = 0 }) =>
    v => {
      const value = trim(v);
      const length = (value.match(/[a-z]/g) || []).length;
      return length >= min || `${label} minimum number of lowercase letters is ${min}`;
    },
  minNumOfSpecialCharacters:
    ({ label, min = 0 }) =>
    v => {
      const value = trim(v);
      const length = (value.match(/[~!@#$%^&*_\-+=`|\\(){}[\]:;"'<>,.?/]/g) || []).length;
      return length >= min || `${label} minimum number of special characters is ${min}`;
    },
};

import React from "react";

import Grid from "@mui/material/Grid";
import { AxiosResponse } from "axios";
import differenceInDays from "date-fns/differenceInDays";
import { makeStyles } from "tss-react/mui";

import {
  SelfUserCredentials,
  SelfUserCredentialsTypeEnum,
  SelfUserWithDataV2,
  SelfUserWithDataV2AuthenticationMechanismsEnum,
} from "@cloudentity/acp-identityself";

import InfoField from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/user/InfoField";
import PasskeyName from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/user/sidePanel/PasskeyName";
import PasswordStatus, {
  countExpiresDate,
} from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/user/sidePanel/PasswordStatus";
import { getDateTime } from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/user/utils";
import Alert from "../Alert";
import SelfServiceChangePassword from "./SelfServiceChangePassword";
import SelfServiceChangeTotp from "./SelfServiceChangeTotp";
import CredentialAccordion from "./SelfServiceCredentialInfoAccordion";
import SelfServiceCredentialPasskeyDelete from "./SelfServiceCredentialPasskeyDelete";
import SelfServiceCredentialVerificationCode from "./SelfServiceCredentialVerificationCode";
import SelfServiceSetPasskey from "./SelfServiceSetPasskey";
import SelfServiceSetPassword from "./SelfServiceSetPassword";
import SelfServiceSetTotp from "./SelfServiceSetTotp";
import { FactorType } from "./utils";

const useStyles = makeStyles()(() => ({
  credentialCard: {
    width: "100%",
    marginLeft: 0,
    borderBottom: "solid 1px rgba(0, 0, 0, 0.12)",
    padding: "16px 0",
    "&:first-of-type": {
      paddingTop: 0,
    },
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
}));

interface Props {
  credential?: SelfUserCredentials;
  isEmptyType?: SelfUserWithDataV2AuthenticationMechanismsEnum;
  userData: SelfUserWithDataV2 | null;
  fetchUser: (withSetter?: boolean) => Promise<AxiosResponse<SelfUserWithDataV2, any> | null>;
  factorType?: FactorType;
  isPreferred: boolean;
  multipleCredentialsOfType?: SelfUserCredentials[];
}

export default function SelfServiceCredentialInfo({
  credential,
  isEmptyType,
  userData,
  fetchUser,
  factorType,
  isPreferred,
  multipleCredentialsOfType,
}: Props) {
  const { classes } = useStyles();

  if (isEmptyType === SelfUserWithDataV2AuthenticationMechanismsEnum.Webauthn) {
    return (
      <CredentialAccordion
        type={isEmptyType}
        factorType={factorType}
        isPreferred={isPreferred}
        isInactive
      >
        <SelfServiceSetPasskey fetchUser={fetchUser} />
      </CredentialAccordion>
    );
  }

  if (isEmptyType === SelfUserWithDataV2AuthenticationMechanismsEnum.Password) {
    return (
      <CredentialAccordion
        type={isEmptyType}
        factorType={factorType}
        isPreferred={isPreferred}
        isInactive
      >
        <SelfServiceSetPassword fetchUser={fetchUser} />
      </CredentialAccordion>
    );
  }

  if (isEmptyType === SelfUserWithDataV2AuthenticationMechanismsEnum.Totp) {
    return (
      <CredentialAccordion
        type={isEmptyType}
        factorType={factorType}
        isPreferred={isPreferred}
        isInactive
      >
        <SelfServiceSetTotp fetchUser={fetchUser} />
      </CredentialAccordion>
    );
  }

  if (isEmptyType === SelfUserWithDataV2AuthenticationMechanismsEnum.Otp) {
    return (
      <CredentialAccordion
        type={isEmptyType}
        factorType={factorType}
        isPreferred={isPreferred}
        isInactive
      >
        <SelfServiceCredentialVerificationCode addresses={userData?.verifiable_addresses ?? []} />
      </CredentialAccordion>
    );
  }

  if (isEmptyType === SelfUserWithDataV2AuthenticationMechanismsEnum.Arculix) {
    return null;
  }

  if (!credential) return null;

  const isPassword = credential.type === SelfUserCredentialsTypeEnum.Password;

  const updatedAt = credential?.updated_at;
  const expiresAt = credential?.expires_at;
  const expiresAfter = credential?.expires_after;

  const expiresDate = countExpiresDate(expiresAt, updatedAt, expiresAfter);

  const passwordExpiresSoon =
    expiresDate && expiresDate !== "N/A" && expiresDate !== "Never"
      ? differenceInDays(new Date(expiresDate), new Date()) < 7
      : false;

  return (
    <CredentialAccordion
      type={credential.type as any}
      factorType={factorType}
      isPreferred={isPreferred}
      noDetailsPadding
      summaryInfo={
        (multipleCredentialsOfType?.length ?? 0) > 1
          ? `${multipleCredentialsOfType?.length} methods`
          : undefined
      }
    >
      {multipleCredentialsOfType?.map(c => (
        <Grid
          key={c.id}
          container
          spacing={2}
          id={`credential-item-${c.type}-container-${c.id}`}
          className={classes.credentialCard}
        >
          {isPassword && <PasswordStatus password={c} />}

          {c.type === SelfUserCredentialsTypeEnum.Webauthn && (
            <PasskeyName credential={c} fetchUser={fetchUser} />
          )}

          {updatedAt && (
            <Grid item xs={6}>
              <InfoField
                id="field-password-updated"
                label="Last updated"
                value={getDateTime(updatedAt)}
                noMarginTop
              />
            </Grid>
          )}

          {expiresAt && (
            <Grid item xs={6}>
              <InfoField
                id="field-password-expires"
                label="Expires"
                value={expiresDate}
                isWarning={passwordExpiresSoon}
                warningText={passwordExpiresSoon ? "Password expired or expires soon" : undefined}
                noMarginTop
              />
            </Grid>
          )}

          {c.type === SelfUserCredentialsTypeEnum.Password ||
          c.type === SelfUserCredentialsTypeEnum.Totp ? (
            <Grid item xs={12} style={{ marginTop: 8 }}>
              <div>
                {c.type === SelfUserCredentialsTypeEnum.Password && <SelfServiceChangePassword />}

                {c.type === SelfUserCredentialsTypeEnum.Totp && <SelfServiceChangeTotp />}
              </div>
            </Grid>
          ) : null}

          {c.type === SelfUserCredentialsTypeEnum.Webauthn &&
            (multipleCredentialsOfType?.length ?? 0) > 1 && (
              <Grid item xs={12}>
                <SelfServiceCredentialPasskeyDelete credential={c} fetchUser={fetchUser} />
              </Grid>
            )}
        </Grid>
      ))}

      {credential.type === SelfUserCredentialsTypeEnum.Webauthn &&
        (multipleCredentialsOfType?.length ?? 0) > 0 && (
          <Alert severity="info" style={{ margin: 8 }}>
            To enroll a new passkey, please use the "No access to passkey" option available on the
            login page
          </Alert>
        )}
    </CredentialAccordion>
  );
}

import React, { useMemo, useState } from "react";

import { Attribute, Claim } from "@cloudentity/acp-admin";

import Dialog from "../../../../common/components/Dialog";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { validators } from "../../../../common/utils/forms/validation";
import {
  getSourcePathWithMetadataKey,
  SourcePath,
  SourceType,
  sourceTypeOptions,
} from "./ClaimUtils";

export interface SamlClaimsCreateProps {
  options: Attribute[];
  workspace: string;
  existingClaims: (string | undefined)[];
  onCreate: (claims: Claim[]) => void;
  onCancel: () => void;
  progress: boolean;
}

const SamlClaimsCreate = ({
  options,
  workspace,
  existingClaims,
  onCreate,
  onCancel,
  progress,
}: SamlClaimsCreateProps) => {
  const data = useMemo(
    () => ({
      source_path: options[0],
      source_type: sourceTypeOptions[0].value,
      scopes: [],
    }),
    [options]
  );

  const formFactory = useFormFactory({ id: "add-claim", data, progress });

  const [paths, setPaths] = useState(options);

  const handleCreate = data => {
    const claim = {
      authorization_server_id: workspace,
      source_type: data.source_type,
      source_path: getSourcePathWithMetadataKey(
        data.source_type,
        data.source_path,
        data.metadata_key
      ),
      name: data.name,
      description: data.description,
      saml_name: data.saml_name,
      saml_name_format: data.saml_name_format,
      type: "saml_assertion",
    };
    onCreate([claim]);
  };

  return (
    <Dialog onClose={onCancel} id="add-claim-dialog" title="Add claim">
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createRequiredField({
          name: "name",
          label: "Name",
          rules: {
            validate: {
              notUniq: validators.notUniq({ label: "Name", options: existingClaims }),
            },
          },
          autoFocus: true,
        })}
        {formFactory.createField({
          name: "description",
          label: "Description",
        })}
        <SourceType
          formFactory={formFactory}
          options={sourceTypeOptions}
          authnCtxOptions={options}
          setPaths={setPaths}
        />
        <SourcePath formFactory={formFactory} options={paths} />

        {formFactory.createRequiredField({
          name: "saml_name",
          label: "SAML name",
        })}

        {formFactory.createRequiredField({
          name: "saml_name_format",
          label: "SAML name format",
        })}

        {formFactory.createFormFooter({ onCancel, onSubmit: handleCreate, submitText: "Add" })}
      </FormContext.Provider>
    </Dialog>
  );
};

export default SamlClaimsCreate;

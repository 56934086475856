import React, { useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import omit from "lodash/omit";

import { OrganizationResponse, WorkspaceResponse } from "@cloudentity/acp-admin";

import Dialog from "../../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import adminOrganizationsApi from "../../../services/adminOrganizationsApi";
import {
  getOrganizationQueryKey,
  listOrganizationsQueryKey,
} from "../../../services/adminOrganizationsQuery";
import OrganizationsHierarchy from "./OrganizationsHierarchy";

interface Props {
  organization: OrganizationResponse;
  rootServer?: Pick<
    OrganizationResponse | WorkspaceResponse,
    "id" | "number_of_child_organizations"
  >;
  onCancel: () => void;
}

export default function ChangeParentOrganizationDialog({
  organization,
  rootServer,
  onCancel,
}: Props) {
  const [progress, setProgress] = useState(false);
  const queryClient = useQueryClient();

  const data = useMemo(() => ({ server: null }), []);
  const formFactory = useFormFactory({ id: "change-parent-organization", data, progress });
  const [selected, setSelected] = useState("");

  const handleAssignParent = () => {
    if (!selected) {
      return;
    }
    setProgress(true);
    adminOrganizationsApi
      .updateOrganization({
        wid: organization.id!,
        org: { ...omit(organization, ["number_of_child_organizations"]), parent_id: selected },
      })
      .then(() => notifySuccess("Parent organization changed"))
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getOrganizationQueryKey(organization.id!) })
      )
      .then(() => queryClient.invalidateQueries({ queryKey: listOrganizationsQueryKey() }))
      .then(() => onCancel())
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to update parent organization"))
      .finally(() => setProgress(false));
  };

  return (
    <Dialog
      onClose={onCancel}
      id="change-parent-organization-dialog"
      aria-label="Change parent organization dialog"
      title={`Change parent organization for ${organization.name}`}
    >
      <FormContext.Provider value={formFactory.context}>
        <OrganizationsHierarchy
          emptyState={<></>}
          onSelectServer={serverId => setSelected(serverId)}
          selected={selected}
          rootOrganization={rootServer}
          showRootCard
          hideHierarchyBelowId={{ id: organization.id || "", reason: "Current organization" }}
          allowSelectRoot
          greyOutNodeWithId={{
            id: organization.parent_id || "",
            reason: "Current organization parent",
          }}
        />

        {formFactory.createFormFooter({
          onCancel,
          disabled: !selected,
          onSubmit: handleAssignParent,
          submitText: "Confirm",
          style: { marginTop: 32 },
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

import { actionWithData } from "../utils/actions";
import { mapReducer, putData } from "../utils/reducers";

const initial = {
  tenantId: "",
  authorizationServerId: "",
};

const mapping = {
  SET_TENANT_ID: putData("tenantId"),
  SET_AUTHORIZATION_SERVER_ID: putData("authorizationServerId"),
};

// actions
export const setTenantId = actionWithData("SET_TENANT_ID");
export const setAuthorizationServerId = actionWithData("SET_AUTHORIZATION_SERVER_ID");

export default mapReducer(mapping, initial);

import React from "react";

import Typography from "@mui/material/Typography";

import { WorkspaceResponse } from "@cloudentity/acp-admin";

import WorkspaceAvatar from "../../nav/WorkspaceAvatar";
import { EMPTY_VALUE_PLACEHOLDER_SERVER, getServerTitle } from "../server-profiles";

interface Props {
  server: WorkspaceResponse;
}

export default function ServerBlock({ server }: Props) {
  return (
    <div style={{ display: "flex", alignItems: "center", flex: 100, minWidth: 0 }} key={server?.id}>
      <WorkspaceAvatar server={server} size="small" />
      <div
        style={{ marginLeft: 6, display: "flex", flexDirection: "column", flex: 1, minWidth: 0 }}
      >
        <Typography
          display="block"
          style={{
            lineHeight: "14px",
            fontSize: 12,
            fontWeight: server.id === EMPTY_VALUE_PLACEHOLDER_SERVER ? "normal" : 600,
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
          }}
        >
          {server.name}
        </Typography>
        <Typography
          variant="caption"
          style={{
            color: "#9EA1B7",
            fontSize: 10,
            fontWeight: 400,
            lineHeight: "12px",
            marginTop: 2,
          }}
        >
          {getServerTitle(server) || server.profile}
        </Typography>
      </div>
    </div>
  );
}

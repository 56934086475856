import { useEffect } from "react";

import { QueryFunctionContext, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import throttle from "lodash/throttle";

import {
  PoolPermissionsResponse,
  PoolResponse,
  Pools,
  PoolsApiListPoolsRequest,
  PoolsApiListUserWorkspacePoolsRequest,
  UserPools,
} from "@cloudentity/acp-identity";

import { getTenantId } from "../../common/api/paths";
import identityPoolsApi from "./adminIdentityPoolsApi";
import {
  useIsQueryEnabledByTenantAndPermission,
  useQueryWithTenantPermissionCheck,
  useQueryWithWorkspacePermissionCheck,
  withQueryError,
} from "./queryUtils";

const LIST_POOLS_QUERY = "LIST_POOLS_QUERY";
const GET_POOL_QUERY = "GET_POOL_QUERY";
const GET_POOL_QUERY_WITHOUT_NOTIFICATION = "GET_POOL_QUERY_WITHOUT_NOTIFICATION";
const LIST_WORKSPACE_POOLS_QUERY = "LIST_WORKSPACE_POOLS_QUERY";
const LIST_USER_WORKSPACE_POOLS_QUERY = "LIST_USER_WORKSPACE_POOLS_QUERY";
const CHECK_POOL_PERMISSIONS = "CHECK_POOL_PERMISSIONS";

export const listPoolsQueryKey = (tid: string, limit?: number) =>
  limit !== undefined ? [LIST_POOLS_QUERY, tid, limit] : [LIST_POOLS_QUERY, tid];
export const listPoolsInfiniteQueryKey = (tid: string) => [LIST_POOLS_QUERY, tid, "infinite"];
export const listWorkspacePoolsQueryKey = (wid: string, limit?: number) =>
  limit !== undefined
    ? [LIST_WORKSPACE_POOLS_QUERY, wid, limit]
    : [LIST_WORKSPACE_POOLS_QUERY, wid];

export const listWorkspacePoolsInfiniteQueryKey = (wid: string) => [
  LIST_WORKSPACE_POOLS_QUERY,
  wid,
  "infinite",
];

export const listUserWorkspacePoolsQueryKey = (wid: string, limit?: number) =>
  limit !== undefined
    ? [LIST_USER_WORKSPACE_POOLS_QUERY, wid, limit]
    : [LIST_USER_WORKSPACE_POOLS_QUERY, wid];

export const getPoolQueryKey = (tid: string, ipId: string) => [GET_POOL_QUERY, tid, ipId];
export const getPoolWithoutNotificationQueryKey = (tid: string, ipId: string) => [
  GET_POOL_QUERY_WITHOUT_NOTIFICATION,
  tid,
  ipId,
];
export const checkPoolPermissionsQueryKey = (ipId: string) => [CHECK_POOL_PERMISSIONS, ipId];

export const useListPools = (
  { tid, limit, ...params }: PoolsApiListPoolsRequest & { tid: string },
  options?: any
) =>
  useQueryWithTenantPermissionCheck(
    "list_identity_pools",
    [...listPoolsQueryKey(tid, limit), params],
    withQueryError<Pools>(async () => {
      const data = await identityPoolsApi.listPools({ limit, ...params });
      return data.data;
    }, "Error occurred while trying to list identity pools"),
    options
  );

export const useListWorkspacePools = (
  { wid, limit, ...params }: PoolsApiListPoolsRequest & { wid: string },
  options?
) =>
  useQueryWithWorkspacePermissionCheck<Pools>(
    wid,
    "list_identity_pools",
    [...listWorkspacePoolsQueryKey(wid, limit), params],
    withQueryError<Pools>(async () => {
      const data = await identityPoolsApi.listWorkspacePools({ wid, limit, ...params });
      return data.data;
    }, "Error occurred while trying to list workspace identity pools"),
    options
  );

export const useListPoolsInfinite = (
  { tid }: PoolsApiListPoolsRequest & { tid: string },
  options?: any
) => {
  const limit = 100;

  const enabled = useIsQueryEnabledByTenantAndPermission("list_identity_pools", options);

  const query = useInfiniteQuery<Pools>({
    queryKey: listPoolsInfiniteQueryKey(tid),
    queryFn: async ({
      pageParam,
    }: QueryFunctionContext<string[], { afterPoolId: string | undefined } | null>) => {
      const res = await identityPoolsApi.listPools({
        limit,
        afterPoolId: pageParam?.afterPoolId,
        order: "asc",
        sort: "name",
      });

      return res.data;
    },
    ...{
      ...options,
      enabled,
      getNextPageParam: lastPage => {
        if ((lastPage.pools || []).length === limit) {
          return { afterPoolId: lastPage.pools?.at(-1)?.id };
        }
        return null;
      },
    },
  });

  useFetchNextPageOnScrollToBottom(query);

  return query;
};

export const useListWorkspacePoolsInfinite = (
  { wid }: PoolsApiListPoolsRequest & { wid: string },
  options?: any
) => {
  const limit = 100;
  const query = useInfiniteQuery<Pools>({
    queryKey: listWorkspacePoolsInfiniteQueryKey(wid),
    queryFn: async ({
      pageParam,
    }: QueryFunctionContext<string[], { afterPoolId: string | undefined } | null>) => {
      const res = await identityPoolsApi.listWorkspacePools({
        wid,
        limit,
        afterPoolId: pageParam?.afterPoolId,
        order: "asc",
        sort: "name",
      });

      return res.data;
    },
    ...{
      ...options,
      getNextPageParam: lastPage => {
        if ((lastPage.pools || []).length === limit) {
          return { afterPoolId: lastPage.pools?.at(-1)?.id };
        }
        return null;
      },
    },
  });

  useFetchNextPageOnScrollToBottom(query);

  return query;
};

const useFetchNextPageOnScrollToBottom = query => {
  useEffect(() => {
    const onScroll = throttle(() => {
      const distanceToBottom =
        document.documentElement.scrollHeight -
        window.innerHeight -
        document.documentElement.scrollTop;
      const isCloseToBottom = distanceToBottom / document.documentElement.scrollHeight < 0.2;

      if (isCloseToBottom && query.hasNextPage && !query.isFetchingNextPage) {
        query.fetchNextPage();
      }
    }, 300);

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [query]);
};

export const useListUserWorkspacePools = (
  { wid, limit, ...params }: PoolsApiListUserWorkspacePoolsRequest,
  options?: any
) =>
  useQuery<UserPools>({
    queryKey: [...listUserWorkspacePoolsQueryKey(wid, limit), params],
    queryFn: withQueryError<Pools>(async () => {
      const data = await identityPoolsApi.listUserWorkspacePools({ wid, limit, ...params });
      return data.data;
    }, "Error occurred while trying to list user workspace identity pools"),
    ...options,
  });

export const useGetPool = (ipId: string, options?: any) =>
  useQuery<PoolResponse>({
    queryKey: getPoolQueryKey(getTenantId(), ipId),
    queryFn: withQueryError<PoolResponse>(async () => {
      const data = await identityPoolsApi.getPool({ ipID: ipId });
      return data.data;
    }, "Error occurred while trying to get identity pool"),
    ...{ ...options, enabled: (options?.enabled ?? true) && !!ipId },
  });

export const useGetPoolWithoutNotification = (ipId: string, options?: any) =>
  useQuery({
    queryKey: getPoolWithoutNotificationQueryKey(getTenantId(), ipId),
    queryFn: () => identityPoolsApi.getPool({ ipID: ipId }),
    ...{ retry: false, retryOnMount: false, refetchOnWindowFocus: false, ...options },
  });

export const useCheckPoolPermissions = (idId: string, options?: any) =>
  useQuery<PoolPermissionsResponse>({
    queryKey: checkPoolPermissionsQueryKey(idId),
    queryFn: async () => {
      const response = await identityPoolsApi.checkPoolPermissions({ ipID: idId });
      return response.data;
    },
    ...{ retry: false, retryOnMount: false, refetchOnWindowFocus: false, ...options },
  });

import React from "react";
import { connect } from "react-redux";

import { withSnackbar } from "notistack";
import { bindActionCreators } from "redux";

import { removeSnackbar } from "./actions/actions";

class Notifier extends React.Component<any, any> {
  render() {
    const { notifications, enqueueSnackbar, removeSnackbar } = this.props;

    notifications.forEach(notification => {
      setTimeout(() => {
        enqueueSnackbar(notification.message, {
          ...{ autoHideDuration: 6000, preventDuplicate: true },
          ...notification.options,
        });

        removeSnackbar({ key: notification.key });
      });
    });

    return null;
  }
}

const mapStateToProps = store => ({
  notifications: store.notifications.notifications,
});

const mapDispatchToProps = dispatch => bindActionCreators({ removeSnackbar }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Notifier));

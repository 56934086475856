import React, { useEffect, useMemo } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { lensPath, map, over, pathOr, prop } from "ramda";

import { ServerResponse } from "@cloudentity/acp-admin";

import FormAccordion from "../../../common/components/FormAccordion";
import RouteLeavingGuard from "../../../common/components/RouteLeavingGuard";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import { validators } from "../../../common/utils/forms/validation";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import CardWithIconAndTitle from "../common/CardWithIconAndTitle";
import { CommonIdpConfig } from "./CommonIdpConfig";
import CommonIdpConfigUpper from "./CommonIdpConfigUpper";
import IdentitiesDetailsFooter from "./IdentitiesDetailsFooter";
import SSOIDPSettings from "./SSOIDPSettings";
import { amrOptions, amrToResponse, getAMRLabel } from "./amrOptions";
import { IdpUiModelOktaType, providers } from "./identities.utils";

const id = "identities-configuration-okta";

interface Props {
  provider: IdpUiModelOktaType;
  server: ServerResponse | undefined;
  updateProgress?: boolean;
  inEdit?: boolean;
  onLogoEdit?: (data: any) => void;
  customSubmit?: boolean;
  errors: any;
  onInit?: (fn: () => void) => void;
  onCancel?: () => void;
  onSubmit: (data: IdpUiModelOktaType) => void;
  onResetErrors: (err: any | null) => void;
  onDelete?: (idp: IdpUiModelOktaType) => void;
}

export default function IdentitiesConfigurationOkta({
  provider,
  server,
  updateProgress,
  inEdit,
  errors,
  onLogoEdit,
  customSubmit,
  onCancel,
  onSubmit,
  onResetErrors,
  onInit,
  onDelete,
}: Props) {
  const data = useMemo(
    () => over(lensPath(["settings", "scopes"]), scopes => scopes || [], provider),
    [provider]
  );

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(server?.id);

  const formFactory = useFormFactory({
    id,
    data,
    progress: updateProgress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit,
  });

  const useOrgAuthorizationServer = formFactory.watch("settings.use_org_authorization_server");

  const submitFn = (provider, data) => ({ ...provider, ...amrToResponse(data), ...data });

  const providerMapData = providers.find(p => p.method === "okta");

  useEffect(() => {
    onInit &&
      onInit(() =>
        formFactory.handleSubmit(data => onSubmit && onSubmit(submitFn(provider, data)))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serverSSOEnabled = server?.sso?.enabled ?? false;

  return (
    <FormContext.Provider value={formFactory.context}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Paper style={{ padding: 32 }}>
            {providerMapData && (
              <CardWithIconAndTitle
                img={providerMapData.icon}
                title={providerMapData.name}
                id={`idp-${providerMapData.name.replace(/ /g, "-")}`}
                style={{ marginBottom: 32, width: "50%" }}
              />
            )}

            <CommonIdpConfigUpper
              formFactory={formFactory}
              provider={provider}
              inEdit={inEdit}
              onLogoEdit={onLogoEdit}
              onUpdate={onSubmit}
            />
            {formFactory.createRequiredField({
              name: "settings.domain",
              label: "Domain",
              defaultValue: pathOr("", ["settings", "domain"], provider),
              rules: {
                validate: {
                  validDomain: validators.validDomain({ label: "Value" }),
                },
              },
            })}
            {formFactory.createRequiredField({
              name: "credentials.supervisor_client.client_id",
              label: "Client ID",
              defaultValue: pathOr("", ["credentials", "supervisor_client", "client_id"], provider),
              withCopy: true,
            })}
            {formFactory.createRequiredField({
              name: "credentials.supervisor_client.private_key",
              label: "Client Private Key",
              rules: {
                validate: {
                  maxLength: () => true,
                  validCert: validators.validPrivateKey({ label: "Client Private Key" }),
                },
              },
              defaultValue: pathOr(
                "",
                ["credentials", "supervisor_client", "private_key"],
                provider
              ),
              multiline: true,
              minRows: 5,
              maxRows: 5,
              placeholder: "-----BEGIN RSA PRIVATE KEY-----\n-----END RSA PRIVATE KEY-----",
              externalErrors:
                errors && errors.status_code === 422 && errors.error === "private key is not valid"
                  ? "Key is not valid"
                  : null,
              onChange: () => onResetErrors(null),
            })}
            <CommonIdpConfig id={id} inEdit={inEdit} formFactory={formFactory} data={provider} />

            {inEdit && (
              <FormAccordion title="Advanced settings" id={id}>
                {formFactory.createAutocompleteField({
                  name: "static_amr",
                  label: "Authentication Method Reference",
                  helperText: "If set overwrites AMR obtained from this authentication method",
                  defaultValue: pathOr([], ["static_amr"], provider),
                  options: map(prop("value"), amrOptions),
                  getOptionLabel: getAMRLabel,
                  multiple: true,
                })}
                {formFactory.createCheckBox({
                  name: "settings.use_org_authorization_server",
                  label: "Use Org authorization server",
                  style: { marginBottom: 12 },
                })}
                {!useOrgAuthorizationServer &&
                  formFactory.createField({
                    name: "settings.authorization_server_id",
                    label: "Authorization server ID",
                    defaultValue: pathOr("", ["settings", "authorization_server_id"], provider),
                  })}
                {formFactory.createCheckBox({
                  name: "settings.get_user_info",
                  label: "Get user info",
                })}
                {formFactory.createCheckBox({
                  name: "settings.send_login_hint",
                  label: (
                    <span>
                      Send <code>login_hint</code>
                    </span>
                  ),
                  helperText: (
                    <span>
                      Sends the entered identifier as a <code>login_hint</code> to the IDP
                    </span>
                  ),
                })}
                {serverSSOEnabled && <SSOIDPSettings formFactory={formFactory} />}
              </FormAccordion>
            )}

            <IdentitiesDetailsFooter
              customSubmit={customSubmit}
              idp={provider}
              formFactory={formFactory}
              onSubmit={data => onSubmit && onSubmit(submitFn(provider, data))}
              onCancel={onCancel}
              onDelete={onDelete}
            />
          </Paper>
        </Grid>
        {inEdit && <RouteLeavingGuard />}
      </Grid>
    </FormContext.Provider>
  );
}

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Cloudentity API
 * Cloudentity Root API
 *
 * The version of the OpenAPI document: latest
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerResponseTypesEnum = exports.ServerProfileEnum = exports.ServerKeyTypeEnum = exports.ServerGrantTypesEnum = exports.ServerAccessTokenStrategyEnum = exports.ScriptExecutionPointTypeEnum = exports.SMSSettingsProviderEnum = exports.SAMLV2SettingsSigningMethodEnum = exports.SAMLV2SettingsNameIdFormatEnum = exports.RoleSubjectTypeEnum = exports.RoleResourceTypeEnum = exports.RoleRoleEnum = exports.Rfc6902PatchOperationOpEnum = exports.PayloadSettingsFormatEnum = exports.OrganizationResponseSubjectFormatEnum = exports.OIDCSettingsAuthenticationMethodEnum = exports.MFAMethodMechanismEnum = exports.LicenseLicenseTypeEnum = exports.JITProvisioningAdminRoleTypeEnum = exports.IDPDiscoveryDiscoveryModeEnum = exports.GatewayAPIApiTypeEnum = exports.FDXConsentRevocationReasonEnum = exports.FDXConsentRevocationInitiatorEnum = exports.EmailSettingsProviderEnum = exports.CustomAppTypeEnum = exports.ClientUserinfoSignedResponseAlgEnum = exports.ClientTokenEndpointAuthSigningAlgEnum = exports.ClientTokenEndpointAuthMethodEnum = exports.ClientSubjectTypeEnum = exports.ClientRevocationEndpointAuthMethodEnum = exports.ClientResponseTypesEnum = exports.ClientRequestObjectSigningAlgEnum = exports.ClientRequestObjectEncryptionEncEnum = exports.ClientRequestObjectEncryptionAlgEnum = exports.ClientIntrospectionEndpointAuthMethodEnum = exports.ClientIdTokenSignedResponseAlgEnum = exports.ClientIdTokenEncryptedResponseEncEnum = exports.ClientIdTokenEncryptedResponseAlgEnum = exports.ClientGrantTypesEnum = exports.ClientClientTypeEnum = exports.ClientClientStatusEnum = exports.ClientAuthorizationEncryptedResponseEncEnum = exports.ClientAuthorizationEncryptedResponseAlgEnum = exports.ClientApplicationTypesEnum = exports.CDRArrangementSpecVersionEnum = exports.CDRArrangementSharingTypeEnum = exports.AuditEventEventSubjectEnum = exports.AuditEventActionEnum = exports.AdvancedConfigurationDisallowedResponseModesEnum = exports.APIApiTypeEnum = void 0;
exports.WorkspacesApi = exports.WorkspacesApiFactory = exports.WorkspacesApiFp = exports.WorkspacesApiAxiosParamCreator = exports.TenantsApi = exports.TenantsApiFactory = exports.TenantsApiFp = exports.TenantsApiAxiosParamCreator = exports.SecurityApi = exports.SecurityApiFactory = exports.SecurityApiFp = exports.SecurityApiAxiosParamCreator = exports.LimitsApi = exports.LimitsApiFactory = exports.LimitsApiFp = exports.LimitsApiAxiosParamCreator = exports.FeaturesApi = exports.FeaturesApiFactory = exports.FeaturesApiFp = exports.FeaturesApiAxiosParamCreator = exports.ConfigurationApi = exports.ConfigurationApiFactory = exports.ConfigurationApiFp = exports.ConfigurationApiAxiosParamCreator = exports.WorkspaceResponseTypeEnum = exports.WorkspaceResponseSubjectFormatEnum = exports.WorkspaceResponseProfileEnum = exports.ServiceWithScopesAndAPIsAndAuthorizationDetailsTypeEnum = exports.ServerDumpTypeEnum = exports.ServerDumpTokenEndpointAuthnMethodsEnum = exports.ServerDumpTokenEndpointAuthMethodsEnum = exports.ServerDumpSubjectIdentifierTypesEnum = exports.ServerDumpSubjectFormatEnum = exports.ServerDumpResponseTypesEnum = exports.ServerDumpProfileEnum = exports.ServerDumpKeyTypeEnum = exports.ServerDumpGrantTypesEnum = exports.ServerDumpAccessTokenStrategyEnum = exports.ServerTypeEnum = exports.ServerTokenEndpointAuthnMethodsEnum = exports.ServerTokenEndpointAuthMethodsEnum = exports.ServerSubjectIdentifierTypesEnum = exports.ServerSubjectFormatEnum = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
    * @export
    * @enum {string}
    */
var APIApiTypeEnum;
(function (APIApiTypeEnum) {
    APIApiTypeEnum["Rest"] = "rest";
    APIApiTypeEnum["Graphql"] = "graphql";
})(APIApiTypeEnum || (exports.APIApiTypeEnum = APIApiTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AdvancedConfigurationDisallowedResponseModesEnum;
(function (AdvancedConfigurationDisallowedResponseModesEnum) {
    AdvancedConfigurationDisallowedResponseModesEnum["Query"] = "query";
    AdvancedConfigurationDisallowedResponseModesEnum["Fragment"] = "fragment";
    AdvancedConfigurationDisallowedResponseModesEnum["FormPost"] = "form_post";
    AdvancedConfigurationDisallowedResponseModesEnum["QueryJwt"] = "query.jwt";
    AdvancedConfigurationDisallowedResponseModesEnum["FragmentJwt"] = "fragment.jwt";
    AdvancedConfigurationDisallowedResponseModesEnum["FormPostJwt"] = "form_post.jwt";
    AdvancedConfigurationDisallowedResponseModesEnum["Jwt"] = "jwt";
})(AdvancedConfigurationDisallowedResponseModesEnum || (exports.AdvancedConfigurationDisallowedResponseModesEnum = AdvancedConfigurationDisallowedResponseModesEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AuditEventActionEnum;
(function (AuditEventActionEnum) {
    AuditEventActionEnum["Authenticated"] = "authenticated";
    AuditEventActionEnum["Challenged"] = "challenged";
    AuditEventActionEnum["Authorized"] = "authorized";
    AuditEventActionEnum["Unauthorized"] = "unauthorized";
    AuditEventActionEnum["Created"] = "created";
    AuditEventActionEnum["Updated"] = "updated";
    AuditEventActionEnum["Deleted"] = "deleted";
    AuditEventActionEnum["Generated"] = "generated";
    AuditEventActionEnum["Requested"] = "requested";
    AuditEventActionEnum["Confirmed"] = "confirmed";
    AuditEventActionEnum["Accepted"] = "accepted";
    AuditEventActionEnum["Rejected"] = "rejected";
    AuditEventActionEnum["Revoked"] = "revoked";
    AuditEventActionEnum["Notified"] = "notified";
    AuditEventActionEnum["Issued"] = "issued";
    AuditEventActionEnum["Denied"] = "denied";
    AuditEventActionEnum["Granted"] = "granted";
    AuditEventActionEnum["Attempted"] = "attempted";
    AuditEventActionEnum["Failed"] = "failed";
    AuditEventActionEnum["Succeeded"] = "succeeded";
    AuditEventActionEnum["Sent"] = "sent";
    AuditEventActionEnum["NotSent"] = "not_sent";
    AuditEventActionEnum["Executed"] = "executed";
})(AuditEventActionEnum || (exports.AuditEventActionEnum = AuditEventActionEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AuditEventEventSubjectEnum;
(function (AuditEventEventSubjectEnum) {
    AuditEventEventSubjectEnum["Request"] = "request";
    AuditEventEventSubjectEnum["GatewayRequest"] = "gateway_request";
    AuditEventEventSubjectEnum["GatewayPolicy"] = "gateway_policy";
    AuditEventEventSubjectEnum["Policy"] = "policy";
    AuditEventEventSubjectEnum["Client"] = "client";
    AuditEventEventSubjectEnum["Credential"] = "credential";
    AuditEventEventSubjectEnum["Login"] = "login";
    AuditEventEventSubjectEnum["PostAuthn"] = "post_authn";
    AuditEventEventSubjectEnum["Recovery"] = "recovery";
    AuditEventEventSubjectEnum["Consent"] = "consent";
    AuditEventEventSubjectEnum["ClientConsents"] = "client_consents";
    AuditEventEventSubjectEnum["CustomerConsents"] = "customer_consents";
    AuditEventEventSubjectEnum["AuthorizationCode"] = "authorization_code";
    AuditEventEventSubjectEnum["AccessToken"] = "access_token";
    AuditEventEventSubjectEnum["SamlAssertion"] = "saml_assertion";
    AuditEventEventSubjectEnum["Scopes"] = "scopes";
    AuditEventEventSubjectEnum["Claims"] = "claims";
    AuditEventEventSubjectEnum["Otp"] = "otp";
    AuditEventEventSubjectEnum["User"] = "user";
    AuditEventEventSubjectEnum["Selfuser"] = "selfuser";
    AuditEventEventSubjectEnum["Schema"] = "schema";
    AuditEventEventSubjectEnum["Pool"] = "pool";
    AuditEventEventSubjectEnum["Password"] = "password";
    AuditEventEventSubjectEnum["Bruteforce"] = "bruteforce";
    AuditEventEventSubjectEnum["Dcr"] = "dcr";
    AuditEventEventSubjectEnum["Script"] = "script";
    AuditEventEventSubjectEnum["Role"] = "role";
    AuditEventEventSubjectEnum["Task"] = "task";
    AuditEventEventSubjectEnum["Jit"] = "jit";
    AuditEventEventSubjectEnum["Tokens"] = "tokens";
    AuditEventEventSubjectEnum["Service"] = "service";
    AuditEventEventSubjectEnum["Server"] = "server";
    AuditEventEventSubjectEnum["Import"] = "import";
    AuditEventEventSubjectEnum["Organization"] = "organization";
    AuditEventEventSubjectEnum["OtpInspect"] = "otp_inspect";
})(AuditEventEventSubjectEnum || (exports.AuditEventEventSubjectEnum = AuditEventEventSubjectEnum = {}));
/**
    * @export
    * @enum {string}
    */
var CDRArrangementSharingTypeEnum;
(function (CDRArrangementSharingTypeEnum) {
    CDRArrangementSharingTypeEnum["OneTime"] = "one_time";
    CDRArrangementSharingTypeEnum["OneTimeWithRefreshToken"] = "one_time_with_refresh_token";
    CDRArrangementSharingTypeEnum["Reusable"] = "reusable";
})(CDRArrangementSharingTypeEnum || (exports.CDRArrangementSharingTypeEnum = CDRArrangementSharingTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var CDRArrangementSpecVersionEnum;
(function (CDRArrangementSpecVersionEnum) {
    CDRArrangementSpecVersionEnum["V1"] = "v1";
})(CDRArrangementSpecVersionEnum || (exports.CDRArrangementSpecVersionEnum = CDRArrangementSpecVersionEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientApplicationTypesEnum;
(function (ClientApplicationTypesEnum) {
    ClientApplicationTypesEnum["SinglePage"] = "single_page";
    ClientApplicationTypesEnum["ServerWeb"] = "server_web";
    ClientApplicationTypesEnum["MobileDesktop"] = "mobile_desktop";
    ClientApplicationTypesEnum["Service"] = "service";
    ClientApplicationTypesEnum["Legacy"] = "legacy";
    ClientApplicationTypesEnum["Dcr"] = "dcr";
})(ClientApplicationTypesEnum || (exports.ClientApplicationTypesEnum = ClientApplicationTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientAuthorizationEncryptedResponseAlgEnum;
(function (ClientAuthorizationEncryptedResponseAlgEnum) {
    ClientAuthorizationEncryptedResponseAlgEnum["Oaep"] = "RSA-OAEP";
    ClientAuthorizationEncryptedResponseAlgEnum["Oaep256"] = "RSA-OAEP-256";
})(ClientAuthorizationEncryptedResponseAlgEnum || (exports.ClientAuthorizationEncryptedResponseAlgEnum = ClientAuthorizationEncryptedResponseAlgEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientAuthorizationEncryptedResponseEncEnum;
(function (ClientAuthorizationEncryptedResponseEncEnum) {
    ClientAuthorizationEncryptedResponseEncEnum["A256Gcm"] = "A256GCM";
    ClientAuthorizationEncryptedResponseEncEnum["A128CbcHs256"] = "A128CBC-HS256";
})(ClientAuthorizationEncryptedResponseEncEnum || (exports.ClientAuthorizationEncryptedResponseEncEnum = ClientAuthorizationEncryptedResponseEncEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientClientStatusEnum;
(function (ClientClientStatusEnum) {
    ClientClientStatusEnum["Active"] = "active";
    ClientClientStatusEnum["Inactive"] = "inactive";
})(ClientClientStatusEnum || (exports.ClientClientStatusEnum = ClientClientStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientClientTypeEnum;
(function (ClientClientTypeEnum) {
    ClientClientTypeEnum["Oauth2"] = "oauth2";
    ClientClientTypeEnum["Saml"] = "saml";
})(ClientClientTypeEnum || (exports.ClientClientTypeEnum = ClientClientTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientGrantTypesEnum;
(function (ClientGrantTypesEnum) {
    ClientGrantTypesEnum["AuthorizationCode"] = "authorization_code";
    ClientGrantTypesEnum["Implicit"] = "implicit";
    ClientGrantTypesEnum["ClientCredentials"] = "client_credentials";
    ClientGrantTypesEnum["RefreshToken"] = "refresh_token";
    ClientGrantTypesEnum["Password"] = "password";
    ClientGrantTypesEnum["UrnietfparamsoauthgrantTypejwtBearer"] = "urn:ietf:params:oauth:grant-type:jwt-bearer";
    ClientGrantTypesEnum["UrnopenidparamsgrantTypeciba"] = "urn:openid:params:grant-type:ciba";
    ClientGrantTypesEnum["UrnietfparamsoauthgrantTypetokenExchange"] = "urn:ietf:params:oauth:grant-type:token-exchange";
    ClientGrantTypesEnum["UrnietfparamsoauthgrantTypedeviceCode"] = "urn:ietf:params:oauth:grant-type:device_code";
})(ClientGrantTypesEnum || (exports.ClientGrantTypesEnum = ClientGrantTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientIdTokenEncryptedResponseAlgEnum;
(function (ClientIdTokenEncryptedResponseAlgEnum) {
    ClientIdTokenEncryptedResponseAlgEnum["Oaep"] = "RSA-OAEP";
    ClientIdTokenEncryptedResponseAlgEnum["Oaep256"] = "RSA-OAEP-256";
})(ClientIdTokenEncryptedResponseAlgEnum || (exports.ClientIdTokenEncryptedResponseAlgEnum = ClientIdTokenEncryptedResponseAlgEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientIdTokenEncryptedResponseEncEnum;
(function (ClientIdTokenEncryptedResponseEncEnum) {
    ClientIdTokenEncryptedResponseEncEnum["A256Gcm"] = "A256GCM";
    ClientIdTokenEncryptedResponseEncEnum["A128CbcHs256"] = "A128CBC-HS256";
})(ClientIdTokenEncryptedResponseEncEnum || (exports.ClientIdTokenEncryptedResponseEncEnum = ClientIdTokenEncryptedResponseEncEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientIdTokenSignedResponseAlgEnum;
(function (ClientIdTokenSignedResponseAlgEnum) {
    ClientIdTokenSignedResponseAlgEnum["Rs256"] = "RS256";
    ClientIdTokenSignedResponseAlgEnum["Es256"] = "ES256";
    ClientIdTokenSignedResponseAlgEnum["Ps256"] = "PS256";
})(ClientIdTokenSignedResponseAlgEnum || (exports.ClientIdTokenSignedResponseAlgEnum = ClientIdTokenSignedResponseAlgEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientIntrospectionEndpointAuthMethodEnum;
(function (ClientIntrospectionEndpointAuthMethodEnum) {
    ClientIntrospectionEndpointAuthMethodEnum["ClientSecretBasic"] = "client_secret_basic";
    ClientIntrospectionEndpointAuthMethodEnum["ClientSecretPost"] = "client_secret_post";
    ClientIntrospectionEndpointAuthMethodEnum["ClientSecretJwt"] = "client_secret_jwt";
    ClientIntrospectionEndpointAuthMethodEnum["PrivateKeyJwt"] = "private_key_jwt";
    ClientIntrospectionEndpointAuthMethodEnum["SelfSignedTlsClientAuth"] = "self_signed_tls_client_auth";
    ClientIntrospectionEndpointAuthMethodEnum["TlsClientAuth"] = "tls_client_auth";
    ClientIntrospectionEndpointAuthMethodEnum["None"] = "none";
})(ClientIntrospectionEndpointAuthMethodEnum || (exports.ClientIntrospectionEndpointAuthMethodEnum = ClientIntrospectionEndpointAuthMethodEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientRequestObjectEncryptionAlgEnum;
(function (ClientRequestObjectEncryptionAlgEnum) {
    ClientRequestObjectEncryptionAlgEnum["Oaep"] = "RSA-OAEP";
    ClientRequestObjectEncryptionAlgEnum["Oaep256"] = "RSA-OAEP-256";
})(ClientRequestObjectEncryptionAlgEnum || (exports.ClientRequestObjectEncryptionAlgEnum = ClientRequestObjectEncryptionAlgEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientRequestObjectEncryptionEncEnum;
(function (ClientRequestObjectEncryptionEncEnum) {
    ClientRequestObjectEncryptionEncEnum["A256Gcm"] = "A256GCM";
    ClientRequestObjectEncryptionEncEnum["A128CbcHs256"] = "A128CBC-HS256";
})(ClientRequestObjectEncryptionEncEnum || (exports.ClientRequestObjectEncryptionEncEnum = ClientRequestObjectEncryptionEncEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientRequestObjectSigningAlgEnum;
(function (ClientRequestObjectSigningAlgEnum) {
    ClientRequestObjectSigningAlgEnum["Any"] = "any";
    ClientRequestObjectSigningAlgEnum["None"] = "none";
    ClientRequestObjectSigningAlgEnum["Rs256"] = "RS256";
    ClientRequestObjectSigningAlgEnum["Es256"] = "ES256";
    ClientRequestObjectSigningAlgEnum["Ps256"] = "PS256";
})(ClientRequestObjectSigningAlgEnum || (exports.ClientRequestObjectSigningAlgEnum = ClientRequestObjectSigningAlgEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientResponseTypesEnum;
(function (ClientResponseTypesEnum) {
    ClientResponseTypesEnum["Token"] = "token";
    ClientResponseTypesEnum["IdToken"] = "id_token";
    ClientResponseTypesEnum["Code"] = "code";
    ClientResponseTypesEnum["CodeIdToken"] = "code id_token";
    ClientResponseTypesEnum["TokenIdToken"] = "token id_token";
    ClientResponseTypesEnum["TokenCode"] = "token code";
    ClientResponseTypesEnum["TokenIdTokenCode"] = "token id_token code";
    ClientResponseTypesEnum["None"] = "none";
})(ClientResponseTypesEnum || (exports.ClientResponseTypesEnum = ClientResponseTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientRevocationEndpointAuthMethodEnum;
(function (ClientRevocationEndpointAuthMethodEnum) {
    ClientRevocationEndpointAuthMethodEnum["ClientSecretBasic"] = "client_secret_basic";
    ClientRevocationEndpointAuthMethodEnum["ClientSecretPost"] = "client_secret_post";
    ClientRevocationEndpointAuthMethodEnum["ClientSecretJwt"] = "client_secret_jwt";
    ClientRevocationEndpointAuthMethodEnum["PrivateKeyJwt"] = "private_key_jwt";
    ClientRevocationEndpointAuthMethodEnum["SelfSignedTlsClientAuth"] = "self_signed_tls_client_auth";
    ClientRevocationEndpointAuthMethodEnum["TlsClientAuth"] = "tls_client_auth";
    ClientRevocationEndpointAuthMethodEnum["None"] = "none";
})(ClientRevocationEndpointAuthMethodEnum || (exports.ClientRevocationEndpointAuthMethodEnum = ClientRevocationEndpointAuthMethodEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientSubjectTypeEnum;
(function (ClientSubjectTypeEnum) {
    ClientSubjectTypeEnum["Public"] = "public";
    ClientSubjectTypeEnum["Pairwise"] = "pairwise";
})(ClientSubjectTypeEnum || (exports.ClientSubjectTypeEnum = ClientSubjectTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientTokenEndpointAuthMethodEnum;
(function (ClientTokenEndpointAuthMethodEnum) {
    ClientTokenEndpointAuthMethodEnum["ClientSecretBasic"] = "client_secret_basic";
    ClientTokenEndpointAuthMethodEnum["ClientSecretPost"] = "client_secret_post";
    ClientTokenEndpointAuthMethodEnum["ClientSecretJwt"] = "client_secret_jwt";
    ClientTokenEndpointAuthMethodEnum["PrivateKeyJwt"] = "private_key_jwt";
    ClientTokenEndpointAuthMethodEnum["SelfSignedTlsClientAuth"] = "self_signed_tls_client_auth";
    ClientTokenEndpointAuthMethodEnum["TlsClientAuth"] = "tls_client_auth";
    ClientTokenEndpointAuthMethodEnum["None"] = "none";
    ClientTokenEndpointAuthMethodEnum["Unspecified"] = "unspecified";
})(ClientTokenEndpointAuthMethodEnum || (exports.ClientTokenEndpointAuthMethodEnum = ClientTokenEndpointAuthMethodEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientTokenEndpointAuthSigningAlgEnum;
(function (ClientTokenEndpointAuthSigningAlgEnum) {
    ClientTokenEndpointAuthSigningAlgEnum["Rs256"] = "RS256";
    ClientTokenEndpointAuthSigningAlgEnum["Es256"] = "ES256";
    ClientTokenEndpointAuthSigningAlgEnum["Ps256"] = "PS256";
    ClientTokenEndpointAuthSigningAlgEnum["Hs256"] = "HS256";
    ClientTokenEndpointAuthSigningAlgEnum["Empty"] = "";
})(ClientTokenEndpointAuthSigningAlgEnum || (exports.ClientTokenEndpointAuthSigningAlgEnum = ClientTokenEndpointAuthSigningAlgEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientUserinfoSignedResponseAlgEnum;
(function (ClientUserinfoSignedResponseAlgEnum) {
    ClientUserinfoSignedResponseAlgEnum["None"] = "none";
    ClientUserinfoSignedResponseAlgEnum["Rs256"] = "RS256";
    ClientUserinfoSignedResponseAlgEnum["Es256"] = "ES256";
})(ClientUserinfoSignedResponseAlgEnum || (exports.ClientUserinfoSignedResponseAlgEnum = ClientUserinfoSignedResponseAlgEnum = {}));
/**
    * @export
    * @enum {string}
    */
var CustomAppTypeEnum;
(function (CustomAppTypeEnum) {
    CustomAppTypeEnum["PostAuthn"] = "post-authn";
})(CustomAppTypeEnum || (exports.CustomAppTypeEnum = CustomAppTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var EmailSettingsProviderEnum;
(function (EmailSettingsProviderEnum) {
    EmailSettingsProviderEnum["Smtp"] = "smtp";
    EmailSettingsProviderEnum["Embedded"] = "embedded";
})(EmailSettingsProviderEnum || (exports.EmailSettingsProviderEnum = EmailSettingsProviderEnum = {}));
/**
    * @export
    * @enum {string}
    */
var FDXConsentRevocationInitiatorEnum;
(function (FDXConsentRevocationInitiatorEnum) {
    FDXConsentRevocationInitiatorEnum["DataAccessPlatform"] = "DATA_ACCESS_PLATFORM";
    FDXConsentRevocationInitiatorEnum["DataProvider"] = "DATA_PROVIDER";
    FDXConsentRevocationInitiatorEnum["DataRecipient"] = "DATA_RECIPIENT";
    FDXConsentRevocationInitiatorEnum["Individual"] = "INDIVIDUAL";
    FDXConsentRevocationInitiatorEnum["Merchant"] = "MERCHANT";
    FDXConsentRevocationInitiatorEnum["Vendor"] = "VENDOR";
})(FDXConsentRevocationInitiatorEnum || (exports.FDXConsentRevocationInitiatorEnum = FDXConsentRevocationInitiatorEnum = {}));
/**
    * @export
    * @enum {string}
    */
var FDXConsentRevocationReasonEnum;
(function (FDXConsentRevocationReasonEnum) {
    FDXConsentRevocationReasonEnum["BusinessRule"] = "BUSINESS_RULE";
    FDXConsentRevocationReasonEnum["UserAction"] = "USER_ACTION";
})(FDXConsentRevocationReasonEnum || (exports.FDXConsentRevocationReasonEnum = FDXConsentRevocationReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
var GatewayAPIApiTypeEnum;
(function (GatewayAPIApiTypeEnum) {
    GatewayAPIApiTypeEnum["Rest"] = "rest";
    GatewayAPIApiTypeEnum["Graphql"] = "graphql";
})(GatewayAPIApiTypeEnum || (exports.GatewayAPIApiTypeEnum = GatewayAPIApiTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var IDPDiscoveryDiscoveryModeEnum;
(function (IDPDiscoveryDiscoveryModeEnum) {
    IDPDiscoveryDiscoveryModeEnum["DomainMatching"] = "domain_matching";
    IDPDiscoveryDiscoveryModeEnum["ScriptExecution"] = "script_execution";
})(IDPDiscoveryDiscoveryModeEnum || (exports.IDPDiscoveryDiscoveryModeEnum = IDPDiscoveryDiscoveryModeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var JITProvisioningAdminRoleTypeEnum;
(function (JITProvisioningAdminRoleTypeEnum) {
    JITProvisioningAdminRoleTypeEnum["Admin"] = "admin";
    JITProvisioningAdminRoleTypeEnum["BusinessAdmin"] = "business_admin";
    JITProvisioningAdminRoleTypeEnum["Auditor"] = "auditor";
    JITProvisioningAdminRoleTypeEnum["Member"] = "member";
})(JITProvisioningAdminRoleTypeEnum || (exports.JITProvisioningAdminRoleTypeEnum = JITProvisioningAdminRoleTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var LicenseLicenseTypeEnum;
(function (LicenseLicenseTypeEnum) {
    LicenseLicenseTypeEnum["Trial"] = "trial";
    LicenseLicenseTypeEnum["Enterprise"] = "enterprise";
})(LicenseLicenseTypeEnum || (exports.LicenseLicenseTypeEnum = LicenseLicenseTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var MFAMethodMechanismEnum;
(function (MFAMethodMechanismEnum) {
    MFAMethodMechanismEnum["Sms"] = "sms";
    MFAMethodMechanismEnum["Email"] = "email";
    MFAMethodMechanismEnum["Arculix"] = "arculix";
})(MFAMethodMechanismEnum || (exports.MFAMethodMechanismEnum = MFAMethodMechanismEnum = {}));
/**
    * @export
    * @enum {string}
    */
var OIDCSettingsAuthenticationMethodEnum;
(function (OIDCSettingsAuthenticationMethodEnum) {
    OIDCSettingsAuthenticationMethodEnum["ClientSecret"] = "client_secret";
    OIDCSettingsAuthenticationMethodEnum["PrivateKeyJwt"] = "private_key_jwt";
})(OIDCSettingsAuthenticationMethodEnum || (exports.OIDCSettingsAuthenticationMethodEnum = OIDCSettingsAuthenticationMethodEnum = {}));
/**
    * @export
    * @enum {string}
    */
var OrganizationResponseSubjectFormatEnum;
(function (OrganizationResponseSubjectFormatEnum) {
    OrganizationResponseSubjectFormatEnum["Hash"] = "hash";
    OrganizationResponseSubjectFormatEnum["Legacy"] = "legacy";
})(OrganizationResponseSubjectFormatEnum || (exports.OrganizationResponseSubjectFormatEnum = OrganizationResponseSubjectFormatEnum = {}));
/**
    * @export
    * @enum {string}
    */
var PayloadSettingsFormatEnum;
(function (PayloadSettingsFormatEnum) {
    PayloadSettingsFormatEnum["Json"] = "json";
    PayloadSettingsFormatEnum["Jws"] = "jws";
})(PayloadSettingsFormatEnum || (exports.PayloadSettingsFormatEnum = PayloadSettingsFormatEnum = {}));
/**
    * @export
    * @enum {string}
    */
var Rfc6902PatchOperationOpEnum;
(function (Rfc6902PatchOperationOpEnum) {
    Rfc6902PatchOperationOpEnum["Add"] = "add";
    Rfc6902PatchOperationOpEnum["Remove"] = "remove";
    Rfc6902PatchOperationOpEnum["Replace"] = "replace";
    Rfc6902PatchOperationOpEnum["Move"] = "move";
    Rfc6902PatchOperationOpEnum["Copy"] = "copy";
    Rfc6902PatchOperationOpEnum["Test"] = "test";
})(Rfc6902PatchOperationOpEnum || (exports.Rfc6902PatchOperationOpEnum = Rfc6902PatchOperationOpEnum = {}));
/**
    * @export
    * @enum {string}
    */
var RoleRoleEnum;
(function (RoleRoleEnum) {
    RoleRoleEnum["Admin"] = "admin";
    RoleRoleEnum["BusinessAdmin"] = "business_admin";
    RoleRoleEnum["Auditor"] = "auditor";
    RoleRoleEnum["Manager"] = "manager";
    RoleRoleEnum["UserManager"] = "user_manager";
    RoleRoleEnum["Member"] = "member";
})(RoleRoleEnum || (exports.RoleRoleEnum = RoleRoleEnum = {}));
/**
    * @export
    * @enum {string}
    */
var RoleResourceTypeEnum;
(function (RoleResourceTypeEnum) {
    RoleResourceTypeEnum["Tenant"] = "tenant";
    RoleResourceTypeEnum["Workspace"] = "workspace";
    RoleResourceTypeEnum["IdentityPool"] = "identity_pool";
})(RoleResourceTypeEnum || (exports.RoleResourceTypeEnum = RoleResourceTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var RoleSubjectTypeEnum;
(function (RoleSubjectTypeEnum) {
    RoleSubjectTypeEnum["IdentityPoolUser"] = "identity_pool_user";
    RoleSubjectTypeEnum["Idp"] = "idp";
})(RoleSubjectTypeEnum || (exports.RoleSubjectTypeEnum = RoleSubjectTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SAMLV2SettingsNameIdFormatEnum;
(function (SAMLV2SettingsNameIdFormatEnum) {
    SAMLV2SettingsNameIdFormatEnum["EmailAddress"] = "urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress";
    SAMLV2SettingsNameIdFormatEnum["Unspecified"] = "urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified";
    SAMLV2SettingsNameIdFormatEnum["Transient"] = "urn:oasis:names:tc:SAML:1.1:nameid-format:transient";
    SAMLV2SettingsNameIdFormatEnum["Persistent"] = "urn:oasis:names:tc:SAML:1.1:nameid-format:persistent";
})(SAMLV2SettingsNameIdFormatEnum || (exports.SAMLV2SettingsNameIdFormatEnum = SAMLV2SettingsNameIdFormatEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SAMLV2SettingsSigningMethodEnum;
(function (SAMLV2SettingsSigningMethodEnum) {
    SAMLV2SettingsSigningMethodEnum["_256"] = "rsa-sha-256";
    SAMLV2SettingsSigningMethodEnum["_512"] = "rsa-sha-512";
    SAMLV2SettingsSigningMethodEnum["_1"] = "rsa-sha-1";
})(SAMLV2SettingsSigningMethodEnum || (exports.SAMLV2SettingsSigningMethodEnum = SAMLV2SettingsSigningMethodEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SMSSettingsProviderEnum;
(function (SMSSettingsProviderEnum) {
    SMSSettingsProviderEnum["Twilio"] = "twilio";
    SMSSettingsProviderEnum["Embedded"] = "embedded";
})(SMSSettingsProviderEnum || (exports.SMSSettingsProviderEnum = SMSSettingsProviderEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ScriptExecutionPointTypeEnum;
(function (ScriptExecutionPointTypeEnum) {
    ScriptExecutionPointTypeEnum["PostAuthnCtx"] = "post_authn_ctx";
    ScriptExecutionPointTypeEnum["AllowedIdpIds"] = "allowed_idp_ids";
    ScriptExecutionPointTypeEnum["TokenMinting"] = "token_minting";
    ScriptExecutionPointTypeEnum["ClientTokenMinting"] = "client_token_minting";
})(ScriptExecutionPointTypeEnum || (exports.ScriptExecutionPointTypeEnum = ScriptExecutionPointTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerAccessTokenStrategyEnum;
(function (ServerAccessTokenStrategyEnum) {
    ServerAccessTokenStrategyEnum["Jwt"] = "jwt";
    ServerAccessTokenStrategyEnum["Opaque"] = "opaque";
})(ServerAccessTokenStrategyEnum || (exports.ServerAccessTokenStrategyEnum = ServerAccessTokenStrategyEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerGrantTypesEnum;
(function (ServerGrantTypesEnum) {
    ServerGrantTypesEnum["AuthorizationCode"] = "authorization_code";
    ServerGrantTypesEnum["Implicit"] = "implicit";
    ServerGrantTypesEnum["ClientCredentials"] = "client_credentials";
    ServerGrantTypesEnum["RefreshToken"] = "refresh_token";
    ServerGrantTypesEnum["Password"] = "password";
    ServerGrantTypesEnum["UrnietfparamsoauthgrantTypejwtBearer"] = "urn:ietf:params:oauth:grant-type:jwt-bearer";
    ServerGrantTypesEnum["UrnopenidparamsgrantTypeciba"] = "urn:openid:params:grant-type:ciba";
    ServerGrantTypesEnum["UrnietfparamsoauthgrantTypetokenExchange"] = "urn:ietf:params:oauth:grant-type:token-exchange";
    ServerGrantTypesEnum["UrnietfparamsoauthgrantTypedeviceCode"] = "urn:ietf:params:oauth:grant-type:device_code";
})(ServerGrantTypesEnum || (exports.ServerGrantTypesEnum = ServerGrantTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerKeyTypeEnum;
(function (ServerKeyTypeEnum) {
    ServerKeyTypeEnum["Rsa"] = "rsa";
    ServerKeyTypeEnum["Ecdsa"] = "ecdsa";
    ServerKeyTypeEnum["Ps"] = "ps";
})(ServerKeyTypeEnum || (exports.ServerKeyTypeEnum = ServerKeyTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerProfileEnum;
(function (ServerProfileEnum) {
    ServerProfileEnum["Default"] = "default";
    ServerProfileEnum["Demo"] = "demo";
    ServerProfileEnum["Workforce"] = "workforce";
    ServerProfileEnum["Consumer"] = "consumer";
    ServerProfileEnum["Partners"] = "partners";
    ServerProfileEnum["ThirdParty"] = "third_party";
    ServerProfileEnum["FapiAdvanced"] = "fapi_advanced";
    ServerProfileEnum["FapiRw"] = "fapi_rw";
    ServerProfileEnum["FapiRo"] = "fapi_ro";
    ServerProfileEnum["OpenbankingUkFapiAdvanced"] = "openbanking_uk_fapi_advanced";
    ServerProfileEnum["OpenbankingUk"] = "openbanking_uk";
    ServerProfileEnum["OpenbankingBr"] = "openbanking_br";
    ServerProfileEnum["OpenbankingBrUnico"] = "openbanking_br_unico";
    ServerProfileEnum["CdrAustralia"] = "cdr_australia";
    ServerProfileEnum["CdrAustraliaFapiRw"] = "cdr_australia_fapi_rw";
    ServerProfileEnum["Fdx"] = "fdx";
    ServerProfileEnum["OpenbankingKsa"] = "openbanking_ksa";
    ServerProfileEnum["Fapi20Security"] = "fapi_20_security";
    ServerProfileEnum["Fapi20MessageSigning"] = "fapi_20_message_signing";
    ServerProfileEnum["ConnectId"] = "connect_id";
})(ServerProfileEnum || (exports.ServerProfileEnum = ServerProfileEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerResponseTypesEnum;
(function (ServerResponseTypesEnum) {
    ServerResponseTypesEnum["Token"] = "token";
    ServerResponseTypesEnum["IdToken"] = "id_token";
    ServerResponseTypesEnum["Code"] = "code";
    ServerResponseTypesEnum["CodeIdToken"] = "code id_token";
    ServerResponseTypesEnum["TokenIdToken"] = "token id_token";
    ServerResponseTypesEnum["TokenCode"] = "token code";
    ServerResponseTypesEnum["TokenIdTokenCode"] = "token id_token code";
    ServerResponseTypesEnum["None"] = "none";
})(ServerResponseTypesEnum || (exports.ServerResponseTypesEnum = ServerResponseTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerSubjectFormatEnum;
(function (ServerSubjectFormatEnum) {
    ServerSubjectFormatEnum["Hash"] = "hash";
    ServerSubjectFormatEnum["Legacy"] = "legacy";
})(ServerSubjectFormatEnum || (exports.ServerSubjectFormatEnum = ServerSubjectFormatEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerSubjectIdentifierTypesEnum;
(function (ServerSubjectIdentifierTypesEnum) {
    ServerSubjectIdentifierTypesEnum["Public"] = "public";
    ServerSubjectIdentifierTypesEnum["Pairwise"] = "pairwise";
})(ServerSubjectIdentifierTypesEnum || (exports.ServerSubjectIdentifierTypesEnum = ServerSubjectIdentifierTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerTokenEndpointAuthMethodsEnum;
(function (ServerTokenEndpointAuthMethodsEnum) {
    ServerTokenEndpointAuthMethodsEnum["ClientSecretBasic"] = "client_secret_basic";
    ServerTokenEndpointAuthMethodsEnum["ClientSecretPost"] = "client_secret_post";
    ServerTokenEndpointAuthMethodsEnum["ClientSecretJwt"] = "client_secret_jwt";
    ServerTokenEndpointAuthMethodsEnum["PrivateKeyJwt"] = "private_key_jwt";
    ServerTokenEndpointAuthMethodsEnum["SelfSignedTlsClientAuth"] = "self_signed_tls_client_auth";
    ServerTokenEndpointAuthMethodsEnum["TlsClientAuth"] = "tls_client_auth";
    ServerTokenEndpointAuthMethodsEnum["None"] = "none";
    ServerTokenEndpointAuthMethodsEnum["Unspecified"] = "unspecified";
})(ServerTokenEndpointAuthMethodsEnum || (exports.ServerTokenEndpointAuthMethodsEnum = ServerTokenEndpointAuthMethodsEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerTokenEndpointAuthnMethodsEnum;
(function (ServerTokenEndpointAuthnMethodsEnum) {
    ServerTokenEndpointAuthnMethodsEnum["ClientSecretBasic"] = "client_secret_basic";
    ServerTokenEndpointAuthnMethodsEnum["ClientSecretPost"] = "client_secret_post";
    ServerTokenEndpointAuthnMethodsEnum["ClientSecretJwt"] = "client_secret_jwt";
    ServerTokenEndpointAuthnMethodsEnum["PrivateKeyJwt"] = "private_key_jwt";
    ServerTokenEndpointAuthnMethodsEnum["SelfSignedTlsClientAuth"] = "self_signed_tls_client_auth";
    ServerTokenEndpointAuthnMethodsEnum["TlsClientAuth"] = "tls_client_auth";
    ServerTokenEndpointAuthnMethodsEnum["None"] = "none";
    ServerTokenEndpointAuthnMethodsEnum["Unspecified"] = "unspecified";
})(ServerTokenEndpointAuthnMethodsEnum || (exports.ServerTokenEndpointAuthnMethodsEnum = ServerTokenEndpointAuthnMethodsEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerTypeEnum;
(function (ServerTypeEnum) {
    ServerTypeEnum["Admin"] = "admin";
    ServerTypeEnum["Developer"] = "developer";
    ServerTypeEnum["System"] = "system";
    ServerTypeEnum["Regular"] = "regular";
    ServerTypeEnum["Organization"] = "organization";
})(ServerTypeEnum || (exports.ServerTypeEnum = ServerTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerDumpAccessTokenStrategyEnum;
(function (ServerDumpAccessTokenStrategyEnum) {
    ServerDumpAccessTokenStrategyEnum["Jwt"] = "jwt";
    ServerDumpAccessTokenStrategyEnum["Opaque"] = "opaque";
})(ServerDumpAccessTokenStrategyEnum || (exports.ServerDumpAccessTokenStrategyEnum = ServerDumpAccessTokenStrategyEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerDumpGrantTypesEnum;
(function (ServerDumpGrantTypesEnum) {
    ServerDumpGrantTypesEnum["AuthorizationCode"] = "authorization_code";
    ServerDumpGrantTypesEnum["Implicit"] = "implicit";
    ServerDumpGrantTypesEnum["ClientCredentials"] = "client_credentials";
    ServerDumpGrantTypesEnum["RefreshToken"] = "refresh_token";
    ServerDumpGrantTypesEnum["Password"] = "password";
    ServerDumpGrantTypesEnum["UrnietfparamsoauthgrantTypejwtBearer"] = "urn:ietf:params:oauth:grant-type:jwt-bearer";
    ServerDumpGrantTypesEnum["UrnopenidparamsgrantTypeciba"] = "urn:openid:params:grant-type:ciba";
    ServerDumpGrantTypesEnum["UrnietfparamsoauthgrantTypetokenExchange"] = "urn:ietf:params:oauth:grant-type:token-exchange";
    ServerDumpGrantTypesEnum["UrnietfparamsoauthgrantTypedeviceCode"] = "urn:ietf:params:oauth:grant-type:device_code";
})(ServerDumpGrantTypesEnum || (exports.ServerDumpGrantTypesEnum = ServerDumpGrantTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerDumpKeyTypeEnum;
(function (ServerDumpKeyTypeEnum) {
    ServerDumpKeyTypeEnum["Rsa"] = "rsa";
    ServerDumpKeyTypeEnum["Ecdsa"] = "ecdsa";
    ServerDumpKeyTypeEnum["Ps"] = "ps";
})(ServerDumpKeyTypeEnum || (exports.ServerDumpKeyTypeEnum = ServerDumpKeyTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerDumpProfileEnum;
(function (ServerDumpProfileEnum) {
    ServerDumpProfileEnum["Default"] = "default";
    ServerDumpProfileEnum["Demo"] = "demo";
    ServerDumpProfileEnum["Workforce"] = "workforce";
    ServerDumpProfileEnum["Consumer"] = "consumer";
    ServerDumpProfileEnum["Partners"] = "partners";
    ServerDumpProfileEnum["ThirdParty"] = "third_party";
    ServerDumpProfileEnum["FapiAdvanced"] = "fapi_advanced";
    ServerDumpProfileEnum["FapiRw"] = "fapi_rw";
    ServerDumpProfileEnum["FapiRo"] = "fapi_ro";
    ServerDumpProfileEnum["OpenbankingUkFapiAdvanced"] = "openbanking_uk_fapi_advanced";
    ServerDumpProfileEnum["OpenbankingUk"] = "openbanking_uk";
    ServerDumpProfileEnum["OpenbankingBr"] = "openbanking_br";
    ServerDumpProfileEnum["OpenbankingBrUnico"] = "openbanking_br_unico";
    ServerDumpProfileEnum["CdrAustralia"] = "cdr_australia";
    ServerDumpProfileEnum["CdrAustraliaFapiRw"] = "cdr_australia_fapi_rw";
    ServerDumpProfileEnum["Fdx"] = "fdx";
    ServerDumpProfileEnum["OpenbankingKsa"] = "openbanking_ksa";
    ServerDumpProfileEnum["Fapi20Security"] = "fapi_20_security";
    ServerDumpProfileEnum["Fapi20MessageSigning"] = "fapi_20_message_signing";
    ServerDumpProfileEnum["ConnectId"] = "connect_id";
})(ServerDumpProfileEnum || (exports.ServerDumpProfileEnum = ServerDumpProfileEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerDumpResponseTypesEnum;
(function (ServerDumpResponseTypesEnum) {
    ServerDumpResponseTypesEnum["Token"] = "token";
    ServerDumpResponseTypesEnum["IdToken"] = "id_token";
    ServerDumpResponseTypesEnum["Code"] = "code";
    ServerDumpResponseTypesEnum["CodeIdToken"] = "code id_token";
    ServerDumpResponseTypesEnum["TokenIdToken"] = "token id_token";
    ServerDumpResponseTypesEnum["TokenCode"] = "token code";
    ServerDumpResponseTypesEnum["TokenIdTokenCode"] = "token id_token code";
    ServerDumpResponseTypesEnum["None"] = "none";
})(ServerDumpResponseTypesEnum || (exports.ServerDumpResponseTypesEnum = ServerDumpResponseTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerDumpSubjectFormatEnum;
(function (ServerDumpSubjectFormatEnum) {
    ServerDumpSubjectFormatEnum["Hash"] = "hash";
    ServerDumpSubjectFormatEnum["Legacy"] = "legacy";
})(ServerDumpSubjectFormatEnum || (exports.ServerDumpSubjectFormatEnum = ServerDumpSubjectFormatEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerDumpSubjectIdentifierTypesEnum;
(function (ServerDumpSubjectIdentifierTypesEnum) {
    ServerDumpSubjectIdentifierTypesEnum["Public"] = "public";
    ServerDumpSubjectIdentifierTypesEnum["Pairwise"] = "pairwise";
})(ServerDumpSubjectIdentifierTypesEnum || (exports.ServerDumpSubjectIdentifierTypesEnum = ServerDumpSubjectIdentifierTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerDumpTokenEndpointAuthMethodsEnum;
(function (ServerDumpTokenEndpointAuthMethodsEnum) {
    ServerDumpTokenEndpointAuthMethodsEnum["ClientSecretBasic"] = "client_secret_basic";
    ServerDumpTokenEndpointAuthMethodsEnum["ClientSecretPost"] = "client_secret_post";
    ServerDumpTokenEndpointAuthMethodsEnum["ClientSecretJwt"] = "client_secret_jwt";
    ServerDumpTokenEndpointAuthMethodsEnum["PrivateKeyJwt"] = "private_key_jwt";
    ServerDumpTokenEndpointAuthMethodsEnum["SelfSignedTlsClientAuth"] = "self_signed_tls_client_auth";
    ServerDumpTokenEndpointAuthMethodsEnum["TlsClientAuth"] = "tls_client_auth";
    ServerDumpTokenEndpointAuthMethodsEnum["None"] = "none";
    ServerDumpTokenEndpointAuthMethodsEnum["Unspecified"] = "unspecified";
})(ServerDumpTokenEndpointAuthMethodsEnum || (exports.ServerDumpTokenEndpointAuthMethodsEnum = ServerDumpTokenEndpointAuthMethodsEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerDumpTokenEndpointAuthnMethodsEnum;
(function (ServerDumpTokenEndpointAuthnMethodsEnum) {
    ServerDumpTokenEndpointAuthnMethodsEnum["ClientSecretBasic"] = "client_secret_basic";
    ServerDumpTokenEndpointAuthnMethodsEnum["ClientSecretPost"] = "client_secret_post";
    ServerDumpTokenEndpointAuthnMethodsEnum["ClientSecretJwt"] = "client_secret_jwt";
    ServerDumpTokenEndpointAuthnMethodsEnum["PrivateKeyJwt"] = "private_key_jwt";
    ServerDumpTokenEndpointAuthnMethodsEnum["SelfSignedTlsClientAuth"] = "self_signed_tls_client_auth";
    ServerDumpTokenEndpointAuthnMethodsEnum["TlsClientAuth"] = "tls_client_auth";
    ServerDumpTokenEndpointAuthnMethodsEnum["None"] = "none";
    ServerDumpTokenEndpointAuthnMethodsEnum["Unspecified"] = "unspecified";
})(ServerDumpTokenEndpointAuthnMethodsEnum || (exports.ServerDumpTokenEndpointAuthnMethodsEnum = ServerDumpTokenEndpointAuthnMethodsEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServerDumpTypeEnum;
(function (ServerDumpTypeEnum) {
    ServerDumpTypeEnum["Admin"] = "admin";
    ServerDumpTypeEnum["Developer"] = "developer";
    ServerDumpTypeEnum["System"] = "system";
    ServerDumpTypeEnum["Regular"] = "regular";
    ServerDumpTypeEnum["Organization"] = "organization";
})(ServerDumpTypeEnum || (exports.ServerDumpTypeEnum = ServerDumpTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ServiceWithScopesAndAPIsAndAuthorizationDetailsTypeEnum;
(function (ServiceWithScopesAndAPIsAndAuthorizationDetailsTypeEnum) {
    ServiceWithScopesAndAPIsAndAuthorizationDetailsTypeEnum["Oauth2"] = "oauth2";
    ServiceWithScopesAndAPIsAndAuthorizationDetailsTypeEnum["Oidc"] = "oidc";
    ServiceWithScopesAndAPIsAndAuthorizationDetailsTypeEnum["System"] = "system";
    ServiceWithScopesAndAPIsAndAuthorizationDetailsTypeEnum["User"] = "user";
    ServiceWithScopesAndAPIsAndAuthorizationDetailsTypeEnum["Openbanking"] = "openbanking";
})(ServiceWithScopesAndAPIsAndAuthorizationDetailsTypeEnum || (exports.ServiceWithScopesAndAPIsAndAuthorizationDetailsTypeEnum = ServiceWithScopesAndAPIsAndAuthorizationDetailsTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var WorkspaceResponseProfileEnum;
(function (WorkspaceResponseProfileEnum) {
    WorkspaceResponseProfileEnum["Default"] = "default";
    WorkspaceResponseProfileEnum["Demo"] = "demo";
    WorkspaceResponseProfileEnum["Workforce"] = "workforce";
    WorkspaceResponseProfileEnum["Consumer"] = "consumer";
    WorkspaceResponseProfileEnum["Partners"] = "partners";
    WorkspaceResponseProfileEnum["ThirdParty"] = "third_party";
    WorkspaceResponseProfileEnum["FapiAdvanced"] = "fapi_advanced";
    WorkspaceResponseProfileEnum["FapiRw"] = "fapi_rw";
    WorkspaceResponseProfileEnum["FapiRo"] = "fapi_ro";
    WorkspaceResponseProfileEnum["OpenbankingUkFapiAdvanced"] = "openbanking_uk_fapi_advanced";
    WorkspaceResponseProfileEnum["OpenbankingUk"] = "openbanking_uk";
    WorkspaceResponseProfileEnum["OpenbankingBr"] = "openbanking_br";
    WorkspaceResponseProfileEnum["OpenbankingBrUnico"] = "openbanking_br_unico";
    WorkspaceResponseProfileEnum["CdrAustralia"] = "cdr_australia";
    WorkspaceResponseProfileEnum["CdrAustraliaFapiRw"] = "cdr_australia_fapi_rw";
    WorkspaceResponseProfileEnum["Fdx"] = "fdx";
    WorkspaceResponseProfileEnum["OpenbankingKsa"] = "openbanking_ksa";
    WorkspaceResponseProfileEnum["Fapi20Security"] = "fapi_20_security";
    WorkspaceResponseProfileEnum["Fapi20MessageSigning"] = "fapi_20_message_signing";
    WorkspaceResponseProfileEnum["ConnectId"] = "connect_id";
})(WorkspaceResponseProfileEnum || (exports.WorkspaceResponseProfileEnum = WorkspaceResponseProfileEnum = {}));
/**
    * @export
    * @enum {string}
    */
var WorkspaceResponseSubjectFormatEnum;
(function (WorkspaceResponseSubjectFormatEnum) {
    WorkspaceResponseSubjectFormatEnum["Hash"] = "hash";
    WorkspaceResponseSubjectFormatEnum["Legacy"] = "legacy";
})(WorkspaceResponseSubjectFormatEnum || (exports.WorkspaceResponseSubjectFormatEnum = WorkspaceResponseSubjectFormatEnum = {}));
/**
    * @export
    * @enum {string}
    */
var WorkspaceResponseTypeEnum;
(function (WorkspaceResponseTypeEnum) {
    WorkspaceResponseTypeEnum["Admin"] = "admin";
    WorkspaceResponseTypeEnum["Developer"] = "developer";
    WorkspaceResponseTypeEnum["System"] = "system";
    WorkspaceResponseTypeEnum["Regular"] = "regular";
    WorkspaceResponseTypeEnum["Organization"] = "organization";
})(WorkspaceResponseTypeEnum || (exports.WorkspaceResponseTypeEnum = WorkspaceResponseTypeEnum = {}));
/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
var ConfigurationApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Returns a JSON with all tenants and their configuration.  Customers that use the on-premise version of the ACP deployment can use the global tenants\' configuration export ACP REST API. The requirement to have the on-premise deployment comes from the fact, that you need to be able to access the system tenant of your deployment to be able to authenticate your client and get access token that allows you to import the tenants configuration.  By default, a system tenant is created for you automatically and a client application with the client credentials grant flow enabled is also provided. The ID of this client is `system` and the client secret can be found in your ACP configuration file.  When requesting an access token, you should provide the `manage_configuration` scope as the one you would like to request.
         * @summary Export global tenants configuration
         * @param {string} [tid] Tenant id
         * @param {string} [workspaceId] Workspace id
         * @param {boolean} [withData] Include data in export
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportConfiguration: function (tid_1, workspaceId_1, withData_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tid_1, workspaceId_1, withData_1], args_1, true), void 0, function (tid, workspaceId, withData, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/api/system/configuration";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication setup required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "setup", [], configuration)];
                        case 1:
                            // authentication setup required
                            // oauth required
                            _a.sent();
                            if (tid !== undefined) {
                                localVarQueryParameter['tid'] = tid;
                            }
                            if (workspaceId !== undefined) {
                                localVarQueryParameter['workspace_id'] = workspaceId;
                            }
                            if (withData !== undefined) {
                                localVarQueryParameter['with_data'] = withData;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Allows to import a JSON file that contains all the tenants and their configuration.  Customers that use the on-premise version of the ACP deployment can use the global tenants\' configuration import ACP REST API. The requirement to have the on-premise deployment comes from the fact, that you need to be able to access the system tenant of your deployment to be able to authenticate your client and get access token that allows you to import the tenants configuration.  By default, a system tenant is created for you automatically and a client application with the client credentials grant flow enabled is also provided. The ID of this client is `system` and the client secret can be found in your ACP configuration file.  When requesting an access token, you should provide the `manage_configuration` scope as the one you would like to request.
         * @summary Import global tenants configuration
         * @param {string} [mode] Insert mode  Defines what happens in case of configuration import conflicts.  The &#x60;mode&#x60; parameter defines what happens if there are any conflicts when importing your configuration. For example, if a client already exists within ACP and you are trying to import a configuration that also has a client with this ID, there are the following ways ACP can handle the request:  &#x60;mode&#x60; set to &#x60;ignore&#x60;, ACP ignores the changes that come from your configuration import.  &#x60;mode&#x60; set to &#x60;fail&#x60;, ACP stops processing the import and returns an error.  &#x60;mode&#x60; set to &#x60;update&#x60;, ACP updates the value from the previous configuration with the value provided in the request.
         * @param {Dump} [dump]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConfiguration: function (mode_1, dump_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([mode_1, dump_1], args_1, true), void 0, function (mode, dump, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/api/system/configuration";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication setup required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "setup", [], configuration)];
                        case 1:
                            // authentication setup required
                            // oauth required
                            _a.sent();
                            if (mode !== undefined) {
                                localVarQueryParameter['mode'] = mode;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(dump, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Patch tenant configuration using RFC 6902 JSON Patch.
         * @summary Patch configuration
         * @param {string} [tid] Tenant id
         * @param {string} [mode]
         * @param {Array<Rfc6902PatchOperation>} [patch]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchConfiguration: function (tid_1, mode_1, patch_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tid_1, mode_1, patch_1], args_1, true), void 0, function (tid, mode, patch, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/api/system/configuration";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication setup required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "setup", [], configuration)];
                        case 1:
                            // authentication setup required
                            // oauth required
                            _a.sent();
                            if (tid !== undefined) {
                                localVarQueryParameter['tid'] = tid;
                            }
                            if (mode !== undefined) {
                                localVarQueryParameter['mode'] = mode;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(patch, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ConfigurationApiAxiosParamCreator = ConfigurationApiAxiosParamCreator;
/**
 * ConfigurationApi - functional programming interface
 * @export
 */
var ConfigurationApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ConfigurationApiAxiosParamCreator)(configuration);
    return {
        /**
         * Returns a JSON with all tenants and their configuration.  Customers that use the on-premise version of the ACP deployment can use the global tenants\' configuration export ACP REST API. The requirement to have the on-premise deployment comes from the fact, that you need to be able to access the system tenant of your deployment to be able to authenticate your client and get access token that allows you to import the tenants configuration.  By default, a system tenant is created for you automatically and a client application with the client credentials grant flow enabled is also provided. The ID of this client is `system` and the client secret can be found in your ACP configuration file.  When requesting an access token, you should provide the `manage_configuration` scope as the one you would like to request.
         * @summary Export global tenants configuration
         * @param {string} [tid] Tenant id
         * @param {string} [workspaceId] Workspace id
         * @param {boolean} [withData] Include data in export
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportConfiguration: function (tid, workspaceId, withData, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.exportConfiguration(tid, workspaceId, withData, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Allows to import a JSON file that contains all the tenants and their configuration.  Customers that use the on-premise version of the ACP deployment can use the global tenants\' configuration import ACP REST API. The requirement to have the on-premise deployment comes from the fact, that you need to be able to access the system tenant of your deployment to be able to authenticate your client and get access token that allows you to import the tenants configuration.  By default, a system tenant is created for you automatically and a client application with the client credentials grant flow enabled is also provided. The ID of this client is `system` and the client secret can be found in your ACP configuration file.  When requesting an access token, you should provide the `manage_configuration` scope as the one you would like to request.
         * @summary Import global tenants configuration
         * @param {string} [mode] Insert mode  Defines what happens in case of configuration import conflicts.  The &#x60;mode&#x60; parameter defines what happens if there are any conflicts when importing your configuration. For example, if a client already exists within ACP and you are trying to import a configuration that also has a client with this ID, there are the following ways ACP can handle the request:  &#x60;mode&#x60; set to &#x60;ignore&#x60;, ACP ignores the changes that come from your configuration import.  &#x60;mode&#x60; set to &#x60;fail&#x60;, ACP stops processing the import and returns an error.  &#x60;mode&#x60; set to &#x60;update&#x60;, ACP updates the value from the previous configuration with the value provided in the request.
         * @param {Dump} [dump]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConfiguration: function (mode, dump, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.importConfiguration(mode, dump, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Patch tenant configuration using RFC 6902 JSON Patch.
         * @summary Patch configuration
         * @param {string} [tid] Tenant id
         * @param {string} [mode]
         * @param {Array<Rfc6902PatchOperation>} [patch]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchConfiguration: function (tid, mode, patch, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchConfiguration(tid, mode, patch, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ConfigurationApiFp = ConfigurationApiFp;
/**
 * ConfigurationApi - factory interface
 * @export
 */
var ConfigurationApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ConfigurationApiFp)(configuration);
    return {
        /**
         * Returns a JSON with all tenants and their configuration.  Customers that use the on-premise version of the ACP deployment can use the global tenants\' configuration export ACP REST API. The requirement to have the on-premise deployment comes from the fact, that you need to be able to access the system tenant of your deployment to be able to authenticate your client and get access token that allows you to import the tenants configuration.  By default, a system tenant is created for you automatically and a client application with the client credentials grant flow enabled is also provided. The ID of this client is `system` and the client secret can be found in your ACP configuration file.  When requesting an access token, you should provide the `manage_configuration` scope as the one you would like to request.
         * @summary Export global tenants configuration
         * @param {string} [tid] Tenant id
         * @param {string} [workspaceId] Workspace id
         * @param {boolean} [withData] Include data in export
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportConfiguration: function (tid, workspaceId, withData, options) {
            return localVarFp.exportConfiguration(tid, workspaceId, withData, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Allows to import a JSON file that contains all the tenants and their configuration.  Customers that use the on-premise version of the ACP deployment can use the global tenants\' configuration import ACP REST API. The requirement to have the on-premise deployment comes from the fact, that you need to be able to access the system tenant of your deployment to be able to authenticate your client and get access token that allows you to import the tenants configuration.  By default, a system tenant is created for you automatically and a client application with the client credentials grant flow enabled is also provided. The ID of this client is `system` and the client secret can be found in your ACP configuration file.  When requesting an access token, you should provide the `manage_configuration` scope as the one you would like to request.
         * @summary Import global tenants configuration
         * @param {string} [mode] Insert mode  Defines what happens in case of configuration import conflicts.  The &#x60;mode&#x60; parameter defines what happens if there are any conflicts when importing your configuration. For example, if a client already exists within ACP and you are trying to import a configuration that also has a client with this ID, there are the following ways ACP can handle the request:  &#x60;mode&#x60; set to &#x60;ignore&#x60;, ACP ignores the changes that come from your configuration import.  &#x60;mode&#x60; set to &#x60;fail&#x60;, ACP stops processing the import and returns an error.  &#x60;mode&#x60; set to &#x60;update&#x60;, ACP updates the value from the previous configuration with the value provided in the request.
         * @param {Dump} [dump]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConfiguration: function (mode, dump, options) {
            return localVarFp.importConfiguration(mode, dump, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Patch tenant configuration using RFC 6902 JSON Patch.
         * @summary Patch configuration
         * @param {string} [tid] Tenant id
         * @param {string} [mode]
         * @param {Array<Rfc6902PatchOperation>} [patch]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchConfiguration: function (tid, mode, patch, options) {
            return localVarFp.patchConfiguration(tid, mode, patch, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ConfigurationApiFactory = ConfigurationApiFactory;
/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
var ConfigurationApi = /** @class */ (function (_super) {
    __extends(ConfigurationApi, _super);
    function ConfigurationApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Returns a JSON with all tenants and their configuration.  Customers that use the on-premise version of the ACP deployment can use the global tenants\' configuration export ACP REST API. The requirement to have the on-premise deployment comes from the fact, that you need to be able to access the system tenant of your deployment to be able to authenticate your client and get access token that allows you to import the tenants configuration.  By default, a system tenant is created for you automatically and a client application with the client credentials grant flow enabled is also provided. The ID of this client is `system` and the client secret can be found in your ACP configuration file.  When requesting an access token, you should provide the `manage_configuration` scope as the one you would like to request.
     * @summary Export global tenants configuration
     * @param {ConfigurationApiExportConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    ConfigurationApi.prototype.exportConfiguration = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.ConfigurationApiFp)(this.configuration).exportConfiguration(requestParameters.tid, requestParameters.workspaceId, requestParameters.withData, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Allows to import a JSON file that contains all the tenants and their configuration.  Customers that use the on-premise version of the ACP deployment can use the global tenants\' configuration import ACP REST API. The requirement to have the on-premise deployment comes from the fact, that you need to be able to access the system tenant of your deployment to be able to authenticate your client and get access token that allows you to import the tenants configuration.  By default, a system tenant is created for you automatically and a client application with the client credentials grant flow enabled is also provided. The ID of this client is `system` and the client secret can be found in your ACP configuration file.  When requesting an access token, you should provide the `manage_configuration` scope as the one you would like to request.
     * @summary Import global tenants configuration
     * @param {ConfigurationApiImportConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    ConfigurationApi.prototype.importConfiguration = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.ConfigurationApiFp)(this.configuration).importConfiguration(requestParameters.mode, requestParameters.dump, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Patch tenant configuration using RFC 6902 JSON Patch.
     * @summary Patch configuration
     * @param {ConfigurationApiPatchConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    ConfigurationApi.prototype.patchConfiguration = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.ConfigurationApiFp)(this.configuration).patchConfiguration(requestParameters.tid, requestParameters.mode, requestParameters.patch, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ConfigurationApi;
}(base_1.BaseAPI));
exports.ConfigurationApi = ConfigurationApi;
/**
 * FeaturesApi - axios parameter creator
 * @export
 */
var FeaturesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Returns a map of all feature flags and their values.  To be able to set a tenant feature flag, you need to use an access token issued for a system tenant and admin workspace.
         * @summary Get tenant features
         * @param {string} tid Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantFeatures: function (tid_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tid_1], args_1, true), void 0, function (tid, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tid' is not null or undefined
                            (0, common_1.assertParamExists)('getTenantFeatures', 'tid', tid);
                            localVarPath = "/api/admin/tenants/{tid}/features"
                                .replace("{".concat("tid", "}"), encodeURIComponent(String(tid)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication adminSystem required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "adminSystem", [], configuration)];
                        case 1:
                            // authentication adminSystem required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Enable or disable one of the tenant\'s features. To be able to set a tenant feature flag, you need to use an access token issued for a system tenant and admin workspace.
         * @summary Set tenant feature
         * @param {string} tid Tenant id
         * @param {SetTenantFeature} [setTenantFeature]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTenantFeature: function (tid_1, setTenantFeature_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tid_1, setTenantFeature_1], args_1, true), void 0, function (tid, setTenantFeature, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tid' is not null or undefined
                            (0, common_1.assertParamExists)('setTenantFeature', 'tid', tid);
                            localVarPath = "/api/admin/tenants/{tid}/feature"
                                .replace("{".concat("tid", "}"), encodeURIComponent(String(tid)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication adminSystem required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "adminSystem", [], configuration)];
                        case 1:
                            // authentication adminSystem required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(setTenantFeature, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.FeaturesApiAxiosParamCreator = FeaturesApiAxiosParamCreator;
/**
 * FeaturesApi - functional programming interface
 * @export
 */
var FeaturesApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.FeaturesApiAxiosParamCreator)(configuration);
    return {
        /**
         * Returns a map of all feature flags and their values.  To be able to set a tenant feature flag, you need to use an access token issued for a system tenant and admin workspace.
         * @summary Get tenant features
         * @param {string} tid Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantFeatures: function (tid, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTenantFeatures(tid, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Enable or disable one of the tenant\'s features. To be able to set a tenant feature flag, you need to use an access token issued for a system tenant and admin workspace.
         * @summary Set tenant feature
         * @param {string} tid Tenant id
         * @param {SetTenantFeature} [setTenantFeature]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTenantFeature: function (tid, setTenantFeature, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setTenantFeature(tid, setTenantFeature, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.FeaturesApiFp = FeaturesApiFp;
/**
 * FeaturesApi - factory interface
 * @export
 */
var FeaturesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.FeaturesApiFp)(configuration);
    return {
        /**
         * Returns a map of all feature flags and their values.  To be able to set a tenant feature flag, you need to use an access token issued for a system tenant and admin workspace.
         * @summary Get tenant features
         * @param {string} tid Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantFeatures: function (tid, options) {
            return localVarFp.getTenantFeatures(tid, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Enable or disable one of the tenant\'s features. To be able to set a tenant feature flag, you need to use an access token issued for a system tenant and admin workspace.
         * @summary Set tenant feature
         * @param {string} tid Tenant id
         * @param {SetTenantFeature} [setTenantFeature]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTenantFeature: function (tid, setTenantFeature, options) {
            return localVarFp.setTenantFeature(tid, setTenantFeature, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.FeaturesApiFactory = FeaturesApiFactory;
/**
 * FeaturesApi - object-oriented interface
 * @export
 * @class FeaturesApi
 * @extends {BaseAPI}
 */
var FeaturesApi = /** @class */ (function (_super) {
    __extends(FeaturesApi, _super);
    function FeaturesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Returns a map of all feature flags and their values.  To be able to set a tenant feature flag, you need to use an access token issued for a system tenant and admin workspace.
     * @summary Get tenant features
     * @param {FeaturesApiGetTenantFeaturesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    FeaturesApi.prototype.getTenantFeatures = function (requestParameters, options) {
        var _this = this;
        return (0, exports.FeaturesApiFp)(this.configuration).getTenantFeatures(requestParameters.tid, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Enable or disable one of the tenant\'s features. To be able to set a tenant feature flag, you need to use an access token issued for a system tenant and admin workspace.
     * @summary Set tenant feature
     * @param {FeaturesApiSetTenantFeatureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    FeaturesApi.prototype.setTenantFeature = function (requestParameters, options) {
        var _this = this;
        return (0, exports.FeaturesApiFp)(this.configuration).setTenantFeature(requestParameters.tid, requestParameters.setTenantFeature, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return FeaturesApi;
}(base_1.BaseAPI));
exports.FeaturesApi = FeaturesApi;
/**
 * LimitsApi - axios parameter creator
 * @export
 */
var LimitsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Delete custom rate limit for a module per tenant.
         * @summary Delete rate limit
         * @param {string} tid Tenant id
         * @param {string} module
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRateLimit: function (tid_1, module_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tid_1, module_1], args_1, true), void 0, function (tid, module, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tid' is not null or undefined
                            (0, common_1.assertParamExists)('deleteRateLimit', 'tid', tid);
                            // verify required parameter 'module' is not null or undefined
                            (0, common_1.assertParamExists)('deleteRateLimit', 'module', module);
                            localVarPath = "/api/admin/tenants/{tid}/rate-limits/{module}"
                                .replace("{".concat("tid", "}"), encodeURIComponent(String(tid)))
                                .replace("{".concat("module", "}"), encodeURIComponent(String(module)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication adminSystem required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "adminSystem", [], configuration)];
                        case 1:
                            // authentication adminSystem required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List custom rate limits per tenant.
         * @summary List rate limits
         * @param {string} tid Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRateLimits: function (tid_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tid_1], args_1, true), void 0, function (tid, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tid' is not null or undefined
                            (0, common_1.assertParamExists)('listRateLimits', 'tid', tid);
                            localVarPath = "/api/admin/tenants/{tid}/rate-limits"
                                .replace("{".concat("tid", "}"), encodeURIComponent(String(tid)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication adminSystem required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "adminSystem", [], configuration)];
                        case 1:
                            // authentication adminSystem required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Set custom rate limit for a module per tenant.
         * @summary Set rate limit
         * @param {string} tid Tenant id
         * @param {string} module
         * @param {SetRateLimitRequest} [customRateLimit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRateLimit: function (tid_1, module_1, customRateLimit_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tid_1, module_1, customRateLimit_1], args_1, true), void 0, function (tid, module, customRateLimit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tid' is not null or undefined
                            (0, common_1.assertParamExists)('setRateLimit', 'tid', tid);
                            // verify required parameter 'module' is not null or undefined
                            (0, common_1.assertParamExists)('setRateLimit', 'module', module);
                            localVarPath = "/api/admin/tenants/{tid}/rate-limits/{module}"
                                .replace("{".concat("tid", "}"), encodeURIComponent(String(tid)))
                                .replace("{".concat("module", "}"), encodeURIComponent(String(module)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication adminSystem required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "adminSystem", [], configuration)];
                        case 1:
                            // authentication adminSystem required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(customRateLimit, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.LimitsApiAxiosParamCreator = LimitsApiAxiosParamCreator;
/**
 * LimitsApi - functional programming interface
 * @export
 */
var LimitsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.LimitsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Delete custom rate limit for a module per tenant.
         * @summary Delete rate limit
         * @param {string} tid Tenant id
         * @param {string} module
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRateLimit: function (tid, module, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteRateLimit(tid, module, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List custom rate limits per tenant.
         * @summary List rate limits
         * @param {string} tid Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRateLimits: function (tid, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listRateLimits(tid, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Set custom rate limit for a module per tenant.
         * @summary Set rate limit
         * @param {string} tid Tenant id
         * @param {string} module
         * @param {SetRateLimitRequest} [customRateLimit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRateLimit: function (tid, module, customRateLimit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setRateLimit(tid, module, customRateLimit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.LimitsApiFp = LimitsApiFp;
/**
 * LimitsApi - factory interface
 * @export
 */
var LimitsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.LimitsApiFp)(configuration);
    return {
        /**
         * Delete custom rate limit for a module per tenant.
         * @summary Delete rate limit
         * @param {string} tid Tenant id
         * @param {string} module
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRateLimit: function (tid, module, options) {
            return localVarFp.deleteRateLimit(tid, module, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List custom rate limits per tenant.
         * @summary List rate limits
         * @param {string} tid Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRateLimits: function (tid, options) {
            return localVarFp.listRateLimits(tid, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Set custom rate limit for a module per tenant.
         * @summary Set rate limit
         * @param {string} tid Tenant id
         * @param {string} module
         * @param {SetRateLimitRequest} [customRateLimit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRateLimit: function (tid, module, customRateLimit, options) {
            return localVarFp.setRateLimit(tid, module, customRateLimit, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.LimitsApiFactory = LimitsApiFactory;
/**
 * LimitsApi - object-oriented interface
 * @export
 * @class LimitsApi
 * @extends {BaseAPI}
 */
var LimitsApi = /** @class */ (function (_super) {
    __extends(LimitsApi, _super);
    function LimitsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Delete custom rate limit for a module per tenant.
     * @summary Delete rate limit
     * @param {LimitsApiDeleteRateLimitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LimitsApi
     */
    LimitsApi.prototype.deleteRateLimit = function (requestParameters, options) {
        var _this = this;
        return (0, exports.LimitsApiFp)(this.configuration).deleteRateLimit(requestParameters.tid, requestParameters.module, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List custom rate limits per tenant.
     * @summary List rate limits
     * @param {LimitsApiListRateLimitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LimitsApi
     */
    LimitsApi.prototype.listRateLimits = function (requestParameters, options) {
        var _this = this;
        return (0, exports.LimitsApiFp)(this.configuration).listRateLimits(requestParameters.tid, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Set custom rate limit for a module per tenant.
     * @summary Set rate limit
     * @param {LimitsApiSetRateLimitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LimitsApi
     */
    LimitsApi.prototype.setRateLimit = function (requestParameters, options) {
        var _this = this;
        return (0, exports.LimitsApiFp)(this.configuration).setRateLimit(requestParameters.tid, requestParameters.module, requestParameters.customRateLimit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return LimitsApi;
}(base_1.BaseAPI));
exports.LimitsApi = LimitsApi;
/**
 * SecurityApi - axios parameter creator
 * @export
 */
var SecurityApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Returns default security configuration
         * @summary Get security
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecurity: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/api/admin/tenants/security";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication adminSystem required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "adminSystem", [], configuration)];
                        case 1:
                            // authentication adminSystem required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get tenant security
         * @param {string} tid Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantSecurity: function (tid_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tid_1], args_1, true), void 0, function (tid, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tid' is not null or undefined
                            (0, common_1.assertParamExists)('getTenantSecurity', 'tid', tid);
                            localVarPath = "/api/admin/tenants/{tid}/security"
                                .replace("{".concat("tid", "}"), encodeURIComponent(String(tid)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication adminSystem required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "adminSystem", [], configuration)];
                        case 1:
                            // authentication adminSystem required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Set tenant security
         * @param {string} tid Tenant id
         * @param {SecureOptions} [setTenantSecurity]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTenantSecurity: function (tid_1, setTenantSecurity_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tid_1, setTenantSecurity_1], args_1, true), void 0, function (tid, setTenantSecurity, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tid' is not null or undefined
                            (0, common_1.assertParamExists)('setTenantSecurity', 'tid', tid);
                            localVarPath = "/api/admin/tenants/{tid}/security"
                                .replace("{".concat("tid", "}"), encodeURIComponent(String(tid)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication adminSystem required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "adminSystem", [], configuration)];
                        case 1:
                            // authentication adminSystem required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(setTenantSecurity, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SecurityApiAxiosParamCreator = SecurityApiAxiosParamCreator;
/**
 * SecurityApi - functional programming interface
 * @export
 */
var SecurityApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SecurityApiAxiosParamCreator)(configuration);
    return {
        /**
         * Returns default security configuration
         * @summary Get security
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecurity: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSecurity(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get tenant security
         * @param {string} tid Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantSecurity: function (tid, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTenantSecurity(tid, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Set tenant security
         * @param {string} tid Tenant id
         * @param {SecureOptions} [setTenantSecurity]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTenantSecurity: function (tid, setTenantSecurity, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setTenantSecurity(tid, setTenantSecurity, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SecurityApiFp = SecurityApiFp;
/**
 * SecurityApi - factory interface
 * @export
 */
var SecurityApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SecurityApiFp)(configuration);
    return {
        /**
         * Returns default security configuration
         * @summary Get security
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecurity: function (options) {
            return localVarFp.getSecurity(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get tenant security
         * @param {string} tid Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantSecurity: function (tid, options) {
            return localVarFp.getTenantSecurity(tid, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Set tenant security
         * @param {string} tid Tenant id
         * @param {SecureOptions} [setTenantSecurity]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTenantSecurity: function (tid, setTenantSecurity, options) {
            return localVarFp.setTenantSecurity(tid, setTenantSecurity, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SecurityApiFactory = SecurityApiFactory;
/**
 * SecurityApi - object-oriented interface
 * @export
 * @class SecurityApi
 * @extends {BaseAPI}
 */
var SecurityApi = /** @class */ (function (_super) {
    __extends(SecurityApi, _super);
    function SecurityApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Returns default security configuration
     * @summary Get security
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    SecurityApi.prototype.getSecurity = function (options) {
        var _this = this;
        return (0, exports.SecurityApiFp)(this.configuration).getSecurity(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get tenant security
     * @param {SecurityApiGetTenantSecurityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    SecurityApi.prototype.getTenantSecurity = function (requestParameters, options) {
        var _this = this;
        return (0, exports.SecurityApiFp)(this.configuration).getTenantSecurity(requestParameters.tid, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Set tenant security
     * @param {SecurityApiSetTenantSecurityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    SecurityApi.prototype.setTenantSecurity = function (requestParameters, options) {
        var _this = this;
        return (0, exports.SecurityApiFp)(this.configuration).setTenantSecurity(requestParameters.tid, requestParameters.setTenantSecurity, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SecurityApi;
}(base_1.BaseAPI));
exports.SecurityApi = SecurityApi;
/**
 * TenantsApi - axios parameter creator
 * @export
 */
var TenantsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Tenant must have unique id, if it\'s not provided will be generated.  Tenant name must be provided.  Tenant url is optional and if omitted, it is set to ACP deployment url with appened id of a tenant, example: https://example.com/default.  When tenant is created, preconfigured authorization servers with default scopes, services and oauth clients are automatically created underneath. See API response for more details.
         * @summary Create tenant
         * @param {boolean} [withDefaultWorkspaces] Create tenant with default workspaces
         * @param {Tenant} [tenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant: function (withDefaultWorkspaces_1, tenant_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([withDefaultWorkspaces_1, tenant_1], args_1, true), void 0, function (withDefaultWorkspaces, tenant, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/api/system/tenants";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication setup required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "setup", [], configuration)];
                        case 1:
                            // authentication setup required
                            // oauth required
                            _a.sent();
                            if (withDefaultWorkspaces !== undefined) {
                                localVarQueryParameter['with_default_workspaces'] = withDefaultWorkspaces;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(tenant, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete tenant
         * @param {string} tid Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant: function (tid_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tid_1], args_1, true), void 0, function (tid, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tid' is not null or undefined
                            (0, common_1.assertParamExists)('deleteTenant', 'tid', tid);
                            localVarPath = "/api/system/tenants/{tid}"
                                .replace("{".concat("tid", "}"), encodeURIComponent(String(tid)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication setup required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "setup", [], configuration)];
                        case 1:
                            // authentication setup required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get tenant system api.
         * @summary Get tenant
         * @param {string} tid Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant: function (tid_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tid_1], args_1, true), void 0, function (tid, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tid' is not null or undefined
                            (0, common_1.assertParamExists)('getTenant', 'tid', tid);
                            localVarPath = "/api/system/tenants/{tid}"
                                .replace("{".concat("tid", "}"), encodeURIComponent(String(tid)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication setup required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "setup", [], configuration)];
                        case 1:
                            // authentication setup required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * To be able to list tenants, you need to use an access token issued for a system tenant and admin workspace.
         * @summary List tenants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdminTenants: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/api/admin/tenants";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication adminSystem required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "adminSystem", [], configuration)];
                        case 1:
                            // authentication adminSystem required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List tenants.
         * @summary List tenants
         * @param {string} [query] optional query filter query is in json format like {\&quot;metadata\&quot;: {\&quot;registration_identifier\&quot;:\&quot;john.doe@cloudentity.com\&quot;}} - parameter must be url-encoded supported parameters &#x60;metadata&#x60; - limits tenants set to tenants with metadata matches provided metadata - for simple values it does exact match, for arrays it does &#x60;contain&#x60; if multiple parameters provided it does logical AND between the results so tenants must match ALL parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenants: function (query_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([query_1], args_1, true), void 0, function (query, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/api/system/tenants";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication setup required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "setup", [], configuration)];
                        case 1:
                            // authentication setup required
                            // oauth required
                            _a.sent();
                            if (query !== undefined) {
                                localVarQueryParameter['query'] = query;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update tenant system api.
         * @summary Update tenant
         * @param {string} tid Tenant id
         * @param {Tenant} [tenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant: function (tid_1, tenant_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tid_1, tenant_1], args_1, true), void 0, function (tid, tenant, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tid' is not null or undefined
                            (0, common_1.assertParamExists)('updateTenant', 'tid', tid);
                            localVarPath = "/api/system/tenants/{tid}"
                                .replace("{".concat("tid", "}"), encodeURIComponent(String(tid)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication setup required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "setup", [], configuration)];
                        case 1:
                            // authentication setup required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(tenant, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TenantsApiAxiosParamCreator = TenantsApiAxiosParamCreator;
/**
 * TenantsApi - functional programming interface
 * @export
 */
var TenantsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TenantsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Tenant must have unique id, if it\'s not provided will be generated.  Tenant name must be provided.  Tenant url is optional and if omitted, it is set to ACP deployment url with appened id of a tenant, example: https://example.com/default.  When tenant is created, preconfigured authorization servers with default scopes, services and oauth clients are automatically created underneath. See API response for more details.
         * @summary Create tenant
         * @param {boolean} [withDefaultWorkspaces] Create tenant with default workspaces
         * @param {Tenant} [tenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant: function (withDefaultWorkspaces, tenant, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createTenant(withDefaultWorkspaces, tenant, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete tenant
         * @param {string} tid Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant: function (tid, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteTenant(tid, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get tenant system api.
         * @summary Get tenant
         * @param {string} tid Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant: function (tid, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTenant(tid, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * To be able to list tenants, you need to use an access token issued for a system tenant and admin workspace.
         * @summary List tenants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdminTenants: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listAdminTenants(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List tenants.
         * @summary List tenants
         * @param {string} [query] optional query filter query is in json format like {\&quot;metadata\&quot;: {\&quot;registration_identifier\&quot;:\&quot;john.doe@cloudentity.com\&quot;}} - parameter must be url-encoded supported parameters &#x60;metadata&#x60; - limits tenants set to tenants with metadata matches provided metadata - for simple values it does exact match, for arrays it does &#x60;contain&#x60; if multiple parameters provided it does logical AND between the results so tenants must match ALL parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenants: function (query, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listTenants(query, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update tenant system api.
         * @summary Update tenant
         * @param {string} tid Tenant id
         * @param {Tenant} [tenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant: function (tid, tenant, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateTenant(tid, tenant, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.TenantsApiFp = TenantsApiFp;
/**
 * TenantsApi - factory interface
 * @export
 */
var TenantsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TenantsApiFp)(configuration);
    return {
        /**
         * Tenant must have unique id, if it\'s not provided will be generated.  Tenant name must be provided.  Tenant url is optional and if omitted, it is set to ACP deployment url with appened id of a tenant, example: https://example.com/default.  When tenant is created, preconfigured authorization servers with default scopes, services and oauth clients are automatically created underneath. See API response for more details.
         * @summary Create tenant
         * @param {boolean} [withDefaultWorkspaces] Create tenant with default workspaces
         * @param {Tenant} [tenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant: function (withDefaultWorkspaces, tenant, options) {
            return localVarFp.createTenant(withDefaultWorkspaces, tenant, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete tenant
         * @param {string} tid Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant: function (tid, options) {
            return localVarFp.deleteTenant(tid, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get tenant system api.
         * @summary Get tenant
         * @param {string} tid Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant: function (tid, options) {
            return localVarFp.getTenant(tid, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * To be able to list tenants, you need to use an access token issued for a system tenant and admin workspace.
         * @summary List tenants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdminTenants: function (options) {
            return localVarFp.listAdminTenants(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List tenants.
         * @summary List tenants
         * @param {string} [query] optional query filter query is in json format like {\&quot;metadata\&quot;: {\&quot;registration_identifier\&quot;:\&quot;john.doe@cloudentity.com\&quot;}} - parameter must be url-encoded supported parameters &#x60;metadata&#x60; - limits tenants set to tenants with metadata matches provided metadata - for simple values it does exact match, for arrays it does &#x60;contain&#x60; if multiple parameters provided it does logical AND between the results so tenants must match ALL parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenants: function (query, options) {
            return localVarFp.listTenants(query, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update tenant system api.
         * @summary Update tenant
         * @param {string} tid Tenant id
         * @param {Tenant} [tenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant: function (tid, tenant, options) {
            return localVarFp.updateTenant(tid, tenant, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TenantsApiFactory = TenantsApiFactory;
/**
 * TenantsApi - object-oriented interface
 * @export
 * @class TenantsApi
 * @extends {BaseAPI}
 */
var TenantsApi = /** @class */ (function (_super) {
    __extends(TenantsApi, _super);
    function TenantsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Tenant must have unique id, if it\'s not provided will be generated.  Tenant name must be provided.  Tenant url is optional and if omitted, it is set to ACP deployment url with appened id of a tenant, example: https://example.com/default.  When tenant is created, preconfigured authorization servers with default scopes, services and oauth clients are automatically created underneath. See API response for more details.
     * @summary Create tenant
     * @param {TenantsApiCreateTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    TenantsApi.prototype.createTenant = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.TenantsApiFp)(this.configuration).createTenant(requestParameters.withDefaultWorkspaces, requestParameters.tenant, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete tenant
     * @param {TenantsApiDeleteTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    TenantsApi.prototype.deleteTenant = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TenantsApiFp)(this.configuration).deleteTenant(requestParameters.tid, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get tenant system api.
     * @summary Get tenant
     * @param {TenantsApiGetTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    TenantsApi.prototype.getTenant = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TenantsApiFp)(this.configuration).getTenant(requestParameters.tid, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * To be able to list tenants, you need to use an access token issued for a system tenant and admin workspace.
     * @summary List tenants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    TenantsApi.prototype.listAdminTenants = function (options) {
        var _this = this;
        return (0, exports.TenantsApiFp)(this.configuration).listAdminTenants(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List tenants.
     * @summary List tenants
     * @param {TenantsApiListTenantsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    TenantsApi.prototype.listTenants = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.TenantsApiFp)(this.configuration).listTenants(requestParameters.query, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update tenant system api.
     * @summary Update tenant
     * @param {TenantsApiUpdateTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    TenantsApi.prototype.updateTenant = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TenantsApiFp)(this.configuration).updateTenant(requestParameters.tid, requestParameters.tenant, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TenantsApi;
}(base_1.BaseAPI));
exports.TenantsApi = TenantsApi;
/**
 * WorkspacesApi - axios parameter creator
 * @export
 */
var WorkspacesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * List workspaces
         * @summary List workspaces
         * @param {string} tenantID Tenant ID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {string} [workspaceTypes] comma separated workspace types that are to be filtered out
         * @param {boolean} [template] optional flag to filter by template
         * @param {string} [themeId] optional flag to filter by theme id
         * @param {string} [searchPhrase] Optional search phrase: workspace id OR workspace name substring (case insensitive) SearchPhrase
         * @param {string} [afterWorkspaceId] optional list workspaces after given id AfterWorkspaceID
         * @param {string} [beforeWorkspaceId] optional list workspaces before given id BeforeWorkspaceID
         * @param {string} [sort] optional sort workspaces by given field Sort
         * @param {string} [order] optional order workspaces by given direction Order
         * @param {number} [limit] optional limit results Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspaces: function (tenantID_1, ifMatch_1, workspaceTypes_1, template_1, themeId_1, searchPhrase_1, afterWorkspaceId_1, beforeWorkspaceId_1, sort_1, order_1, limit_1) {
            var args_1 = [];
            for (var _i = 11; _i < arguments.length; _i++) {
                args_1[_i - 11] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenantID_1, ifMatch_1, workspaceTypes_1, template_1, themeId_1, searchPhrase_1, afterWorkspaceId_1, beforeWorkspaceId_1, sort_1, order_1, limit_1], args_1, true), void 0, function (tenantID, ifMatch, workspaceTypes, template, themeId, searchPhrase, afterWorkspaceId, beforeWorkspaceId, sort, order, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantID' is not null or undefined
                            (0, common_1.assertParamExists)('listWorkspaces', 'tenantID', tenantID);
                            localVarPath = "/api/admin/tenants/{tenantID}/workspaces"
                                .replace("{".concat("tenantID", "}"), encodeURIComponent(String(tenantID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication setup required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "setup", [], configuration)];
                        case 1:
                            // authentication setup required
                            // oauth required
                            _a.sent();
                            if (workspaceTypes !== undefined) {
                                localVarQueryParameter['workspace_types'] = workspaceTypes;
                            }
                            if (template !== undefined) {
                                localVarQueryParameter['template'] = template;
                            }
                            if (themeId !== undefined) {
                                localVarQueryParameter['theme_id'] = themeId;
                            }
                            if (searchPhrase !== undefined) {
                                localVarQueryParameter['search_phrase'] = searchPhrase;
                            }
                            if (afterWorkspaceId !== undefined) {
                                localVarQueryParameter['after_workspace_id'] = afterWorkspaceId;
                            }
                            if (beforeWorkspaceId !== undefined) {
                                localVarQueryParameter['before_workspace_id'] = beforeWorkspaceId;
                            }
                            if (sort !== undefined) {
                                localVarQueryParameter['sort'] = sort;
                            }
                            if (order !== undefined) {
                                localVarQueryParameter['order'] = order;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.WorkspacesApiAxiosParamCreator = WorkspacesApiAxiosParamCreator;
/**
 * WorkspacesApi - functional programming interface
 * @export
 */
var WorkspacesApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.WorkspacesApiAxiosParamCreator)(configuration);
    return {
        /**
         * List workspaces
         * @summary List workspaces
         * @param {string} tenantID Tenant ID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {string} [workspaceTypes] comma separated workspace types that are to be filtered out
         * @param {boolean} [template] optional flag to filter by template
         * @param {string} [themeId] optional flag to filter by theme id
         * @param {string} [searchPhrase] Optional search phrase: workspace id OR workspace name substring (case insensitive) SearchPhrase
         * @param {string} [afterWorkspaceId] optional list workspaces after given id AfterWorkspaceID
         * @param {string} [beforeWorkspaceId] optional list workspaces before given id BeforeWorkspaceID
         * @param {string} [sort] optional sort workspaces by given field Sort
         * @param {string} [order] optional order workspaces by given direction Order
         * @param {number} [limit] optional limit results Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspaces: function (tenantID, ifMatch, workspaceTypes, template, themeId, searchPhrase, afterWorkspaceId, beforeWorkspaceId, sort, order, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listWorkspaces(tenantID, ifMatch, workspaceTypes, template, themeId, searchPhrase, afterWorkspaceId, beforeWorkspaceId, sort, order, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.WorkspacesApiFp = WorkspacesApiFp;
/**
 * WorkspacesApi - factory interface
 * @export
 */
var WorkspacesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.WorkspacesApiFp)(configuration);
    return {
        /**
         * List workspaces
         * @summary List workspaces
         * @param {string} tenantID Tenant ID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {string} [workspaceTypes] comma separated workspace types that are to be filtered out
         * @param {boolean} [template] optional flag to filter by template
         * @param {string} [themeId] optional flag to filter by theme id
         * @param {string} [searchPhrase] Optional search phrase: workspace id OR workspace name substring (case insensitive) SearchPhrase
         * @param {string} [afterWorkspaceId] optional list workspaces after given id AfterWorkspaceID
         * @param {string} [beforeWorkspaceId] optional list workspaces before given id BeforeWorkspaceID
         * @param {string} [sort] optional sort workspaces by given field Sort
         * @param {string} [order] optional order workspaces by given direction Order
         * @param {number} [limit] optional limit results Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspaces: function (tenantID, ifMatch, workspaceTypes, template, themeId, searchPhrase, afterWorkspaceId, beforeWorkspaceId, sort, order, limit, options) {
            return localVarFp.listWorkspaces(tenantID, ifMatch, workspaceTypes, template, themeId, searchPhrase, afterWorkspaceId, beforeWorkspaceId, sort, order, limit, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.WorkspacesApiFactory = WorkspacesApiFactory;
/**
 * WorkspacesApi - object-oriented interface
 * @export
 * @class WorkspacesApi
 * @extends {BaseAPI}
 */
var WorkspacesApi = /** @class */ (function (_super) {
    __extends(WorkspacesApi, _super);
    function WorkspacesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * List workspaces
     * @summary List workspaces
     * @param {WorkspacesApiListWorkspacesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    WorkspacesApi.prototype.listWorkspaces = function (requestParameters, options) {
        var _this = this;
        return (0, exports.WorkspacesApiFp)(this.configuration).listWorkspaces(requestParameters.tenantID, requestParameters.ifMatch, requestParameters.workspaceTypes, requestParameters.template, requestParameters.themeId, requestParameters.searchPhrase, requestParameters.afterWorkspaceId, requestParameters.beforeWorkspaceId, requestParameters.sort, requestParameters.order, requestParameters.limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return WorkspacesApi;
}(base_1.BaseAPI));
exports.WorkspacesApi = WorkspacesApi;

import React, { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";

import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";
import { useTheme } from "@mui/material/styles";
import throttle from "lodash/throttle";
import { makeStyles } from "tss-react/mui";

import FormInputLabel from "../../components/FormInputLabel";
import { TRANSPARENT_HEX_VALUE } from "../../components/nav/utils";

const useStyles = makeStyles()(theme => ({
  container: {
    marginBottom: 12,
  },
  innerContainer: {
    border: "solid 1px #c4c4c4",
    boxSizing: "border-box",
    borderRadius: 4,
    backgroundColor: theme.custom.greys.fieldBackground,
    padding: 12,
    display: "flex",
    alignItems: "center",
  },
  input: {
    height: 24,
    width: 24,
    background: "none",
    borderRadius: 4,
    border: "none",
    padding: 0,
    margin: 0,
    cursor: "pointer",
    marginRight: 12,

    "&::-webkit-color-swatch": {
      borderColor: theme.custom.greys.border,
      borderRadius: 4,
      padding: 0,
      margin: 0,
    },
    "&::-webkit-color-swatch-wrapper": {
      border: "none",
      borderRadius: 10,
      padding: 0,
      margin: 0,
    },
  },
  textInput: {
    width: 80,
    "&>input": {
      padding: 0,
    },
  },
}));

const hexColorRegex = new RegExp(/^#[0-9A-F]{6}(:?[0-9A-F]{2})?$/i);

export interface ColorFieldProps {
  form: any;
  id: string;
  name: string;
  label: string;
  value: string;
  disabled?: boolean;
  style?: CSSProperties;
  onChange?: (value: string) => void;
}

export default function ColorField({
  form,
  id,
  name,
  label,
  value,
  disabled,
  style = {},
  onChange,
  ...props
}: ColorFieldProps) {
  const { classes } = useStyles();
  const theme = useTheme();
  const [isInvalid, setIsInvalid] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  const onDebouncedChange =
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(
      throttle(value => {
        setIsInvalid(false);
        onChange && onChange(value);
        form.setValue(name, value);
        if (inputRef.current) {
          inputRef.current.value = value;
        }
      }, 100),
      [name]
    );

  useEffect(() => {
    onDebouncedChange(value);
  }, [value, onDebouncedChange]);

  const { ref, ...rest } = form.register(name, {
    pattern: {
      value: hexColorRegex,
    },
  });

  return (
    <Controller
      name={name}
      control={form.control}
      render={() => (
        <div className={classes.container}>
          <FormInputLabel
            id={`${id}-${name}-switch-label`}
            forId={`${id}-${name}-text-input`}
            label={label}
            style={{ marginBottom: 4 }}
          />
          <div
            className={classes.innerContainer}
            style={isFocused ? { borderColor: theme.palette.primary.main } : {}}
          >
            <input
              ref={inputRef}
              type="color"
              id={`${id}-${name}-switch`}
              className={classes.input}
              defaultValue={value}
              onChange={e => {
                onDebouncedChange && onDebouncedChange(e.currentTarget.value);
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              disabled={disabled}
              style={{ visibility: value === TRANSPARENT_HEX_VALUE ? "hidden" : "visible" }}
              {...props}
            />
            {value === TRANSPARENT_HEX_VALUE && (
              <div
                onClick={() => {
                  inputRef.current?.click();
                  setIsFocused(true);
                }}
                style={{
                  visibility: value !== TRANSPARENT_HEX_VALUE ? "hidden" : "visible",
                  position: "absolute",
                  width: 24,
                  height: 24,
                  borderRadius: 4,
                  marginRight: 12,
                  cursor: "pointer",
                  background:
                    "repeating-conic-gradient(#CECECE 0% 25%, transparent 0% 50%) 50% / 16px 16px",
                }}
              ></div>
            )}
            <Input
              id={`${id}-${name}-text-input`}
              defaultValue={value}
              disabled={disabled}
              {...rest}
              inputRef={ref}
              onChange={e => {
                if (!e.currentTarget.value.includes("#")) {
                  e.currentTarget.value = `#${e.currentTarget.value}`;
                }

                if (hexColorRegex.test(e.currentTarget.value)) {
                  onDebouncedChange && onDebouncedChange(e.currentTarget.value);
                } else {
                  setIsInvalid(true);
                }
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  e.currentTarget.blur();
                }
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className={classes.textInput}
              error={isInvalid || !!form.formState.errors[name]}
              disableUnderline
              inputProps={{
                "data-testid": `${id}-${name}-text-input`,
              }}
            />
          </div>
          <FormHelperText style={{ minHeight: 20 }} error>
            {(isInvalid || form.formState.errors[name]) && "Invalid HEX color"}
          </FormHelperText>
        </div>
      )}
    />
  );
}

import React, { useMemo } from "react";

import Typography from "@mui/material/Typography";

import Dialog from "../../../../common/components/Dialog";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";

interface Props {
  dataKey: string;
  onClose: () => void;
}

function PermissionTokenCopyKeyDialog({ dataKey, onClose }: Props) {
  const data = useMemo(() => ({ dataKey }), [dataKey]);
  const formFactory = useFormFactory({ id: "permission-token-copy-key", data });

  return (
    <Dialog id="permission-token-copy-key-dialog" title="Permission Token Key">
      <Typography style={{ marginBottom: 32 }}>
        Here is the generated key. It won't be available later so please store it now in some secure
        place so you can use it later.
      </Typography>
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createReadOnlyField({
          name: "dataKey",
          label: "Key",
          withCopy: true,
        })}

        {formFactory.createFormFooter({
          onSubmit: onClose,
          submitText: "Continue",
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

export default PermissionTokenCopyKeyDialog;

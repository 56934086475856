import React from "react";
import { useWatch } from "react-hook-form";

import FormSection from "../../../../common/components/FormSection";
import { FormFactory } from "../../../../common/utils/forms/formFactory";
import { validators } from "../../../../common/utils/forms/validation";
import { useFeature } from "../../../../common/utils/hooks/useFeature";

interface Props {
  formFactory: FormFactory;
}

function MfaSettingsArculix({ formFactory }: Props) {
  const isArculixFlagEnabled = useFeature("arculix");

  const isArculixEnabled = useWatch({
    name: "arculix.enabled",
    control: formFactory.control,
  });

  if (!isArculixFlagEnabled) return null;

  return (
    <>
      <FormSection title="Arculix" id="arculix-section">
        {formFactory.createSwitch({
          name: "arculix.enabled",
          style: {
            width: "inherit",
            marginBottom: 0,
            display: "flex",
            alignItems: "center",
          },
        })}
      </FormSection>

      {isArculixEnabled && (
        <>
          {formFactory.createRequiredField({
            name: "arculix.auth.arculix.client_id",
            label: "Client ID",
          })}

          {formFactory.createRequiredField({
            name: "arculix.auth.arculix.client_secret",
            label: "Client Secret",
          })}

          {formFactory.createRequiredField({
            name: "arculix.settings.arculix.token_endpoint",
            label: "Token Endpoint",
            rules: {
              validate: { validUrl: validators.validURL({ label: "Token Endpoint" }) },
            },
            withLink: true,
          })}

          {formFactory.createRequiredField({
            name: "arculix.settings.arculix.url",
            label: "Arculix URL",
            rules: {
              validate: { validUrl: validators.validURL({ label: "Arculix URL" }) },
            },
            withLink: true,
          })}

          {formFactory.createRequiredField({
            name: "arculix.settings.arculix.faye_url",
            label: "Faye URL",
            rules: {
              validate: { validUrl: validators.validURL({ label: "Faye URL" }) },
            },
            withLink: true,
          })}

          {formFactory.createRequiredField({
            name: "arculix.settings.arculix.dbfp_url",
            label: "DBFP URL",
            rules: {
              validate: { validUrl: validators.validURL({ label: "DBFP URL" }) },
            },
            withLink: true,
          })}
        </>
      )}
    </>
  );
}

export default MfaSettingsArculix;

import React from "react";

import ButtonMUI, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  button: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.9,
    },
  },
}));

export default function LoadingButton({ children, className, ...buttonProps }: LoadingButtonProps) {
  const { cx, classes } = useStyles();

  return (
    <ButtonMUI className={cx(classes.button, className)} {...buttonProps}>
      {children}
    </ButtonMUI>
  );
}

import { useState } from "react";

import { lensPath, path, pathOr, set } from "ramda";

import { handleErrorWithNotify } from "../../../../../../common/components/notifications/notificationService";

export const useFormExtraErrors = () => {
  const [extraErrors, setExtraErrors] = useState({});

  const setError = err => {
    if (
      path(["response", "data", "error"], err) === "some fields did not pass the validation" &&
      path(["response", "data", "status_code"], err) === 422
    ) {
      const details: { Causes: { InstanceLocation: string; Message: string }[] } = pathOr(
        { Causes: [] },
        ["response", "data", "details"],
        err
      );
      const paths = details.Causes.map(c => ({
        path: c.InstanceLocation.split("/").slice(1),
        message: c.Message,
      }));

      const extraErrors = paths.reduce(
        (acc, p) => set(lensPath(p.path), { __errors: [p.message] }, acc),
        {}
      );

      setExtraErrors(extraErrors);
      return;
    }

    handleErrorWithNotify("Error occurred while trying to update user payload")(err);
    return err;
  };

  const resetExtraErrors = () => setExtraErrors({});

  return { extraErrors, setError, resetExtraErrors };
};

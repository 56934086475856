import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { GlobalStoreContext } from "../../admin/GlobalStore/GlobalStore";
import oauth2Api from "../../admin/services/oauth2Api";
import { authorize, cloudentityAuth, logout } from "../auth/actions/actions";
import Progress from "./Progress";

export default function PrivateRoute({
  tenantId,
  authorizationServerId,
  scopes,
  responseType,
  portalType,
}) {
  const globalStoreContext = useContext(GlobalStoreContext);

  useEffect(() => {
    const accessToken = cloudentityAuth.getAccessToken();
    if (accessToken) {
      oauth2Api
        .userinfo()
        .then(() => {
          globalStoreContext.authorization.setAuthorized(true);
        })
        .catch(() => {
          logout(portalType);
        });
    } else {
      authorize(tenantId, authorizationServerId, scopes, responseType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!globalStoreContext.authorization.authorized) {
    return <Progress isGlobalLoader />;
  }

  return <Outlet />;
}

import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";

import Editor, { loader } from "@monaco-editor/react";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import { Edit2 } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { SupportedJSONSchema } from "@cloudentity/acp-admin";
import { PoolResponse, Schema } from "@cloudentity/acp-identity";

import Alert from "../../../../../common/components/Alert";
import RouteLeavingGuard from "../../../../../common/components/RouteLeavingGuard";
import Tabs from "../../../../../common/components/Tabs";
import { FormContext } from "../../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../../common/utils/forms/formFactory";
import { useCheckPoolPermissions } from "../../../../services/adminIdentityPoolsQuery";
import PageContent from "../../../common/PageContent";
import SquareChip from "../../../common/SquareChip";
import { getOptionLabel, toAutocompleteOptions } from "../identityPoolsList/utils";
import {
  businessMetadataSchemaLabel,
  businessMetadataSchemaLabelTooltip,
  defaultBusinessMetadataSchemaId,
  metadataSchemaLabel,
  metadataSchemaLabelTooltip,
  payloadSchemaLabel,
  payloadSchemaLabelTooltip,
  SaveFormType,
} from "../utils";

loader.config({
  paths: {
    vs: "./assets/monaco/min/vs",
  },
});

const useStyles = makeStyles()(() => ({
  editorContainer: {
    padding: 1,
    height: "calc(100% - 101px)",
  },
  editor: {
    ".margin": {
      backgroundColor: "#F2F3F4 !important",
      ".line-numbers": {
        fontWeight: "bold",
      },
    },
    ".monaco-editor-background": {
      backgroundColor: "#F2F3F4",
    },
  },
  content: {
    height: "100%",
    display: "flex",
  },
  leftContainer: {
    width: 600,
    minWidth: 600,
    marginRight: 32,
  },
  rightContainer: {
    height: "100%",
    flex: 1,
    border: "1px solid #D5D7D9",
    borderRadius: 4,
  },
  footer: {
    textAlign: "right",
    padding: "4px 8px 4px 0",
  },
}));

interface IdentityPoolSchemasProps {
  handleUpdate: (data: SaveFormType) => void;
  pool: PoolResponse | undefined;
  schemas: Schema[];
}

const IdentityPoolSchemas = ({ handleUpdate, pool, schemas }: IdentityPoolSchemasProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const checkPoolPermissionsQuery = useCheckPoolPermissions(pool?.id!);

  const data = useMemo(() => ({ ...pool }), [pool]);
  const formFactory = useFormFactory({
    id: "identity-pool-schemas",
    data,
    noManagePermission: !checkPoolPermissionsQuery.data?.update_identity_pool,
  });
  const [tab, setTab] = useState<"metadata" | "business_metadata" | "payload">("payload");

  const schemaOptions = useMemo(() => schemas.map(s => ({ value: s.id, name: s.name })), [schemas]);
  const schemaAutocompleteOptions = toAutocompleteOptions(schemaOptions);

  const metadataSchemaId = formFactory.watch("metadata_schema_id");
  const businessMetadataSchemaId = formFactory.watch("business_metadata_schema_id");
  const payloadSchemaId = formFactory.watch("payload_schema_id");

  const metadataSchema = useMemo(
    () => schemas.find(schema => schema.id === metadataSchemaId),
    [schemas, metadataSchemaId]
  );
  const businessMetadataSchema = useMemo(
    () => schemas.find(schema => schema.id === businessMetadataSchemaId),

    [schemas, businessMetadataSchemaId]
  );
  const payloadSchema = useMemo(
    () => schemas.find(schema => schema.id === payloadSchemaId),
    [schemas, payloadSchemaId]
  );

  let schemaValue: SupportedJSONSchema | undefined;
  switch (tab) {
    case "metadata":
      schemaValue = metadataSchema?.schema;
      break;
    case "business_metadata":
      schemaValue = businessMetadataSchema?.schema;
      break;
    case "payload":
      schemaValue = payloadSchema?.schema;
      break;
  }

  const value = JSON.stringify(schemaValue, null, 2);

  const defaultBusinessMetadataSchemaExists = !!schemas.find(
    sch => sch.id === defaultBusinessMetadataSchemaId
  );

  return (
    <PageContent noScrollHeight style={{ minWidth: 560 }}>
      <FormContext.Provider value={formFactory.context}>
        <div className={classes.content}>
          <div className={classes.leftContainer}>
            <Alert id="pool-schemas-details-alert" severity="info" title="Usage">
              <p>
                The schema assignment screen allows administrators to define three distinct schemas
                tailored to various user management needs. Its flexible configuration facilitates
                customized attribute definitions to meet specific organizational requirements. These
                attributes, represented by the schemas, can be managed and viewed by personas with
                specific roles.
              </p>
              <p>
                Attributes In the User Attributes Schema, can be viewed and managed by user
                themselves, tenant administrators, and business administrators. Attributes defined
                in the Admin Managed User Attributes Schema are exclusive to tenant administrators
                and tenant-level business administrators for viewing and management. Attributes
                defined in the Business Admin Managed User Attributes Schema can also be viewed and
                managed by tenant administrators and tenant-level business administrators. However,
                the distinction lies in this set of attributes being delegatable for user management
                to business administrators belonging to B2B organizations.
              </p>
              <p>
                Select the schema that best aligns with your organizational needs and user
                management use cases. This decision ensures efficient management and alignment with
                organizational objectives.
              </p>
            </Alert>

            {formFactory.createAutocompleteField({
              name: "payload_schema_id",
              label: payloadSchemaLabel,
              options: schemaAutocompleteOptions,
              getOptionLabel: getOptionLabel(schemaOptions),
              disableClearable: true,
              labelProps: {
                tooltip: payloadSchemaLabelTooltip,
              },
              onChange: (e, option) => {
                setTab("payload");
                formFactory.setValue("payload_schema_id", option, { shouldValidate: true });
              },
              rules: {
                required: "User Attributes Schema is required",
              },
            })}

            {formFactory.createAutocompleteField({
              name: "metadata_schema_id",
              label: metadataSchemaLabel,
              options: schemaAutocompleteOptions,
              getOptionLabel: getOptionLabel(schemaOptions),
              disableClearable: true,
              labelProps: {
                tooltip: metadataSchemaLabelTooltip,
              },
              onChange: (e, option) => {
                setTab("metadata");
                formFactory.setValue("metadata_schema_id", option, { shouldValidate: true });
              },
              rules: {
                required: "Admin Managed User Attributes Schema is required",
              },
            })}

            {formFactory.createAutocompleteField({
              name: "business_metadata_schema_id",
              label: businessMetadataSchemaLabel,
              defaultValue: null,
              options: schemaAutocompleteOptions,
              getOptionLabel: getOptionLabel(schemaOptions),
              disableClearable: defaultBusinessMetadataSchemaExists,
              labelProps: {
                tooltip: businessMetadataSchemaLabelTooltip,
              },
              onChange: (e, option) => {
                formFactory.setValue("business_metadata_schema_id", option, {
                  shouldValidate: true,
                });
                setTab(option ? "business_metadata" : "payload");
              },
              rules: defaultBusinessMetadataSchemaExists
                ? {
                    required: "Business Admin Managed User Attributes Schema is required",
                  }
                : {},
            })}

            {formFactory.createFormFooter({
              onSubmit: handleUpdate,
            })}

            <RouteLeavingGuard />
          </div>
          <div className={classes.rightContainer}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Tabs value={tab} onChange={(_, tab) => setTab(tab)} style={{ margin: "8px 42px 0" }}>
                <Tab id="payload" label={payloadSchemaLabel} value="payload" key="payload" />
                <Tab id="metadata" label={metadataSchemaLabel} value="metadata" key="metadata" />
                {businessMetadataSchema && (
                  <Tab
                    id="business_metadata"
                    label={businessMetadataSchemaLabel}
                    value="business_metadata"
                    key="business_metadata"
                  />
                )}
              </Tabs>
              <SquareChip label="Read Only" style={{ marginRight: 24 }} />
            </div>

            <div className={classes.editorContainer}>
              <Editor
                theme="my-theme2"
                defaultLanguage="json"
                options={{
                  minimap: {
                    enabled: false,
                  },
                  scrollBeyondLastLine: false,
                  folding: false,
                  lineNumbersMinChars: 3,
                  automaticLayout: true,
                  readOnly: true,
                }}
                value={value}
                wrapperProps={{
                  id: "identity-pool-schemas-editor",
                }}
                className={classes.editor}
              />
            </div>

            <div className={classes.footer}>
              <Button
                onClick={() => {
                  navigate(
                    `/identity-pools/schemas/${
                      tab === "metadata"
                        ? metadataSchemaId
                        : tab === "business_metadata"
                        ? businessMetadataSchemaId
                        : payloadSchemaId
                    }/schema`
                  );
                }}
              >
                <Edit2 size={12} style={{ marginRight: 8 }} /> Edit
              </Button>
            </div>
          </div>
        </div>
      </FormContext.Provider>
    </PageContent>
  );
};

export default IdentityPoolSchemas;

import React, { useState } from "react";
import { FieldValues } from "react-hook-form";

import Grid from "@mui/material/Grid";

import { Attribute } from "@cloudentity/acp-admin";

import { useFormContext } from "../../../../common/utils/forms2/Form";
import { useFeature } from "../../../../common/utils/hooks/useFeature";
import { IdpUiModelType } from "../identities.utils";
import ConfigureIDPDisabledJITDefaultRole from "./ConfigureIDPDisabledJITDefaultRole";
import { IdentitiesDetailsProvisioningAccessRole } from "./IdentitiesDetailsProvisioningAccessRole";
import IdentitiesDetailsProvisioningIdentifiers from "./IdentitiesDetailsProvisioningIdentifiers";
import IdentitiesDetailsProvisioningMapping from "./IdentitiesDetailsProvisioningMapping";
import IdentitiesDetailsProvisioningPoolSelector from "./IdentitiesDetailsProvisioningPoolSelector";

interface Props {
  method: IdpUiModelType;
  attributes: Attribute[];
  noManagePermission: boolean;
  handleUpdate: (provisioning: "enabled" | "disabled", data: FieldValues) => void;
}

export default function IdentitiesDetailsProvisioningConfig({
  method,
  attributes,
  noManagePermission,
  handleUpdate,
}: Props) {
  const [bulkSelectionActive, setBulkSelectionActive] = useState(false);
  const isRolesEnabled = useFeature("roles");
  const isWithRolesEnabled = useFeature("with_roles");

  const { form } = useFormContext();
  const provisioning = form.watch("provisioning");

  if (
    provisioning === "disabled" &&
    method.authorization_server_id === "admin" &&
    isRolesEnabled &&
    isWithRolesEnabled
  ) {
    return <ConfigureIDPDisabledJITDefaultRole method={method} handleUpdate={handleUpdate} />;
  }

  return provisioning === "enabled" ? (
    <>
      <IdentitiesDetailsProvisioningPoolSelector serverId={method.authorization_server_id!} />

      <Grid item xs={12} lg={12}>
        {method.authorization_server_id === "admin" && <IdentitiesDetailsProvisioningAccessRole />}

        <IdentitiesDetailsProvisioningIdentifiers method={method} attributes={attributes} />

        <IdentitiesDetailsProvisioningMapping
          attributes={attributes}
          noManagePermission={noManagePermission}
          method={method}
          bulkSelectionActive={bulkSelectionActive}
          setBulkSelectionActive={setBulkSelectionActive}
          handleUpdate={handleUpdate}
        />
      </Grid>
    </>
  ) : null;
}

import { combineReducers, createStore } from "redux";

import getMiddleware from "./middleware";

export default function configureStore(reducers, initialState) {
  return createStore(
    combineReducers({
      ...reducers,
    }),
    initialState,
    getMiddleware()
  );
}

import React, { useMemo, useState } from "react";

import { AuthenticationContextSettings, Claim } from "@cloudentity/acp-admin";

import Dialog from "../../../../common/components/Dialog";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { validators } from "../../../../common/utils/forms/validation";
import {
  getSourcePathWithMetadataKey,
  PathOptions,
  SourcePath,
  SourceType,
  sourceTypeOptions,
} from "./ClaimUtils";

export interface SamlClaimsEditProps {
  options: AuthenticationContextSettings["attributes"];
  model: Claim & { metadata_key?: string };
  existingClaims: string[];
  onEdit: (claim: Claim) => void;
  onCancel: () => void;
  progress: boolean;
}

const SamlClaimsEdit = ({
  options,
  model,
  existingClaims,
  onEdit,
  onCancel,
  progress,
}: SamlClaimsEditProps) => {
  const data = useMemo(
    () => ({
      source_type: sourceTypeOptions.find(t => t.value === model.source_type)?.value,
      source_path:
        PathOptions(model.source_type, options).find(o => o.name === model.source_path) ||
        model.source_path,
      metadata_key: model.metadata_key,
      name: model.name,
      description: model.description,
      saml_name: model.saml_name,
      saml_name_format: model.saml_name_format,
    }),
    [model, options]
  );

  const formFactory = useFormFactory({ id: "edit-claim", data, progress });

  const [paths, setPaths] = useState(PathOptions(model.source_type, options));

  const handleEdit = data => {
    const claim = {
      ...model,
      source_type: data.source_type,
      source_path: getSourcePathWithMetadataKey(
        data.source_type,
        data.source_path,
        data.metadata_key
      ),
      name: data.name,
      description: data.description,
      saml_name: data.saml_name,
      saml_name_format: data.saml_name_format,
    };
    onEdit(claim);
  };

  return (
    <Dialog onClose={onCancel} id="edit-claim-dialog" title="Edit claim">
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createRequiredField({
          name: "name",
          label: "Name",
          rules: {
            validate: {
              notUniq: validators.notUniq({
                label: "Name",
                options: existingClaims.filter(v => v !== data.name),
              }),
            },
          },
        })}
        {formFactory.createField({
          name: "description",
          label: "Description",
        })}
        <SourceType
          formFactory={formFactory}
          options={sourceTypeOptions}
          authnCtxOptions={options}
          setPaths={setPaths}
        />
        <SourcePath formFactory={formFactory} options={paths} />

        {formFactory.createRequiredField({
          name: "saml_name",
          label: "SAML name",
        })}

        {formFactory.createRequiredField({
          name: "saml_name_format",
          label: "SAML name format",
        })}

        {formFactory.createFormFooter({ onCancel, onSubmit: handleEdit, submitText: "Update" })}
      </FormContext.Provider>
    </Dialog>
  );
};

export default SamlClaimsEdit;

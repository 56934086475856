import React, { useCallback, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import difference from "lodash/difference";

import { Attribute, Mapping, ServerResponse } from "@cloudentity/acp-admin";

import RouteLeavingGuardSimple from "../../../common/components/RouteLeavingGuardSimple";
import { IdpType } from "../../services/adminIDPsApi";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import IdentitiesDetailsMappingsTableRow from "./IdentitiesDetailsMappingsTableRow";
import { IdpUiModelType } from "./identities.utils";
import {
  allDefined,
  buildMappingsModel,
  hasModelChanged,
  RowMapping,
  StaticMappingTypes,
} from "./identitiesMappings.utils";

const ID = "identities-mappings";

function targetOptions(mappings: RowMapping[], server: ServerResponse | undefined) {
  return difference(
    server?.authentication_context_settings?.attributes ?? [],
    mappings.filter(m => m.editValue.target).map(m => m.editValue.target)
  ).filter(v => v) as Attribute[];
}

interface Props {
  idp: IdpUiModelType;
  server: ServerResponse | undefined;
  onUpdate: (provider: IdpUiModelType) => void;
}

export default function IdentitiesDetailsMappingsTable({ idp, server, onUpdate }: Props) {
  const [mappings, setMappings] = useState<RowMapping[]>([]);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(idp.authorization_server_id);

  const handleRemove = useCallback(
    (index: number) =>
      setMappings(mapping => mapping.slice(0, index).concat(mapping.slice(index + 1))),
    []
  );

  const handleChange = useCallback(
    (
      index: number,
      value: { source?: Attribute | null | StaticMappingTypes; target?: Attribute | null }
    ) => {
      setMappings(mappings => {
        return mappings.map((mapping, i) => {
          if (i === index) {
            return { ...mapping, editValue: { ...mapping.editValue, ...value } };
          } else {
            return mapping;
          }
        }) as RowMapping[];
      });
    },
    []
  );

  useEffect(() => setMappings(buildMappingsModel(idp, server)), [idp, server]);

  const sourceOptions = idp?.attributes ?? [];
  const noChanges = !hasModelChanged(mappings, idp) || !allDefined(mappings);

  return (
    <TableContainer component={Paper}>
      <Table id={`${ID}-table`} aria-label="identities mappings table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Source</TableCell>
            <TableCell align="left">Source name</TableCell>
            <TableCell padding="none" />
            <TableCell align="left">Target name</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {mappings.length === 0 && (
            <TableRow>
              <TableCell align="center" colSpan={5} style={{ padding: 28 }}>
                Removing all mappings will restore the defaults
              </TableCell>
            </TableRow>
          )}
          {mappings.map((row, index) => (
            <IdentitiesDetailsMappingsTableRow
              key={index}
              row={row}
              index={index}
              onChange={handleChange}
              onRemove={handleRemove}
              sourceOptions={sourceOptions}
              targetOptions={targetOptions(mappings, server)}
              idpType={idp.method as IdpType}
              editable={!!checkWorkspacePermissionsQuery.data?.manage_idps}
            />
          ))}
        </TableBody>
      </Table>
      {checkWorkspacePermissionsQuery.data?.manage_idps && (
        <div style={{ padding: "32px 24px", display: "flex", justifyContent: "space-between" }}>
          <div>
            <Button
              onClick={() =>
                setMappings([
                  ...mappings,
                  {
                    editValue: { source: null, target: null, allowWeakDecoding: false },
                    static: false,
                  },
                ])
              }
              variant="outlined"
              id={`${ID}-add-button`}
            >
              + Add mapping
            </Button>

            <Button
              onClick={() => {
                setMappings([
                  ...mappings,
                  {
                    editValue: { source: null, target: null, allowWeakDecoding: false },
                    static: true,
                  },
                ]);
              }}
              variant="outlined"
              id={`${ID}-add-static-button`}
              style={{ marginLeft: 16 }}
            >
              + Add static mapping
            </Button>
          </div>
          <Button
            onClick={() => {
              const newMappings = mappings
                .map(mapping => {
                  if (!mapping) {
                    return null;
                  }
                  if (mapping.static) {
                    return {
                      mode: "static",
                      target: mapping.editValue.target?.name,
                      static: mapping.editValue.source,
                      type: mapping.editValue.target?.type,
                    };
                  }
                  if (idp.method === "saml_v2") {
                    return {
                      source: mapping.editValue.source?.name,
                      target: mapping.editValue.target?.name,
                      allow_weak_decoding:
                        mapping.editValue.source?.type === "string_array"
                          ? true
                          : mapping.editValue.source?.type !== mapping.editValue.target?.type,
                      type: mapping.editValue.target?.type,
                    } as Mapping;
                  }

                  return {
                    source: mapping.editValue.source?.name,
                    target: mapping.editValue.target?.name,
                    allow_weak_decoding:
                      mapping.editValue.source?.type !== mapping.editValue.target?.type,
                    type: mapping.editValue.target?.type,
                  } as Mapping;
                })
                .filter((v): v is Mapping => !!v);

              onUpdate({ ...idp, mappings: newMappings });
            }}
            disabled={noChanges}
            variant="contained"
            id={`${ID}-save-button`}
          >
            Save mappings
          </Button>
        </div>
      )}
      <RouteLeavingGuardSimple when={!noChanges} />
    </TableContainer>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";

import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ModelError } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import RouterLink from "../../../common/components/RouterLink";
import {
  handleErrorWithNotify,
  notifyError,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { IdentityTabs } from "../../../common/utils/viewTabs";
import adminIDPsApi, { IdpType } from "../../services/adminIDPsApi";
import {
  getIDPQueryKey,
  listIDPsForIdentityPoolQueryKey,
  listIDPsQueryKey,
  useGetIDP,
} from "../../services/adminIDPsQuery";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import { useGetAuthorizationServer } from "../../services/adminServersQuery";
import PageContainer from "../common/PageContainer";
import PageContent from "../common/PageContent";
import PageHeader from "../common/PageHeader";
import IdentitiesDetailsContent from "./IdentitiesDetailsContent";
import {
  getIdentityDetailsTabs,
  IdentityUserView,
  IdpUiModelType,
  transformIdpToAPI,
  transformIdpToModel,
} from "./identities.utils";

interface IdentitiesDetailsControllerProps {
  type: IdpType | undefined;
  id: string;
  serverId: string;
  tab: IdentityTabs;
  handleTabChange: (tab: IdentityTabs) => void;
  enabledDiscoveryAlert: boolean;
  setEnabledDiscoveryAlert: (enabled: boolean) => void;
  withBreadcrumb?: boolean;
  handleBackToListView: () => void;
}

const IdentitiesDetailsController = ({
  type,
  id,
  serverId,
  tab,
  handleTabChange,
  enabledDiscoveryAlert,
  setEnabledDiscoveryAlert,
  withBreadcrumb = true,
  handleBackToListView,
}: IdentitiesDetailsControllerProps) => {
  const [updateProgress, setUpdateProgress] = useState(false);
  const [progress, setProgress] = useState(false);
  const [errors, setErrors] = useState(null);
  const [usersTabView, setUsersTabView] = useState<IdentityUserView>("list");

  const navigate = useNavigate();
  const tenantId = getTenantId();
  const queryClient = useQueryClient();

  const getAuthorizationServerQuery = useGetAuthorizationServer(tenantId, serverId);
  const getIDPQuery = useGetIDP(serverId, type, id);
  const server = getAuthorizationServerQuery.data;

  useEffect(() => {
    if (
      getIDPQuery.error &&
      (getIDPQuery.error as AxiosError<ModelError>)?.response?.data?.status_code === 404
    ) {
      notifyError("IDP not found");
      navigate(`/${serverId}/identities`);
    }
  }, [getIDPQuery.error, serverId, navigate]);

  const idp = useMemo(() => {
    if (getIDPQuery.data) {
      return transformIdpToModel(getIDPQuery.data, server?.issuer_url ?? "");
    }
  }, [getIDPQuery.data, server]);

  const handleUpdate = useCallback(
    (provider: IdpUiModelType) => {
      setUpdateProgress(true);
      setErrors(null);
      if (enabledDiscoveryAlert) {
        setEnabledDiscoveryAlert(false);
        window.history.replaceState({}, "");
      }
      return adminIDPsApi
        .updateIDP({
          aid: serverId,
          type: provider.method,
          iid: id,
          body: {
            ...transformIdpToAPI(provider),
            name: provider.name?.trim(),
          },
        })
        .then(() => queryClient.invalidateQueries({ queryKey: getIDPQueryKey(id, serverId) }))
        .then(() =>
          queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(getTenantId(), serverId) })
        )
        .then(() =>
          queryClient.invalidateQueries({
            queryKey: listIDPsForIdentityPoolQueryKey(getTenantId()),
          })
        )
        .then(() => notifySuccess("Identity provider updated successfully"))
        .catch(err => {
          setErrors(err.response?.data ?? null);
          handleErrorWithNotify("Error occurred while trying to update identity")(err);
          return Promise.reject(err);
        })
        .finally(() => setUpdateProgress(false));
    },
    [enabledDiscoveryAlert, serverId, id, setEnabledDiscoveryAlert, queryClient]
  );

  const handleRemove = useCallback(
    (provider: IdpUiModelType) => {
      setProgress(true);
      adminIDPsApi
        .removeIDP({ wid: serverId, iid: provider.id ?? "" })
        .then(() =>
          queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(getTenantId(), serverId) })
        )
        .then(() =>
          queryClient.invalidateQueries({
            queryKey: listIDPsForIdentityPoolQueryKey(getTenantId()),
          })
        )
        .then(handleBackToListView)
        .catch(handleErrorWithNotify("Error occurred while trying to remove IDP"))
        .finally(() => setProgress(false));
    },
    [serverId, handleBackToListView, queryClient]
  );

  const loading = getAuthorizationServerQuery.isLoading || getIDPQuery.isLoading || progress;
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(serverId);

  const fullWidth = [
    IdentityTabs.ATTRIBUTES,
    IdentityTabs.USERS,
    IdentityTabs.MAPPINGS,
    IdentityTabs.PROVISIONING,
  ].includes(tab);

  return (
    <PageContainer progress={loading}>
      {!idp && <div>Not found</div>}
      {idp && (
        <>
          <PageHeader
            title={withBreadcrumb ? idp.name ?? "" : "Configure IDP"}
            breadcrumb={
              withBreadcrumb ? (
                <>
                  <RouterLink to={`/${serverId}/identities`}>Identity Providers</RouterLink> &gt;
                  Settings
                </>
              ) : undefined
            }
            tabs={getIdentityDetailsTabs(idp.method!, checkWorkspacePermissionsQuery.data)}
            currentTab={tab}
            onChangeTab={handleTabChange}
          />
          <PageContent fullWidth={fullWidth}>
            <IdentitiesDetailsContent
              tab={tab}
              enabledDiscoveryAlert={enabledDiscoveryAlert}
              setEnabledDiscoveryAlert={setEnabledDiscoveryAlert}
              idp={idp}
              errors={errors}
              setErrors={setErrors}
              updateProgress={updateProgress}
              handleUpdate={handleUpdate}
              handleDelete={handleRemove}
              authorizationServerData={getAuthorizationServerQuery.data}
              usersTabView={usersTabView}
              setUsersTabView={setUsersTabView}
            />
          </PageContent>
        </>
      )}
    </PageContainer>
  );
};

export default IdentitiesDetailsController;

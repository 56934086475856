import React, { useMemo, useState } from "react";

import Grid from "@mui/material/Grid";
import { UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { JSONSchema7 } from "json-schema";
import { isEmpty, path } from "ramda";
import { makeStyles } from "tss-react/mui";

import { GrantTenantRoleRequestRoleEnum } from "@cloudentity/acp-admin";
import {
  PoolResponse,
  PoolResponseAuthenticationMechanismsEnum,
  Schema,
} from "@cloudentity/acp-identity";

import { portalMode } from "../../../../../../../common/api/paths";
import Alert from "../../../../../../../common/components/Alert";
import Dialog from "../../../../../../../common/components/Dialog";
import { passwordPolicyToScore } from "../../../../../../../common/components/PasswordStrengthMeter";
import { notifyErrorOrDefaultTo } from "../../../../../../../common/components/notifications/notificationService";
import { FormContext } from "../../../../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../../../../common/utils/forms/formFactory";
import { validators } from "../../../../../../../common/utils/forms/validation";
import { useFeature } from "../../../../../../../common/utils/hooks/useFeature";
import TenantRoleSelectField from "../../../../administrator/TenantRoleSelectField";
import WorkspaceRoleSelectField from "../../../../administrator/WorkspaceRoleSelectField";
import { useTenantRoles } from "../../../../administrator/useTenantRoles";
import {
  extractOnlyRequiredProperties,
  getUIOrderBasedOnRequiredFields,
  mapFieldNameToTitle,
} from "../../../schemas/schemas.utils";
import IdentityPoolThemeSelector from "../../IdentityPoolThemeSelector";
import { useIdentityPoolThemeSelector } from "../../useIdentityPoolThemeSelector";
import { useFormExtraErrors } from "../identityPoolUsers.utils";
import SchemaForm from "../user/SchemaForm";

export type CreateData = {
  mode: "invite" | "set-credentials";
  password?: string;
  email?: string;
  phone?: string;
  identifier: "email" | "mobile";
  payload: any;
  metadata: any;
  businessMetadata: any;
  role: GrantTenantRoleRequestRoleEnum;
  roles?: string[];
  addAnotherItem?: boolean;
  serverId?: string;
  rememberThemeWorkspaceId?: boolean;
  themeWorkspaceId?: string;
  temporaryPassword: boolean;
};
const useStyles = makeStyles()(() => ({
  identifierContainer: {
    marginBottom: 16,
    "& .iti__country-list": {
      width: 518,
    },
  },
  formContainer: {
    paddingTop: "0 !important",
    "& h6": {
      display: "none !important",
    },
  },
}));

interface Props {
  pool: PoolResponse | undefined;
  payloadSchema: Schema | undefined;
  metadataSchema: Schema | undefined;
  businessMetadataSchema: Schema | undefined;
  allowMetadata: boolean;
  allowBusinessMetadata: boolean;
  onCreate: (data: CreateData) => Promise<any>;
  onClose: () => void;
  progress: boolean;
  initialData?: object;
  isRolesVisible?: boolean;
  workspaceId?: string;
  isAddAnotherItemVisible?: boolean;
  disableWhenMetadataHasRequiredProperties?: boolean;
}

export default function IdentityPoolUserCreate({
  pool,
  payloadSchema,
  metadataSchema,
  businessMetadataSchema,
  allowMetadata,
  allowBusinessMetadata,
  onCreate,
  onClose,
  progress,
  initialData,
  isRolesVisible,
  workspaceId,
  isAddAnotherItemVisible,
  disableWhenMetadataHasRequiredProperties,
}: Props) {
  const { classes } = useStyles();

  const [requestError, setRequestError] = useState("");
  const {
    extraErrors: payloadExtraErrors,
    setError: setPayloadError,
    resetExtraErrors: resetPayloadExtraErrors,
  } = useFormExtraErrors();
  const {
    extraErrors: metadataExtraErrors,
    setError: setMetadataError,
    resetExtraErrors: resetMetadataExtraErrors,
  } = useFormExtraErrors();
  const {
    extraErrors: businessMetadataExtraErrors,
    setError: setBusinessMetadataError,
    resetExtraErrors: resetBusinessMetadataExtraErrors,
  } = useFormExtraErrors();
  const [passwordErrors, setPasswordErrors] = useState<string>();

  const passwordPolicy = pool?.password_policy;
  const passwordStrengthScore =
    (passwordPolicy?.strength && passwordPolicyToScore[passwordPolicy?.strength]) || 0;
  const showModeSelector = !!pool?.authentication_mechanisms?.includes(
    PoolResponseAuthenticationMechanismsEnum.Password
  );

  const [submitAttempt, setSubmitAttempt] = useState(false);
  const [payload, setPayload] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [businessMetadata, setBusinessMetadata] = useState(null);

  const [formsInstanceId, setFormsInstanceId] = useState(1);

  const { mustSelectThemeServerId } = useIdentityPoolThemeSelector({ pool: pool! });

  const payloadCreateSchema = extractOnlyRequiredProperties(payloadSchema?.schema || {});
  const metadataCreateSchema = extractOnlyRequiredProperties(metadataSchema?.schema || {});
  const businessMetadataCreateSchema = extractOnlyRequiredProperties(
    businessMetadataSchema?.schema || {}
  );

  const createUserDisabled =
    disableWhenMetadataHasRequiredProperties &&
    (!isEmpty(metadataCreateSchema) || !isEmpty(businessMetadataCreateSchema));

  const data = useMemo(() => initialData, [initialData]);
  const formFactory = useFormFactory({
    id: "identity-pool-create-user",
    data,
    progress,
    mode: "onTouched",
    disabled: createUserDisabled,
  });
  const identifier = formFactory.watch("identifier");
  const mode = formFactory.watch("mode");

  const payloadSchemaWithMappedTitles = mapFieldNameToTitle(payloadCreateSchema);
  const metadataSchemaWithMappedTitles = mapFieldNameToTitle(metadataCreateSchema);
  const businessMetadataSchemaWithMappedTitles = mapFieldNameToTitle(businessMetadataCreateSchema);

  const validatePayload = validator.validateFormData(
    payload,
    (payloadSchemaWithMappedTitles as JSONSchema7) || {}
  );

  const validateMetadata = validator.validateFormData(
    metadata,
    (metadataSchemaWithMappedTitles as JSONSchema7) || {}
  );

  const validateBusinessMetadata = validator.validateFormData(
    businessMetadata,
    (businessMetadataSchemaWithMappedTitles as JSONSchema7) || {}
  );

  const payloadUISchema: UiSchema = {
    "ui:order": getUIOrderBasedOnRequiredFields(payloadSchemaWithMappedTitles || {}),
  };

  const metadataUISchema: UiSchema = {
    "ui:order": getUIOrderBasedOnRequiredFields(metadataSchemaWithMappedTitles || {}),
  };

  const businessMetadataUISchema: UiSchema = {
    "ui:order": getUIOrderBasedOnRequiredFields(businessMetadataSchemaWithMappedTitles || {}),
  };

  const isAdminWorkspaceAccessEnabled = useFeature("admin_workspace_access");
  const { isEnabled: isRoleVisible } = useTenantRoles({
    identityPoolId: pool?.id ?? "",
    disabled: !isAdminWorkspaceAccessEnabled,
  });

  return (
    <Dialog
      onClose={onClose}
      title="Create User"
      id="create-identity-pool-user-dialog"
      fullWidth
      maxWidth="md"
    >
      <FormContext.Provider value={formFactory.context}>
        <Grid container spacing={2}>
          {createUserDisabled && (
            <Grid item xs={12}>
              <Alert severity="error" title="Create users is disabled">
                We apologize, but account creation is not possible due to permission issues. Please
                contact the administrator for assistance.
              </Alert>

              {formFactory.createFormFooter({ onCancel: onClose })}
            </Grid>
          )}
          {!createUserDisabled && (
            <>
              <Grid item xs={12} className={classes.identifierContainer}>
                {formFactory.createRadioGroupField({
                  name: "identifier",
                  values: [
                    { key: "email", label: "Email", value: "email" },
                    { key: "mobile", label: "Mobile", value: "mobile" },
                  ],
                  onChange: () => setRequestError(""),
                  style: { marginBottom: 12, ...(portalMode === "b2b" && { display: "none" }) },
                })}
                {identifier === "email" &&
                  formFactory.createRequiredField({
                    name: "email",
                    label: "Email",
                    rules: {
                      validate: {
                        validEmail: validators.validEmail({ label: "Value" }),
                      },
                    },
                    inputProps: {
                      autoComplete: "off",
                    },
                    optional: false,
                    externalErrors: requestError || null,
                    onChange: () => setRequestError(""),
                    style: { marginBottom: 0 },
                  })}
                {identifier === "mobile" &&
                  formFactory.createPhoneInput({
                    name: "phone",
                    label: "Phone",
                    selector: "#identity-pool-create-user-phone-input",
                    optional: false,
                    required: true,
                    externalErrors: requestError || null,
                    onChange: () => setRequestError(""),
                    style: { marginBottom: 0 },
                  })}
              </Grid>

              {payloadSchema?.schema && !isEmpty(payloadSchemaWithMappedTitles) && (
                <Grid
                  item
                  xs={12}
                  lg={12}
                  style={{ marginBottom: 16 }}
                  className={portalMode === "b2b" ? classes.formContainer : undefined}
                >
                  <SchemaForm
                    key={formsInstanceId + 1}
                    formData={payload}
                    setFormData={setPayload}
                    schema={payloadSchemaWithMappedTitles as JSONSchema7}
                    UISchema={payloadUISchema}
                    submitAttempt={submitAttempt}
                    extraErrors={payloadExtraErrors}
                    resetExtraErrors={resetPayloadExtraErrors}
                  />
                </Grid>
              )}

              {allowMetadata &&
                metadataSchema?.schema &&
                !isEmpty(metadataSchemaWithMappedTitles) && (
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    style={{ marginTop: 0, marginBottom: 16 }}
                    className={portalMode === "b2b" ? classes.formContainer : undefined}
                  >
                    <SchemaForm
                      key={formsInstanceId + 2}
                      formData={metadata}
                      setFormData={setMetadata}
                      schema={metadataSchemaWithMappedTitles as JSONSchema7}
                      UISchema={metadataUISchema}
                      submitAttempt={submitAttempt}
                      extraErrors={metadataExtraErrors}
                      resetExtraErrors={resetMetadataExtraErrors}
                      disabled={createUserDisabled}
                    />
                  </Grid>
                )}

              {allowBusinessMetadata &&
                businessMetadataSchema?.schema &&
                !isEmpty(businessMetadataSchemaWithMappedTitles) && (
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    style={{ marginTop: 0, marginBottom: 16 }}
                    className={portalMode === "b2b" ? classes.formContainer : undefined}
                  >
                    <SchemaForm
                      key={formsInstanceId + 3}
                      formData={businessMetadata}
                      setFormData={setBusinessMetadata}
                      schema={businessMetadataSchemaWithMappedTitles as JSONSchema7}
                      UISchema={businessMetadataUISchema}
                      submitAttempt={submitAttempt}
                      extraErrors={businessMetadataExtraErrors}
                      resetExtraErrors={resetBusinessMetadataExtraErrors}
                      disabled={createUserDisabled}
                    />
                  </Grid>
                )}

              <Grid
                item
                xs={12}
                style={{
                  marginBottom: mode === "set-credentials" ? 0 : 16,
                  ...(showModeSelector ? {} : { display: "none" }),
                }}
              >
                {formFactory.createFormCardsField({
                  name: "mode",
                  label: "Mode",
                  cards: [
                    {
                      title: "Send invitation",
                      subtitle: "Invitation message will be sent to the user via email or phone",
                      value: "invite",
                    },
                    {
                      title: "Set credentials",
                      subtitle: "Set a password for the user",
                      value: "set-credentials",
                    },
                  ],
                  style: { marginBottom: 8 },
                })}
              </Grid>
              {mode === "invite" && mustSelectThemeServerId && (
                <Grid item xs={12}>
                  <IdentityPoolThemeSelector
                    pool={pool!}
                    formFactory={formFactory}
                    showRememberCheckbox
                  />
                </Grid>
              )}

              {mode === "set-credentials" && (
                <Grid item xs={12}>
                  {formFactory.createRequiredField({
                    name: "password",
                    label: "Password",
                    toggleVisibility: true,
                    defaultVisibility: false,
                    withCopy: true,
                    withPasswordStrengthMeter: true,
                    rules: {
                      validate: {
                        minLength: validators.minLength({
                          label: "Password",
                          min: passwordPolicy?.min_length ?? 0,
                        }),
                        validPasswordStrength: validators.validPasswordStrength({
                          minScore: passwordStrengthScore,
                        }),
                        minNumOfDigits: validators.minNumOfDigits({
                          label: "Password",
                          min: passwordPolicy?.digits ?? 0,
                        }),
                        minNumOfCapitalLetters: validators.minNumOfCapitalLetters({
                          label: "Password",
                          min: passwordPolicy?.capital_letters ?? 0,
                        }),
                        minNumOfLowercaseLetters: validators.minNumOfLowercaseLetters({
                          label: "Password",
                          min: passwordPolicy?.lowercase_letters ?? 0,
                        }),
                        minNumOfSpecialCharacters: validators.minNumOfSpecialCharacters({
                          label: "Password",
                          min: passwordPolicy?.special_characters ?? 0,
                        }),
                      },
                    },
                    externalErrors: passwordErrors,
                  })}

                  {formFactory.createCheckBox({
                    name: "temporaryPassword",
                    label: "Password must be changed upon first login",
                  })}
                </Grid>
              )}

              {isRoleVisible && (
                <Grid item xs={12} style={{ marginBottom: 16 }}>
                  <TenantRoleSelectField
                    identityPoolId={pool?.id ?? ""}
                    formFactory={formFactory}
                  />
                </Grid>
              )}

              {isRolesVisible && workspaceId && (
                <Grid item xs={12}>
                  <WorkspaceRoleSelectField
                    wid={workspaceId}
                    formFactory={formFactory}
                    poolId={pool?.id}
                    optional
                  />
                </Grid>
              )}

              <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                {!!isAddAnotherItemVisible ? (
                  formFactory.createCheckBox({
                    name: "addAnotherItem",
                    label: "Create another user",
                    style: { marginBottom: 0, flex: 1 },
                  })
                ) : (
                  <div />
                )}

                {formFactory.createFormFooter({
                  disabled:
                    createUserDisabled ||
                    (submitAttempt &&
                      (validatePayload.errors.length > 0 ||
                        validateMetadata.errors.length > 0 ||
                        validateBusinessMetadata.errors.length > 0)),
                  onCancel: onClose,
                  onSubmit: data => {
                    setSubmitAttempt(true);
                    if (
                      validatePayload.errors.length > 0 ||
                      validateMetadata.errors.length > 0 ||
                      validateBusinessMetadata.errors.length > 0
                    ) {
                      return;
                    }
                    onCreate({
                      ...data,
                      payload,
                      metadata,
                      businessMetadata,
                    })
                      .then(() => {
                        if (data.addAnotherItem) {
                          setSubmitAttempt(false);
                          formFactory.reset(
                            { ...initialData, addAnotherItem: true },
                            { keepDefaultValues: true }
                          );
                          setPayload(null);
                          setMetadata(null);
                          setFormsInstanceId(id => id + 2);
                        }
                      })
                      .catch(err => {
                        if (path(["response", "status"], err) === 409) {
                          setRequestError("Identifier is already registered");
                          return err;
                        }
                        setPayloadError(err);
                        setMetadataError(err);
                        setBusinessMetadataError(err);

                        if (
                          err?.response?.data &&
                          err.response.data.status_code === 422 &&
                          err.response.data.error === "password doesn't match password policy" &&
                          err.response.data.details?.length
                        ) {
                          setPasswordErrors(err.response.data.details[0].message);
                        }

                        return notifyErrorOrDefaultTo("Error occurred when trying to create user")(
                          err
                        );
                      });
                  },
                  submitText: "Create",
                  onSubmitButtonClick: () => setSubmitAttempt(true),
                })}
              </Grid>
            </>
          )}
        </Grid>
      </FormContext.Provider>
    </Dialog>
  );
}

import React, { Fragment, ReactNode } from "react";

function escapeRegExp(text: string) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export function highlight(pattern: string, value: string): ReactNode {
  if (!pattern || !value) return <>{value}</>;

  const output: ReactNode[] = [];
  let lastPart = value;
  let result: RegExpExecArray | null = null;

  do {
    const key = output.length;
    result = new RegExp(escapeRegExp(pattern), "i").exec(lastPart);
    if (result) {
      const { index } = result;
      const before = lastPart.slice(0, index);
      const item = lastPart.slice(index, index + pattern.length);
      const after = lastPart.slice(index + pattern.length);
      lastPart = after;
      output.push(<Fragment key={key}>{before}</Fragment>, <b key={key + 1}>{item}</b>);
    } else {
      output.push(<Fragment key={key}>{lastPart}</Fragment>);
    }
  } while (result);

  return <>{output}</>;
}

export function numberFormatter(num: number | undefined) {
  if (typeof num !== "number" || num === null || num === undefined) {
    return { number: null, unit: null };
  }

  if (num >= 1000000000) {
    return {
      number: (num / 1000000000).toFixed(1).replace(/\.0$/, ""),
      unit: "G",
    };
  }
  if (num >= 1000000) {
    return {
      number: (num / 1000000).toFixed(1).replace(/\.0$/, ""),
      unit: "M",
    };
  }
  if (num >= 10000) {
    // K from 10.000 and higher
    return {
      number: (num / 1000).toFixed(1).replace(/\.0$/, ""),
      unit: "K",
    };
  }
  return { number: num, unit: null };
}

export function scrollToElement(id: string, offset?: number) {
  const element = document.getElementById(id);
  if (element) {
    const y = element.getBoundingClientRect().top + window.pageYOffset - (offset ?? 0);
    window.scrollTo({ top: y, behavior: "smooth" });
  }
}

import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { getTenantId } from "../../../common/api/paths";
import Dialog from "../../../common/components/Dialog";
import { handleErrorWithNotify } from "../../../common/components/notifications/notificationService";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import { validators } from "../../../common/utils/forms/validation";
import adminCustomAppsApi from "../../services/adminCustomAppsApi";
import { listServerCustomAppsQueryKey } from "../../services/adminCustomAppsQuery";
import { useWorkspace } from "../common/useWorkspace";
import { customAppTypesOptions } from "./custom-applications.common";

interface Props {
  onCreated: () => void;
  onCancel: () => void;
}

const initialFormData = {
  type: customAppTypesOptions[0].value,
  url: "https://",
};

const AddCustomApplicationDialog = ({ onCreated, onCancel }: Props) => {
  const [workspace] = useWorkspace();
  const [createProgress, setCreateProgress] = useState(false);

  const formFactory = useFormFactory({
    id: "create-custom-application",
    data: initialFormData,
    progress: createProgress,
  });

  const queryClient = useQueryClient();

  const handleSubmit = data => {
    setCreateProgress(true);
    return adminCustomAppsApi
      .createCustomApp({
        wid: workspace,
        customApp: {
          id: "",
          server_id: workspace,
          tenant_id: getTenantId(),
          name: data.name,
          url: data.url,
          type: data.type,
        },
      })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: listServerCustomAppsQueryKey(getTenantId(), workspace),
        })
      )
      .then(onCreated)
      .catch(handleErrorWithNotify("Error occurred while trying to create custom application"))
      .finally(() => {
        setCreateProgress(false);
      });
  };

  return (
    <Dialog
      onClose={onCancel}
      id="create-custom-application-dialog"
      title="Create Custom Application"
    >
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createSelect({
          label: "Type",
          name: "type",
          options: customAppTypesOptions,
          optional: false,
        })}

        {formFactory.createRequiredField({
          label: "Name",
          name: "name",
        })}

        {formFactory.createRequiredField({
          label: "Application URL",
          name: "url",
          rules: {
            validate: { validHttpsURL: validators.validHttpsURL({ label: "Application URL" }) },
          },
        })}

        {formFactory.createFormFooter({
          onCancel,
          onSubmit: handleSubmit,
          submitText: "Create",
        })}
      </FormContext.Provider>
    </Dialog>
  );
};

export default AddCustomApplicationDialog;

import { append, lensProp, over, filter } from "ramda";

import { mapReducer } from "../../../utils/reducers";
import types from "../actions/types";

const initial = {
  notifications: [],
};

const pushNotification = (state, action) =>
  over(lensProp("notifications"), append(action.data), state);
const removeNotification = (state, action) =>
  over(
    lensProp("notifications"),
    filter(n => n.key !== action.data.key),
    state
  );

const mapping = {
  [types.SHOW_NOTIFICATION]: pushNotification,
  [types.REMOVE_SNACKBAR]: removeNotification,
};

export default mapReducer(mapping, initial);

import React, { useEffect, useMemo } from "react";

import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { lensPath, map, over, pathOr, prop } from "ramda";

import { ServerResponse } from "@cloudentity/acp-admin";

import FormAccordion from "../../../common/components/FormAccordion";
import RouteLeavingGuard from "../../../common/components/RouteLeavingGuard";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import CardWithIconAndTitle from "../common/CardWithIconAndTitle";
import { CommonIdpConfig } from "./CommonIdpConfig";
import CommonIdpConfigUpper from "./CommonIdpConfigUpper";
import IdentitiesDetailsFooter from "./IdentitiesDetailsFooter";
import SSOIDPSettings from "./SSOIDPSettings";
import { amrOptions, amrToResponse, getAMRLabel, getLabel } from "./amrOptions";
import { graphUserAttributesOptions } from "./graphUserAttributesOptions";
import { IdpUiModelAzureB2CType, providers } from "./identities.utils";

const id = "identities-configuration-azureb2c";

interface Props {
  provider: IdpUiModelAzureB2CType;
  server: ServerResponse | undefined;
  updateProgress?: boolean;
  inEdit?: boolean;
  onLogoEdit?: (data: any) => void;
  customSubmit?: boolean;
  onInit?: (fn: () => void) => void;
  onCancel?: () => void;
  onSubmit: (data: IdpUiModelAzureB2CType) => void;
  onDelete?: (idp: IdpUiModelAzureB2CType) => void;
}

export default function IdentitiesConfigurationAzureB2C({
  provider,
  server,
  updateProgress,
  inEdit,
  onLogoEdit,
  customSubmit,
  onCancel,
  onSubmit,
  onInit,
  onDelete,
}: Props) {
  const data = useMemo(
    () => over(lensPath(["settings", "scopes"]), scopes => scopes || [], provider),
    [provider]
  );

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(server?.id);

  const formFactory = useFormFactory({
    id,
    data,
    progress: updateProgress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit,
  });

  const fetchGroups = formFactory.watch("settings.fetch_groups");
  const getUser = formFactory.watch("settings.get_user");

  const submitFn = (provider, data) => ({ ...provider, ...amrToResponse(data), ...data });

  const providerMapData = providers.find(p => p.method === "azureb2c");

  useEffect(() => {
    onInit &&
      onInit(() =>
        formFactory.handleSubmit(data => onSubmit && onSubmit(submitFn(provider, data)))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serverSSOEnabled = server?.sso?.enabled ?? false;

  return (
    <FormContext.Provider value={formFactory.context}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Paper style={{ padding: 32 }}>
            {providerMapData && (
              <CardWithIconAndTitle
                img={providerMapData.icon}
                title={providerMapData.name}
                id={`idp-${providerMapData.name.replace(/ /g, "-")}`}
                style={{ marginBottom: 32, width: "50%" }}
              />
            )}

            <CommonIdpConfigUpper
              formFactory={formFactory}
              provider={provider}
              inEdit={inEdit}
              onLogoEdit={onLogoEdit}
              onUpdate={onSubmit}
            />
            {formFactory.createRequiredField({
              name: "settings.tenant",
              label: "Tenant ID",
              defaultValue: pathOr("", ["settings", "tenant"], provider),
              helperText: (
                <Link
                  href="https://cloudentity.com/developers/howtos/identities/azure-b2c/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  How to configure Azureb2c AD?
                </Link>
              ),
            })}
            {formFactory.createField({
              name: "settings.custom_domain",
              label: "Custom Domain",
              defaultValue: pathOr("", ["settings", "custom_domain"], provider),
            })}
            {formFactory.createRequiredField({
              name: "settings.policy",
              label: "Policy",
              defaultValue: pathOr("", ["settings", "policy"], provider),
            })}
            {formFactory.createRequiredField({
              name: "settings.client_id",
              label: "Client ID",
              defaultValue: pathOr("", ["settings", "client_id"], provider),
              withCopy: true,
              inputProps: {
                autoComplete: "off",
              },
            })}
            {formFactory.createRequiredField({
              name: "credentials.client_secret",
              label: "Client Secret",
              defaultValue: pathOr("", ["credentials", "client_secret"], provider),
              toggleVisibility: true,
              defaultVisibility: false,
              withCopy: true,
              inputProps: {
                autoComplete: "off",
              },
            })}
            {formFactory.createReadOnlyField({
              name: "redirectUrl",
              label: "Redirect URL",
              withCopy: true,
            })}
            <CommonIdpConfig formFactory={formFactory} data={provider} />

            {inEdit && (
              <FormAccordion title="Advanced settings" id={id}>
                {formFactory.createAutocompleteField({
                  name: "settings.scopes",
                  label: "Scopes",
                  options: [],
                  freeSolo: true,
                  multiple: true,
                  optional: false,
                  helperText:
                    "To add multiple values, press the 'enter' key after adding each value",
                })}
                {formFactory.createAutocompleteField({
                  name: "static_amr",
                  label: "Authentication Method Reference",
                  helperText: "If set overwrites AMR obtained from this authentication method",
                  defaultValue: pathOr([], ["static_amr"], provider),
                  options: map(prop("value"), amrOptions),
                  getOptionLabel: getAMRLabel,
                  multiple: true,
                })}
                {formFactory.createCheckBox({
                  name: "settings.get_user",
                  label: "Fetch user attributes from Azure Graph",
                  style: { marginBottom: 12 },
                })}
                <div style={{ display: getUser ? "block" : "none", marginLeft: 32 }}>
                  {formFactory.createAutocompleteField({
                    name: "settings.graph_user_attributes",
                    label: "Graph User Attributes",
                    defaultValue: pathOr([], ["settings", "graph_user_attributes"], provider),
                    options: map(prop("value"), graphUserAttributesOptions),
                    getOptionLabel: getLabel(graphUserAttributesOptions),
                    freeSolo: true,
                    multiple: true,
                  })}
                </div>
                {formFactory.createCheckBox({
                  name: "settings.fetch_groups",
                  label: "Fetch groups",
                  style: { marginBottom: 12 },
                })}
                <div style={{ display: fetchGroups ? "block" : "none", marginLeft: 32 }}>
                  {formFactory.createCheckBox({
                    name: "settings.only_security_groups",
                    label: "Only security groups",
                  })}
                  {formFactory.createSelect({
                    name: "settings.group_name_format",
                    label: "Group name format",
                    options: [
                      { name: "ID", value: "id" },
                      { name: "Name", value: "name" },
                    ],
                  })}
                </div>
                {formFactory.createCheckBox({
                  name: "settings.send_login_hint",
                  label: (
                    <span>
                      Send <code>login_hint</code>
                    </span>
                  ),
                  helperText: (
                    <span>
                      Sends the entered identifier as a <code>login_hint</code> to the IDP
                    </span>
                  ),
                })}
                {serverSSOEnabled && <SSOIDPSettings formFactory={formFactory} />}
              </FormAccordion>
            )}

            <IdentitiesDetailsFooter
              customSubmit={customSubmit}
              idp={provider}
              formFactory={formFactory}
              onSubmit={data => onSubmit && onSubmit(submitFn(provider, data))}
              onCancel={onCancel}
              onDelete={onDelete}
            />
          </Paper>
        </Grid>
        {inEdit && <RouteLeavingGuard />}
      </Grid>
    </FormContext.Provider>
  );
}

import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import { useQueryClient } from "@tanstack/react-query";
import { lensProp, not, over } from "ramda";

import { OrganizationResponse, Server, ServerResponse } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import {
  handleErrorWithNotify,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { listOrganizationsQueryKey } from "../../../services/adminOrganizationsQuery";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import adminServersApi from "../../../services/adminServersApi";
import {
  getAuthorizationServerQueryKey,
  listWorkspacesQueryKey,
  useGetAuthorizationServer,
} from "../../../services/adminServersQuery";
import adminTenantsApi from "../../../services/adminTenantsApi";
import {
  getTenantQueryKey,
  useCheckTenantPermissions,
  useGetTenant,
} from "../../../services/adminTenantsQuery";

interface Props {
  organization: OrganizationResponse;
  onClick: () => void;
}

export default function ToggleAsTemplateMenuItems({ organization, onClick }: Props) {
  const queryClient = useQueryClient();
  const getTenantQuery = useGetTenant(getTenantId());
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(organization.id);
  const getAuthorizationServer = useGetAuthorizationServer(getTenantId(), organization.id);

  const toggleAsTemplate = (server: ServerResponse) => {
    adminServersApi
      .updateAuthorizationServer({
        wid: server.id!,
        server: over(lensProp("template"), not, server) as unknown as Server,
      })
      .then(() => {
        if (server.template && getTenantQuery.data?.settings?.default_template_id === server.id) {
          return adminTenantsApi
            .updateTenant({
              tenant: {
                ...getTenantQuery.data,
                settings: {
                  ...getTenantQuery.data?.settings,
                  default_template_id: "",
                },
              },
            })
            .then(() =>
              queryClient.invalidateQueries({ queryKey: getTenantQueryKey(getTenantId()) })
            );
        }

        return Promise.resolve();
      })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getAuthorizationServerQueryKey(getTenantId(), server.id),
        })
      )
      .then(() => queryClient.invalidateQueries({ queryKey: listOrganizationsQueryKey() }))
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
      .then(() => {
        if (server.template) {
          notifySuccess("Organization successfully unmarked as template");
        } else {
          notifySuccess("Organization successfully marked as template");
        }
      })
      .catch(handleErrorWithNotify("Error occur while trying to mark organization as template"));
  };

  if (
    getAuthorizationServer.isLoading ||
    checkWorkspacePermissionsQuery.isLoading ||
    checkTenantPermissionsQuery.isLoading ||
    getTenantQuery.isLoading
  ) {
    return (
      <MenuItem style={{ justifyContent: "center" }} disabled>
        <CircularProgress size={12} />
      </MenuItem>
    );
  }

  if (
    getAuthorizationServer.isSuccess &&
    !organization.template &&
    checkWorkspacePermissionsQuery.data?.get_workspace &&
    checkWorkspacePermissionsQuery.data?.update_workspace &&
    !checkTenantPermissionsQuery.data?.list_organizations
  ) {
    return (
      <MenuItem
        id={`${organization.id}-mark-as-template`}
        onClick={() => {
          toggleAsTemplate(getAuthorizationServer.data);
          onClick();
        }}
        key="mark-as-template"
      >
        Mark As Template
      </MenuItem>
    );
  } else if (
    getAuthorizationServer.isSuccess &&
    organization.template &&
    checkWorkspacePermissionsQuery.data?.get_workspace &&
    checkWorkspacePermissionsQuery.data?.update_workspace &&
    checkTenantPermissionsQuery.data?.update_tenant &&
    !checkTenantPermissionsQuery.data?.list_organizations
  ) {
    return (
      <MenuItem
        id={`${organization.id}-unmark-as-template`}
        onClick={() => {
          toggleAsTemplate(getAuthorizationServer.data);
          onClick();
        }}
        key="unmark-as-template"
      >
        Unmark As Template
      </MenuItem>
    );
  }

  return null;
}

import React, { useMemo, useState } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useQueryClient } from "@tanstack/react-query";

import { Server } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import FormSection from "../../../../common/components/FormSection";
import RouteLeavingGuard from "../../../../common/components/RouteLeavingGuard";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { validators } from "../../../../common/utils/forms/validation";
import { useFeature } from "../../../../common/utils/hooks/useFeature";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import adminServersApi from "../../../services/adminServersApi";
import {
  getAuthorizationServerQueryKey,
  useGetAuthorizationServer,
} from "../../../services/adminServersQuery";
import { useWorkspace } from "../../common/useWorkspace";

export default function AuthenticationSession() {
  const queryClient = useQueryClient();

  const [workspace] = useWorkspace();
  const tenantId = getTenantId();

  const [updateProgress, setUpdateProgress] = useState(false);
  const serverQuery = useGetAuthorizationServer(tenantId, workspace);
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);
  const canUpdateWorkspace = !!checkWorkspacePermissionsQuery?.data?.update_workspace;
  const isRiskEngineEnabled = useFeature("risk_engine");

  const data = useMemo(
    () => ({
      enabled: false,
      ...(serverQuery.data?.sso?.enabled
        ? {
            max_age: "1h0m0s",
            max_idle: "0h0m0s",
            domain: "",
            loa_threshold: 0,
          }
        : {}),
      ...(serverQuery.data?.sso ?? {}),
    }),
    [serverQuery.data]
  );

  const formFactory = useFormFactory({
    id: "sso-settings",
    data,
    progress: updateProgress,
    disabled: !canUpdateWorkspace,
  });

  const isSSOEnabled = formFactory.watch("enabled");
  const loaThreshold = formFactory.watch("loa_threshold");

  const handleUpdate = updatedData => {
    const body = {
      ...serverQuery.data,
      sso: {
        ...serverQuery.data?.sso,
        ...updatedData,
      },
      tenant_id: tenantId,
    };

    setUpdateProgress(true);
    adminServersApi
      .updateAuthorizationServer({ wid: serverQuery.data?.id!, server: body as Server })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getAuthorizationServerQueryKey(getTenantId(), workspace),
        })
      )
      .then(() => notifySuccess("OAuth settings saved successfully"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to update OAuth Settings"))
      .finally(() => setUpdateProgress(false));
  };

  return (
    <FormContext.Provider value={formFactory.context}>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Paper style={{ padding: 32 }}>
            <FormSection id="session-type" title="Session Type" />
            {formFactory.createFormCardsField({
              name: "enabled",
              cards: [
                {
                  title: "Persistent Session (SSO mode)",
                  subtitle: "Once activated, the user session is stored.",
                  value: true,
                },
                {
                  title: "Non-Persistent Session",
                  subtitle:
                    "Authentication context will be discarded after handling the request that triggered authentication.",
                  value: false,
                },
              ],
            })}
            {isSSOEnabled && (
              <div>
                <FormSection id="session-lifetime" title="Session Lifetime" />
                {formFactory.createDurationField({
                  id: "sso_max_age",
                  name: "max_age",
                  label: "Session Max Age",
                  description: (
                    <span>
                      The maximum time a session can be used. If set to 0, a default value of{" "}
                      <code>1h</code> is used.
                    </span>
                  ),
                  validate: {
                    inRangeDuration: validators.inRangeDuration({
                      label: "Time To Live",
                      min: "0h0m00s",
                      max: "744h0m0s",
                    }),
                  },
                })}
                {formFactory.createDurationField({
                  id: "sso_max_idle",
                  name: "max_idle",
                  label: "Session Max Idle Time",
                  description: (
                    <span>
                      The maximum time a session can be idle. If set to 0, a default value of{" "}
                      <code>Max Age</code> is used.
                    </span>
                  ),
                  validate: {
                    inRangeDuration: validators.inRangeDuration({
                      label: "Time To Live",
                      min: "0h0m00s",
                      max: "744h0m0s",
                    }),
                  },
                })}
                {formFactory.createField({
                  name: "domain",
                  label: "SSO cookie domain",
                  helperText: "If not set, defaults to server domain.",
                  rules: {
                    validate: {
                      validDomain: v =>
                        v.length === 0 ||
                        validators.validDomain({ label: "Value", require_tld: false })(v),
                    },
                  },
                })}

                {isRiskEngineEnabled && (
                  <>
                    <FormSection id="oauth-tokens-risk-engine-label" title="Risk engine" />

                    <div style={{ display: "flex", alignItems: "center" }}>
                      {formFactory.createSliderField({
                        name: "loa_threshold",
                        title: "Level of assurance threshold",
                        helperText:
                          "Users will be prompted for a MFA if the real-time level of assurance falls below the required threshold.",
                        withValueIndicator: true,
                        sliderProps: {
                          value: Number(parseFloat((loaThreshold * 100).toFixed(2))),
                          onChange: (_, value) =>
                            formFactory.setValue(
                              "loa_threshold",
                              Number(((value as number) / 100).toFixed(2))
                            ),
                          step: 1,
                          size: "small",
                        },
                      })}
                    </div>
                  </>
                )}
              </div>
            )}

            {formFactory.createFormFooter({
              onSubmit: handleUpdate,
            })}
          </Paper>
        </Grid>
      </Grid>
      <RouteLeavingGuard />
    </FormContext.Provider>
  );
}

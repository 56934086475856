import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  DefaultValues,
  FieldValues,
  UseFormReturn,
  useForm as useReactHookForm,
} from "react-hook-form";

interface Props<T extends FieldValues> {
  form: ReturnType<typeof useForm<T>>;
}

export interface Context<T extends FieldValues> {
  form: UseFormReturn<T>;
  initialValues?: DefaultValues<T>;
  progress?: boolean;
  disabled?: boolean;
  submitButtonDisabled: boolean;
  setSubmitButtonDisabled: Dispatch<SetStateAction<boolean>>;
  id: string;
  noManagePermission?: boolean;
}

export const FormContext = React.createContext({} as Context<any>);

export function useForm<T extends FieldValues>(
  args: Omit<Parameters<typeof useReactHookForm<T>>[0], "defaultValues"> & {
    initialValues?: DefaultValues<T>;
    progress?: boolean;
    noManagePermission?: boolean;
    id: string;
  }
) {
  const initialValues = args?.initialValues;
  const form = useReactHookForm<T>({ ...args, defaultValues: initialValues });
  const progress = args?.progress;
  const noManagePermission = args?.noManagePermission;
  const id = args?.id;

  const reset = form.reset;

  useEffect(() => {
    reset(initialValues, { keepDefaultValues: true });
  }, [reset, initialValues]);

  return useMemo(
    () => ({ ...form, initialValues, progress, noManagePermission, id }),
    [form, initialValues, progress, noManagePermission, id]
  );
}

export const useFormContext = () => {
  return React.useContext(FormContext);
};

export default function Form<T extends FieldValues>({
  form,
  children,
}: PropsWithChildren<Props<T>>) {
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const context: Context<T> = {
    form: form,
    initialValues: form.initialValues,
    progress: form.progress,
    disabled: form.progress || form.formState.disabled || form.noManagePermission,
    submitButtonDisabled,
    setSubmitButtonDisabled,
    id: form.id,
    noManagePermission: form.noManagePermission,
  };

  return (
    <FormContext.Provider value={context}>
      <form>{children}</form>
    </FormContext.Provider>
  );
}

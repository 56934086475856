import CloudentityAuth from "@cloudentity/auth";

import {
  acpBasePath,
  authPathPrefix,
  getRedirectUrlTo,
  getServerId,
  getTenantId,
  isDeveloperApp,
  pathPrefix,
} from "../../api/paths";
import { getParameterByName } from "../../utils/query.utils";

export const cloudentityAuth = new CloudentityAuth({
  responseType: ["code"],
  tenantId: getTenantId(),
  authorizationServerId: getServerId(),
  clientId: `${getServerId()}${isDeveloperApp() ? "-developer" : ""}`,
  scopes: [],
  redirectUri: getRedirectUrlTo("callback"),
  silentAuthRedirectUri: getRedirectUrlTo("silent"),
  authorizationUri: authPathPrefix + "/oauth2/authorize",
  tokenUri: authPathPrefix + "/oauth2/token",
  userInfoUri: authPathPrefix + "/userinfo",
  logoutUri: authPathPrefix + "/oauth2/revoke",
});

export const authorize = (tenantId, authorizationServerId, scopes = [], responseType = []) => {
  const prompt = getParameterByName("prompt");
  const idpHint = getParameterByName("idp_hint");
  const loginHint = getParameterByName("login_hint");
  const acrValues = getParameterByName("acr_values");

  cloudentityAuth.authorize({
    scopes,
    prompt,
    responseType,
    idpHint,
    loginHintIdentifier: loginHint,
    acrValues,
  });
};

type LogoutOptions = {
  withPromptLogin: boolean;
  loginWithSelectAccount?: boolean;
};

export const logout = (portalType: "admin" | "developer" | "user", options?: LogoutOptions) => {
  const withSelectAccount = portalType !== "admin" || options?.loginWithSelectAccount;
  const idpHint = getParameterByName("idp_hint");

  let params: string[] = [];

  if (options?.withPromptLogin) {
    if (withSelectAccount) {
      params.push("prompt=login+select_account");
    } else {
      params.push("prompt=login");
    }
  }

  if (idpHint) {
    params.push(`idp_hint=${idpHint}`);
  }

  const paramsToQuery = params.length > 0 ? "%3F" + params.join("%26") : "";

  const redirectTo = `${acpBasePath}${pathPrefix}${paramsToQuery}`;
  const logoutRedirect = `${authPathPrefix}/logout?redirect_to=${redirectTo}`;

  cloudentityAuth.logout();
  window.location.href = logoutRedirect;
};

import { map } from "ramda";

export const trimStringValues = obj =>
  map(
    value =>
      typeof value === "string"
        ? value.trim()
        : typeof value === "object"
        ? trimStringValues(value)
        : value,
    obj
  );

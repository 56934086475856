import React, { useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import {
  UpdateUserVerifiableAddressStatusEnum,
  UserVerifiableAddress,
  UserVerifiableAddressStatusEnum,
  UserVerifiableAddressTypeEnum,
} from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../../../common/api/paths";
import Dialog from "../../../../../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../common/components/notifications/notificationService";
import { FormContext } from "../../../../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../../../../common/utils/forms/formFactory";
import { validators } from "../../../../../../../common/utils/forms/validation";
import adminIdentityUsersApi from "../../../../../../services/adminIdentityUsersApi";
import { getUserQueryKey } from "../../../../../../services/adminIdentityUsersQuery";

interface Props {
  address: UserVerifiableAddress;
  onClose: () => void;
}

const EditUserAddressDialog = ({ address, onClose }: Props) => {
  const [progress, setProgress] = useState(false);

  const queryClient = useQueryClient();

  const data = useMemo(
    () => ({
      ...address,
      addressType: address.type,
      [UserVerifiableAddressTypeEnum.Email]:
        address.type === UserVerifiableAddressTypeEnum.Email ? address.address : "",
      [UserVerifiableAddressTypeEnum.Mobile]:
        address.type !== UserVerifiableAddressTypeEnum.Email ? address.address : "",
    }),
    [address]
  );
  const formFactory = useFormFactory({
    id: "edit-user-address",
    data,
    progress,
  });
  const addressType = formFactory.watch("addressType");

  const handleEdit = data => {
    setProgress(true);
    adminIdentityUsersApi
      .updateUserVerifiableAddress({
        ipID: address.user_pool_id,
        userID: address.user_id,
        updateAddress: {
          address: address.address,
          status:
            address.status === UserVerifiableAddressStatusEnum.Active
              ? UpdateUserVerifiableAddressStatusEnum.Active
              : UpdateUserVerifiableAddressStatusEnum.Inactive,
          verified: data.verified,
        },
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getUserQueryKey(getTenantId(), address.user_id) })
      )
      .then(() => onClose())
      .then(() => notifySuccess("User address successfully updated"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to remove user address"))
      .finally(() => setProgress(false));
  };

  return (
    <Dialog onClose={onClose} id="user-edit-address-dialog" title="Edit User Address">
      <FormContext.Provider value={formFactory.context}>
        {addressType === UserVerifiableAddressTypeEnum.Email &&
          formFactory.createRequiredField({
            name: UserVerifiableAddressTypeEnum.Email,
            label: "Email",
            rules: {
              validate: {
                validEmail: validators.validEmail({ label: "Value" }),
              },
            },
            inputProps: {
              autoComplete: "off",
            },
            optional: false,
            disabled: true,
          })}
        {addressType === UserVerifiableAddressTypeEnum.Mobile &&
          formFactory.createPhoneInput({
            name: UserVerifiableAddressTypeEnum.Mobile,
            label: "Phone",
            selector: "#edit-user-address-phone-input",
            optional: false,
            required: true,
            disabled: true,
          })}

        {formFactory.createCheckBox({
          name: "verified",
          label: "Verified",
        })}

        {formFactory.createFormFooter({
          onCancel: () => onClose(),
          onSubmit: handleEdit,
          submitText: "Update",
        })}
      </FormContext.Provider>
    </Dialog>
  );
};

export default EditUserAddressDialog;

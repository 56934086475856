import React, { CSSProperties, useCallback, useEffect, useState } from "react";

import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ColorThief from "colorthief";
import debounce from "lodash/debounce";
import { Search } from "react-feather";
import { makeStyles } from "tss-react/mui";
import isURL from "validator/lib/isURL";

import { TRANSPARENT_HEX_VALUE } from "../../../../common/components/nav/utils";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { validators } from "../../../../common/utils/forms/validation";
import { useGetEnvironment } from "../../../services/adminEnvironmentQuery";
import { useSearchImages } from "../../../services/adminImagesQuery";
import { initialColors } from "../../workspaceDirectory/WorkspacesColorInput";
import LogosList from "./LogosList";
import { Branding } from "./SetupBrandingPreview";

export const NO_LOGO_ID = "imgNoLogo";
export const NO_LOGO_URL = "/static/images/no-logo.svg";

export const buildImgId = i => `img${i}`;

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "22px",
    color: theme.palette.secondary.dark,
  },
  icon: {
    color: theme.palette.primary.main,
    width: 16,
    height: 16,
  },
}));

interface Props {
  companyName: string;
  style?: CSSProperties;
  onBrandingChange: (b: Partial<Branding>) => void;
}

export default function SetupLogo({ companyName, style, onBrandingChange }: Props) {
  const { classes } = useStyles();

  const getEnvironmentQuery = useGetEnvironment();
  const hasImageSearch = !!getEnvironmentQuery.data?.has_google_image_search;

  const [customLogoQuery, setCustomLogoQuery] = useState<string | null>(hasImageSearch ? null : "");
  const [selectedLogo, setSelectedLogo] = useState<{
    id: string;
    logoUrl: string;
  } | null>({
    id: NO_LOGO_ID,
    logoUrl: NO_LOGO_URL,
  });

  const formFactory = useFormFactory({ id: "custom-logo-query" });

  const [items, setItems] = useState<{ url?: string }[]>([]);

  const removeTrailingDigits = (str: string) => str.replace(/\d{3,}$/, "");
  const removeTrailingSymbols = (str: string) => str.replaceAll(/[^a-zA-Z0-9-_ ]/g, "");

  const query =
    customLogoQuery !== null
      ? customLogoQuery
      : removeTrailingSymbols(removeTrailingDigits(companyName));

  const searchImagesQuery = useSearchImages(
    { q: query + " logo png" },
    {
      enabled:
        hasImageSearch &&
        !!query &&
        !isURL(query, {
          require_protocol: true,
          require_tld: false,
        }),
    }
  );

  useEffect(() => {
    const images = searchImagesQuery.data?.images || [];
    if (images.length > 0) {
      setItems(images);
    }
  }, [searchImagesQuery.data]);

  useEffect(() => {
    if (
      customLogoQuery !== null &&
      isURL(customLogoQuery.trim(), {
        require_protocol: true,
        require_tld: false,
      })
    ) {
      setItems([{ url: customLogoQuery }]);
    }
  }, [customLogoQuery]);

  useEffect(() => {
    if (selectedLogo?.logoUrl === NO_LOGO_URL) {
      return onBrandingChange({
        logoUrl: NO_LOGO_URL,
        primaryColor: "#0083FF",
        headerColor: "#f0f0f0",
        palette: [
          "#0083FF",
          "#f0f0f0",
          "#222533",
          ...initialColors.filter(c => !["#0083FF", "#f0f0f0", "#222533"].includes(c)),
        ].slice(0, 5),
      });
    }

    if (selectedLogo?.logoUrl !== NO_LOGO_URL) {
      const img = document.querySelector("#" + selectedLogo?.id) as HTMLImageElement;
      if (img) {
        if (img.complete) {
          const palette = calculatePalette(img);
          const opacity = opacityRatio(img);
          onBrandingChange({
            logoUrl: selectedLogo?.logoUrl,
            palette:
              opacity < 1 ? [...palette.slice(0, palette.length), TRANSPARENT_HEX_VALUE] : palette,
            primaryColor: palette[0],
            headerColor: palette[1],
          });
        } else {
          img.addEventListener("load", () => {
            const palette = calculatePalette(img);
            const opacity = opacityRatio(img);
            onBrandingChange({
              logoUrl: selectedLogo?.logoUrl,
              palette:
                opacity < 1
                  ? [...palette.slice(0, palette.length), TRANSPARENT_HEX_VALUE]
                  : palette,
              primaryColor: palette[0],
              headerColor: palette[1],
            });
          });
        }
      }
    }
  }, [selectedLogo, onBrandingChange]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdateQuery = useCallback(
    debounce(q => setCustomLogoQuery(q), 500),
    []
  );

  return (
    <FormContext.Provider value={formFactory.context}>
      <div style={style}>
        {customLogoQuery === null && (
          <div className={classes.container}>
            <Typography className={classes.header}>Select logo</Typography>
            <IconButton
              id="searchButton"
              size="small"
              onClick={() => {
                setCustomLogoQuery("");
                setItems([]);
              }}
            >
              <Search className={classes.icon} />
            </IconButton>
          </div>
        )}
        {customLogoQuery !== null &&
          formFactory.createField({
            name: "query",
            label: "",
            onChange: e => {
              handleUpdateQuery(e.currentTarget.value);
            },
            placeholder: hasImageSearch ? companyName : "URL to your company logo",
            helperText: hasImageSearch
              ? "Type company name or provide direct URL to your company logo"
              : "Provide direct URL to your company logo",
            rules: {
              validate: hasImageSearch
                ? {}
                : { validURL: validators.validURL({ label: "Logo URL" }) },
            },
            optional: false,
            withStartAdornment: <Search className={classes.icon} />,
            withEndAdornment: (
              <IconButton
                tabIndex={-1}
                onClick={() => {
                  setCustomLogoQuery(hasImageSearch ? null : "");
                  if (!hasImageSearch) {
                    formFactory.setValue("query", "", { shouldValidate: true });
                    setItems([]);
                    setSelectedLogo({
                      id: NO_LOGO_ID,
                      logoUrl: NO_LOGO_URL,
                    });
                  }
                }}
                style={{ padding: 6 }}
              >
                <Close className={classes.icon} />
              </IconButton>
            ),
          })}
        <LogosList logos={items} selected={selectedLogo} onSelect={setSelectedLogo} />
      </div>
    </FormContext.Provider>
  );
}

const colorArrayToHex = arr =>
  `#${arr
    .map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    })
    .join("")}`;

const calculatePalette = (img: HTMLImageElement) => {
  try {
    const colorThief = new ColorThief();
    const dominantColor = colorThief.getColor(img);
    const palette = colorThief.getPalette(img, 5);

    const dominantColorAsHex = colorArrayToHex(dominantColor);
    return [
      dominantColorAsHex,
      "#f0f0f0",
      ...palette.map(arr => colorArrayToHex(arr)).filter(c => c !== dominantColorAsHex),
    ];
  } catch (e) {
    return [];
  }
};

function opacityRatio(img: HTMLImageElement) {
  try {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
      return 1;
    }

    canvas.width = img.width;
    canvas.height = img.height;
    context?.drawImage(img, 0, 0);
    const data = context.getImageData(0, 0, canvas.width, canvas.height).data;
    let opacity = 0;
    for (let i = 0; i < data.length; i += 4) {
      opacity += data[i + 3];
    }
    return opacity / 255 / (data.length / 4);
  } catch (e) {
    return 1;
  }
}

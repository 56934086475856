import React, { CSSProperties } from "react";
import { Controller } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import FormInputLabel from "../../../common/components/FormInputLabel";

export interface RadioGroupFieldProps {
  form: any;
  id: string;
  name: string;
  title?: string;
  helperText?: string;
  values: { value: string; key: string; label: string; disabled?: boolean }[];
  style?: CSSProperties;
  disabled?: boolean;
  row?: boolean;
  onChange?: (value: string) => void;
  validate?: any;
}

function RadioGroupField({
  form,
  id,
  name,
  title,
  helperText,
  values,
  style = {},
  disabled,
  row = false,
  onChange,
  validate,
}: RadioGroupFieldProps) {
  return (
    <div style={{ marginBottom: 32, width: "100%", ...style }}>
      <Controller
        name={name}
        control={form.control}
        rules={{ ...validate }}
        render={({ field, fieldState }) => (
          <FormControl component="fieldset">
            {title && (
              <FormInputLabel
                id={`${id}-${name}-label`}
                forId={`${id}-${name}-input`}
                label={title}
                optional={false}
              />
            )}
            <RadioGroup
              name={name}
              value={field.value}
              id={id}
              data-testid={id}
              onChange={e => {
                field.onChange(e.target.value);
                onChange && onChange(e.target.value);
              }}
              row={row}
            >
              {values.map(({ value, key, label, disabled: valueDisabled }) => (
                <FormControlLabel
                  value={value}
                  control={<Radio color="primary" />}
                  label={label}
                  key={key}
                  data-testid={`${id}-${key}`}
                  disabled={valueDisabled || disabled}
                />
              ))}
            </RadioGroup>

            <FormHelperText
              error={!!fieldState.error}
              id={`${id}-${name}-helper-text`}
              style={{ marginTop: 3 }}
            >
              {fieldState.error?.message || helperText}
            </FormHelperText>
          </FormControl>
        )}
      />
    </div>
  );
}

export default RadioGroupField;

import React, { useState } from "react";
import { Controller, RegisterOptions } from "react-hook-form";

import Editor, { EditorProps, loader } from "@monaco-editor/react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from "tss-react/mui";

import FormInputLabel from "../../components/FormInputLabel";
import { useFormContext } from "../forms2/Form";

loader.config({
  paths: {
    vs: "./assets/monaco/min/vs",
  },
});

const useStyles = makeStyles()(theme => ({
  inputContainer: {
    border: "solid 1px #D5D7D9",
    borderRadius: 4,
    padding: 1,
  },
  error: {
    borderColor: theme.palette.error.main,
  },
  editor: {
    ".margin": {
      backgroundColor: "#F2F3F4 !important",
      ".line-numbers": {
        fontWeight: 400,
        color: theme.palette.secondary.light,
      },
    },
  },
}));

export interface EditorInputProps {
  name: string;
  id?: string;
  label?: string;
  height?: string;
  errorText?: string;
  disabled?: boolean;
  editorProps?: EditorProps;
  rules?: Pick<RegisterOptions, "required" | "validate">;
}

const EditorInput = ({
  name,
  id,
  label,
  height,
  errorText = "Value is not a valid JSON",
  disabled = false,
  editorProps,
  rules,
}: EditorInputProps) => {
  const [isSyntaxError, setSyntaxError] = useState(false);
  const { classes, cx } = useStyles();

  const { form, id: formId, disabled: formDisable } = useFormContext();
  const inputID = `${id || formId}-${name}`;

  return (
    <FormControl variant="outlined" fullWidth style={{ marginBottom: 32 }}>
      {label && <FormInputLabel id={`${inputID}-label`} label={label} />}
      <Controller
        name={name}
        control={form.control}
        rules={{
          validate: {
            validJson: () => !isSyntaxError || errorText,
          },
          ...rules,
        }}
        render={({ field: { onChange, value }, fieldState }) => (
          <>
            <div className={cx(classes.inputContainer, fieldState.error && classes.error)}>
              <Editor
                defaultLanguage="json"
                options={{
                  minimap: {
                    enabled: false,
                  },
                  overviewRulerBorder: false,
                  lineDecorationsWidth: 0,
                  scrollBeyondLastLine: false,
                  wordBasedSuggestions: false,
                  readOnly: disabled || formDisable,
                }}
                height={height || "200px"}
                value={value}
                onChange={v => onChange(v ?? "")}
                wrapperProps={{
                  id: `${inputID}-editor`,
                }}
                onValidate={annotations => {
                  const isError = annotations && annotations.length > 0;
                  setSyntaxError(isError);
                  setTimeout(() => {
                    form.trigger(name);
                  });
                }}
                className={cx(classes.editor)}
                {...editorProps}
              />
            </div>
            {fieldState.error && (
              <FormHelperText style={{ minHeight: 20 }} error>
                {fieldState.error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

export default EditorInput;

import trimEnd from "lodash/trimEnd";
import { pathOr } from "ramda";

export const acpBaseURI = trimEnd(document.baseURI, "/");
export const acpBasePath = acpBaseURI.replace(window.location.origin, "");

const rootEl = document.getElementById("root");
export const routerType = rootEl?.getAttribute("data-router-type") || "default";
const dataPortalType = rootEl?.getAttribute("data-portal-type");
const dataServerId = rootEl?.getAttribute("data-server-id") || "admin";
export const portalType =
  (dataPortalType === "developer" && "developer") ||
  (dataPortalType === "admin" && dataServerId === "admin" ? "admin" : "user");
export const portalMode = rootEl?.getAttribute("data-mode") || "portal";

export const isDeveloperApp = () => portalType === "developer";

export const getTenantId = () => rootEl?.getAttribute("data-tenant-id") || "default";
export const getServerId = () => dataServerId;

const routerTypeMapping = (tid, aid) => ({
  default: {
    auth: `/${tid}/${aid}`,
    identity: {
      api: `/api/identity/${tid}`,
    },
    identityself: {
      api: `/${tid}/${aid}/identity`,
    },
    admin: {
      path: portalMode === "b2b" ? `/${tid}/${aid}/b2b` : `/${tid}/${aid}/app`,
      api: `/api/${aid}/${tid}`,
    },
    developer: {
      path: `/${tid}/${aid}/app`,
      api: `/api/developer/${tid}/${aid}`,
    },
    user: {
      path: portalMode === "b2b" ? `/${tid}/${aid}/b2b` : `/${tid}/${aid}/app`,
      api: `/${tid}/${aid}`,
    },
    analytics: {
      api: `/api/_analytics/${tid}`,
    },
    permissions: {
      api: `/api/permissions/${tid}`,
    },
  },
  tenant: {
    auth: `/${aid}`,
    identity: {
      api: "/api/identity",
    },
    identityself: {
      api: `/${aid}/identity`,
    },
    admin: {
      path: portalMode === "b2b" ? `/${aid}/b2b` : `/${aid}/app`,
      api: `/api/${aid}`,
    },
    developer: {
      path: `/${aid}/app`,
      api: `/api/developer/${aid}`,
    },
    user: {
      path: portalMode === "b2b" ? `/${aid}/b2b` : `/${aid}/app`,
      api: `/${aid}`,
    },
    analytics: {
      api: "/api/_analytics",
    },
    permissions: {
      api: `/api/permissions`,
    },
  },
  server: {
    auth: "",
    identity: {
      api: "/api/identity",
    },
    identityself: {
      api: `/identity`,
    },
    admin: {
      path: portalMode === "b2b" ? "/b2b" : "/app",
      api: "/api/admin",
    },
    developer: {
      path: "/app",
      api: "/api/developer",
    },
    user: {
      path: portalMode === "b2b" ? "/b2b" : "/app",
      api: "/",
    },
    analytics: {
      api: "/api/_analytics/",
    },
    permissions: {
      api: "/api/permissions",
    },
  },
});

export const pathByPortalTypeAndTenantIdAndServerId = (portalType, tid, aid) =>
  pathOr("", [routerType, portalType, "path"], routerTypeMapping(tid, aid));

export const adminPathByTenantIdAndServerId = (tid, aid) =>
  pathByPortalTypeAndTenantIdAndServerId("admin", tid, aid);

export const apiPrefixByTenantIdAndServerId = (tid, aid) =>
  pathOr(
    "",
    [routerType, portalMode === "b2b" ? "admin" : portalType, "api"],
    routerTypeMapping(tid, aid)
  );

export const apiPrefix = apiPrefixByTenantIdAndServerId(
  getTenantId(),
  portalMode === "b2b" ? "admin" : getServerId()
);

export const userApiPrefix = pathOr(
  "",
  [routerType, "user", "api"],
  routerTypeMapping(getTenantId(), getServerId())
);

export const identityPathPrefix = pathOr(
  "",
  [routerType, "identity", "api"],
  routerTypeMapping(getTenantId(), getServerId())
);

export const identityselfPathPrefix = pathOr(
  "",
  [routerType, "identityself", "api"],
  routerTypeMapping(getTenantId(), getServerId())
);

export const permissionsPathPrefix = pathOr(
  "",
  [routerType, "permissions", "api"],
  routerTypeMapping(getTenantId(), getServerId())
);

export const pathPrefix = pathOr(
  "",
  [routerType, portalType, "path"],
  routerTypeMapping(getTenantId(), getServerId())
);

export const getRedirectUrlTo = (suffix: "callback" | "silent") =>
  `${acpBaseURI}${pathPrefix}/${suffix}`;

export const authPathByTenantIdAndServerId = (tenantId, serverId) =>
  `${acpBaseURI}${pathOr("", [routerType, "auth"], routerTypeMapping(tenantId, serverId))}`;

export const authPathPrefix =
  process.env.NODE_ENV === "development" && process.env.START_DEV === "true"
    ? authPathByTenantIdAndServerId(getTenantId(), getServerId()).replace("3000", "8443")
    : authPathByTenantIdAndServerId(getTenantId(), getServerId());

const acpPortalHref = `${acpBaseURI}${pathPrefix}`;
export const acpPortalHrefWithPath = path => `${acpPortalHref}${path}`;

export const analyticsPathPrefix = pathOr(
  "",
  [routerType, "analytics", "api"],
  routerTypeMapping(getTenantId(), getServerId())
);

export const getAdminPortalUrl = (issuerUrl: string | undefined) =>
  issuerUrl
    ? process.env.NODE_ENV === "development" && process.env.START_DEV === "true"
      ? `${issuerUrl ?? ""}/${portalMode === "b2b" ? "app" : "b2b"}`.replace("8443", "3000")
      : `${issuerUrl ?? ""}/${portalMode === "b2b" ? "app" : "b2b"}`
    : "";

export const getAdminPortalUrlWithPoolContext = (
  baseUrl: string,
  pathName: string,
  portalMode: string,
  currentWorkspace?: { id: string; isOrganization: boolean }
) => {
  if (portalMode === "b2b") {
    const isOrganizationRegex = /\/organizations\/([^/]*)\/.+$/;
    const isOrganizationPoolRegex = /\/organizations\/([^/]*)\/users\/(.+)$/;
    const organizationId = pathName.match(isOrganizationRegex)?.at(1);
    const organizationIdWithPool = pathName.match(isOrganizationPoolRegex)?.at(1);
    const poolId = pathName.match(isOrganizationPoolRegex)?.at(2);

    if (organizationIdWithPool) {
      return baseUrl + `/${organizationIdWithPool}/pools/${poolId}/settings`;
    }

    if (organizationId) {
      return baseUrl + `/${organizationId}/overview`;
    }

    return baseUrl + "/organization-directory";
  } else if (currentWorkspace?.isOrganization) {
    return baseUrl + `/organizations/${currentWorkspace.id}`;
  }

  return baseUrl;
};

export const previewBasePath = `${acpBasePath}${adminPathByTenantIdAndServerId(
  getTenantId(),
  "mock"
).replace(/\/app$/, "/")}`;

import React, { useEffect, useRef, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "tss-react/mui";

import { PoolResponse, PoolResponseAuthenticationMechanismsEnum } from "@cloudentity/acp-identity";

import { getTenantId, previewBasePath } from "../../../../../common/api/paths";
import { useGetAuthorizationServer } from "../../../../services/adminServersQuery";
import {
  createIdpButton,
  createPoolMethodButton,
  handleAddSeparator,
  handleIdpDiscoveryEnabled,
  handlePasswordMethodFields,
  handlePoolNoMethods,
} from "./utils";

const useStyles = makeStyles()(() => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 24,
    background: "white",
    backgroundImage: "radial-gradient(#F4F4F4 1.5px, transparent 0)",
    backgroundSize: "8px 8px",
    backgroundPosition: "-4px -4px",
    border: "1px solid #E9EBFA",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  previewContainer: {
    position: "relative",
    flex: 1,
    border: "solid 1px lightgray",
    borderRadius: 4,
    padding: 4,
    maxWidth: 530,
    maxHeight: 820,
    width: "100%",
  },
  preview: {
    border: "none",
    pointerEvents: "none",
  },
  progress: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
}));

const defaultUrl = `${previewBasePath}/login/preview?preview=true`;
const identityPoolUrl = `${previewBasePath}/preview?page=identity/login/index.tmpl&preview=true`;

interface Props {
  workspace: string;
  idps: {
    method: string;
    name: string;
  }[];
  pool: PoolResponse | undefined;
}

export default function IdentitiesListSimplePreview({ workspace, idps, pool }: Props) {
  const { classes } = useStyles();

  const [url, setUrl] = useState(defaultUrl);
  const [progress, setProgress] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const getAuthorizationServerQuery = useGetAuthorizationServer(getTenantId(), workspace);
  const idpDiscoveryEnabled = !!getAuthorizationServerQuery.data?.idp_discovery?.enabled;

  const handlePoolOptions = (content: Document) => {
    const form = content.querySelector("#sign-in-form") as HTMLElement;
    const poolMethodsContainer = content.querySelector("#pool-methods-container") as HTMLElement;
    const methods = pool?.authentication_mechanisms ?? [];

    if (pool && form) {
      if (poolMethodsContainer) {
        form.removeChild(poolMethodsContainer);
      }

      const hasPassword = methods.includes(PoolResponseAuthenticationMechanismsEnum.Password);
      const hasOtp = methods.includes(PoolResponseAuthenticationMechanismsEnum.Otp);
      const hasWebauthn = methods.includes(PoolResponseAuthenticationMechanismsEnum.Webauthn);

      const methodsContainer = document.createElement("div");
      methodsContainer.id = "pool-methods-container";

      handleAddSeparator(methodsContainer, hasPassword && methods.length > 1);
      handlePoolNoMethods(form, methods.length === 0);
      handlePasswordMethodFields(content, hasPassword);

      if (hasOtp) {
        methodsContainer.appendChild(
          createPoolMethodButton(PoolResponseAuthenticationMechanismsEnum.Otp)
        );
      }

      if (hasWebauthn) {
        methodsContainer.appendChild(
          createPoolMethodButton(PoolResponseAuthenticationMechanismsEnum.Webauthn)
        );
      }

      form.appendChild(methodsContainer);
    }
  };

  const reloadIframeContent = () => {
    setProgress(true);
    if (iframeRef?.current) {
      let content =
        iframeRef.current?.contentWindow?.document || iframeRef.current?.contentDocument;
      if (content) {
        if (idps.length === 1 && idps[0].method === "identity_pool") {
          setUrl(identityPoolUrl);
          handlePoolOptions(content);
        } else {
          setUrl(defaultUrl);
          handleIdpDiscoveryEnabled(content, idpDiscoveryEnabled);
        }

        const idpsList = content.querySelector("#idps-list") as HTMLElement;
        if (idpsList) {
          idpsList.style.maxHeight = "unset";
          idpsList.innerHTML = "";

          idps.forEach(({ name, method }) => {
            const button = createIdpButton(name, method);
            if (button) {
              idpsList.appendChild(button);
            }
          });
        }
      }
    }
    setProgress(false);
  };

  useEffect(() => {
    reloadIframeContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idps]);

  const loading = !isVisible || progress || getAuthorizationServerQuery.isLoading;

  return (
    <div className={classes.container}>
      {loading && <LinearProgress className={classes.progress} />}
      <div className={classes.previewContainer}>
        <iframe
          src={url}
          width="100%"
          height="100%"
          title="preview"
          className={classes.preview}
          ref={iframeRef}
          style={isVisible ? {} : { visibility: "hidden" }}
          onLoad={() => {
            reloadIframeContent();
            setIsVisible(true);
          }}
        />
      </div>
    </div>
  );
}

import { FormFactory } from "../../../common/utils/forms/formFactory";

interface SSOIDPSettingsProps {
  formFactory: FormFactory;
}

export default function SSOIDPSettings({ formFactory }: SSOIDPSettingsProps) {
  return formFactory.createCheckBox({
    name: "sso_settings.disabled",
    label: "Disable SSO",
    helperText:
      "If selected, Single Sign-On sessions will not be initiated for this identity provider",
  });
}

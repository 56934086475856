import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";

import debounce from "lodash/debounce";
import { makeStyles } from "tss-react/mui";

import { ServerResponse, ServerResponseTypeEnum, WorkspaceResponse } from "@cloudentity/acp-admin";

import { FormFactory } from "../../../../common/utils/forms/formFactory";
import { useInfiniteScrollForItemsWithQuery } from "../../common/EnhancedTableAsync/useItemsWithQuery";
import useWorkspacesSeqOrCursor from "../../common/EnhancedTableAsync/useWorkspacesSeqOrCursor";
import ServerBlock from "./ServerBlock";

const useStyles = makeStyles()(() => ({
  listItem: {
    padding: "8px 8px",
    margin: "0 6px",
    borderRadius: 4,
    "&:hover": {
      cursor: "pointer",
      background: "#F7F8FF",
    },
  },
}));

interface Props {
  label: string;
  labelTooltip?: ReactNode;
  name: string;
  defaultValue?: Pick<ServerResponse, "id" | "name"> | null;
  emptyValue?: Pick<WorkspaceResponse, "id" | "name"> | null;
  helperText?: string;
  workspaceType?: ServerResponseTypeEnum;
  template?: boolean;
  formFactory: FormFactory;
  optional?: boolean;
}

export default function AssignedWorkspaceField({
  label,
  labelTooltip,
  name,
  defaultValue,
  emptyValue,
  helperText,
  workspaceType,
  template,
  formFactory,
  optional,
}: Props) {
  const { classes } = useStyles();
  const ref = useRef<HTMLUListElement>();
  const data = useWorkspacesSeqOrCursor({
    forceMode: "seq",
    ignoreUrlParams: true,
    forceLimit: 50,
    workspaceType,
    template,
  });

  const [selectedWorkspaceInEdit, setSelectedWorkspaceInEdit] = useState<
    Pick<WorkspaceResponse, "id" | "name">[]
  >(emptyValue ? [emptyValue] : []);

  const setValue = formFactory.setValue;

  const { onScroll } = useInfiniteScrollForItemsWithQuery({
    getElement: () => ref.current,
    onLastPage: data.onLastPage,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFiltersUpdate = useCallback(debounce(data.onFiltersUpdate, 250), []);

  useEffect(() => {
    if (defaultValue && !data.isLoading) {
      setSelectedWorkspaceInEdit([defaultValue]);
      setValue(name, [defaultValue], { shouldValidate: true });
    }
  }, [defaultValue, name, setValue, data.isLoading]);

  return (
    <>
      {formFactory.createAutocompleteField({
        name,
        label,
        labelProps: {
          tooltip: labelTooltip,
        },
        helperText,
        options: data.totalData,
        optional: !!optional,
        loading: data.isFetching,
        getOptionLabel: option =>
          typeof option === "object" ? option?.name || option?.id || "" : option || "",
        renderTags: tagValue => {
          return tagValue.map(option => {
            if (!option) {
              return emptyValue ? (
                <ServerBlock server={emptyValue} key="empty-value-placeholder" />
              ) : null;
            }

            return <ServerBlock server={option} key={option.id} />;
          });
        },
        filterOptions: v => v,
        isOptionEqualToValue: (option, value) => option.id === value?.id,
        onFocus: () => {
          setSelectedWorkspaceInEdit([]);
        },
        value: selectedWorkspaceInEdit,
        onChange: (
          _,
          value: string | WorkspaceResponse | (string | WorkspaceResponse)[] | null
        ) => {
          if (value && Array.isArray(value) && value.at(-1)) {
            const srv = value.at(-1) as WorkspaceResponse;
            setSelectedWorkspaceInEdit([srv]);
            setValue(name, srv, { shouldValidate: true });
            onFiltersUpdate("", data.filters);
          }
        },
        onInputChange: (e, value, reason) => {
          if (reason === "clear") {
            onFiltersUpdate("", data.filters);
            setSelectedWorkspaceInEdit([]);
            setValue(name, null, { shouldValidate: true });
          }
          if (reason === "reset") {
            onFiltersUpdate("", data.filters);
          }
          if (reason === "input") {
            formFactory.clearErrors(name);
            onFiltersUpdate(value, data.filters);
          }
        },
        ListboxProps: {
          ref,
          onScroll,
        } as any,
        onBlur: () => {
          const v = formFactory.getValues(name);
          setSelectedWorkspaceInEdit([v]);
          onFiltersUpdate("", data.filters);
        },
        renderOption: (props, option) => {
          return (
            <li {...props} key={option.id} className={classes.listItem}>
              <ServerBlock server={option} />
            </li>
          );
        },
        openOnFocus: true,
        multiple: true,
        limitTags: 1,
        blurOnSelect: true,
        rules: !optional
          ? {
              required: `${label} is required`,
            }
          : {},
      })}
    </>
  );
}

import React, { useMemo } from "react";

import { getTenantId } from "../../../../common/api/paths";
import { FormFactory } from "../../../../common/utils/forms/formFactory";
import { useGetAuthorizationServer } from "../../../services/adminServersQuery";
import { useWorkspace } from "../../common/useWorkspace";
import { getAllPaths } from "../../workspaceDirectory/identityPools/schemas/schemas.utils";

interface Props {
  formFactory: FormFactory;
}

export default function ClaimWorkspaceSourceType({ formFactory }: Props) {
  const [workspace] = useWorkspace();
  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace);

  const options = useMemo(() => {
    const schema = serverQuery.data?.metadata?.schema || {};

    return getAllPaths(schema) || [];
  }, [serverQuery.data]);

  return (
    <>
      {formFactory.createAutocompleteField({
        name: "metadata_key",
        label: "Metadata key",
        options: options,
        defaultValue: "",
        freeSolo: true,
        autoHighlight: false,
        autoSelect: true,
      })}
    </>
  );
}

import React, { useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { BaseUserWithData, UserWithData } from "@cloudentity/acp-identity";

import adminB2BUsersApi from "../admin/services/adminB2BUsersApi";
import {
  convertBaseUserWithDataToBaseExtendedUser,
  getB2BUserQueryKey,
} from "../admin/services/adminIdentityUsersQuery";
import { getTenantId } from "../common/api/paths";
import Dialog from "../common/components/Dialog";
import { notifyErrorOrDefaultTo } from "../common/components/notifications/notificationService";
import { FormContext } from "../common/utils/forms2/Form";
import { useFormFactory } from "../common/utils/forms/formFactory";

interface Props {
  user: BaseUserWithData | UserWithData | undefined;
  onCancel: () => void;
}

export default function EditB2BAdministratorDialog({ user, onCancel }: Props) {
  const [progress, setProgress] = useState(false);

  const queryClient = useQueryClient();

  const initialData = useMemo(
    () => ({
      user: convertBaseUserWithDataToBaseExtendedUser(user as BaseUserWithData),
    }),
    [user]
  );

  const formFactory = useFormFactory({
    id: "manage-access-add-identity-pool-user",
    progress,
    data: initialData,
  });

  const handleSave = data => {
    setProgress(true);

    adminB2BUsersApi
      .updateB2BUser({
        ipID: user?.user_pool_id ?? "",
        userID: user?.id ?? "",
        updateUser: {
          payload: {
            given_name: data.user.payload.given_name.trim(),
            family_name: data.user.payload.family_name.trim(),
          },
        },
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getB2BUserQueryKey(getTenantId(), user?.id) })
      )
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to update user"))
      .finally(() => {
        setProgress(false);
        onCancel();
      });
  };

  return (
    <Dialog onClose={onCancel} id="edit-administrator-dialog" title="Edit administrator">
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createField({
          name: "user.payload.given_name",
          label: "First name",
          optional: false,
        })}
        {formFactory.createField({
          name: "user.payload.family_name",
          label: "Last name",
          optional: false,
        })}

        {formFactory.createFormFooter({
          onCancel,
          onSubmit: handleSave,
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

import React, { useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { Pool } from "@cloudentity/acp-identity";

import adminIDPsApi from "../../../admin/services/adminIDPsApi";
import { useListIDPs } from "../../../admin/services/adminIDPsQuery";
import identityPoolsApi from "../../../admin/services/adminIdentityPoolsApi";
import {
  getPoolQueryKey,
  listUserWorkspacePoolsQueryKey,
  useGetPool,
} from "../../../admin/services/adminIdentityPoolsQuery";
import { getTenantId } from "../../../common/api/paths";
import Dialog from "../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";

interface Props {
  workspaceId: string;
  poolId: string;
  onClose: () => void;
}

export default function PopulationEdit({ workspaceId, poolId, onClose }: Props) {
  const queryClient = useQueryClient();
  const [updateProgress, setUpdateProgress] = useState(false);
  const tenantId = getTenantId();

  const idpsListQuery = useListIDPs(tenantId, workspaceId);
  const getPoolQuery = useGetPool(poolId);
  const pool = getPoolQuery.data;

  const data = useMemo(() => getPoolQuery.data, [getPoolQuery.data]);

  const formFactory = useFormFactory({
    id: "edit-population",
    data,
    progress: updateProgress,
  });

  const handleUpdate = data => {
    if (!pool) return;

    const poolIdpsWithSameName =
      idpsListQuery.data?.idps?.filter(
        idp => idp.identity_pool_id === pool.id && idp.name === pool.name
      ) ?? [];
    setUpdateProgress(true);

    Promise.all(
      poolIdpsWithSameName.map(idp =>
        adminIDPsApi.updateIDP({
          aid: idp.authorization_server_id,
          type: idp.method,
          iid: idp.id,
          body: { ...idp, name: data.name },
        })
      )
    )
      .then(() =>
        identityPoolsApi.updatePool({
          ipID: pool.id ?? "",
          pool: { ...pool, name: data.name, description: data.description } as Pool,
        })
      )
      .then(() => {
        notifySuccess("User population saved successfully");
        onClose();
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listUserWorkspacePoolsQueryKey(workspaceId) })
      )
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getPoolQueryKey(tenantId, pool.id ?? "") })
      )
      .catch(
        notifyErrorOrDefaultTo("Error occurred when trying to update user population settings")
      )
      .finally(() => setUpdateProgress(false));
  };

  return (
    <Dialog
      onClose={onClose}
      id="edit-population-dialog"
      title="Edit user population"
      loading={idpsListQuery.isLoading || getPoolQuery.isLoading}
    >
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createRequiredField({
          name: "name",
          label: "Name",
          autoFocus: true,
        })}

        {formFactory.createField({
          name: "description",
          label: "Description",
          multiline: true,
          maxRows: 3,
          minRows: 3,
        })}

        {formFactory.createFormFooter({
          onCancel: onClose,
          onSubmit: handleUpdate,
          submitWithEnterKey: true,
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

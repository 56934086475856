import React, { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";

import identitySelfApi from "../../services/identitySelfApi";
import { FormContext } from "../../utils/forms2/Form";
import { useFormFactory } from "../../utils/forms/formFactory";
import Button from "../Button";
import Dialog from "../Dialog";
import { notifyErrorOrDefaultTo, notifySuccess } from "../notifications/notificationService";

const data = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export default function SelfServiceChangePassword() {
  const [dialog, setDialog] = useState(false);
  const [progress, setProgress] = useState(false);

  const formFactory = useFormFactory({
    id: "change-password",
    data,
    progress,
  });

  function handleClose() {
    setDialog(false);
  }

  const newPassword = formFactory.watch("newPassword");

  useEffect(() => {
    formFactory.trigger("confirmPassword");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword]);

  function handleChangePassword(newData: typeof data) {
    setProgress(true);
    identitySelfApi
      .changePassword({ old_password: newData.oldPassword, new_password: newData.newPassword })
      .then(handleClose)
      .then(() => notifySuccess("New password set successfully"))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to change password"))
      .finally(() => setProgress(false));
  }

  return (
    <>
      <Button
        id="change-password-button"
        onClick={() => setDialog(true)}
        variant="contained"
        size="small"
        style={{ fontSize: 12 }}
      >
        Change
      </Button>

      {dialog && (
        <Dialog onClose={handleClose} id="change-password-dialog" title="Change password">
          <Typography component="div" variant="textMD" style={{ marginBottom: 32 }}>
            Choose a strong password, and don't reuse it for other accounts.
          </Typography>

          <FormContext.Provider value={formFactory.context}>
            {formFactory.createRequiredField({
              name: "oldPassword",
              label: "Old password",
              toggleVisibility: true,
              defaultVisibility: false,
            })}

            {formFactory.createRequiredField({
              name: "newPassword",
              label: "New password",
              toggleVisibility: true,
              defaultVisibility: false,
              withPasswordStrengthMeter: true,
            })}

            {formFactory.createRequiredField({
              name: "confirmPassword",
              label: "Confirm password",
              toggleVisibility: true,
              defaultVisibility: false,
              rules: {
                validate: {
                  matchNewPassword: v =>
                    v === newPassword || "New password does not match confirm password",
                },
              },
            })}

            {formFactory.createFormFooter({
              onCancel: handleClose,
              onSubmit: handleChangePassword,
              submitText: "Change",
            })}
          </FormContext.Provider>
        </Dialog>
      )}
    </>
  );
}

import React, { useMemo, useState } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useQueryClient } from "@tanstack/react-query";

import { ServerResponseTypeEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import RouteLeavingGuardSimple from "../../../../common/components/RouteLeavingGuardSimple";
import {
  handleErrorWithNotify,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { getEnvironmentQueryKey } from "../../../services/adminEnvironmentQuery";
import { listOrganizationsQueryKey } from "../../../services/adminOrganizationsQuery";
import { useCheckTenantPermissions } from "../../../services/adminPermissionsQuery";
import { listWorkspacesQueryKey, useGetWorkspace } from "../../../services/adminServersQuery";
import adminTenantsApi from "../../../services/adminTenantsApi";
import { getTenantQueryKey, useGetTenant } from "../../../services/adminTenantsQuery";
import PageContent from "../../common/PageContent";
import AssignedWorkspaceField from "../administrator/AssignedWorkspaceField";

export default function OrganizationsManagementSettings() {
  const [progress, setProgress] = useState(false);

  const queryClient = useQueryClient();
  const getTenantQuery = useGetTenant(getTenantId());
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canUpdateTenant = !!checkTenantPermissionsQuery.data?.update_tenant;

  const getDefaultParentServerQuery = useGetWorkspace(
    getTenantQuery.data?.settings?.default_workspace_id || "",
    { enabled: !!getTenantQuery.data?.settings?.default_workspace_id }
  );

  const getDefaultOrganizationTemplate = useGetWorkspace(
    getTenantQuery.data?.settings?.default_template_id || "",
    { enabled: !!getTenantQuery.data?.settings?.default_template_id }
  );

  const data = useMemo(
    () => ({
      default_parent_workspace: !getDefaultParentServerQuery.isError
        ? getDefaultParentServerQuery.data || null
        : {
            id: getTenantQuery.data?.settings?.default_workspace_id || "",
            name: `${getTenantQuery.data?.settings?.default_workspace_id || ""} (deleted)`,
          },
      default_organization_template: !getDefaultOrganizationTemplate.isError
        ? getDefaultOrganizationTemplate.data || null
        : {
            id: getTenantQuery.data?.settings?.default_template_id || "",
            name: `${getTenantQuery.data?.settings?.default_template_id || ""} (deleted)`,
          },
    }),
    [
      getDefaultParentServerQuery.isError,
      getDefaultParentServerQuery.data,
      getTenantQuery.data?.settings?.default_workspace_id,
      getDefaultOrganizationTemplate.isError,
      getDefaultOrganizationTemplate.data,
      getTenantQuery.data?.settings?.default_template_id,
    ]
  );

  const formFactory = useFormFactory({
    id: "organizations-management-settings",
    progress,
    disabled:
      !canUpdateTenant ||
      getTenantQuery.isFetching ||
      getDefaultOrganizationTemplate.isFetching ||
      getDefaultParentServerQuery.isFetching,
    data,
  });

  const handleToggleAsDefaultTemplate = ({
    default_parent_workspace,
    default_organization_template,
  }) => {
    setProgress(true);
    adminTenantsApi
      .updateTenant({
        tenant: {
          ...getTenantQuery.data,
          settings: {
            ...getTenantQuery.data?.settings,
            default_template_id: default_organization_template?.id || "",
            default_workspace_id: default_parent_workspace?.id || "",
          },
        },
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getTenantQueryKey(getTenantQuery.data?.id) })
      )
      .then(() => queryClient.invalidateQueries({ queryKey: getEnvironmentQueryKey() }))
      .then(() => queryClient.invalidateQueries({ queryKey: listOrganizationsQueryKey() }))
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
      .then(() => notifySuccess("Organization settings updated successfully"))
      .catch(handleErrorWithNotify("Error occur while trying to update organization settings"))
      .finally(() => setProgress(false));
  };

  const defaultParentWorkspace = formFactory.watch("default_parent_workspace");
  const defaultOrganizationTemplate = formFactory.watch("default_organization_template");

  const saveButtonDisabled =
    !canUpdateTenant ||
    ((getTenantQuery.data?.settings?.default_workspace_id || "") ===
      (defaultParentWorkspace?.id || "") &&
      (getTenantQuery.data?.settings?.default_template_id || "") ===
        (defaultOrganizationTemplate?.id || ""));

  return (
    <PageContent>
      <FormContext.Provider value={formFactory.context}>
        <Grid container>
          <Grid item xs={12} lg={7}>
            <Paper style={{ padding: 32 }}>
              <AssignedWorkspaceField
                label="Default organization parent"
                name="default_parent_workspace"
                defaultValue={data.default_parent_workspace}
                workspaceType={ServerResponseTypeEnum.Regular}
                labelTooltip="This workspace will be set as a parent of every new organization for which a parent is not explicitly set."
                helperText="Set a workspace to which organizations are attached by default"
                formFactory={formFactory}
                optional
              />
              <AssignedWorkspaceField
                label="Default organization template"
                name="default_organization_template"
                defaultValue={data.default_organization_template}
                workspaceType={ServerResponseTypeEnum.Organization}
                template
                labelTooltip="If no organization template is selected the default template will be used for creation of a new organization."
                helperText="Set a default template with your preferred configuration when creating a new organization"
                formFactory={formFactory}
                optional
              />

              {formFactory.createFormFooter({
                onSubmit: handleToggleAsDefaultTemplate,
                disabled: saveButtonDisabled,
              })}
            </Paper>
          </Grid>
        </Grid>
        <RouteLeavingGuardSimple when={!saveButtonDisabled} />
      </FormContext.Provider>
    </PageContent>
  );
}

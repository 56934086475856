import React, { useMemo, useState } from "react";
import { useParams } from "react-router";

import { useQueryClient } from "@tanstack/react-query";

import { AddUserVerifiableAddressTypeEnum } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../../../common/api/paths";
import Dialog from "../../../../../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../common/components/notifications/notificationService";
import { FormContext } from "../../../../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../../../../common/utils/forms/formFactory";
import { validators } from "../../../../../../../common/utils/forms/validation";
import adminIdentityUsersApi from "../../../../../../services/adminIdentityUsersApi";
import {
  getUserQueryKey,
  listUsersQueryKey,
} from "../../../../../../services/adminIdentityUsersQuery";

export default function AddUserAddressDialog({ onClose }) {
  const { id = "", userID = "" } = useParams<{ id: string; userID: string; tab: string }>();
  const tenantId = getTenantId();

  const [requestError, setRequestError] = useState("");
  const [progress, setProgress] = useState(false);

  const queryClient = useQueryClient();
  const data = useMemo(() => ({ identifier: "email", phone: "" }), []);
  const formFactory = useFormFactory({ id: "add-user-address", data, progress });
  const identifier = formFactory.watch("identifier");

  const handleSubmit = data => {
    setProgress(true);
    adminIdentityUsersApi
      .addUserVerifiableAddress({
        ipID: id,
        userID,
        newAddress: {
          address: data.email || data.phone,
          type: data.email
            ? AddUserVerifiableAddressTypeEnum.Email
            : AddUserVerifiableAddressTypeEnum.Mobile,
          verified: data.verified,
        },
      })
      .then(() => queryClient.invalidateQueries({ queryKey: getUserQueryKey(tenantId, userID) }))
      .then(() => queryClient.invalidateQueries({ queryKey: listUsersQueryKey(tenantId, id) }))
      .then(() => onClose())
      .then(() => notifySuccess("User address successfully added"))
      .catch(err => {
        if (err.response.status === 409) {
          return formFactory.setError(
            "email",
            {
              message: "User address with given value already exists",
            },
            { shouldFocus: true }
          );
        }
        return notifyErrorOrDefaultTo("Error occurred when trying to add user address")(err);
      })
      .finally(() => setProgress(false));
  };
  return (
    <Dialog onClose={onClose} id="add-user-address-dialog" title="Add User Address">
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createRadioGroupField({
          name: "identifier",
          values: [
            { key: "email", label: "Email", value: "email" },
            { key: "mobile", label: "Mobile", value: "mobile" },
          ],
          onChange: () => setRequestError(""),
          style: { marginBottom: 12 },
        })}
        {identifier === "email" &&
          formFactory.createRequiredField({
            name: "email",
            label: "Email",
            rules: {
              validate: {
                validEmail: validators.validEmail({ label: "Value" }),
              },
            },
            inputProps: {
              autoComplete: "off",
            },
            optional: false,
            externalErrors: requestError || null,
            onChange: () => setRequestError(""),
          })}
        {identifier === "mobile" &&
          formFactory.createPhoneInput({
            name: "phone",
            label: "Phone",
            selector: "#add-user-address-phone-input",
            optional: false,
            required: true,
            externalErrors: requestError || null,
            onChange: () => setRequestError(""),
          })}

        {formFactory.createCheckBox({
          name: "verified",
          label: "Verified",
        })}

        {formFactory.createFormFooter({
          onCancel: onClose,
          onSubmit: handleSubmit,
          submitText: "Add",
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

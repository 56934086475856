import React, { ReactNode, useState } from "react";
import { useNavigate } from "react-router";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { ExternalLink, Link, Search } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { ScriptExecutionPoint, ScriptExecutionPointTypeEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { useLocalStorage } from "../../../common/utils/hooks/useLocalStorage";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import adminScriptsApi from "../../services/adminScriptsApi";
import {
  getExecutionPointsQueryKey,
  useGetScripts,
  useListScriptExecutionPoints,
} from "../../services/adminScriptsQuery";

const useStyles = makeStyles()(theme => ({
  listItem: {
    display: "flex",
    alignItems: "center",
    padding: 16,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.custom.greys.background,
    },
  },
  listBox: {
    padding: 0,
    margin: 0,
    "&>li": {
      padding: 13,
      borderBottom: "1px solid #EFEEEF",
      "&:last-of-type": {
        borderBottom: "none",
      },
    },
  },
  withHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

interface Props {
  label: string | ReactNode;
  stringLabel?: string;
  description?: ReactNode;
  caption?: ReactNode;
  tooltip?: ReactNode;
  targetFk: string;
  type: ScriptExecutionPointTypeEnum;
  serverId: string;
  hideButtonLink?: boolean;
}

export default function ConnectScriptExtension({
  label,
  stringLabel,
  description,
  caption,
  tooltip,
  targetFk,
  type,
  serverId,
  hideButtonLink,
}: Props) {
  const { classes } = useStyles();
  const tenantId = getTenantId();

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(serverId);

  const [isExtensionInputFocused, setIsExtensionInputFocused] = useState(false);
  const [updateScriptsProgress, setUpdateScriptsProgress] = useState(false);
  const [, setNavMode] = useLocalStorage(`${serverId}_nav_mode`);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const getScriptsQuery = useGetScripts(tenantId, serverId);

  const getExecutionPointsQuery = useListScriptExecutionPoints(tenantId, serverId);

  const scriptID = (getExecutionPointsQuery.data?.execution_points ?? []).find(
    (wep: ScriptExecutionPoint) => wep.type === type && wep.target_fk === targetFk
  )?.script_id;

  const handleChangeScript = (e, value) => {
    const executions = [
      ...(getExecutionPointsQuery.data?.execution_points ?? []).filter(
        (ep: ScriptExecutionPoint) => ep.type !== type && ep.target_fk === targetFk
      ),
      {
        tenant_id: tenantId,
        server_id: serverId,
        type,
        target_fk: targetFk,
        script_id: value ? value.id : null,
      },
    ];

    setUpdateScriptsProgress(true);
    adminScriptsApi
      .setScriptExecutionPoints({ wid: serverId, executions: { execution_points: executions } })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getExecutionPointsQueryKey(tenantId, serverId) })
      )
      .then(() => notifySuccess(`${stringLabel || label} updated successfully`))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to set script execution points"))
      .finally(() => {
        setUpdateScriptsProgress(false);
        setIsExtensionInputFocused(false);
      });
  };

  const progress =
    getScriptsQuery.isLoading || getExecutionPointsQuery.isLoading || updateScriptsProgress;

  return (
    <Paper id="connect-extension-container">
      <div
        style={{
          padding: "32px 24px",
          position: "relative",
        }}
      >
        <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
          <div>
            <Typography variant="h5" id="script-input-label" style={{ marginBottom: 12 }}>
              {label}
              {tooltip}
            </Typography>
            {description && (
              <Typography variant="textXSM" component="div" style={{ marginBottom: 24 }}>
                {description}
              </Typography>
            )}
            <Autocomplete
              id="script-input"
              options={getScriptsQuery.data?.scripts || []}
              value={(getScriptsQuery.data?.scripts || []).find(s => s.id === scriptID) || null}
              renderOption={(props, option) => (
                <li {...props} className={classes.listItem}>
                  <Link size={12} style={{ marginRight: 12 }} />
                  <Typography variant="caption">{option.name}</Typography>
                </li>
              )}
              getOptionLabel={option => option.name || ""}
              style={{ width: 300, marginBottom: 4 }}
              disabled={progress || !checkWorkspacePermissionsQuery.data?.manage_scripts}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  placeholder={
                    isExtensionInputFocused ? (progress ? "Loading..." : "Search") : "Select script"
                  }
                  onFocus={() => {
                    setIsExtensionInputFocused(true);
                  }}
                  onBlur={() => {
                    setIsExtensionInputFocused(false);
                  }}
                  InputProps={{
                    ...(params?.InputProps || {}),
                    startAdornment: isExtensionInputFocused ? (
                      <InputAdornment position="start">
                        <Search size="16" className={classes.icon} strokeWidth="2.5" />
                      </InputAdornment>
                    ) : null,
                    endAdornment: (
                      <>
                        {progress ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              onChange={handleChangeScript}
              ListboxProps={{
                className: classes.listBox,
              }}
            />
            <Typography variant="caption">
              {caption || "Select the extensions that you have created"}
            </Typography>
          </div>
          {!hideButtonLink && (
            <Button
              id="edit-button"
              color="primary"
              variant="outlined"
              role="link"
              style={{ width: 242 }}
              disabled={progress}
              onClick={() => {
                setNavMode("advanced");
                navigate(`/${serverId}/extensions`);
              }}
            >
              <ExternalLink size="16" style={{ marginRight: 10 }} />
              Manage Scripts
            </Button>
          )}
        </div>
      </div>
    </Paper>
  );
}

import React, { lazy, useCallback, useEffect, useMemo, useState } from "react";

import Search from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import debounce from "lodash/debounce";
import { ArrowLeft, Edit, XCircle } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { Policy } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import Dialog from "../../../common/components/Dialog";
import FormInputLabel from "../../../common/components/FormInputLabel";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import { getPoliciesQueryKey, useGetPolicies } from "../../services/adminPoliciesQuery";
import PoliciesCreate from "./PoliciesCreate";

const PoliciesEdit = lazy(() => import("./PoliciesEdit"));

const useStyles = makeStyles()(theme => ({
  goBack: {
    paddingRight: 14,
    cursor: "pointer",
  },
  clearSearchIcon: {
    cursor: "pointer",
  },
  dialog: {
    minWidth: 584,
  },
  dialogHidden: {
    display: "none",
  },
  searchField: {
    marginBottom: 24,
  },
  emptyState: {
    width: "100%",
    height: 345,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.secondary.light,
    fontSize: 14,
  },
  emptyStateTitle: {
    marginLeft: 14,
  },
  table: {
    borderRadius: 4,
    marginBottom: 24,
    height: 396,
  },
  anyRow: {
    cursor: "pointer",
  },
  anyValue: {
    fontWeight: 500,
  },
  defaultValue: {
    padding: "14px 16px",
  },
  otherRowCells: {
    borderBottom: "none",
    padding: "6px 16px",
  },
}));

export interface PolicySelectionProps {
  workspaceID: string;
  policy?: Policy;
  initialPolicy?: Policy;
  label: string;
  type: string;
  target?: string;
  onClose?: () => void;
  onChange?: (policy?: Policy, isNewPolicy?: boolean) => void;
  onSave?: (policy: Policy) => Promise<unknown>;
  goBackButton?: boolean;
  previewOpen?: boolean;
  onPreviewClosed?: () => void;
}

export const Unrestricted = "Unrestricted (No Policy)";

export function PolicySelection({
  goBackButton,
  label,
  onChange,
  onClose,
  onSave,
  policy,
  target,
  type,
  workspaceID,
  previewOpen,
  onPreviewClosed,
}: PolicySelectionProps) {
  const [innerPolicy, setInnerPolicy] = useState<Policy | undefined>(policy);
  const [policyPreview, setPolicyPreview] = useState<Policy | false>(false);
  const [policyCreate, setPolicyCreate] = useState<Policy | false>(false);
  const [searchPhraseForAPI, setSearchPhraseForAPI] = useState("");

  const queryClient = useQueryClient();

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspaceID);
  const policiesQuery = useGetPolicies(
    getTenantId(),
    workspaceID,
    type,
    undefined,
    searchPhraseForAPI
  );

  const innerOnClose = () => {
    onClose && onClose();
  };
  const innerCreatePolicy = () => {
    setPolicyCreate({ type: type });
  };
  const innerPolicyPreview = policy => {
    setPolicyPreview(policy);
  };

  const innerOnPolicyCreated = policy => {
    queryClient
      .invalidateQueries({ queryKey: getPoliciesQueryKey(getTenantId(), workspaceID, type) })
      .then(() => {
        setPolicyCreate(false);
        setPolicyPreview(policy);
        setInnerPolicy(policy);
        onChange && onChange(policy, true);
      });
  };

  const innerOnSave = async (policy: Policy) => {
    await onSave!(policy);
    innerOnClose();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchDebounced = useCallback(
    debounce((searchPhrase = "") => setSearchPhraseForAPI(searchPhrase), 250),
    []
  );

  const onSearch = useCallback(
    searchPhrase => {
      setSearchDebounced(searchPhrase);
    },
    [setSearchDebounced]
  );

  const policies = useMemo(() => {
    const policies = policiesQuery.data?.policies || [];
    return innerPolicy ? policies.filter(p => p.id !== innerPolicy.id) : policies;
  }, [innerPolicy, policiesQuery.data]);

  useEffect(() => {
    setPolicyPreview(previewOpen && policy ? policy : false);
  }, [previewOpen, policy]);

  return (
    <>
      <PolicySelectionDialog
        initialPolicy={policy}
        policy={innerPolicy}
        goBackButton={goBackButton}
        onSave={onSave && innerOnSave}
        onClose={innerOnClose}
        target={target}
        onSearch={onSearch}
        isSearching={policiesQuery.isFetching}
        policies={policies}
        label={label}
        onCreateNewPolicy={innerCreatePolicy}
        onPreviewPolicy={innerPolicyPreview}
        onSelectPolicy={onChange}
        hidden={!!(policyPreview || policyCreate)}
        hasManagePermission={!!checkWorkspacePermissionsQuery.data?.manage_policies}
      />
      {policyPreview && (
        <div
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <PoliciesEdit
            workspace={workspaceID}
            policyId={policyPreview.id}
            onBack={() => {
              setPolicyPreview(false);
              onPreviewClosed && onPreviewClosed();
            }}
          />
        </div>
      )}
      {policyCreate && (
        <div
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <PoliciesCreate
            title={"Create Policy " + (target ? `for: ${target}` : "")}
            type={policyCreate.type as string}
            typeReadonly
            workspace={workspaceID}
            existingIds={(policiesQuery.data?.policies || []).map(p => p.id as string)}
            onCancel={() => setPolicyCreate(false)}
            onCreate={innerOnPolicyCreated}
          />
        </div>
      )}
    </>
  );
}

export interface PolicySelectionDialogProps {
  onSelectPolicy?: (policy?: Policy) => void;
  onPreviewPolicy: (policy: Policy) => void;
  onCreateNewPolicy: () => void;
  onSave?: PolicySelectionProps["onSave"];
  onClose: () => void;
  onSearch: (searchPhrase?: string) => void;
  isSearching: boolean;
  policies: Policy[];
  policy: PolicySelectionProps["policy"];
  initialPolicy: PolicySelectionProps["initialPolicy"];
  target: PolicySelectionProps["target"];
  label: PolicySelectionProps["label"];
  goBackButton?: PolicySelectionProps["goBackButton"];
  hidden?: boolean;
  hasManagePermission: boolean;
}

const emptyInnerSelectedPolicy = { policy_name: Unrestricted };

export const PolicySelectionDialog = ({
  goBackButton,
  label,
  onClose,
  onCreateNewPolicy,
  onPreviewPolicy,
  onSave,
  onSelectPolicy,
  onSearch,
  isSearching,
  policies,
  policy,
  initialPolicy,
  target,
  hidden,
  hasManagePermission,
}: PolicySelectionDialogProps) => {
  const { cx, classes } = useStyles();
  const [innerSelectedPolicy, setInnerSelectedPolicy] = useState<Policy | undefined>(
    policy || emptyInnerSelectedPolicy
  );
  const [saveProgress, setSaveProgress] = useState(false);

  useEffect(() => {
    if (policy) {
      setInnerSelectedPolicy(policy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy]);

  const [searchValue, setSearchValue] = useState("");

  const onFilter = (newSearchPhrase: string) => {
    setSearchValue(newSearchPhrase);
    onSearch(newSearchPhrase);
  };

  const selectPolicy = (policy?: Policy) => {
    setInnerSelectedPolicy(policy || emptyInnerSelectedPolicy);
    onSelectPolicy && onSelectPolicy(policy);
  };

  const isSelected = (policyRow?: Policy) => {
    if (!innerSelectedPolicy) {
      return false;
    }

    return innerSelectedPolicy.id === policyRow?.id;
  };

  const clearFilter = () => {
    onFilter("");
  };

  const previewPolicy = (e, policy) => {
    e.stopPropagation();
    onPreviewPolicy(policy);
  };

  return (
    <Dialog
      onClose={onClose}
      onClick={e => {
        e.stopPropagation();
      }}
      id="select-policy-dialog"
      classes={{
        paperWidthSm: cx(classes.dialog, hidden ? classes.dialogHidden : undefined),
      }}
      title={
        <Grid container>
          {goBackButton && (
            <Grid item className={classes.goBack}>
              <ArrowLeft onClick={onClose} data-testid="policy-selection-cancel-arrow" />
            </Grid>
          )}
          <Grid item xs zeroMinWidth>
            <Typography id="select-policy-title" variant="h4">
              Select Policy {target && <>for: {target}</>}
            </Typography>
          </Grid>
        </Grid>
      }
    >
      <FormInputLabel id="select-policy-input-label" label={label} />
      <TextField
        className={classes.searchField}
        variant="outlined"
        placeholder="Search policies"
        value={searchValue}
        onChange={e => {
          onFilter(e.target.value);
        }}
        fullWidth
        inputProps={{
          "data-testid": "policy-selection-search-input",
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              className={searchValue !== "" ? classes.clearSearchIcon : undefined}
            >
              {(isSearching && <CircularProgress size={20} />) ||
                (searchValue === "" && <Search />) || <XCircle onClick={clearFilter} />}
            </InputAdornment>
          ),
        }}
        helperText="Use the search if your policy does not appear on the list"
      />
      <TableContainer component={Paper} className={classes.table}>
        <Table id="policy-selection-table" size="small">
          <TableBody>
            <TableRow
              className={classes.anyRow}
              hover={true}
              selected={isSelected()}
              onClick={() => selectPolicy(undefined)}
            >
              <TableCell
                className={cx(classes.anyValue, classes.otherRowCells, classes.defaultValue)}
              >
                Unrestricted
                {!initialPolicy && (
                  <Typography variant="caption" style={{ marginLeft: 8 }}>
                    (current)
                  </Typography>
                )}
              </TableCell>
              <TableCell align="right" className={classes.otherRowCells} style={{ fontSize: 12 }}>
                No policy
              </TableCell>
            </TableRow>
            {policy && (
              <TableRow
                data-testid={"policy-selection-list-item-" + policy.id}
                key={policy.id}
                onClick={() => selectPolicy(policy)}
                className={classes.anyRow}
                hover={true}
                selected={isSelected(policy)}
              >
                <TableCell className={cx(classes.otherRowCells, classes.anyValue)}>
                  {policy.policy_name}
                  <Typography variant="caption" style={{ marginLeft: 8 }}>
                    {initialPolicy?.id === policy?.id ? "(current)" : "(new)"}
                  </Typography>
                </TableCell>
                <TableCell align="right" className={classes.otherRowCells}>
                  <Tooltip title="Edit policy" placement="top">
                    <IconButton onClick={e => previewPolicy(e, policy)} size="large">
                      <Edit size="16px" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )}
            {policies.length > 0 &&
              policies.map(policy => (
                <TableRow
                  data-testid={"policy-selection-list-item-" + policy.id}
                  key={policy.id}
                  onClick={() => selectPolicy(policy)}
                  className={classes.anyRow}
                  hover={true}
                  selected={isSelected(policy)}
                >
                  <TableCell className={cx(classes.otherRowCells, classes.anyValue)}>
                    {policy.policy_name}
                    {initialPolicy?.id === policy?.id && (
                      <Typography variant="caption" style={{ marginLeft: 8 }}>
                        (current)
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="right" className={classes.otherRowCells}>
                    <Tooltip title="Edit policy" placement="top">
                      <IconButton onClick={e => previewPolicy(e, policy)} size="large">
                        <Edit size="16px" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        {hasManagePermission && (
          <Button
            id="search-policy-add-new-button"
            size="large"
            onClick={onCreateNewPolicy}
            style={{ paddingTop: 12, paddingBottom: 12 }}
            disabled={saveProgress}
          >
            + Create policy
          </Button>
        )}
        {onSave && (
          <LoadingButton
            id="search-policy-save-button"
            variant="contained"
            size="large"
            disabled={!innerSelectedPolicy || innerSelectedPolicy.id === initialPolicy?.id}
            onClick={() => {
              if (innerSelectedPolicy) {
                setSaveProgress(true);
                onSave(innerSelectedPolicy).finally(() => setSaveProgress(false));
              }
            }}
            style={{ width: 100, float: "right", marginLeft: 14 }}
            loading={saveProgress}
          >
            Save
          </LoadingButton>
        )}
        {!goBackButton && (
          <Button
            id="search-policy-cancel-button"
            size="large"
            onClick={onClose}
            style={{ width: 100, float: "right", paddingTop: 12, paddingBottom: 12 }}
            disabled={saveProgress}
          >
            Cancel
          </Button>
        )}
      </div>
    </Dialog>
  );
};

import React from "react";
import { useNavigate } from "react-router";

import MoreHoriz from "@mui/icons-material/MoreHoriz";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { PoolResponse } from "@cloudentity/acp-identity";

import OverflowTooltip from "../../../../../common/components/OverflowTooltip";
import SelectablePaper from "../../../../../common/components/SelectablePaper";
import { usePoolRootUrl } from "../utils";
import IdentityPoolCardIcons from "./IdentityPoolCardIcons";
import { getNumberOfUsers } from "./utils";

const useStyles = makeStyles()(theme => ({
  cardTop: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
  },
  cardTopLeft: {
    display: "flex",
    maxWidth: "100%",
    width: "100%",
  },
  card: {
    padding: 10,
  },
  cardTopLeftName: {
    maxWidth: "calc(100% - 75px)",
    marginTop: 6,
    display: "flex",
    flexDirection: "column",
    "& > p": {
      color: "black",
      fontWeight: 600,
      lineHeight: "16px",
    },
    "& > span": {
      color: theme.palette.secondary.light,
    },
  },
  cardTopLeftType: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardBottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 8,
  },
  cardBottomItem: {
    display: "flex",
    alignItems: "center",
    width: "50%",
  },
  cardBottomItemInactive: {
    opacity: 0.2,
  },
  cardBottomItemIcon: {
    backgroundColor: "#F2F3F4",
    width: 18,
    height: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 8,
  },
  cardActions: {
    display: "flex",
    position: "absolute",
    right: -8,
    top: -8,
  },
  iconButton: {
    padding: 8,
    marginLeft: 2,
  },
  population: {
    fontWeight: "bold",
    padding: "3px 12px",
    backgroundColor: "#F2F3F4",
    minWidth: 50,
    textAlign: "center",
  },
}));

interface Props {
  pool: PoolResponse;
  onMenuOpen?: ({ elementId, pool }: { elementId: string; pool: PoolResponse }) => void;
  onSelect?: (id: string) => void;
  showIcons?: boolean;
}

export default function IdentityPoolsGridItem({
  pool,
  onMenuOpen,
  onSelect,
  showIcons = true,
}: Props) {
  const { classes } = useStyles();
  const { rootUrl } = usePoolRootUrl();

  const navigate = useNavigate();
  const { id, name, description } = pool;

  return (
    <SelectablePaper
      id={`${pool.id}`}
      className={classes.card}
      selectable
      onSelect={() => {
        if (onSelect) {
          onSelect(pool.id ?? "");
        } else {
          navigate(`${rootUrl}/${pool.id}/users`);
        }
      }}
    >
      <div className={classes.cardTop}>
        <div className={classes.cardTopLeft}>
          <Avatar style={{ marginRight: 16, backgroundColor: pool.badge_color || "#BDBDBD" }}>
            {(name && name[0] && name[0].toUpperCase()) || ""}
          </Avatar>
          <div className={classes.cardTopLeftName}>
            <OverflowTooltip id="pool-card-name-tooltip" value={name} />
            <Typography variant="textXSM" className={classes.cardTopLeftType}>
              {description || "No description"}
            </Typography>
          </div>
        </div>
        {onMenuOpen && (
          <div className={classes.cardActions}>
            <IconButton
              onClick={e => {
                e.stopPropagation();
                onMenuOpen({ elementId: e.currentTarget.id, pool });
              }}
              id={`pool-${id}-menu-button`}
              className={classes.iconButton}
            >
              <MoreHoriz />
            </IconButton>
          </div>
        )}
      </div>
      <div>
        <div className={classes.cardBottom}>
          <div className={classes.cardBottomItem}>
            {showIcons && <IdentityPoolCardIcons pool={pool} />}
          </div>
          <div className={classes.cardBottomItem} style={{ justifyContent: "flex-end" }}>
            <Typography
              variant="textSM"
              className={classes.population}
              id={`pool-${pool.id}-population`}
            >
              {getNumberOfUsers(pool.number_of_users)}
            </Typography>
          </div>
        </div>
      </div>
    </SelectablePaper>
  );
}

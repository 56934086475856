/* eslint-disable @typescript-eslint/ban-types */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMemo } from "react";
import { createUseCache } from "./makeStyles";
import { createUseCssAndCx } from "./cssAndCx";
import { assert } from "./tools/assert";
import { objectFromEntries } from "./tools/polyfills/Object.fromEntries";
import { objectKeys } from "./tools/objectKeys";
import { typeGuard } from "./tools/typeGuard";
import { getDependencyArrayRef } from "./tools/getDependencyArrayRef";
import { mergeClasses } from "./mergeClasses";
import { isSSR } from "./tools/isSSR";
export function createTss(params) {
    counter = 0;
    nestedSelectorUsageTrackRecord.splice(0, nestedSelectorUsageTrackRecord.length);
    const { useContext, usePlugin, cache: cacheProvidedAtInception } = params;
    const { useCache } = createUseCache({ cacheProvidedAtInception });
    const { useCssAndCx } = createUseCssAndCx({ useCache });
    const usePluginDefault = ({ classes, cx, css }) => ({ classes, cx, css });
    const tss = createTss_internal({
        useContext,
        useCache,
        useCssAndCx,
        "usePlugin": usePlugin !== null && usePlugin !== void 0 ? usePlugin : usePluginDefault,
        "name": undefined,
        "doesUseNestedSelectors": false
    });
    return { tss };
}
let counter = 0;
const nestedSelectorUsageTrackRecord = [];
function createTss_internal(params) {
    const { useContext, useCache, useCssAndCx, usePlugin, name, doesUseNestedSelectors } = params;
    return {
        "withParams": () => createTss_internal(Object.assign({}, params)),
        "withName": nameOrWrappedName => createTss_internal(Object.assign(Object.assign({}, params), { "name": typeof nameOrWrappedName !== "object"
                ? nameOrWrappedName
                : Object.keys(nameOrWrappedName)[0] })),
        "withNestedSelectors": () => createTss_internal(Object.assign(Object.assign({}, params), { "doesUseNestedSelectors": true })),
        "create": (cssObjectByRuleNameOrGetCssObjectByRuleName) => {
            // NOTE: Not isomorphic. Not guaranteed to be the same on client and server.
            // Do not attempt to 'simplify' the code without taking this fact into account.
            const idOfUseStyles = `x${counter++}`;
            // NOTE: Cleanup for hot module reloading.
            if (name !== undefined) {
                // eslint-disable-next-line no-constant-condition
                while (true) {
                    const wrap = nestedSelectorUsageTrackRecord.find(wrap => wrap.name === name);
                    if (wrap === undefined) {
                        break;
                    }
                    nestedSelectorUsageTrackRecord.splice(nestedSelectorUsageTrackRecord.indexOf(wrap), 1);
                }
            }
            const getCssObjectByRuleName = typeof cssObjectByRuleNameOrGetCssObjectByRuleName ===
                "function"
                ? cssObjectByRuleNameOrGetCssObjectByRuleName
                : () => cssObjectByRuleNameOrGetCssObjectByRuleName;
            return function useStyles(params) {
                var _a, _b, _c;
                const _d = (params !== null && params !== void 0 ? params : {}), { classesOverrides } = _d, paramsAndPluginParams = __rest(_d, ["classesOverrides"]);
                const context = useContext();
                const { css, cx } = useCssAndCx();
                const cache = useCache();
                let classes = useMemo(() => {
                    const refClassesCache = {};
                    // @ts-expect-error: Type safety non achievable.
                    const cssObjectByRuleName = getCssObjectByRuleName(Object.assign(Object.assign(Object.assign({}, params), context), (!doesUseNestedSelectors
                        ? {}
                        : {
                            "classes": typeof Proxy === "undefined"
                                ? {}
                                : new Proxy({}, {
                                    "get": (_target, ruleName) => {
                                        /* prettier-ignore */
                                        if (typeof ruleName === "symbol") {
                                            assert(false);
                                        }
                                        if (isSSR &&
                                            name === undefined) {
                                            throw new Error([
                                                `tss-react: In SSR setups, in order to use nested selectors, you must also give a unique name to the useStyle function.`,
                                                `Solution: Use tss.withName("ComponentName").withNestedSelectors<...>()... to set a name.`
                                            ].join("\n"));
                                        }
                                        update_nested_selector_usage_track_record: {
                                            if (name === undefined) {
                                                break update_nested_selector_usage_track_record;
                                            }
                                            /* prettier-ignore */
                                            let wrap = nestedSelectorUsageTrackRecord.find(wrap => wrap.name === name && wrap.idOfUseStyles === idOfUseStyles);
                                            /* prettier-ignore */
                                            if (wrap === undefined) {
                                                /* prettier-ignore */
                                                wrap = { name, idOfUseStyles, "nestedSelectorRuleNames": new Set() };
                                                /* prettier-ignore */
                                                nestedSelectorUsageTrackRecord.push(wrap);
                                            }
                                            /* prettier-ignore */
                                            wrap.nestedSelectorRuleNames.add(ruleName);
                                        }
                                        detect_potential_conflicts: {
                                            if (name === undefined) {
                                                break detect_potential_conflicts;
                                            }
                                            const hasPotentialConflict = nestedSelectorUsageTrackRecord.find(wrap => wrap.name ===
                                                name &&
                                                wrap.idOfUseStyles !==
                                                    idOfUseStyles &&
                                                wrap.nestedSelectorRuleNames.has(ruleName)) !== undefined;
                                            if (!hasPotentialConflict) {
                                                break detect_potential_conflicts;
                                            }
                                            throw new Error([
                                                `tss-react: There are in your codebase two different useStyles named "${name}" that`,
                                                `both use use the nested selector ${ruleName}.\n`,
                                                `This may lead to CSS class name collisions, causing nested selectors to target elements outside of the intended scope.\n`,
                                                `Solution: Ensure each useStyles using nested selectors has a unique name.\n`,
                                                `Use: tss.withName("UniqueName").withNestedSelectors<...>()...`
                                            ].join(" "));
                                        }
                                        /* prettier-ignore */
                                        return (refClassesCache[ruleName] = `${cache.key}-${name !== undefined ? name : idOfUseStyles}-${ruleName}-ref`);
                                    }
                                })
                        })));
                    const classes = objectFromEntries(objectKeys(cssObjectByRuleName).map(ruleName => {
                        const cssObject = cssObjectByRuleName[ruleName];
                        if (!cssObject.label) {
                            cssObject.label = `${name !== undefined ? `${name}-` : ""}${ruleName}`;
                        }
                        return [
                            ruleName,
                            `${css(cssObject)}${typeGuard(ruleName, ruleName in refClassesCache)
                                ? ` ${refClassesCache[ruleName]}`
                                : ""}`
                        ];
                    }));
                    objectKeys(refClassesCache).forEach(ruleName => {
                        if (ruleName in classes) {
                            return;
                        }
                        classes[ruleName] =
                            refClassesCache[ruleName];
                    });
                    return classes;
                }, [
                    cache,
                    css,
                    cx,
                    getDependencyArrayRef(params),
                    ...Object.values(context)
                ]);
                classes = useMemo(() => mergeClasses(classes, classesOverrides, cx), [classes, getDependencyArrayRef(classesOverrides), cx]);
                // @ts-expect-error: Type safety non achievable.
                const pluginResultWrap = usePlugin(Object.assign(Object.assign({ classes,
                    css,
                    cx,
                    idOfUseStyles,
                    name }, context), paramsAndPluginParams));
                return Object.assign({ "classes": (_a = pluginResultWrap.classes) !== null && _a !== void 0 ? _a : classes, "css": (_b = pluginResultWrap.css) !== null && _b !== void 0 ? _b : css, "cx": (_c = pluginResultWrap.cx) !== null && _c !== void 0 ? _c : cx }, context);
            };
        }
    };
}

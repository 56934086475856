import React, { useState } from "react";

import { UserVerifiableAddressTypeEnum } from "@cloudentity/acp-identity";

import Dialog from "../../../../../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../common/components/notifications/notificationService";
import { FormContext } from "../../../../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../../../../common/utils/forms/formFactory";
import { useGetPool } from "../../../../../../services/adminIdentityPoolsQuery";
import identityUsersApi from "../../../../../../services/adminIdentityUsersApi";
import { useGetUser } from "../../../../../../services/adminIdentityUsersQuery";
import { useWorkspace } from "../../../../../common/useWorkspace";
import { usePoolRootUrl } from "../../../utils";
import IdentityPoolThemeSelector from "../../IdentityPoolThemeSelector";
import {
  setPoolWorkspaceId,
  useIdentityPoolThemeSelector,
} from "../../useIdentityPoolThemeSelector";

interface Props {
  poolId: string;
  userId: string;
  onClose: () => void;
}

const data = {
  address: "",
};

export default function SendActivationMessageDialog({ poolId, userId, onClose }: Props) {
  const [progress, setProgress] = useState(false);

  const [workspace] = useWorkspace();
  const { mode } = usePoolRootUrl();

  const poolQuery = useGetPool(poolId);
  const getUserQuery = useGetUser(poolId, userId);

  const formFactory = useFormFactory({
    id: "send-activation-message",
    data,
    progress: progress || poolQuery.isLoading || getUserQuery.isLoading,
  });

  const { themeWorkspaceId, mustSelectThemeServerId } = useIdentityPoolThemeSelector({
    pool: poolQuery.data,
  });

  const handleSendMessage = data =>
    identityUsersApi
      .sendActivationMessage({
        ipID: poolId,
        userID: userId,
        sendActivationMessage: { address: data.address },
        serverId: mode === "workspace" ? workspace : data.themeWorkspaceId || themeWorkspaceId,
      })
      .then(() => {
        notifySuccess(
          <span>
            Invite sent to: <strong>{data.address}</strong>
          </span>
        );
        onClose();
      })
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to send invitation message"))
      .finally(() => setProgress(false));

  const handleSubmit = data => {
    setProgress(true);
    if (data.rememberThemeWorkspaceId) {
      setPoolWorkspaceId(poolQuery.data?.id || "", data.themeWorkspaceId || "");
      return handleSendMessage(data);
    }

    return handleSendMessage(data);
  };

  return (
    <Dialog
      onClose={onClose}
      id="send-activation-message-dialog"
      title="Activation message address selection"
    >
      <FormContext.Provider value={formFactory.context}>
        {getUserQuery.data?.verifiable_addresses &&
          formFactory.createSelect({
            name: "address",
            label: "Email",
            options: (getUserQuery.data?.verifiable_addresses || [])
              .filter(a => a.type === UserVerifiableAddressTypeEnum.Email)
              .map(({ address }) => ({
                value: address,
                name: address,
              })),
            optional: false,
            multiple: false,
            rules: {
              required: "Email is required",
            },
          })}
        {mustSelectThemeServerId && (
          <IdentityPoolThemeSelector
            pool={poolQuery.data}
            formFactory={formFactory}
            showRememberCheckbox
          />
        )}
        {formFactory.createFormFooter({
          onCancel: onClose,
          onSubmit: handleSubmit,
          submitText: "Send",
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

import React, { useMemo, useState } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useQueryClient } from "@tanstack/react-query";

import {
  GrantIdentityPoolRoleRequestRoleEnum,
  GrantTenantRoleRequestRoleEnum,
  GrantTenantRoleRequestTypeEnum,
  RevokeTenantRoleRequestRoleEnum,
  RevokeTenantRoleRequestTypeEnum,
  TenantPermissionsResponse,
} from "@cloudentity/acp-admin";
import { BaseUserWithData, UserIdentifierTypeEnum, UserWithData } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../common/api/paths";
import DialogFullScreen from "../../../../common/components/DialogFullScreen";
import Progress from "../../../../common/components/Progress";
import RouteLeavingGuard from "../../../../common/components/RouteLeavingGuard";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { useCheckPoolPermissions } from "../../../services/adminIdentityPoolsQuery";
import identityUsersApi from "../../../services/adminIdentityUsersApi";
import {
  getUserQueryKey,
  listUsersQueryKey,
  useGetUser,
} from "../../../services/adminIdentityUsersQuery";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import adminRolesApi from "../../../services/adminRolesApi";
import {
  listTenantRoles,
  listUserRoles,
  useListIdentityPoolRoles,
  useListWorkspaceRoles,
} from "../../../services/adminRolesQuery";
import {
  getUserRole,
  getUserRolesBySubjects,
  grantAndRevokeWorkspaceRoles,
  tenantRoleToDisplayRole,
} from "../identityPools/identityPool/users/list/utils";
import IdentityPoolUserSidePanel from "../identityPools/identityPool/users/user/sidePanel/IdentityPoolUserSidePanel";
import AdministratorUserDeleteButton from "./AdministratorUserDeleteButton";
import ManageUserActions from "./ManageUserActions";
import TenantRoleSelectField from "./TenantRoleSelectField";
import WorkspaceRoleSelectField, {
  isAnyWorkspaceOrPoolRoleAllowed,
} from "./WorkspaceRoleSelectField";
import WorkspaceRoleTable from "./WorkspaceRoleTable";
import { useTenantRoles } from "./useTenantRoles";

export const tenantRoleOptions: {
  value: GrantTenantRoleRequestRoleEnum;
  name: string;
  description: string;
}[] = [
  {
    value: GrantTenantRoleRequestRoleEnum.Member,
    name: "Member",
    description: "Can administer assigned workspaces or organizations",
  },
  {
    value: GrantTenantRoleRequestRoleEnum.Auditor,
    name: tenantRoleToDisplayRole(GrantTenantRoleRequestRoleEnum.Auditor),
    description: "Read only access to all tenant resources",
  },
  {
    value: GrantTenantRoleRequestRoleEnum.Admin,
    name: tenantRoleToDisplayRole(GrantTenantRoleRequestRoleEnum.Admin),
    description: "Full access to all tenant resources",
  },
  {
    value: GrantTenantRoleRequestRoleEnum.BusinessAdmin,
    name: tenantRoleToDisplayRole(GrantTenantRoleRequestRoleEnum.BusinessAdmin),
    description: "Onboard and manage all organizations via the delegated admin portal",
  },
];

export const getTenantRoleOptionsByPermissions = (permissions?: TenantPermissionsResponse) => {
  let options = [
    {
      value: GrantTenantRoleRequestRoleEnum.Member,
      name: "Member",
      description: "Can administer assigned workspaces or organizations",
    },
  ];

  if (permissions?.manage_auditor_role) {
    options = [
      ...options,
      tenantRoleOptions.find(o => o.value === GrantTenantRoleRequestRoleEnum.Auditor)!,
    ];
  }

  if (permissions?.manage_admin_role) {
    options = [
      ...options,
      tenantRoleOptions.find(o => o.value === GrantTenantRoleRequestRoleEnum.Admin)!,
    ];
  }

  if (permissions?.manage_business_admin_role) {
    options = [
      ...options,
      tenantRoleOptions.find(o => o.value === GrantTenantRoleRequestRoleEnum.BusinessAdmin)!,
    ];
  }

  return options;
};

export const getUserEmails = (user: UserWithData | BaseUserWithData) =>
  user?.identifiers
    ?.filter(i => i.type === UserIdentifierTypeEnum.Email)
    .map(i => i.identifier)
    .join(", ") || "";

interface Props {
  workspaceId: string;
  poolId: string;
  userId: string;
  onEdited?: (user: UserWithData) => void;
  onClose: () => void;
}

export default function AdministratorUserEdit({
  workspaceId,
  poolId,
  userId,
  onEdited,
  onClose,
}: Props) {
  const [progress, setProgress] = useState(false);

  const queryClient = useQueryClient();
  const getUserQuery = useGetUser(poolId, userId);
  const checkPoolPermissionsQuery = useCheckPoolPermissions(poolId);

  const user = getUserQuery.data;

  const { query: tenantRolesQuery } = useTenantRoles({
    identityPoolId: poolId,
  });

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspaceId);

  const listWorkspaceRolesQuery = useListWorkspaceRoles(workspaceId, {
    enabled: !!checkWorkspacePermissionsQuery.data?.read_roles,
  });
  const listIdentityPoolRolesQuery = useListIdentityPoolRoles(poolId!, {
    enabled: !!checkWorkspacePermissionsQuery.data?.read_roles,
  });

  const initialRole =
    user && tenantRolesQuery.data ? getUserRole(user, tenantRolesQuery.data?.subjects || []) : null;

  const initialRoles = useMemo(
    () =>
      getUserRolesBySubjects(
        user,
        tenantRolesQuery.data?.subjects,
        listWorkspaceRolesQuery.data?.subjects,
        listIdentityPoolRolesQuery.data?.subjects
      ),
    [user, tenantRolesQuery.data, listWorkspaceRolesQuery.data, listIdentityPoolRolesQuery.data]
  );

  const data = useMemo(() => {
    const emails = getUserEmails(user!);

    return {
      email: emails,
      given_name: user?.payload?.given_name,
      family_name: user?.payload?.family_name,
      role: initialRole,
      roles: [
        ...initialRoles.workspace.map(r => r),
        ...(initialRoles.identityPool.includes(GrantIdentityPoolRoleRequestRoleEnum.UserManager)
          ? ["team_manager"]
          : []),
      ],
      ...(user || {}),
    };
  }, [user, initialRole, initialRoles]);

  const formFactory = useFormFactory({
    id: "administrator-edit-user",
    data,
    progress: progress || getUserQuery.isFetching,
    noManagePermission: !checkPoolPermissionsQuery.data?.manage_identity_pool_users,
  });

  const handleUpdate = data => {
    setProgress(true);
    return identityUsersApi
      .updateUser({
        ipID: poolId,
        userID: userId,
        updateUser: {
          payload: {
            given_name: data.given_name.trim(),
            family_name: data.family_name.trim(),
          },
        },
      })
      .then(res => {
        if (
          (data.roles || []).length > 0 &&
          isAnyWorkspaceOrPoolRoleAllowed(
            checkWorkspacePermissionsQuery.data,
            checkPoolPermissionsQuery.data
          ) &&
          user
        ) {
          return grantAndRevokeWorkspaceRoles(
            queryClient,
            workspaceId,
            user,
            initialRoles,
            data.roles
          ).then(() => res);
        }
        if (data.role && data.role !== initialRole) {
          const common = {
            identity_pool_id: poolId,
            identity_pool_user_id: userId,
            tenant_id: getTenantId(),
          };

          if (data.role && !initialRole) {
            return adminRolesApi
              .grantTenantRole({
                request: {
                  role: data.role,
                  type: GrantTenantRoleRequestTypeEnum.IdentityPoolUser,
                  ...common,
                },
              })
              .then(() => queryClient.invalidateQueries({ queryKey: listTenantRoles() }))
              .then(() =>
                queryClient.invalidateQueries({
                  queryKey: listUserRoles(user?.user_pool_id, user?.id),
                })
              )
              .then(() => res);
          }

          if (data.role && initialRole) {
            return adminRolesApi
              .revokeTenantRole({
                request: {
                  role: initialRole as unknown as RevokeTenantRoleRequestRoleEnum,
                  type: RevokeTenantRoleRequestTypeEnum.IdentityPoolUser,
                  ...common,
                },
              })
              .then(() =>
                adminRolesApi.grantTenantRole({
                  request: {
                    role: data.role as GrantTenantRoleRequestRoleEnum,
                    type: GrantTenantRoleRequestTypeEnum.IdentityPoolUser,
                    ...common,
                  },
                })
              )
              .then(() => queryClient.invalidateQueries({ queryKey: listTenantRoles() }))
              .then(() =>
                queryClient.invalidateQueries({
                  queryKey: listUserRoles(user?.user_pool_id, user?.id),
                })
              )
              .then(() => res);
          }

          return res;
        }
        return res;
      })
      .then(res => {
        onEdited && onEdited(res.data);
        queryClient.setQueryData(getUserQueryKey(getTenantId(), userId), res.data);
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listUsersQueryKey(getTenantId(), poolId) })
      )
      .then(() => notifySuccess("User edited successfully"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to edit user"))
      .finally(() => {
        setProgress(false);
      });
  };

  return (
    <DialogFullScreen
      id="administrator-edit-user"
      onClose={onClose}
      onCancel={onClose}
      title="User Profile"
    >
      <FormContext.Provider value={formFactory.context}>
        {getUserQuery.isLoading && <Progress />}
        {!getUserQuery.isLoading && (
          <Grid container spacing={4} style={{ maxWidth: 1440 }}>
            <Grid item md={7} sm={12}>
              <Paper style={{ padding: "32px" }}>
                {formFactory.createField({
                  name: "email",
                  label: "Email",
                  disabled: true,
                  optional: false,
                  withCopy: true,
                })}
                {formFactory.createRequiredField({
                  name: "given_name",
                  label: "First Name",
                })}
                {formFactory.createRequiredField({
                  name: "family_name",
                  label: "Last Name",
                })}

                <TenantRoleSelectField
                  user={user}
                  identityPoolId={poolId}
                  formFactory={formFactory}
                />
                {!!checkWorkspacePermissionsQuery.data?.read_roles && workspaceId !== "admin" && (
                  <WorkspaceRoleSelectField
                    user={user}
                    wid={workspaceId}
                    workspaceSubjects={listWorkspaceRolesQuery.data?.subjects}
                    formFactory={formFactory}
                  />
                )}
              </Paper>

              <WorkspaceRoleTable user={user} />

              <div
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
              >
                {checkPoolPermissionsQuery.data?.manage_identity_pool_users && (
                  <AdministratorUserDeleteButton
                    disabled={getUserQuery.isFetching}
                    user={user!}
                    email={data.email}
                    onDeleted={() => {
                      onClose();
                    }}
                  />
                )}

                {formFactory.createFormFooter({
                  style: { marginTop: 32 },
                  onSubmit: handleUpdate,
                })}
              </div>
            </Grid>
            {user && (
              <Grid item md={5} sm={12}>
                <IdentityPoolUserSidePanel
                  user={user}
                  workspaceId={workspaceId}
                  hideManageButton
                  poolId={poolId}
                  onlyInfo
                  customManageButton={
                    checkPoolPermissionsQuery.data?.manage_identity_pool_users ? (
                      <ManageUserActions
                        identityPoolId={poolId}
                        workspaceId={workspaceId}
                        userId={userId}
                        onCloseEditDialog={onClose}
                      />
                    ) : null
                  }
                />
              </Grid>
            )}
          </Grid>
        )}
        <RouteLeavingGuard />
      </FormContext.Provider>
    </DialogFullScreen>
  );
}

import React, { CSSProperties, ReactNode } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import TTLField from "../../../admin/components/common/TTLField";

export interface DurationFieldProps {
  id?: string;
  form: UseFormReturn;
  name: string;
  label?: string;
  description: string | ReactNode;
  validate?: any;
  style?: CSSProperties;
  disabled?: boolean;
  defaultValue?: string;
  withMilliseconds?: boolean;
}

export default function DurationField({
  id = "",
  form,
  name,
  label = "",
  description,
  validate,
  style = {},
  disabled,
  defaultValue = "0h0m0s",
  withMilliseconds,
}: DurationFieldProps) {
  return (
    <Controller
      name={name}
      control={form.control}
      rules={{ validate }}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <TTLField
            id={id}
            title={label}
            description={description}
            value={field.value}
            onChange={v => {
              field.onChange(v);
            }}
            errorText={fieldState.error?.message}
            style={style}
            disabled={disabled}
            withMilliseconds={withMilliseconds}
          />
        );
      }}
    />
  );
}

import React from "react";

import { Attribute } from "@cloudentity/acp-admin";

import Dialog from "../../../common/components/Dialog";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import { validators } from "../../../common/utils/forms/validation";
import { attributesDataTypeOptions } from "./attrbutesDataTypeOptions";

interface Props {
  attributes: Attribute[];
  onCreate: (attribute: Attribute) => void;
  onCancel: () => void;
  progress: boolean;
}

export default function AuthNContextAddAttribute({
  attributes,
  onCreate,
  onCancel,
  progress,
}: Props) {
  const formFactory = useFormFactory({ id: "authn-context-add-attribute", progress });

  return (
    <Dialog onClose={onCancel} id="add-scope-dialog" title="Add attribute">
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createRequiredField({
          name: "name",
          label: "Name",
          rules: {
            validate: {
              notUniq: validators.notUniq({ label: "Name", options: attributes.map(a => a.name) }),
            },
          },
          autoFocus: true,
        })}
        {formFactory.createSelect({
          name: "type",
          label: "Data type",
          options: attributesDataTypeOptions,
          rules: {
            required: "Data type is required",
          },
        })}
        {formFactory.createRequiredField({ name: "description", label: "Description" })}

        {formFactory.createFormFooter({ onCancel, onSubmit: onCreate, submitText: "Create" })}
      </FormContext.Provider>
    </Dialog>
  );
}

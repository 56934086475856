import React, { Dispatch, SetStateAction } from "react";

import { UiSchema } from "@rjsf/utils";
import isEmpty from "lodash/isEmpty";

import { SupportedJSONSchema } from "@cloudentity/acp-admin";
import { BaseUserWithData } from "@cloudentity/acp-identity";

import SchemaForm from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/user/SchemaForm";
import {
  getUIOrderBasedOnRequiredFields,
  mapFieldNameToTitle,
} from "../../../admin/components/workspaceDirectory/identityPools/schemas/schemas.utils";
import { FormFactory } from "../../../common/utils/forms/formFactory";

interface Props {
  user: BaseUserWithData;
  payloadSchema: SupportedJSONSchema | null;
  formFactory: FormFactory;
  progress: boolean;
  submitAttempt: boolean;
  extraErrors: any;
  resetExtraErrors: () => void;
  disabled?: boolean;
  payload: { [key: string]: object };
  setPayload: Dispatch<SetStateAction<{ [key: string]: object }>>;
}
export default function UserEditPayload({
  user,
  payloadSchema,
  formFactory,
  progress,
  submitAttempt,
  extraErrors,
  resetExtraErrors,
  disabled,
  payload,
  setPayload,
}: Props) {
  const payloadSchemaWithMappedTitles = mapFieldNameToTitle(payloadSchema || {});

  const payloadUISchema: UiSchema = {
    "ui:order": getUIOrderBasedOnRequiredFields(payloadSchemaWithMappedTitles || {}),
  };

  return (
    <div style={{ padding: "32px 32px 0" }}>
      {formFactory.createRequiredField({
        name: "email",
        label: "Email",
        disabled: true,
        defaultValue: (user.identifiers && user.identifiers[0].identifier) || "",
        style: { marginBottom: 10 },
      })}

      {!isEmpty(payloadSchemaWithMappedTitles) && (
        <SchemaForm
          formData={payload}
          setFormData={setPayload}
          schema={payloadSchemaWithMappedTitles}
          UISchema={payloadUISchema}
          submitAttempt={submitAttempt}
          updateProgress={progress}
          extraErrors={extraErrors}
          resetExtraErrors={resetExtraErrors}
          disabled={disabled}
        />
      )}
    </div>
  );
}

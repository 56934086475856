import React, { useState } from "react";

import { Token } from "@cloudentity/acp-permissions";

import Dialog from "../../../../common/components/Dialog";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { validators } from "../../../../common/utils/forms/validation";

interface Props {
  onSubmit: (data: any) => Promise<void>;
  onClose: () => void;
  data?: Token;
}

function PermissionTokenCreateUpdateDialog({ onSubmit, onClose, data }: Props) {
  const [progress, setProgress] = useState(false);

  const id = data ? "permission-token-update" : "permission-token-create";
  const text = data ? "Update" : "Create";

  const formFactory = useFormFactory({ id, progress, data: data ?? undefined });

  return (
    <Dialog id={`${id}-dialog`} title={`${text} Token`} onClose={onClose}>
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createRequiredField({
          name: "name",
          label: "Name",
          autoFocus: true,
          rules: {
            validate: {
              onlyAlphanumeric: validators.onlyAlphanumericWithWhitespaces({ label: "Name" }),
            },
          },
        })}

        {formFactory.createField({
          name: "description",
          label: "Description",
        })}

        {formFactory.createFormFooter({
          onCancel: onClose,
          onSubmit: data => {
            setProgress(true);
            onSubmit(data).finally(() => setProgress(false));
          },
          submitText: text,
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

export default PermissionTokenCreateUpdateDialog;

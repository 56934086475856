import { identity } from "ramda";

const errorHandlers = {};

export const registerErrorHandler = (key, handler) => (errorHandlers[key] = handler);

export const reportError = dispatch => error => {
  if (error.status && errorHandlers[error.status]) {
    dispatch(errorHandlers[error.status]());
  } else {
    throw error;
  }
};

export const action = type => () => ({ type });

export const actionWithData = type => data => ({ type, data });

export const actionWithKeyData = type => (key, data) => ({ type, data, key });

export const dispatchActionWithData = type => data => dispatch => dispatch({ type, data });

export const dispatchAndResolve =
  (dispatch, action, transform = identity) =>
  (...args) => {
    const newArgs = args.map(transform);
    dispatch(action(...newArgs));
    return Promise.resolve(...newArgs);
  };

export const dispatchAndReject =
  (dispatch, action) =>
  (...args) => {
    dispatch(action(...args));
    return Promise.reject(...args);
  };

export const dispatchActionsAndResolve =
  (dispatch, ...actions) =>
  (...args) => {
    actions.forEach(a => dispatch(a(...args)));
    return Promise.resolve(...args);
  };

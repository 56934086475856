export enum EApplicationTabs {
  OVERVIEW = "overview",
  OAUTH = "oauth",
  SCOPES = "scopes",
  RARS = "rars",
  POLICIES = "policies",
  PRIVACY = "privacy",
  ENDPOINTS = "endpoints",
  METADATA = "metadata",
  SAML = "saml",
}

export enum AnalyticsTabs {
  OAUTH2 = "oauth2",
  METRICS = "metrics",
  ENFORCEMENT = "enforcement",
  RISK = "risk",
}

export enum GatewayTabs {
  QUICKSTART = "quickstart",
  SETTINGS = "settings",
  APIS = "apis",
  THIRD_PARTY_TOKENS = "3rd-party-tokens",
}

export enum IdentityTabs {
  CONFIGURATION = "configuration",
  ATTRIBUTES = "attributes",
  USERS = "users",
  MAPPINGS = "mappings",
  EXTENSIONS = "extensions",
  PROVISIONING = "provisioning",
}

export enum ServiceTabs {
  OVERVIEW = "overview",
  SCOPES = "scopes",
  RAR = "rar",
  APIS = "apis",
}

export enum TenantManagementTabs {
  OVERVIEW = "overview",
  FEATURES = "features",
  RATE_LIMITS = "rate-limits",
  SECURITY = "security",
}

"use strict";
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeClasses = void 0;
const objectKeys_1 = require("./tools/objectKeys");
function mergeClasses(classesFromUseStyles, classesOverrides, cx) {
    //NOTE: We use this test to be resilient in case classesOverrides is not of the expected type.
    if (!(classesOverrides instanceof Object)) {
        return classesFromUseStyles;
    }
    const out = {};
    (0, objectKeys_1.objectKeys)(classesFromUseStyles).forEach(ruleName => (out[ruleName] = cx(classesFromUseStyles[ruleName], classesOverrides[ruleName])));
    (0, objectKeys_1.objectKeys)(classesOverrides).forEach(ruleName => {
        if (ruleName in classesFromUseStyles) {
            return;
        }
        const className = classesOverrides[ruleName];
        //...Same here, that why we don't do className === undefined
        if (typeof className !== "string") {
            return;
        }
        out[ruleName] = className;
    });
    return out;
}
exports.mergeClasses = mergeClasses;

import React, { useState } from "react";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { PoolResponse } from "@cloudentity/acp-identity";

import SelectablePaper from "../../../../common/components/SelectablePaper";
import { FormFactory } from "../../../../common/utils/forms/formFactory";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import CardWithIconAndTitle from "../../common/CardWithIconAndTitle";
import IdentityPoolsCreate from "../../workspaceDirectory/identityPools/identityPoolCreate/IdentityPoolsCreate";
import { providers } from "../identities.utils";

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12,
  },
  label: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.secondary.dark,
  },
  button: {
    fontSize: 12,
    fontWeight: 600,
  },
}));

interface Props {
  formFactory: FormFactory;
  pools: PoolResponse[];
  poolIDsWithConnectedIDPs: (string | undefined)[];
  selected: PoolResponse | null;
  setSelected: (selectedPool: PoolResponse | null) => void;
  onSubmit: (data: any) => void;
  onCancel: (() => void) | undefined;
  customSubmit: boolean;
  workspaceIdForNewPool?: string;
}

export default function IdentitiesConfigurationIdentityPoolSelectExisting({
  formFactory,
  pools,
  poolIDsWithConnectedIDPs,
  selected,
  setSelected,
  onSubmit,
  onCancel,
  customSubmit,
  workspaceIdForNewPool,
}: Props) {
  const { classes } = useStyles();

  const [createPoolDialog, setCreatePoolDialog] = useState(false);

  const providerMapData = providers.find(p => p.method === "identity_pool");
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.label}>Select identity pool</Typography>
        {checkTenantPermissionsQuery.data?.create_identity_pool && (
          <Button onClick={() => setCreatePoolDialog(true)} className={classes.button}>
            + CREATE POOL
          </Button>
        )}
      </div>

      {pools.length === 0 && <Typography>No pools</Typography>}

      <Grid container spacing={2} style={{ marginBottom: 32 }}>
        {pools.map(pool => {
          const isConnected = poolIDsWithConnectedIDPs.includes(pool.id);
          return (
            <Grid item xs={4} key={pool.id}>
              <SelectablePaper
                selected={selected?.id === pool.id}
                onSelect={() => setSelected(pool)}
                selectable={!isConnected}
              >
                <CardWithIconAndTitle
                  img={providerMapData?.icon}
                  title={pool.name}
                  id={`identity-pool-${pool.id}`}
                  noBorder
                  titleStyle={{ fontWeight: 600 }}
                  subtitle={isConnected ? "Already connected" : ""}
                  subtitleStyle={{
                    fontSize: 8,
                    maxWidth: "calc(100% - 28px)",
                    lineHeight: "10px",
                  }}
                  style={isConnected ? { backgroundColor: "#F2F3F4" } : {}}
                />
              </SelectablePaper>
            </Grid>
          );
        })}
      </Grid>
      {!customSubmit && (
        <>
          <Divider style={{ marginBottom: 48 }} />

          {formFactory.createFormFooter({
            submitText: "Next",
            onSubmit,
            onCancel,
            disabled: !selected,
          })}
        </>
      )}
      {createPoolDialog && (
        <IdentityPoolsCreate
          workspaceIdForNewPool={workspaceIdForNewPool}
          onClose={() => {
            setCreatePoolDialog(false);
          }}
          onCreated={pool => {
            setSelected(pool);
            setCreatePoolDialog(false);
          }}
        />
      )}
    </>
  );
}

import React, { useMemo, useState } from "react";

import Dialog from "../../../common/components/Dialog";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import { validators } from "../../../common/utils/forms/validation";

interface Props {
  logoURI: string | undefined;
  onCancel: () => void;
  onEdit: (logoURI: string) => void;
}

export default function IdpEditLogoUrlDialog({ logoURI, onCancel, onEdit }: Props) {
  const [progress, setProgress] = useState(false);
  const data = useMemo(() => ({ logoURI }), [logoURI]);

  const formFactory = useFormFactory({ id: "redirects-urls-dialog", data, progress });

  return (
    <Dialog onClose={onCancel} id="logo-url-dialog" title="Edit Logo URI">
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createRequiredField({
          name: `logoURI`,
          label: "Logo URI",
          rules: {
            validate: {
              validURL: validators.validURL({ label: "Logo URI" }),
            },
          },
          helperText:
            "The URL of the logo to display for the IDP. If none is set, the default badge for this type of IDP will be shown. Recommended size is 150x150 pixels.",
        })}

        {formFactory.createFormFooter({
          onCancel,
          onSubmit: data => {
            setProgress(true);
            new Promise<void>(resolutionFunc => {
              onEdit(data.logoURI);
              resolutionFunc();
            })
              .then(onCancel)
              .finally(() => setProgress(false));
          },
          submitText: "Update",
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

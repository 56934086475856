import React, { CSSProperties } from "react";
import { Controller } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "tss-react/mui";

import Switch from "../../../common/components/Switch";
import { useFormContext } from "../forms2/Form";

const useStyles = makeStyles()(() => ({
  label: {
    marginLeft: 8,
  },
}));

export interface SwitchBlockProps {
  name: string;
  label?: string;
  style?: CSSProperties;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SwitchBlock({ name, label, style, disabled, onChange }: SwitchBlockProps) {
  const { classes } = useStyles();
  const { form, disabled: formDisabled, progress, id } = useFormContext();

  return (
    <FormControl fullWidth style={style}>
      <Controller
        render={({ field }) => (
          <FormControlLabel
            label={label}
            id={`${id}-${name}-switch-label`}
            classes={{ label: classes.label }}
            style={{ marginLeft: 0 }}
            control={
              <Switch
                id={`${id}-${name}-switch`}
                checked={!!field.value}
                onChange={e => {
                  onChange && onChange(e);
                  field.onChange(e.target.checked);
                }}
                progress={progress}
                disabled={formDisabled || disabled}
              />
            }
          />
        )}
        name={name}
        control={form.control}
      />
    </FormControl>
  );
}

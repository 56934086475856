import React, { useMemo, useState } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useQueryClient } from "@tanstack/react-query";
import { JSONSchema7 } from "json-schema";
import isEqual from "lodash/isEqual";
import isMatch from "lodash/isMatch";
import omit from "lodash/omit";

import { ServerResponseTypeEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import FormSection from "../../../common/components/FormSection";
import RouteLeavingGuardSimple from "../../../common/components/RouteLeavingGuardSimple";
import RouterLink from "../../../common/components/RouterLink";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import adminServersApi from "../../services/adminServersApi";
import {
  getAuthorizationServerQueryKey,
  getWorkspaceQueryKey,
  listWorkspacesQueryKey,
  useGetAuthorizationServer,
} from "../../services/adminServersQuery";
import WorkspaceDetailsInfoPanel from "../common/WorkspaceDetailsInfoPanel";
import { useWorkspace } from "../common/useWorkspace";
import SchemaForm from "../workspaceDirectory/identityPools/identityPool/users/user/SchemaForm";
import {
  getUIOrderBasedOnRequiredFields,
  mapFieldNameToTitle,
} from "../workspaceDirectory/identityPools/schemas/schemas.utils";
import Domains from "./Domains";

export default function SettingsGeneral() {
  const [workspace] = useWorkspace();
  const queryClient = useQueryClient();

  const [updateProgress, setUpdateProgress] = useState(false);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);
  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace);

  // form
  const [payload, setPayload] = useState({});

  const schemaWithMappedTitles = mapFieldNameToTitle(serverQuery.data?.metadata?.schema ?? {});
  const uiSchema: UiSchema = {
    "ui:order": getUIOrderBasedOnRequiredFields(schemaWithMappedTitles),
  };

  const validateMetadata = validator.validateFormData(
    payload,
    (schemaWithMappedTitles as JSONSchema7) || {}
  );
  // end

  const data = useMemo(() => {
    setPayload(serverQuery.data?.metadata?.payload || {});
    return serverQuery.data || { name: "", domains: [] };
  }, [serverQuery.data]);

  const formFactory = useFormFactory({
    id: "workspace-details",
    data,
    progress: updateProgress,
  });

  const handleUpdate = data => {
    if (validateMetadata.errors.length > 0) {
      return;
    }

    const d = {
      ...serverQuery.data,
      ...data,
      organization: {
        ...serverQuery.data?.organization,
        ...(serverQuery.data?.type === ServerResponseTypeEnum.Organization
          ? {
              domains: (data.organization.domains || []).map(d =>
                typeof d === "string" ? d : d.value
              ),
            }
          : {}),
      },
      metadata: { ...serverQuery.data?.metadata, payload },
    };
    setUpdateProgress(true);

    if (checkWorkspacePermissionsQuery.data?.update_workspace) {
      return adminServersApi
        .updateAuthorizationServer({ wid: workspace, server: d })
        .then(() => {
          notifySuccess("Workspace settings saved successfully");
        })
        .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
        .then(() => queryClient.invalidateQueries({ queryKey: getWorkspaceQueryKey(workspace) }))
        .then(() =>
          queryClient.invalidateQueries({
            queryKey: getAuthorizationServerQueryKey(getTenantId(), workspace),
          })
        )
        .catch(notifyErrorOrDefaultTo("Error occurred when trying to update workspace settings"))
        .finally(() => setUpdateProgress(false));
    } else if (checkWorkspacePermissionsQuery.data?.update_workspace_metadata) {
      return adminServersApi
        .updateWorkspaceMetadata({ wid: workspace, workspaceMetadata: payload })
        .then(() =>
          adminServersApi.updateWorkspaceMetadata({
            wid: workspace,
            workspaceMetadata: { payload },
          })
        )
        .then(() => {
          notifySuccess("Workspace settings saved successfully");
        })
        .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
        .then(() =>
          queryClient.invalidateQueries({
            queryKey: getAuthorizationServerQueryKey(getTenantId(), workspace),
          })
        )
        .catch(notifyErrorOrDefaultTo("Error occurred when trying to update workspace settings"))
        .finally(() => setUpdateProgress(false));
    }
  };

  const payloadHasChanges = !isEqual(serverQuery.data?.metadata?.payload || {}, payload);

  const domains = formFactory.watch("organization.domains");
  const allValues = formFactory.watch();
  const formFactoryHasChanges = !isMatch(data, omit(allValues, ["organization.domains"]));
  const domainsHasChanges =
    (domains || []).length !== (serverQuery.data?.organization?.domains || []).length ||
    !(domains || []).every(
      (_, index) =>
        (domains || []).map(d => (typeof d === "string" ? d : d.value)).at(index) ===
        (serverQuery.data?.organization?.domains || []).at(index)
    );

  const formHasChanges = payloadHasChanges || formFactoryHasChanges || domainsHasChanges;

  return (
    <FormContext.Provider value={formFactory.context}>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <WorkspaceDetailsInfoPanel
            workspace={serverQuery.data}
            isOrganization={serverQuery.data?.type === ServerResponseTypeEnum.Organization}
          />

          <Paper style={{ padding: 32 }}>
            <FormSection
              title={
                serverQuery.data?.type === ServerResponseTypeEnum.Organization
                  ? "Organization Details"
                  : "Workspace Details"
              }
              id="workspace-details"
            />
            {formFactory.createRequiredField({
              name: "name",
              label: "Name",
              disabled: !checkWorkspacePermissionsQuery.data?.update_workspace,
              tooltip: !checkWorkspacePermissionsQuery.data?.update_workspace
                ? "No permission to edit this field"
                : undefined,
            })}

            {formFactory.createField({
              name: "description",
              label: "Description",
              multiline: true,
              maxRows: 3,
              minRows: 3,
              disabled: !checkWorkspacePermissionsQuery.data?.update_workspace,
              tooltip: !checkWorkspacePermissionsQuery.data?.update_workspace
                ? "No permission to edit this field"
                : undefined,
            })}

            {serverQuery.data?.type === ServerResponseTypeEnum.Organization && (
              <Domains
                formFactory={formFactory}
                name="organization.domains"
                disabled={!checkWorkspacePermissionsQuery.data?.update_workspace}
              />
            )}

            <SchemaForm
              formData={payload}
              setFormData={setPayload}
              schema={schemaWithMappedTitles}
              UISchema={uiSchema}
              submitAttempt={true}
              extraErrors={{}}
              resetExtraErrors={() => {}}
              disabled={!checkWorkspacePermissionsQuery.data?.update_workspace_metadata}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 32,
              }}
            >
              <RouterLink to={`/${workspace}/settings/custom-attributes`}>
                + Define more attributes
              </RouterLink>
              {(checkWorkspacePermissionsQuery.data?.update_workspace_metadata ||
                checkWorkspacePermissionsQuery.data?.update_workspace) &&
                formFactory.createFormFooter({
                  disabled: validateMetadata.errors.length > 0 || !formHasChanges,
                  onSubmit: handleUpdate,
                })}
            </div>
          </Paper>
        </Grid>
      </Grid>
      <RouteLeavingGuardSimple when={formHasChanges} />
    </FormContext.Provider>
  );
}

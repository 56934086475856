import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import Checkbox from "../../../common/components/Checkbox";
import useEffectNotOnMount from "../../../common/utils/hooks/useEffectNotOnMount";
import { RowMapping, StaticMappingTypes } from "./identitiesMappings.utils";

const useStyles = makeStyles()(() => ({
  empty: {
    color: "gray",
  },
}));

function getField(
  value: StaticMappingTypes | null,
  type: string | undefined,
  onChange: (value: StaticMappingTypes) => void
) {
  switch (type) {
    case "string": {
      return (
        <TextField
          fullWidth
          onChange={e => onChange(e.target.value)}
          label="Value"
          value={value ?? ""}
        />
      );
    }
    case "number": {
      return (
        <TextField
          fullWidth
          type="number"
          onChange={e => onChange(e.target.value)}
          label="Value"
          value={value ?? ""}
        />
      );
    }
    case "bool": {
      return <Checkbox onChange={(_, checked) => onChange(checked)} checked={Boolean(value)} />;
    }
    case "string_array": {
      return (
        <div style={{ marginTop: 16 }}>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            renderInput={params => <TextField {...params} label="Values" />}
            renderTags={(values, getTagProps) =>
              values.map((value, index: number) => (
                <Chip {...getTagProps({ index })} label={value} size="small" />
              ))
            }
            onChange={(_, value) => onChange(value)}
            value={Array.isArray(value) ? value : []}
          />
          <Typography variant="textSM">
            To add multiple values, press the 'enter' after adding each value
          </Typography>
        </div>
      );
    }
    default: {
      return <div style={{ color: "gray" }}>Not supported target attribute type</div>;
    }
  }
}

interface Props {
  row: RowMapping;
  onChange: (value: StaticMappingTypes | null) => void;
}

export default function IdentitiesDetailsMappingsTableRowStaticSource({ row, onChange }: Props) {
  const { classes } = useStyles();

  useEffectNotOnMount(() => {
    onChange(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row.editValue.target?.type]);

  if (!row.static) {
    return null;
  }

  return !row.editValue.target ? (
    <div className={classes.empty}>Select target to display input</div>
  ) : (
    <div>{getField(row.editValue?.source, row.editValue.target.type, onChange)}</div>
  );
}

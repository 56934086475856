import React, { ReactNode, useEffect, useState } from "react";

import Password from "@mui/icons-material/Password";
import PinOutlined from "@mui/icons-material/PinOutlined";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { MoreVertical, Smartphone } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { PoolAuthenticationMechanismsEnum } from "@cloudentity/acp-identity";

import FingerprintIcon from "../../../../../common/components/icons/FingerprintIcon";
import { FormFactory } from "../../../../../common/utils/forms/formFactory";
import { useFeature } from "../../../../../common/utils/hooks/useFeature";

const useStyles = makeStyles()(theme => ({
  container: {
    marginBottom: 32,
  },
  inputContainer: {
    marginBottom: 16,
    position: "relative",
  },
  badge: {
    position: "absolute",
    top: -16,
    right: 8,
    background: "#E9EBEC",
    borderRadius: "4px 4px 0px 0px",
    color: theme.palette.secondary.light,
    padding: "2px 4px",
  },
  addButtonContainer: {
    marginTop: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  select: {
    "& .MuiInputBase-input": {
      padding: "15px 14px",
    },
  },
  menuPaper: {
    minWidth: 165,
  },
  input: {
    backgroundColor: theme.custom.greys.fieldBackground,
  },
}));

export const poolAuthMechanismMapper: {
  [key in PoolAuthenticationMechanismsEnum]: { icon: any; label: string; id: string };
} = {
  [PoolAuthenticationMechanismsEnum.Otp]: {
    id: "otp",
    icon: <PinOutlined />,
    label: "Verification Code",
  },
  [PoolAuthenticationMechanismsEnum.Password]: {
    id: "password",
    icon: <Password style={{ fontSize: 23 }} />,
    label: "Password",
  },
  [PoolAuthenticationMechanismsEnum.Webauthn]: {
    id: "webauthn",
    icon: <FingerprintIcon />,
    label: "Passwordless",
  },
  [PoolAuthenticationMechanismsEnum.Totp]: {
    id: "totp",
    icon: <Smartphone />,
    label: "Authenticator App",
  },
  [PoolAuthenticationMechanismsEnum.Arculix]: {
    id: "arculix",
    icon: <Smartphone />,
    label: "Arculix",
  },
};

export interface IdentityPoolsAuthnSelectProps {
  id: string;
  label: ReactNode;
  authenticationMechanismsKeyName:
    | "authentication_mechanisms"
    | "second_factor_authentication_mechanisms";
  preferredAuthenticationMechanismsKeyName: string;
  options: PoolAuthenticationMechanismsEnum[];
  formFactory: FormFactory;
  progress: boolean;
  settings?: {
    [key in PoolAuthenticationMechanismsEnum]?: () => void;
  };
  disabled?: boolean;
  noManagePermission: boolean;
  onMoveToOtherFactor: (method: PoolAuthenticationMechanismsEnum) => void;
}

export default function IdentityPoolsAuthnSelect({
  id,
  label,
  authenticationMechanismsKeyName,
  preferredAuthenticationMechanismsKeyName,
  options,
  formFactory,
  progress,
  settings,
  disabled,
  noManagePermission,
  onMoveToOtherFactor,
}: IdentityPoolsAuthnSelectProps) {
  const { classes } = useStyles();
  const theme = useTheme();

  const [moreMenu, setMoreMenu] = useState<{
    method: PoolAuthenticationMechanismsEnum;
    anchorEl: HTMLElement;
  }>();

  const [addMethodAnchorEl, setAddMethodAnchorEl] = useState<HTMLElement | null>(null);

  const isIdentityPoolMFAEnabled = useFeature("identity_pool_mfa");

  const preferredAuthenticationMechanism =
    formFactory.watch(preferredAuthenticationMechanismsKeyName) || "";
  const authenticationMechanisms: PoolAuthenticationMechanismsEnum[] =
    formFactory.watch(authenticationMechanismsKeyName) || [];

  useEffect(() => {
    formFactory.register(preferredAuthenticationMechanismsKeyName);
    formFactory.register(authenticationMechanismsKeyName);
  }, [preferredAuthenticationMechanismsKeyName, authenticationMechanismsKeyName, formFactory]);

  return (
    <div className={classes.container}>
      {label}

      {authenticationMechanisms
        .slice()
        .sort((a: any) => (a === preferredAuthenticationMechanism ? -1 : 1))
        .map(method => (
          <div className={classes.inputContainer} key={poolAuthMechanismMapper[method].id}>
            <OutlinedInput
              id={`authn-method-label-input-${method}`}
              key={poolAuthMechanismMapper[method].id}
              startAdornment={
                <InputAdornment position="start">
                  {poolAuthMechanismMapper[method].icon}
                </InputAdornment>
              }
              endAdornment={
                !noManagePermission && (
                  <div style={{ width: 38 }}>
                    {(!!settings?.[method] || authenticationMechanisms.includes(method)) && (
                      <IconButton
                        aria-label={`toggle ${method} menu visibility`}
                        tabIndex={-1}
                        onClick={e => setMoreMenu({ method, anchorEl: e.currentTarget })}
                        id={`${method}-more-button`}
                        disabled={progress}
                      >
                        <MoreVertical size={16} />
                      </IconButton>
                    )}
                  </div>
                )
              }
              value={poolAuthMechanismMapper[method].label}
              disabled
              fullWidth
              className={classes.input}
              inputProps={{
                style: { WebkitTextFillColor: theme.palette.secondary.light },
                "data-ispreferred": preferredAuthenticationMechanism === (method as any),
              }}
            />
            {preferredAuthenticationMechanism === (method as any) && (
              <Typography variant="textSM" component="div" className={classes.badge}>
                PREFERRED
              </Typography>
            )}
          </div>
        ))}

      {!noManagePermission && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            id={`add-${id}-button`}
            variant="text"
            color="primary"
            style={{ fontSize: 12, textTransform: "none" }}
            disabled={options.length === 0 || disabled}
            onClick={e => setAddMethodAnchorEl(e.currentTarget)}
          >
            + Add method
          </Button>
          <Menu
            anchorEl={addMethodAnchorEl}
            keepMounted
            open={Boolean(addMethodAnchorEl)}
            onClose={() => setAddMethodAnchorEl(null)}
            classes={{ paper: classes.menuPaper }}
          >
            {options.map(o => (
              <MenuItem
                key={o}
                onClick={() => {
                  formFactory.setValue(authenticationMechanismsKeyName, [
                    ...authenticationMechanisms,
                    o,
                  ]);
                  setAddMethodAnchorEl(null);
                }}
              >
                {poolAuthMechanismMapper[o].label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}

      {moreMenu && !noManagePermission && (
        <Menu
          id={`${id}-${moreMenu.method}-menu`}
          anchorEl={moreMenu.anchorEl}
          keepMounted
          open={Boolean(moreMenu.anchorEl)}
          onClose={() => setMoreMenu(undefined)}
          classes={{ paper: classes.menuPaper }}
        >
          {isIdentityPoolMFAEnabled &&
            moreMenu.method !== PoolAuthenticationMechanismsEnum.Arculix && (
              <MenuItem
                onClick={() => {
                  onMoveToOtherFactor(moreMenu.method);
                  setMoreMenu(undefined);
                }}
              >
                {"authentication_mechanisms" === authenticationMechanismsKeyName
                  ? "Move to 2FA"
                  : "Move to 1FA"}
              </MenuItem>
            )}
          {preferredAuthenticationMechanism !== (moreMenu.method as any) &&
            authenticationMechanisms.includes(moreMenu.method) && (
              <MenuItem
                onClick={() => {
                  formFactory.setValue(
                    preferredAuthenticationMechanismsKeyName,
                    moreMenu.method as any
                  );
                  setMoreMenu(undefined);
                }}
              >
                Make Preferred
              </MenuItem>
            )}
          {preferredAuthenticationMechanism === (moreMenu.method as any) && (
            <MenuItem
              onClick={() => {
                formFactory.setValue(preferredAuthenticationMechanismsKeyName, "");
                setMoreMenu(undefined);
              }}
            >
              Clear Preferred
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              formFactory.setValue(
                authenticationMechanismsKeyName,
                authenticationMechanisms.filter(am => am !== moreMenu.method)
              );
              setMoreMenu(undefined);
            }}
          >
            Remove
          </MenuItem>
          {settings?.[moreMenu.method] !== undefined && (
            <MenuItem
              onClick={() => {
                settings[moreMenu.method]?.();
                setMoreMenu(undefined);
              }}
            >
              Settings
            </MenuItem>
          )}
        </Menu>
      )}
    </div>
  );
}

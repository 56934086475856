import React, { useEffect, useState } from "react";
import { ReactNode } from "react";

import { scrollDoc } from "../../common/components/guidedTours/utils";
import { getFromLocalStorage, setInLocalStorage } from "../../common/utils/localStorage.utils";

type Step = {
  id: string;
  targets: (HTMLElement | null)[] | (() => (HTMLElement | null)[]);
  params?: Record<string, any>;
};

type TourInputArgs = {
  id: string;
  runOnce: boolean;
  steps: Step[];
} & (
  | { startOnRegister: false }
  | { startOnRegister: true; startOnRegisterParams?: { flowId: string } }
);

type Tours = {
  [id: string]: TourInputArgs & {
    flowId: string | null;
    currentStep: Step | null;
    getSeenKey: (flowId: string | null) => string;
    isFinished: boolean;
  };
};

type Context = {
  register: (args: TourInputArgs) => void;
  getCurrentTour: () => string | null;
  getCurrentStep: () => Step | null;
  start: (tourId: string, flowId?: string) => void;
  goBack: (tourId: string, stepId: string) => void;
  complete: (tourId: string, stepId: string) => void;
  isFinished: (tourId: string) => boolean;
  isMarkedAsSeen: (tourId: string) => boolean | undefined;
  completeAndStopAll: (tourId: string) => void;
};

export const GlobalToursContext = React.createContext({} as Context);

export const GlobalToursProvider = ({ children }: { children: ReactNode }) => {
  const [tours, setTours] = useState<Tours>({});

  const register: Context["register"] = args => {
    setTours(tours => ({
      ...tours,
      [args.id]: {
        ...args,
        flowId: null,
        currentStep: null,
        getSeenKey: flowId => (flowId ? `tour_${args.id}_${flowId}_seen` : `tour_${args.id}_seen`),
        isFinished: false,
      },
    }));
  };

  const getCurrentTour: Context["getCurrentTour"] = () => {
    const tour = Object.values(tours).find(tour => tour.currentStep !== null);
    return tour?.id ?? null;
  };

  const getCurrentStep: Context["getCurrentStep"] = () => {
    const currentTour = getCurrentTour();
    if (!currentTour) return null;
    return tours[currentTour].currentStep;
  };

  const completeAndStopAll: Context["completeAndStopAll"] = (tourId: string) => {
    const tour = tours[tourId];
    if (!tour) return;
    const key = tour.getSeenKey(tour.flowId);
    setInLocalStorage(key, true);
    stopAll();
  };

  const stopAll = () => {
    setTours(tours =>
      Object.values(tours).reduce(
        (tours, tour) => ({
          ...tours,
          [tour.id]: {
            ...tour,
            currentStep: null,
          },
        }),
        tours
      )
    );
  };

  const start: Context["start"] = (tourId, flowId) => {
    const tour = tours[tourId];
    if (!tour || tour.currentStep !== null) return;

    const newFlowId = flowId ?? null;
    const key = tour.getSeenKey(newFlowId);
    const seen = getFromLocalStorage(key);
    if (seen && tour.runOnce) return;

    stopAll();
    setTours(tours => ({
      ...tours,
      [tourId]: {
        ...tour,
        flowId: newFlowId,
        currentStep: tour.steps[0] ?? null,
        isFinished: false,
      },
    }));
  };

  const goBack: Context["goBack"] = (tourId, stepId) => {
    const tour = tours[tourId];
    if (!tour) return;

    const completedStepIndex = tour.steps.findIndex(step => step.id === stepId);
    if (completedStepIndex === -1) return;

    const prevStep = tour.steps[completedStepIndex - 1] ?? null;
    if (prevStep) {
      setTours(tours => ({
        ...tours,
        [tourId]: {
          ...tour,
          currentStep: prevStep,
        },
      }));
    }
  };

  const complete: Context["complete"] = (tourId, stepId) => {
    const tour = tours[tourId];
    if (!tour) return;

    const completedStepIndex = tour.steps.findIndex(step => step.id === stepId);
    if (completedStepIndex === -1) return;

    const nextStep = tour.steps[completedStepIndex + 1] ?? null;
    if (nextStep === null) {
      const key = tour.getSeenKey(tour.flowId);
      setInLocalStorage(key, true);
    }

    setTours(tours => ({
      ...tours,
      [tourId]: {
        ...tour,
        currentStep: nextStep,
        isFinished: nextStep === null,
      },
    }));
  };

  const isFinished: Context["isFinished"] = tourId => tours[tourId]?.isFinished ?? false;
  const isMarkedAsSeen: Context["isMarkedAsSeen"] = tourId => {
    const tour = tours[tourId];
    if (!tour) return undefined;

    const key = tour.getSeenKey(null);
    const seen = !!getFromLocalStorage(key);
    return seen;
  };

  const currentStep = getCurrentStep();

  useEffect(() => {
    const scrollEl = scrollDoc();
    const orgOverflow = scrollEl.style.overflow;
    if (currentStep) {
      scrollEl.style.overflow = "hidden";
      return () => {
        scrollEl.style.overflow = orgOverflow;
      };
    }
  }, [currentStep]);

  useEffect(() => {
    const tourStartedOnRegister = Object.values(tours).find(tour => tour.startOnRegister);

    if (tourStartedOnRegister && tourStartedOnRegister.startOnRegister) {
      setTours(tours => ({
        ...tours,
        [tourStartedOnRegister.id]: {
          ...tourStartedOnRegister,
          startOnRegister: false,
        },
      }));

      start(tourStartedOnRegister.id, tourStartedOnRegister.startOnRegisterParams?.flowId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tours]);

  return (
    <GlobalToursContext.Provider
      value={{
        register,
        getCurrentTour,
        getCurrentStep,
        start,
        goBack,
        complete,
        isFinished,
        isMarkedAsSeen,
        completeAndStopAll,
      }}
    >
      {children}
    </GlobalToursContext.Provider>
  );
};

export const custom = {
  shadows: {
    surfaceResting: "0px 1px 1px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(0, 0, 0, 0.31)",
    surfaceHover: "0px 1px 16px -4px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.31)",
    menu: "0px 4px 24px rgba(0, 0, 0, 0.1), 0px 3px 25px rgba(0, 0, 0, 0.1)",
    formInner: "0px 1px 1px rgba(0, 0, 0, 0.04), 0px 3px 2px rgba(0, 0, 0, 0.04)",
  },
  greys: {
    textDisabled: "#A0A3B5",
    backgroundDisabled: "#F5F5F5",
    background: "#F4F4F4",
    border: "#DCDCDC",
    default: "#C2C3C6",
    fieldBackground: "#FCFCFF",
  },
  headerBackground: "#06223B",
};

import React, { useMemo, useState } from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { System } from "@cloudentity/acp-permissions";

import ConfirmationDialog from "../../../../common/components/ConfirmationDialog";
import RouteLeavingGuard from "../../../../common/components/RouteLeavingGuard";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";

interface Props {
  system: System;
  systemNames: string[];
  onUpdate: (system: System) => void;
  onRemove: () => void;
}

function PermissionConfiguration({ system, systemNames, onUpdate, onRemove }: Props) {
  const data = useMemo(() => system, [system]);
  const [remove, setRemove] = useState(false);

  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const formFactory = useFormFactory({
    id: "permission-configuration",
    data,
    noManagePermission: !checkTenantPermissionsQuery.data?.manage_permission_systems,
  });

  return (
    <FormContext.Provider value={formFactory.context}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Paper style={{ padding: 32 }}>
            {formFactory.createRequiredField({
              name: "name",
              label: "Name",
              rules: {
                validate: {
                  notUniq: v =>
                    systemNames
                      .filter(s => s !== system.name)
                      .map(s => s.toLowerCase())
                      .indexOf(v.trim().toLowerCase()) === -1 ||
                    "Permission system with a given name already exists",
                },
              },
            })}

            {formFactory.createField({
              name: "description",
              label: "Description",
            })}

            {formFactory.createReadOnlyField({
              name: "prefix",
              label: "Prefix",
              helperText: `Put the "${data.prefix}" in front of all definitions and references in your schema`,
              withCopy: true,
            })}

            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              {checkTenantPermissionsQuery.data?.manage_permission_systems && (
                <Button
                  variant="text"
                  id="permission-system-delete-button"
                  color="error"
                  onClick={() => setRemove(true)}
                >
                  Delete Permission System
                </Button>
              )}
              {formFactory.createFormFooter({
                onSubmit: data => onUpdate({ ...data, name: data.name.trim() }),
              })}
            </div>
          </Paper>
        </Grid>
        <RouteLeavingGuard />
      </Grid>

      {remove && (
        <ConfirmationDialog
          title="Delete Permission System"
          content={
            <>
              You're about to delete the <b>{system.name}</b> permission system. This cannot be
              undone. Delete anyway?
            </>
          }
          confirmText="Delete"
          onCancel={() => setRemove(false)}
          onConfirm={onRemove}
        />
      )}
    </FormContext.Provider>
  );
}

export default PermissionConfiguration;

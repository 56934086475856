"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = exports.tss = exports.withStyles = exports.makeStyles = void 0;
const styles_1 = require("@mui/material/styles");
const index_1 = require("../index");
const tss_1 = require("../tss");
const themeStyleOverridesPlugin_1 = require("./themeStyleOverridesPlugin");
/** @see <https://docs.tss-react.dev/setup> */
_a = (0, index_1.createMakeAndWithStyles)({
    useTheme: styles_1.useTheme
}), exports.makeStyles = _a.makeStyles, exports.withStyles = _a.withStyles;
exports.tss = (0, tss_1.createTss)({
    "useContext": function useContext() {
        const theme = (0, styles_1.useTheme)();
        return { theme };
    },
    "usePlugin": themeStyleOverridesPlugin_1.useMuiThemeStyleOverridesPlugin
}).tss;
exports.useStyles = exports.tss.create({});

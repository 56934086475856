import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { lensIndex, lensPath, over, remove, set } from "ramda";

import { StaticIDP, StaticSettings, StaticUser } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import adminIDPsApi from "../../services/adminIDPsApi";
import {
  getIDPQueryKey,
  listIDPsForIdentityPoolQueryKey,
  listIDPsQueryKey,
} from "../../services/adminIDPsQuery";
import StaticIDPEditUser from "./StaticIDPEditUser";
import StaticIDPUsersList from "./StaticIDPUsersList";
import { IdentityUserView, IdpUiModelStaticType, transformIdpToAPI } from "./identities.utils";

interface Props {
  idp: IdpUiModelStaticType;
  users: StaticUser[];
  view: IdentityUserView;
  onViewChange: (view: IdentityUserView) => void;
}

const newUser: StaticUser = {
  additional_attributes: {},
  authentication_context: {},
  email: "",
  email_verified: false,
  password: "",
  phone_number: "",
  phone_number_verified: false,
  username: "",
};

export default function IdentitiesDetailsUsers({ idp, users, view, onViewChange }: Props) {
  const [editUser, setEditUser] = useState<{ data: StaticUser; index: number } | null>(null);
  const [saveProgress, setSaveProgress] = useState(false);
  const queryClient = useQueryClient();

  const handleSave = (index: number) => (data: StaticUser) => {
    const u = over(lensIndex(index), v => ({ ...v, ...data }), users);
    const i: IdpUiModelStaticType = set(lensPath(["credentials", "users"]), u, idp);

    setSaveProgress(true);
    adminIDPsApi
      .updateIDP({
        aid: idp.authorization_server_id,
        type: idp.method,
        iid: idp.id,
        body: transformIdpToAPI(i),
      })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: listIDPsQueryKey(getTenantId(), idp.authorization_server_id),
        })
      )
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listIDPsForIdentityPoolQueryKey(getTenantId()) })
      )
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getIDPQueryKey(idp.id ?? "", idp.authorization_server_id),
        })
      )
      .then(() => notifySuccess("User updated successfully"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to update user"))
      .finally(() => {
        setSaveProgress(false);
        setEditUser(null);
      });
  };

  const handleAdd = (data: StaticUser) => {
    const body: StaticIDP = {
      ...transformIdpToAPI(idp),
      credentials: { users: [...(idp.credentials?.users ?? []), data] },
      settings: idp.settings as StaticSettings,
    };

    setSaveProgress(true);
    adminIDPsApi
      .updateIDP({
        aid: idp.authorization_server_id,
        type: idp.method,
        iid: idp.id,
        body,
      })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: listIDPsQueryKey(getTenantId(), idp.authorization_server_id),
        })
      )
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listIDPsForIdentityPoolQueryKey(getTenantId()) })
      )
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getIDPQueryKey(idp.id ?? "", idp.authorization_server_id),
        })
      )
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to add user"))
      .finally(() => {
        setSaveProgress(false);
        onViewChange("list");
      });
  };

  const handleDelete = (index: number) => {
    const body: IdpUiModelStaticType = over(
      lensPath(["credentials", "users"]),
      u => remove(index, 1, u),
      idp
    );

    adminIDPsApi
      .updateIDP({
        aid: idp.authorization_server_id,
        type: idp.method,
        iid: idp.id,
        body: transformIdpToAPI(body),
      })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: listIDPsQueryKey(getTenantId(), idp.authorization_server_id),
        })
      )
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listIDPsForIdentityPoolQueryKey(getTenantId()) })
      )
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getIDPQueryKey(idp.id ?? "", idp.authorization_server_id),
        })
      )
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to remove user"))
      .finally(() => onViewChange("list"));
  };

  return (
    <>
      <StaticIDPUsersList
        users={users}
        serverId={idp.authorization_server_id!}
        onRowClick={(data, index) => setEditUser({ data, index })}
        onDeleteClick={handleDelete}
        onViewChange={onViewChange}
      />
      {view === "create" && (
        <StaticIDPEditUser
          user={newUser}
          serverId={idp.authorization_server_id!}
          onCancel={() => onViewChange("list")}
          onSave={handleAdd}
          progress={saveProgress}
        />
      )}
      {editUser && (
        <StaticIDPEditUser
          user={editUser.data}
          serverId={idp.authorization_server_id!}
          onCancel={() => setEditUser(null)}
          onSave={handleSave(editUser.index)}
          progress={saveProgress}
          inEdit
        />
      )}
    </>
  );
}

import React, { useEffect, useMemo, useState } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useQueryClient } from "@tanstack/react-query";

import { Server } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import FormSection from "../../../../common/components/FormSection";
import RouteLeavingGuard from "../../../../common/components/RouteLeavingGuard";
import ApplicationReadOnlyInputWithCopy from "../../../../common/components/applications/ApplicationReadOnlyInputWithCopy";
import ReadOnlyInputButtonWithLabel from "../../../../common/components/applications/ReadOnlyInputButtonWithLabel";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { validators } from "../../../../common/utils/forms/validation";
import adminServersApi from "../../../services/adminServersApi";
import {
  getAuthorizationServerQueryKey,
  useGetAuthorizationServer,
} from "../../../services/adminServersQuery";
import PageContent from "../../common/PageContent";
import { useWorkspace } from "../../common/useWorkspace";
import SSOLogoutDomains from "./SSOLogoutDomains";

export default function SSOConfigurationView() {
  const queryClient = useQueryClient();

  const [workspace] = useWorkspace();
  const tenantId = getTenantId();

  const [updateProgress, setUpdateProgress] = useState(false);
  const [logoutDomainsDialog, setLogoutDomainsDialog] = useState(false);
  const serverQuery = useGetAuthorizationServer(tenantId, workspace);

  const data = useMemo(
    () => ({
      enabled: false,
      logout_redirect_domains: [],
      post_logout_url: "",
      ...(serverQuery.data?.sso?.enabled
        ? {
            max_age: "1h0m0s",
            max_idle: "0h0m0s",
            domain: "",
          }
        : {}),
      ...(serverQuery.data?.sso ?? {}),
    }),
    [serverQuery.data]
  );

  const formFactory = useFormFactory({
    id: "sso-settings",
    data,
    progress: updateProgress,
  });

  const isSSOEnabled = formFactory.watch("enabled");
  const ssoDomainValue = formFactory.watch("domain");
  const logoutRedirectDomains = formFactory.watch("logout_redirect_domains");

  const effectiveLogoutRedirectDomains = [
    ...(!!ssoDomainValue && ssoDomainValue !== "" && isSSOEnabled
      ? [`${ssoDomainValue} (SSO domain)`]
      : []),
    ...(logoutRedirectDomains || []),
  ];

  const handleSetLogoutDomains = redirectDomains => {
    formFactory.setValue("logout_redirect_domains", redirectDomains);
  };

  const handleUpdate = updatedData => {
    const body = {
      ...serverQuery.data,
      sso: {
        ...serverQuery.data?.sso,
        ...updatedData,
      },
      tenant_id: tenantId,
    };

    setUpdateProgress(true);
    adminServersApi
      .updateAuthorizationServer({ wid: serverQuery.data?.id!, server: body as Server })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getAuthorizationServerQueryKey(getTenantId(), workspace),
        })
      )
      .then(() => notifySuccess("OAuth settings saved successfully"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to update OAuth Settings"))
      .finally(() => setUpdateProgress(false));
  };

  useEffect(() => {
    formFactory.register("logout_redirect_domains");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <FormContext.Provider value={formFactory.context}>
      <PageContent>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Paper style={{ padding: 32 }}>
              {formFactory.createFormCardsField({
                name: "enabled",
                cards: [
                  {
                    title: "Persistent Session (SSO mode)",
                    subtitle: "Once activated, the user session is stored.",
                    value: true,
                  },
                  {
                    title: "Non-Persistent Session",
                    subtitle:
                      "Authentication context will be discarded after handling the request that triggered authentication.",
                    value: false,
                  },
                ],
              })}
              {isSSOEnabled && (
                <div>
                  {formFactory.createDurationField({
                    id: "sso_max_age",
                    name: "max_age",
                    label: "Session Max Age",
                    description: (
                      <span>
                        The maximum time a session can be used. If set to 0, a default value of{" "}
                        <code>1h</code> is used.
                      </span>
                    ),
                    validate: {
                      inRangeDuration: validators.inRangeDuration({
                        label: "Time To Live",
                        min: "0h0m00s",
                        max: "744h0m0s",
                      }),
                    },
                  })}
                  {formFactory.createDurationField({
                    id: "sso_max_idle",
                    name: "max_idle",
                    label: "Session Max Idle Time",
                    description: (
                      <span>
                        The maximum time a session can be idle. If set to 0, a default value of{" "}
                        <code>Max Age</code> is used.
                      </span>
                    ),
                    validate: {
                      inRangeDuration: validators.inRangeDuration({
                        label: "Time To Live",
                        min: "0h0m00s",
                        max: "744h0m0s",
                      }),
                    },
                  })}
                  {formFactory.createField({
                    name: "domain",
                    label: "SSO cookie domain",
                    helperText: "If not set, defaults to server domain.",
                    rules: {
                      validate: {
                        validDomain: v =>
                          v.length === 0 ||
                          validators.validDomain({ label: "Value", require_tld: false })(v),
                      },
                    },
                  })}
                </div>
              )}
              <FormSection id="logout-subtitle-label" title="Logout Settings" />
              <Paper style={{ background: "#F7FAFF", padding: 32, marginBottom: 32 }}>
                {effectiveLogoutRedirectDomains.length > 0 ? (
                  <ApplicationReadOnlyInputWithCopy
                    id="logout-redirect-domains"
                    label="Allowed Logout Redirect Domains"
                    value={effectiveLogoutRedirectDomains}
                    progress={updateProgress}
                    onEdit={() => setLogoutDomainsDialog(true)}
                    onRemove={() => handleSetLogoutDomains([])}
                  />
                ) : (
                  <ReadOnlyInputButtonWithLabel
                    id="logout-redirect-domains-setup-label"
                    label="Allowed Logout Redirect Domains"
                    content="Add supplementary domains for logout redirect"
                    disabled={updateProgress}
                    onClick={() => setLogoutDomainsDialog(true)}
                  />
                )}
                {logoutDomainsDialog && (
                  <SSOLogoutDomains
                    ssoDomain={ssoDomainValue}
                    redirectDomains={logoutRedirectDomains || []}
                    onUpdate={redirectDomains => {
                      handleSetLogoutDomains(redirectDomains);
                      setLogoutDomainsDialog(false);
                    }}
                    onCancel={() => setLogoutDomainsDialog(false)}
                    isSSOEnabled={isSSOEnabled}
                  />
                )}
              </Paper>
              {formFactory.createField({
                name: "post_logout_url",
                label: "Post-Logout Redirect URL",
                helperText: (
                  <span>
                    Default logout redirect target if <code>redirect_to</code> parameter is not
                    provided.
                  </span>
                ),
                rules: {
                  validate: { validURL: validators.validURL({ label: "Post-logout URL" }) },
                },
              })}
              {formFactory.createFormFooter({
                onSubmit: handleUpdate,
              })}
            </Paper>
          </Grid>
        </Grid>
      </PageContent>
      <RouteLeavingGuard />
    </FormContext.Provider>
  );
}

import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { useQueryClient } from "@tanstack/react-query";

import { getTenantId } from "../../../common/api/paths";
import RemoveConfirmationDialog from "../../../common/components/RemoveConfirmationDialog";
import RouteLeavingGuard from "../../../common/components/RouteLeavingGuard";
import RouterLink from "../../../common/components/RouterLink";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { BRAND_NAME } from "../../../common/theme/theme";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import adminCustomAppsApi from "../../services/adminCustomAppsApi";
import {
  getCustomAppQueryKey,
  listServerCustomAppsQueryKey,
  useGetCustomApp,
} from "../../services/adminCustomAppsQuery";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import PageContainer from "../common/PageContainer";
import PageContent from "../common/PageContent";
import PageHeader from "../common/PageHeader";
import { useWorkspace } from "../common/useWorkspace";
import { customAppTypesOptions } from "./custom-applications.common";

export default function CustomApplicationDetails() {
  const navigate = useNavigate();
  const { customAppID } = useParams<{ customAppID: string }>();
  const [workspace] = useWorkspace();
  const tenantId = getTenantId();

  const [progress, setProgress] = useState(false);
  const [removeConfirmDialog, setRemoveConfirmDialog] = useState(false);

  const queryClient = useQueryClient();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);
  const getCustomAppQuery = useGetCustomApp(tenantId, workspace, customAppID || "");

  if (getCustomAppQuery.isError && (getCustomAppQuery.error as any)?.response?.status === 404) {
    navigate(`/${workspace}/custom-applications`);
  }

  const data = useMemo(() => {
    return {
      ...getCustomAppQuery.data,
      client_secret: getCustomAppQuery?.data?.client?.client_secret,
      issuer_url: getCustomAppQuery?.data?.client?.server_issuer_url,
    };
  }, [getCustomAppQuery.data]);

  const formFactory = useFormFactory({
    id: "custom-application-details",
    data,
    progress: progress || getCustomAppQuery.isFetching,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_custom_apps,
  });

  const onSubmit = data => {
    setProgress(true);
    adminCustomAppsApi
      .updateCustomApp({
        wid: workspace,
        customAppID: customAppID!,
        customApp: { ...getCustomAppQuery.data, ...data },
      })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getCustomAppQueryKey(tenantId, workspace, customAppID!),
        })
      )
      .then(() => notifySuccess("Custom applications successfully updated"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to update custom application"))
      .finally(() => setProgress(false));
  };

  const onCancel = () => navigate(`/${workspace}/custom-applications`);

  const handleRemoveCustomApplication = () => {
    setProgress(true);
    adminCustomAppsApi
      .deleteCustomApp({ wid: workspace, customAppID: customAppID! })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: listServerCustomAppsQueryKey(tenantId, workspace),
        })
      )
      .then(() => {
        notifySuccess("Custom application successfully deleted");
        navigate(`/${workspace}/custom-applications`);
      })
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to delete custom application"))
      .finally(() => setProgress(false));
  };

  return (
    <FormContext.Provider value={formFactory.context}>
      <PageContainer>
        <PageHeader
          title={getCustomAppQuery.data?.name || "Custom Application Details"}
          breadcrumb={
            <>
              <RouterLink to={`/${workspace}/custom-applications`}>Custom Applications</RouterLink>{" "}
              &gt; {getCustomAppQuery.data?.name}
            </>
          }
        ></PageHeader>
        <PageContent progress={getCustomAppQuery.isFetching} fullWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={7}>
              <Paper style={{ padding: 32 }}>
                {formFactory.createSelect({
                  name: "type",
                  label: "Type",
                  options: customAppTypesOptions,
                  disabled: true,
                  optional: false,
                })}
                {formFactory.createRequiredField({ name: "name", label: "Name" })}
                {formFactory.createRequiredField({ name: "url", label: "Application URL" })}
                {formFactory.createField({
                  name: "client_id",
                  label: "Client ID",
                  disabled: true,
                  optional: false,
                  withCopy: true,
                })}
                {formFactory.createField({
                  name: "client_secret",
                  label: "Client Secret",
                  disabled: true,
                  optional: false,
                  withCopy: true,
                  toggleVisibility: true,
                  withProgress: !getCustomAppQuery.isSuccess,
                  defaultVisibility: false,
                })}
                {formFactory.createField({
                  name: "issuer_url",
                  label: "Issuer URL",
                  disabled: true,
                  optional: false,
                  withProgress: !getCustomAppQuery.isSuccess,
                  withCopy: true,
                })}

                <div
                  style={{
                    display: checkWorkspacePermissionsQuery.data?.manage_custom_apps
                      ? "flex"
                      : "block",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {checkWorkspacePermissionsQuery.data?.manage_custom_apps && (
                    <Button
                      variant="text"
                      id="service-delete-button"
                      color="error"
                      onClick={() => setRemoveConfirmDialog(true)}
                    >
                      Delete Custom Application
                    </Button>
                  )}
                  {formFactory.createFormFooter({
                    onCancel,
                    onSubmit,
                  })}
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Alert id="custom-applications-details-alert" severity="info">
                <AlertTitle>Connecting Post AuthN extension</AlertTitle>
                <p>
                  This Post AuthN extension application enhances the authentication and
                  authorization process by allowing users to complete additional business processes
                  or interactions during the authentication process.
                </p>

                <p>
                  When a user attempts to authenticate through {BRAND_NAME}, they will be redirected
                  to a customer hosted application where they may need to provide additional
                  information or complete certain tasks. Once these steps have been successfully
                  completed, the user will be redirected back to the OAuth or SAML flow to complete
                  the process.
                </p>

                <p>
                  This helps ensure that the authentication and authorization process is thorough
                  and meets the specific needs of the business.
                </p>
                <Link
                  href="https://cloudentity.com/developers/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Integrate Post AuthN extension application with {BRAND_NAME}.
                </Link>
              </Alert>
            </Grid>
          </Grid>
        </PageContent>
      </PageContainer>

      {removeConfirmDialog && (
        <RemoveConfirmationDialog
          title="Delete Custom Application"
          type="custom application"
          name={getCustomAppQuery.data?.name ?? ""}
          onCancel={() => setRemoveConfirmDialog(false)}
          onConfirm={handleRemoveCustomApplication}
          progress={progress}
        />
      )}

      <RouteLeavingGuard />
    </FormContext.Provider>
  );
}

import React from "react";
import { useNavigate } from "react-router";

import Paper from "@mui/material/Paper";
import { map, pathOr, prop } from "ramda";

import { OrganizationResponse, ServerResponse } from "@cloudentity/acp-admin";

import FormAccordion from "../../../../common/components/FormAccordion";
import FormInfoBoxWithButton from "../../../../common/components/FormInfoBoxWithButton";
import RouteLeavingGuard from "../../../../common/components/RouteLeavingGuard";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import CardWithIconAndTitle from "../../common/CardWithIconAndTitle";
import { CommonIdpConfig } from "../CommonIdpConfig";
import CommonIdpConfigUpper from "../CommonIdpConfigUpper";
import IdentitiesDetailsFooter from "../IdentitiesDetailsFooter";
import SSOIDPSettings from "../SSOIDPSettings";
import { amrOptions, amrToResponse, getAMRLabel } from "../amrOptions";
import { IdpUiModelType, providers } from "../identities.utils";

const id = "identities-configuration-identity-pool";

interface Props {
  provider: IdpUiModelType;
  server: ServerResponse | undefined;
  updateProgress?: boolean;
  inEdit?: boolean;
  organizations: OrganizationResponse[];
  onInit?: (fn: () => void) => void;
  onCancel?: () => void;
  onSubmit: (data: IdpUiModelType) => void;
  onDelete?: (idp: IdpUiModelType) => void;
}

export default function IdentitiesConfigurationOrganizationDetail({
  provider,
  server,
  updateProgress,
  onCancel,
  onSubmit,
  inEdit,
  organizations,
  onDelete,
}: Props) {
  const navigate = useNavigate();

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(server?.id);

  const formFactory = useFormFactory({
    id,
    data: provider,
    progress: updateProgress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps,
  });

  const submitFn = (provider, data) => ({
    ...provider,
    ...amrToResponse(data),
    ...data,
  });

  const providerMapData = providers.find(p => p.method === "organization");

  const organization = organizations.find(p => p.id === provider.workspace_id);

  const serverSSOEnabled = server?.sso?.enabled ?? false;

  return (
    <FormContext.Provider value={formFactory.context}>
      <Paper style={{ padding: 32 }}>
        {providerMapData && (
          <CardWithIconAndTitle
            img={providerMapData.icon}
            title={providerMapData.name}
            id={`idp-${providerMapData.name.replace(/ /g, "-")}`}
            style={{ marginBottom: 32, width: "50%" }}
            titleStyle={{ fontWeight: 600 }}
          />
        )}

        <CommonIdpConfigUpper
          formFactory={formFactory}
          provider={provider}
          onUpdate={onSubmit}
          inEdit={inEdit}
        />

        {organization && (
          <FormInfoBoxWithButton
            id="organization"
            label="Organization"
            value={organization.name || provider.id || ""}
            buttonLabel="Manage"
            onClick={() => {
              navigate(`/${provider.workspace_id}/settings/general`);
            }}
          />
        )}

        <CommonIdpConfig formFactory={formFactory} data={provider} />

        <FormAccordion title="Advanced settings" id={id}>
          {formFactory.createAutocompleteField({
            name: "static_amr",
            label: "Authentication Method Reference",
            helperText: "If set overwrites AMR obtained from this authentication method",
            defaultValue: pathOr([], ["static_amr"], provider),
            options: map(prop("value"), amrOptions),
            getOptionLabel: getAMRLabel,
            multiple: true,
          })}
          {serverSSOEnabled && <SSOIDPSettings formFactory={formFactory} />}

          {formFactory.createCheckBox({
            name: "config.reload_claims_at_refresh_token",
            label: "Reload Claims at Refresh Token",
            helperText: "If checked, the claims are reloaded while issuing an access token",
          })}
        </FormAccordion>

        <IdentitiesDetailsFooter
          idp={provider}
          formFactory={formFactory}
          onSubmit={data => onSubmit && onSubmit(submitFn(provider, data))}
          onCancel={onCancel}
          onDelete={onDelete}
        />
      </Paper>
      <RouteLeavingGuard />
    </FormContext.Provider>
  );
}

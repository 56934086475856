import React, { useState } from "react";
import { useNavigate } from "react-router";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ExternalLink, Link, Search } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { getTenantId } from "../../../common/api/paths";
import { useListServerCustomApps } from "../../services/adminCustomAppsQuery";
import { IdpUiModelType } from "./identities.utils";

const useStyles = makeStyles()(theme => ({
  listItem: {
    display: "flex",
    alignItems: "center",
    padding: 16,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.custom.greys.background,
    },
  },
  listBox: {
    padding: 0,
    margin: 0,
    "&>li": {
      padding: 13,
      borderBottom: "1px solid #EFEEEF",
      "&:last-of-type": {
        borderBottom: "none",
      },
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

interface Props {
  idp: IdpUiModelType;
  updateProgress: boolean;
  onUpdate: (provider: IdpUiModelType) => void;
  editable: boolean;
  hideButtonLink?: boolean;
}

export default function ConnectCustomApplicationExtension({
  idp,
  updateProgress,
  onUpdate,
  editable,
  hideButtonLink,
}: Props) {
  const { classes } = useStyles();
  const tenantId = getTenantId();

  const [isExtensionInputFocused, setIsExtensionInputFocused] = useState(false);
  const navigate = useNavigate();

  const customAppsQuery = useListServerCustomApps(tenantId, idp.authorization_server_id!);

  const handleChangeExtension = (e, value) => {
    setIsExtensionInputFocused(false);
    onUpdate({
      ...idp,
      post_authn_app_id: value ? value.id : null,
    });
  };

  const progress = customAppsQuery.isFetching || updateProgress;

  return (
    <Paper id="connect-custom-application-extension-container">
      <div
        style={{
          padding: "32px 24px",
          position: "relative",
        }}
      >
        <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
          <div>
            <Typography
              variant="h5"
              id="connect-custom-application-extension-input-label"
              style={{ marginBottom: 12 }}
            >
              Post Authentication application
            </Typography>
            <Autocomplete
              id="connect-custom-application-extension-input"
              options={customAppsQuery.data?.custom_apps ?? []}
              value={
                (customAppsQuery.data?.custom_apps || []).find(
                  ca => ca.id === idp.post_authn_app_id
                ) || null
              }
              renderOption={(props, option) => (
                <li {...props} className={classes.listItem}>
                  <Link size={12} style={{ marginRight: 12 }} />
                  <Typography variant="caption">{option.name}</Typography>
                </li>
              )}
              getOptionLabel={option => option.name || ""}
              style={{ width: 300, marginBottom: 4 }}
              disabled={progress || !editable}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  placeholder={
                    isExtensionInputFocused
                      ? progress
                        ? "Loading..."
                        : "Search"
                      : "Select custom application"
                  }
                  onFocus={() => {
                    setIsExtensionInputFocused(true);
                  }}
                  onBlur={() => {
                    setIsExtensionInputFocused(false);
                  }}
                  InputProps={{
                    ...(params?.InputProps || {}),
                    startAdornment: isExtensionInputFocused ? (
                      <InputAdornment position="start">
                        <Search size="16" className={classes.icon} strokeWidth="2.5" />
                      </InputAdornment>
                    ) : null,
                    endAdornment: (
                      <>
                        {progress ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              onChange={handleChangeExtension}
              ListboxProps={{
                className: classes.listBox,
              }}
            />
            <Typography variant="caption">Select the extensions that you have created</Typography>
          </div>
          {!hideButtonLink && (
            <Button
              id="edit-button"
              color="primary"
              variant="outlined"
              role="link"
              style={{ width: 212 }}
              disabled={progress}
              onClick={() => {
                navigate(`/${idp.authorization_server_id}/custom-applications`);
              }}
            >
              <ExternalLink size="16" style={{ marginRight: 10 }} />
              Manage Custom Apps
            </Button>
          )}
        </div>
      </div>
    </Paper>
  );
}

import React, { useRef } from "react";

import Divider from "@mui/material/Divider";
import isEmpty from "lodash/isEmpty";

import FormSection from "../../../../common/components/FormSection";
import { BRAND_NAME } from "../../../../common/theme/theme";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import {
  useFeature,
  useFeatureIsOrganizationsEnabled,
} from "../../../../common/utils/hooks/useFeature";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import { embeddedIdps, ProviderMapperType, providers, ProvidersType } from "../identities.utils";
import IdentitiesCreateIdpCard from "./IdentitiesCreateIdpCard";

export interface IdentitiesCreateAddStepProps {
  onNext?: (selected: ProviderMapperType | undefined) => void;
  onCancel?: () => void;
  customSubmit?: boolean;
  selected: ProviderMapperType | undefined;
  setSelected: (selected: ProviderMapperType | undefined) => void;
}

export default function IdentitiesCreateAddStep({
  onNext,
  onCancel,
  selected,
  setSelected,
  customSubmit = false,
}: IdentitiesCreateAddStepProps) {
  const formFactory = useFormFactory({ id: "identities-create-add-step" });
  const cloudentityIDPEnabled = useFeature("cloudentity_idp");
  const isOrganizationsEnabled = useFeatureIsOrganizationsEnabled();
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const container = useRef<HTMLDivElement>(null);

  const cloudentityProviders = providers
    .filter(p => cloudentityIDPEnabled || p.method !== "cloudentity")
    .filter(p => isOrganizationsEnabled || p.method !== "organization")
    .filter(p => p.type === "cloudentity")
    .filter(p =>
      checkTenantPermissionsQuery.data?.create_identity_pool ? true : p.method !== "identity_pool"
    );

  const thirdPartyProviders = providers.filter(
    p =>
      p.type !== ProvidersType.custom &&
      p.type !== ProvidersType.cloudentity &&
      p.method !== "saml" &&
      !embeddedIdps.includes(p.method)
  );

  return (
    <FormContext.Provider value={formFactory.context}>
      {cloudentityProviders.length > 0 && (
        <>
          <FormSection
            title={`${BRAND_NAME} Providers`}
            id="cloudentity-providers-title"
            noBorder
          />
          <div style={{ marginBottom: 32, display: "flex", gap: 24 }}>
            {cloudentityProviders.map(provider => (
              <IdentitiesCreateIdpCard
                key={provider.method}
                provider={provider}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
          </div>
        </>
      )}

      <FormSection
        title={`Third Party Providers (${thirdPartyProviders.length})`}
        id="third-party-providers-title"
        noBorder
        style={{ paddingTop: 8 }}
      ></FormSection>
      <div ref={container} style={{ marginBottom: 32, display: "flex", gap: 24, flexWrap: "wrap" }}>
        {thirdPartyProviders.map(provider => (
          <IdentitiesCreateIdpCard
            key={provider.method}
            provider={provider}
            selected={selected}
            setSelected={setSelected}
            subtitle={provider.description}
          />
        ))}
      </div>

      <FormSection
        title="Other Providers"
        id="other-providers-title"
        noBorder
        style={{ paddingTop: 8 }}
      />
      <div style={{ paddingBottom: 32, display: "flex", gap: 24 }}>
        {providers
          .filter(p => p.type !== ProvidersType.cloudentity && p.type !== ProvidersType.enterprise)
          .map(provider => (
            <IdentitiesCreateIdpCard
              key={provider.method}
              provider={provider}
              selected={selected}
              setSelected={setSelected}
              subtitle={provider.description}
            />
          ))}
      </div>
      {!customSubmit && (
        <>
          <Divider style={{ marginBottom: 48 }} />

          {formFactory.createFormFooter({
            onCancel,
            disabled: isEmpty(selected),
            onSubmit: () => onNext && onNext(selected),
            submitText: "Next",
          })}
        </>
      )}
    </FormContext.Provider>
  );
}

import React, { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import AppAvatar from "../../common/components/AppAvatar";
import Progress from "../../common/components/Progress";
import { BRAND_NAME } from "../../common/theme/theme";
import userApi from "../services/userApi";

const useStyles = makeStyles()((theme, _, classes) => ({
  paperRoot: {
    padding: 32,
    borderRadius: 4,
  },
  panelsContainer: {
    margin: "0px -32px",
  },
  panelSummary: {
    height: 80,
    paddingLeft: 32,
    paddingRight: 32,
  },
  panelDetails: {
    paddingLeft: 88,
    paddingRight: 32,
  },
  revokeButton: {
    display: "none",
  },
  panelRootExpanded: {
    background: "rgb(250, 250, 250)",
    [`& .${classes.revokeButton}`]: {
      display: "block",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.9,
      },
    },
  },
}));

const fakeData = [
  {
    client: {
      name: "Example client",
      id: "client-id",
    },
    granted_scopes: [
      {
        scope_display_name: "First scope",
        scope_description: "First scope description",
        scope_id: "scope-id-1",
      },
      {
        scope_display_name: "Second scope",
        scope_description: "Seccond scope description",
        scope_id: "scope-id-2",
      },
    ],
  },
];

const Dashboard = ({ preview = false }) => {
  const { classes } = useStyles();

  const [progress, setProgress] = useState(false);
  const [data, setData] = useState([]);

  const parseClients = res => {
    const withScopes = res.data.clients.map(c => ({
      ...c,
      granted_scopes: c.granted_scopes.map(gs => {
        const scope = res.data.scopes.find(s => s.scope_name === gs.scope_name) || {
          scope_display_name: gs.granted_scope_name,
          scope_description: "",
          scope_id: gs.scope_id,
        };
        return {
          scope_display_name: gs.is_dynamic
            ? `${scope.scope_display_name}: ${gs.granted_scope_name}`
            : scope.scope_display_name,
          scope_description: scope.scope_description,
          scope_id: scope.scope_id,
        };
      }),
    }));
    setData(withScopes);
  };

  useEffect(() => {
    if (!preview) {
      setProgress(true);
      userApi
        .getClients()
        .then(parseClients)
        .finally(() => setProgress(false));
    } else {
      setData(fakeData);
    }
  }, [preview]);

  const handleRevoke = app => () => {
    setProgress(true);
    userApi
      .revokeClient({ cid: app.client.id })
      .then(() => userApi.getClients())
      .then(parseClients)
      .finally(() => setProgress(false));
  };

  return (
    <Container style={{ marginTop: 40, position: "relative" }} maxWidth="md">
      {progress && <Progress />}
      {!progress && (
        <>
          <Paper className={classes.paperRoot}>
            <Typography
              id="dashboard-header"
              variant="h5"
              style={{ marginBottom: 10, color: "rgba(0, 0, 0, 0.87)" }}
            >
              Third-party applications with account access
            </Typography>
            <Typography
              id="dashboard-description"
              style={{ marginBottom: 25, color: "rgba(0, 0, 0, 0.6)" }}
            >
              You gave these applications access to some of your {BRAND_NAME} Account data,
              including info that may be sensitive. Remove access for those you no longer trust or
              use.
            </Typography>
            <div className={classes.panelsContainer}>
              {data.map(d => (
                <Accordion
                  key={d.client?.id}
                  className="client-panel"
                  square
                  elevation={0}
                  classes={{ expanded: classes.panelRootExpanded }}
                  {...(preview ? { defaultExpanded: true } : {})}
                >
                  <AccordionSummary
                    classes={{ root: classes.panelSummary }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                      <AppAvatar style={{ marginRight: 16 }}>
                        {d.client.name[0].toUpperCase()}
                      </AppAvatar>
                      <Typography className="client-name">{d.client.name}</Typography>
                      <div style={{ flex: 1 }} />
                      <Button
                        className={classes.revokeButton + " client-revoke"}
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleRevoke(d)}
                      >
                        Revoke
                      </Button>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails classes={{ root: classes.panelDetails }}>
                    <Grid container>
                      <Grid item md={5} xs={12}>
                        <Typography
                          className="client-description"
                          style={{ color: "rgb(107, 124, 145)" }}
                        >
                          Has access to :
                        </Typography>
                      </Grid>
                      <Grid item md={7} xs={12}>
                        <ul>
                          {d.granted_scopes.map(scope => (
                            <li style={{ marginBottom: 24 }} key={scope.scope_id}>
                              <Typography
                                className="client-scope-name"
                                style={{ color: "rgba(0, 0, 0, 0.87)" }}
                              >
                                {scope.scope_display_name}
                              </Typography>
                              <Typography
                                className="client-scope-description"
                                variant="caption"
                                style={{
                                  margin: "7px 0",
                                  display: "block",
                                  color: "rgba(0, 0, 0, 0.6)",
                                }}
                              >
                                {scope.scope_description}
                              </Typography>
                              <Divider />
                            </li>
                          ))}
                        </ul>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
              {data.length === 0 && (
                <Alert
                  severity="warning"
                  icon={false}
                  style={{
                    margin: "0 32px",
                    background: "rgba(253, 194, 69, 0.05)",
                    border: "1px solid rgb(245, 166, 35)",
                    color: "rgb(245, 166, 35)",
                  }}
                  variant="outlined"
                >
                  You haven't granted consents to any applications yet
                </Alert>
              )}
            </div>
          </Paper>
          <Typography
            id="dashboard-footer"
            style={{ color: "rgba(0, 0, 0, 0.38)", marginTop: 16 }}
            variant="subtitle2"
          >
            {BRAND_NAME} may also have access to some of your third-party accounts in order to
            manage those connections.
          </Typography>
        </>
      )}
    </Container>
  );
};

export default Dashboard;

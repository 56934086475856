import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { OrganizationResponse, WorkspaceResponse } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import Dialog from "../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import adminServersApi from "../../services/adminServersApi";
import {
  getAuthorizationServerQueryKey,
  listWorkspacesQueryKey,
} from "../../services/adminServersQuery";
import { useListThemes } from "../../services/adminThemesQuery";

interface Props {
  server: WorkspaceResponse | OrganizationResponse;
  onClose: () => void;
}

export default function BindThemToServerDialog({ server, onClose }: Props) {
  const [progress, setProgress] = useState(false);

  const formFactory = useFormFactory({
    id: "webhook-api-key-info",
    data: server,
    progress,
  });

  const themesQuery = useListThemes(getTenantId());
  const queryClient = useQueryClient();

  const handleSubmit = data => {
    setProgress(true);
    adminServersApi
      .bindServerTheme({ wid: server.id!, themeID: data.theme_id })
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getAuthorizationServerQueryKey(getTenantId(), server.id),
        })
      )
      .then(() => {
        notifySuccess("Server successfully bound to theme");
        onClose();
      })
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to bind server to theme"))
      .finally(() => setProgress(false));
  };

  const getOptionLabel = option =>
    (themesQuery.data?.themes || []).find(theme => theme.id === option)?.name || option;

  return (
    <Dialog id="bind-theme-to-server-dialog" open title="Bind theme">
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createAutocompleteField({
          name: "theme_id",
          label: "Theme",
          options: (themesQuery.data?.themes || []).map(t => t.id),
          optional: false,
          getOptionLabel,
          rules: {
            required: "Theme is required",
          },
        })}
        {formFactory.createFormFooter({
          onCancel: onClose,
          onSubmit: handleSubmit,
          submitText: "Bind",
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

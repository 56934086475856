import React, { CSSProperties, ReactNode } from "react";
import { Controller } from "react-hook-form";

import Tooltip from "../../../admin/components/common/Tooltip";
import Switch from "../../../common/components/Switch";
import FormInputLabel from "../../components/FormInputLabel";
import { useFormContext } from "../forms2/Form";

export interface SwitchFieldProps {
  name: string;
  label?: string;
  style?: CSSProperties;
  disabled?: boolean;
  disabledReason?: ReactNode;
  progress?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SwitchField({
  name,
  label,
  style = {},
  disabled,
  disabledReason,
  progress,
  onChange,
}: SwitchFieldProps) {
  const { form, disabled: formDisabled, progress: formProgress, id } = useFormContext();

  return (
    <div style={{ marginBottom: 32, width: "100%", ...style }}>
      {label && <FormInputLabel id={`${id}-${name}-switch-label`} label={label} />}
      <Controller
        render={({ field }) => (
          <Tooltip title={disabled ? disabledReason || "" : ""}>
            <div>
              <Switch
                id={`${id}-${name}-switch`}
                checked={!!field.value}
                onChange={e => {
                  onChange && onChange(e);
                  field.onChange(e.target.checked);
                }}
                disabled={formDisabled || disabled}
                progress={formProgress || progress}
                color="primary"
                containerStyle={{ justifyContent: "flex-start" }}
              />
            </div>
          </Tooltip>
        )}
        name={name}
        control={form.control}
      />
    </div>
  );
}

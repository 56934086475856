import React, { useEffect, useMemo, useState } from "react";

import Grid from "@mui/material/Grid";

import { OrganizationResponse, ServerResponse } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import Progress from "../../../../common/components/Progress";
import RouteLeavingGuard from "../../../../common/components/RouteLeavingGuard";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { useListIDPs } from "../../../services/adminIDPsQuery";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import useOrganizationsSeqOrCursor from "../../common/EnhancedTableAsync/useOrganizationsSeqOrCursor";
import { amrToResponse } from "../amrOptions";
import { IdpUiModelType } from "../identities.utils";
import IdentitiesConfigurationOrganizationDetail from "./IdentitiesConfigurationOrganizationDetail";
import IdentitiesConfigurationOrganizationSelectExisting from "./IdentitiesConfigurationOrganizationSelectExisting";

const id = "identities-configuration-identity-pool";

interface Props {
  provider: IdpUiModelType;
  server: ServerResponse | undefined;
  updateProgress?: boolean;
  inEdit?: boolean;
  customSubmit?: boolean;
  onInit?: (fn: () => void) => void;
  onCancel?: () => void;
  onSubmit: (data: IdpUiModelType) => void;
  aid?: string;
  onDelete?: (idp: IdpUiModelType) => void;
}

export default function IdentitiesConfigurationOrganization({
  provider,
  server,
  updateProgress,
  inEdit,
  customSubmit, // in workspace wizard
  onCancel,
  onSubmit,
  onInit,
  aid,
  onDelete,
}: Props) {
  const tenantId = getTenantId();

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(server?.id);

  const formFactory = useFormFactory({
    id,
    data: provider,
    progress: updateProgress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit,
  });

  const submitFn = (provider, data) => ({
    ...provider,
    ...amrToResponse(data),
    ...data,
    ...(selected ? { name: selected.name, workspace_id: selected.id } : {}),
  });

  const [selected, setSelected] = useState<OrganizationResponse | null>(null);

  const data = useOrganizationsSeqOrCursor();

  const listIDPsQuery = useListIDPs(tenantId, aid || server?.id);

  const idps = useMemo(() => listIDPsQuery.data?.idps ?? [], [listIDPsQuery.data]);

  const organizationIDsWithConnectedIDPs = useMemo(
    () =>
      idps
        .filter(idp => idp.method === "organization")
        .map(idp => idp.workspace_id)
        .filter(v => v),
    [idps]
  );

  useEffect(() => {
    onInit &&
      onInit(() =>
        formFactory.handleSubmit(data => onSubmit && onSubmit(submitFn(provider, data)))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (data.totalData.length > 0 && !selected) {
      const notConnected = data.totalData.find(
        p => !organizationIDsWithConnectedIDPs.includes(p.id)
      );
      setSelected(notConnected ?? null);
    }
  }, [data, idps, selected, organizationIDsWithConnectedIDPs]);

  const progress = data.firstPageLoading || listIDPsQuery.isLoading;

  return (
    <FormContext.Provider value={formFactory.context}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          {progress ? (
            <Progress />
          ) : (
            <div style={{ padding: 8 }}>
              {!inEdit && ( // IDP create organization
                <IdentitiesConfigurationOrganizationSelectExisting
                  formFactory={formFactory}
                  data={data}
                  organizationIDsWithConnectedIDPs={organizationIDsWithConnectedIDPs}
                  selected={selected}
                  setSelected={setSelected}
                  onSubmit={data => onSubmit && onSubmit(submitFn(provider, data))}
                  onCancel={onCancel}
                  customSubmit={!!customSubmit}
                />
              )}

              {inEdit && ( // IDP detail view
                <IdentitiesConfigurationOrganizationDetail
                  provider={provider}
                  server={server}
                  updateProgress={updateProgress}
                  organizations={data.totalData}
                  onSubmit={data => onSubmit && onSubmit(submitFn(provider, data))}
                  onDelete={onDelete}
                  onCancel={onCancel}
                  inEdit
                />
              )}
            </div>
          )}
        </Grid>
      </Grid>
      {inEdit && <RouteLeavingGuard />}
    </FormContext.Provider>
  );
}

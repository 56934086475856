import React, { ReactNode, MouseEvent } from "react";

import Button from "@mui/material/Button";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  tooltip: {
    padding: 16,
  },
  tooltipText: {
    wordBreak: "normal",
  },
  button: {
    fontSize: 12,
    marginLeft: -6,
  },
}));

interface Props {
  children: ReactNode;
  title: string | ReactNode;
  actionLabel: string;
  onAction: (e: MouseEvent<HTMLElement>) => void;
  actionButtonId?: string;
}

export default function TooltipWithAction({
  children,
  title,
  actionLabel,
  onAction,
  actionButtonId,
  ...props
}: Props & TooltipProps) {
  const { classes } = useStyles();

  return (
    <Tooltip
      title={
        <>
          <span className={classes.tooltipText}>{title}</span>
          <Button onClick={onAction} size="small" className={classes.button} id={actionButtonId}>
            {actionLabel}
          </Button>
        </>
      }
      classes={{ tooltip: classes.tooltip }}
      {...props}
    >
      {children}
    </Tooltip>
  );
}

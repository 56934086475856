import React from "react";

import { UserVerifiableAddress, UserVerifiableAddressTypeEnum } from "@cloudentity/acp-identity";

import Dialog from "../../../../../../../common/components/Dialog";
import { FormContext } from "../../../../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../../../../common/utils/forms/formFactory";
import { useGetPool } from "../../../../../../services/adminIdentityPoolsQuery";
import IdentityPoolThemeSelector from "../../IdentityPoolThemeSelector";
import {
  setPoolWorkspaceId,
  useIdentityPoolThemeSelector,
} from "../../useIdentityPoolThemeSelector";

interface Props {
  id: string;
  poolId: string;
  title: string;
  submitText: string;
  addresses: UserVerifiableAddress[];
  onCancel: () => void;
  onSubmit: (address: string, serverId: string) => void;
  progress: boolean;
}

const data = {
  address: "",
};

export default function IdentityPoolUserSelectAddressDialog({
  id,
  poolId,
  title,
  submitText,
  addresses,
  onSubmit,
  progress,
  onCancel,
}: Props) {
  const getPoolQuery = useGetPool(poolId);
  const { isLoading, mustSelectThemeServerId } = useIdentityPoolThemeSelector({
    pool: getPoolQuery.data,
  });

  const formFactory = useFormFactory({
    id: "reset-password",
    data,
    progress: progress || getPoolQuery.isLoading || isLoading,
  });

  return (
    <Dialog onClose={onCancel} id={id} title={title}>
      <FormContext.Provider value={formFactory.context}>
        {addresses && (
          <>
            {formFactory.createSelect({
              name: "address",
              label: "Address",
              options: addresses
                .filter(i => i.type === UserVerifiableAddressTypeEnum.Email)
                .map(({ address }) => ({
                  value: address,
                  name: address,
                })),
              optional: false,
              multiple: false,
              rules: {
                required: "Address is required",
              },
            })}
            {mustSelectThemeServerId && (
              <IdentityPoolThemeSelector
                formFactory={formFactory}
                pool={getPoolQuery.data}
                showRememberCheckbox
              />
            )}
          </>
        )}
        {formFactory.createFormFooter({
          onCancel,
          onSubmit: ({ address, themeWorkspaceId, rememberThemeWorkspaceId }) => {
            if (rememberThemeWorkspaceId) {
              setPoolWorkspaceId(poolId, themeWorkspaceId);
              onSubmit(address, themeWorkspaceId);
            }
            onSubmit(address, themeWorkspaceId);
          },
          submitText,
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

import React, { CSSProperties } from "react";
import { connect } from "react-redux";

import Refresh from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import errorSvg from "../../assets/images/icons/common/Error.svg";

interface State {
  hasError: boolean;
  version: string;
}

interface Props {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, version: props.version };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  refresh = () => {
    window.location.reload();
  };

  public render() {
    if (this.state.hasError) {
      return (
        <ErrorView
          title="Unexpected error"
          details="Something went wrong! Please click button below to try again"
          action={<RefreshButton />}
          version={this.state.version}
        />
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = state => {
  return {
    version: state?.admin?.environment?.commit ?? "N/A",
  };
};

export default connect(mapStateToProps)(ErrorBoundary);

const RefreshButton = () => {
  return (
    <IconButton onClick={() => window.location.reload()} size="large">
      <Refresh />
    </IconButton>
  );
};

const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    backgroundSize: "cover",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  errorTitle: {
    color: theme.palette.secondary.light,
    marginBottom: 16,
    marginTop: 34,
    fontWeight: 300,
  },
  errorDetails: {
    color: theme.palette.secondary.light,
    padding: "0 16px",
    textAlign: "center",
    fontWeight: 300,
  },
  footer: {
    position: "fixed",
    bottom: 32,
    left: "50%",
    transform: "translateX(-50%)",
  },
}));

interface ErrorViewProps {
  title: string;
  details: string;
  action?: React.ReactNode;
  version?: string;
  style?: CSSProperties;
}

export const ErrorView = ({ title, details, action, version, style = {} }: ErrorViewProps) => {
  const { cx, classes } = useStyles();
  return (
    <>
      <div className={classes.root} style={style}>
        <img src={errorSvg} alt="error" />
        <Typography variant="h2" className={classes.errorTitle}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.errorDetails}>
          {details}
        </Typography>
        {action}
      </div>
      {version && (
        <Typography variant="textSM" className={cx(classes.footer, classes.errorDetails)}>
          Version: {version}
        </Typography>
      )}
    </>
  );
};

import React, { CSSProperties, useEffect, useMemo, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import { pathOr } from "ramda";
import { makeStyles } from "tss-react/mui";

import tableIcon from "../../../assets/images/icons/table-icon.svg";
import RowEmptyState from "../../../common/components/RowEmptyState";
import AdvancedTableColumnFilter from "../../../common/components/table/AdvancedTableColumnFilter";
import { getHoverRowStyles } from "../../../common/styles/tableStyles";
import { getFromLocalStorage, setInLocalStorage } from "../../../common/utils/localStorage.utils";
import { getParameterByName, updateQueryParam } from "../../../common/utils/query.utils";
import { HeadCellsType } from "./EnhancedTableAsync/EnhancedTableAsync";

function descendingComparator(a, b, orderBy = "") {
  const bValue = pathOr("", orderBy.split("."), b);
  const aValue = pathOr("", orderBy.split("."), a);

  if (bValue < aValue) {
    return -1;
  }

  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

interface Props {
  classes: any;
  order: "asc" | "desc";
  orderBy?: string;
  onRequestSort: (event: any, property: string) => void;
  headCells: (HeadCellsType[0] & { style: CSSProperties })[];
}

function EnhancedTableHead({ classes, order, orderBy, onRequestSort, headCells }: Props) {
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<HTMLButtonElement | null>(null);

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const columnFiltersHeadCell = useMemo(() => {
    return headCells.find(hc => hc.type === "filters");
  }, [headCells]);

  return (
    <>
      <TableHead data-testid="table-head">
        <TableRow>
          {headCells
            .filter(headCell => !headCell.hidden)
            .map(headCell => (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.path || orderBy === headCell.id ? order : false}
                align={headCell.align}
                {...(headCell.minWidthCell ? { className: classes.minWidthCell } : {})}
                style={headCell.style || {}}
                colSpan={headCell.colSpan}
                width={headCell.width}
              >
                <Tooltip
                  title={headCell.tooltip || ""}
                  disableHoverListener={!headCell.tooltip}
                  placement="top-start"
                >
                  <div>
                    {headCell.sortable && (
                      <TableSortLabel
                        active={orderBy === headCell.path || orderBy === headCell.id}
                        direction={
                          ((orderBy === headCell.path || orderBy === headCell.id) && order) || "asc"
                        }
                        onClick={createSortHandler(headCell.path || headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.path || orderBy === headCell.id ? (
                          <span
                            className={classes.visuallyHidden}
                            data-testid={`header-sorting-${headCell.id}`}
                          >
                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    )}
                    {!headCell.sortable && headCell.label}
                  </div>
                </Tooltip>
                {headCell.type === "filters" && (
                  <IconButton
                    onClick={e => setFiltersAnchorEl(e.currentTarget)}
                    style={{ padding: 12 }}
                  >
                    <img src={tableIcon} alt="table column filters" width="21" height="21" />
                  </IconButton>
                )}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>

      {filtersAnchorEl && columnFiltersHeadCell && (
        <AdvancedTableColumnFilter
          anchorEl={filtersAnchorEl}
          handleClose={() => setFiltersAnchorEl(null)}
          initialActiveColumns={columnFiltersHeadCell.columnFilters?.initialActiveColumns || []}
          columns={columnFiltersHeadCell.columnFilters?.columns || []}
          activeColumns={columnFiltersHeadCell.columnFilters?.activeColumns || []}
          setActiveColumns={columnFiltersHeadCell.columnFilters?.onSetActiveColumns || (() => {})}
        />
      )}
    </>
  );
}

const useStyles = makeStyles()((theme, _, classes) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  progressContainer: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    top: -1,
    left: -1,
    right: -1,
    bottom: -1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  minWidthCell: {
    width: "1%",
    whiteSpace: "nowrap",
  },
  firstLine: {
    fontWeight: 500,
    color: theme.palette.secondary.dark,
  },
  secondLine: {
    color: theme.palette.secondary.light,
  },
  iconButton: {
    border: "1px solid #ECECEC",
  },
  ...getHoverRowStyles(classes),
}));

export interface EnhancedTableProps {
  highlightedItem?: any;
  data: any[];
  createRow: (
    row: any,
    index: number,
    classes?: any,
    cx?: any,
    highlighted?: boolean
  ) => JSX.Element | string;
  emptyStateText?: string;
  emptyTextColSpan?: number;
  headCells: any[];
  id: string;
  disablePagination?: boolean;
  paperStyles?: CSSProperties;
  tableContainerStyle?: CSSProperties;
  scrollToTopOnChangeRowsPerPage?: boolean;
  stickyHeader?: boolean;
  loading?: boolean;
  initialPagination?: {
    order?: "desc" | "asc";
    orderBy?: string;
    page?: string;
    rowsPerPage?: string;
  };
}

const updateTableDataInLocalStorage = (id, data) => {
  let curr = {};
  try {
    curr = JSON.parse(getFromLocalStorage("tables") || "{}");
  } catch (e) {
    //
  }

  setInLocalStorage("tables", JSON.stringify({ ...curr, [id]: { ...(curr[id] || {}), ...data } }));
};

const getTableDataFromLocalStorage = (id, key) => {
  let curr = {};
  try {
    curr = JSON.parse(getFromLocalStorage("tables") || "{}");
  } catch (e) {
    //
  }

  return curr[id]?.[key] || "";
};

export const updateTableLimitInLocalStorage = (id, limit) =>
  updateTableDataInLocalStorage(id, { limit });

export const getTableLimitInLocalStorage = id => getTableDataFromLocalStorage(id, "limit");

export default function EnhancedTable({
  highlightedItem,
  data,
  createRow,
  headCells,
  emptyStateText = "No data",
  emptyTextColSpan,
  id,
  disablePagination,
  paperStyles = {},
  tableContainerStyle = {},
  scrollToTopOnChangeRowsPerPage = true,
  stickyHeader = false,
  loading,
  initialPagination = {},
}: EnhancedTableProps) {
  const { cx, classes } = useStyles();
  const initialValues = {
    order: "asc" as "asc",
    orderBy: headCells.find(cell => cell.sortable)?.id,
    page: "0",
    rowsPerPage: getTableLimitInLocalStorage(id) || "10",
    ...initialPagination,
  };

  const tableId = useMemo(() => getParameterByName("tableId"), []);
  const values = useMemo(
    () =>
      id !== tableId
        ? {
            order: initialValues.order,
            orderBy: initialValues.orderBy,
            page: parseInt(initialValues.page),
            rowsPerPage: parseInt(initialValues.rowsPerPage),
          }
        : {
            order: (getParameterByName("order") as "asc" | "desc") || initialValues.order,
            orderBy: getParameterByName("orderBy") || initialValues.orderBy,
            page: parseInt(getParameterByName("page") || initialValues.page),
            rowsPerPage: parseInt(getParameterByName("rowsPerPage") || initialValues.rowsPerPage),
          },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, tableId]
  );

  const [order, setOrder] = useState<"asc" | "desc">(values.order as "asc" | "desc");
  const [orderBy, setOrderBy] = useState(values.orderBy);
  const [page, setPage] = useState(values.page);
  const [rowsPerPage, setRowsPerPage] = useState(values.rowsPerPage);

  const [highlightedRow, setHighlightedRow] = useState<number | null>(null);

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const p = parseInt(event.target.value, 10);
    setRowsPerPage(p);
    updateTableLimitInLocalStorage(id, p);
    setPage(0);
    if (scrollToTopOnChangeRowsPerPage) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    }
  };

  const dataToRender = stableSort(data, getComparator(order, orderBy));

  const highlightedIndex = highlightedItem
    ? dataToRender.findIndex(v => v.id === highlightedItem.id) >= 0
      ? dataToRender.findIndex(v => v.id === highlightedItem.id)
      : null
    : null;

  useEffect(() => {
    if (highlightedIndex !== null) {
      setPage(Math.floor(highlightedIndex / rowsPerPage));
      setHighlightedRow(highlightedIndex % rowsPerPage);
    } else {
      setHighlightedRow(null);
    }
  }, [highlightedIndex, rowsPerPage]);

  useEffect(() => {
    if (!disablePagination) {
      updateQueryParam("order", order);
      orderBy && updateQueryParam("orderBy", orderBy);
      updateQueryParam("page", page);
      updateQueryParam("rowsPerPage", rowsPerPage);
    }
  }, [order, orderBy, page, rowsPerPage, disablePagination]);

  useEffect(() => {
    if (!disablePagination) {
      const tableId = getParameterByName("tableId");
      if (id !== tableId) {
        setOrder(initialValues.order);
        setOrderBy(initialValues.orderBy);
        setPage(parseInt(initialValues.page));
        setRowsPerPage(parseInt(initialValues.rowsPerPage));
        updateQueryParam("tableId", id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, disablePagination]);

  useEffect(() => {
    const maxPage = Math.max(Math.ceil(data.length / rowsPerPage) - 1, 0);
    if (maxPage < page) {
      setPage(maxPage);
    }
  }, [data.length, rowsPerPage, page]);

  return (
    <Paper style={paperStyles}>
      <TableContainer style={tableContainerStyle}>
        <Table
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          id={id}
          stickyHeader={stickyHeader}
          role="table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {dataToRender
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) =>
                createRow(row, page * rowsPerPage + index, classes, cx, highlightedRow === index)
              )}
            {data.length === 0 && (
              <TableRow role="row">
                <TableCell
                  role="cell"
                  colSpan={emptyTextColSpan || headCells.length}
                  style={{ padding: 0 }}
                >
                  <RowEmptyState text={emptyStateText} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!disablePagination && (
        <TablePagination
          id={`${id}-navigation`}
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          data-testid="pagination"
          SelectProps={{ "data-testid": "pagination-select" } as any}
          component="div"
        />
      )}

      {loading && (
        <div className={classes.progressContainer}>
          <CircularProgress />
        </div>
      )}
    </Paper>
  );
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { ArrowLeft, ArrowRight } from "react-feather";
import { makeStyles } from "tss-react/esm/mui";

import B2BPortalPreview from "../../../../b2b/B2BPortalPreview";
import IconButton from "../../../../common/components/IconButton";
import {
  getDefaultStyling,
  getNewTheme,
  getStyling,
} from "../../../../common/utils/hooks/useStyling";
import { getBasicStyle } from "../../customBranding/Preview";
import ThemeTemplatePreview from "../../workspaceDirectory/appearance/ThemeTemplatePreview";
import { INITIATED_ORGANIZATION_ID } from "./SetupBranding";

const useStyles = makeStyles()(() => ({
  container: {},
  previewContainer: {
    height: "100%",
    backgroundImage: "radial-gradient(circle at 1px 1px, #CECECE 3px, transparent 0)",
    backgroundSize: "24px 24px",
    borderLeft: "1px solid #D0D5DD",
    boxShadow:
      "0px 0px 12px 0px rgba(0, 0, 0, 0.10) inset, 0px 1px 1px 0px rgba(0, 0, 0, 0.08) inset",
  },
  portalPreview: {
    height: "100%",
    "&>div": {
      height: "100%",
    },
    "&>div>div": {
      height: "100%",
    },
  },
  b2bPortalPreview: {
    height: "100%",
    pointerEvents: "none",
    userSelect: "none",
    "&>div": {
      height: "100%",
    },
    "&>div>div": {
      height: "100%",
    },
    header: {
      position: "absolute",
      "& #logo": {
        height: "48px !important",
      },
    },
    "& #nav-drawer > div": {
      position: "absolute",
    },
  },
  previewLabel: {
    background: "#A4A6A7",
    padding: "4px 16px",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    marginLeft: 12,
    color: "#fff",
  },
}));

export type Branding = {
  logoUrl: string;
  primaryColor: string;
  headerColor: string;
  palette: string[];
} | null;

interface Props {
  branding: Branding;
  companyName?: string;
}

export default function SetupBrandingPreview({ branding, companyName }: Props) {
  const { classes } = useStyles();
  const theme = useTheme();
  const defaultStyling = useMemo(() => getDefaultStyling(theme), [theme]);

  const [isLoaded, setIsLoaded] = useState(false);

  const loginRef = useRef<HTMLIFrameElement>(null);
  const previewRef = useRef<HTMLIFrameElement>(null);

  const handleScrollLeft = () => {
    if (previewRef.current) {
      previewRef.current.scrollLeft = 0;
    }
  };
  const handleScrollRight = () => {
    if (previewRef.current) {
      previewRef.current.scrollLeft = previewRef.current.scrollWidth;
    }
  };

  const reloadIframeStyle = useCallback((ref, styles) => {
    if (ref && ref.current) {
      let content = ref.current?.contentWindow?.document || ref.current?.contentDocument;
      if (content && content.head) {
        const styleTag = content.querySelector("style");
        if (!styleTag) {
          const style = content.createElement("style");
          style.textContent = styles;
          content.head.appendChild(style);
        } else {
          styleTag.textContent = styles;
        }
      }
    }
  }, []);

  const basicStyle = useMemo(
    () =>
      getBasicStyle(
        getStyling({
          styling: {
            logo_url: branding?.logoUrl,
            logo_size: "L",
            font_url: "",
            colors: {
              header: branding?.headerColor,
              primary: branding?.primaryColor,
            },
          },
          defaults: defaultStyling,
        }),
        {},
        "workspace"
      ),
    [branding, defaultStyling]
  );

  useEffect(() => {
    if (!branding || !isLoaded) {
      return;
    }

    reloadIframeStyle(loginRef, basicStyle);
  }, [isLoaded, branding, basicStyle, reloadIframeStyle]);

  const handleReloadAfterInit = () => {
    reloadIframeStyle(loginRef, basicStyle);

    if (loginRef && loginRef.current) {
      let content = loginRef.current?.contentWindow?.document || loginRef.current?.contentDocument;
      const btn = content?.querySelector(".submit-button");
      if (btn) {
        btn.removeAttribute("disabled");
      }

      const idpsListHeader = content?.querySelector("#idps-list-header");
      if (idpsListHeader) {
        idpsListHeader.classList.remove("non-visible");
      }
    }

    setTimeout(() => setIsLoaded(true), 250);
  };

  return (
    <div className={classes.previewContainer}>
      <div
        style={{ display: "flex", alignItems: "center", overflow: "hidden", paddingTop: "12%" }}
        ref={previewRef}
      >
        <div
          style={{
            width: "460px",
            height: "60vh",
            marginLeft: 64,
            flex: "none",
            visibility: isLoaded ? "visible" : "hidden",
          }}
        >
          <span className={classes.previewLabel}>Login screen</span>
          <ThemeTemplatePreview
            themeId="default"
            previewPath="pages/authorization/login/index.tmpl"
            selectedPath="pages/authorization/login/index.tmpl"
            iframeRef={loginRef}
            onLoad={handleReloadAfterInit}
            style={{ height: "100%", overflow: "hidden" }}
          />
        </div>
        <div
          style={{
            width: "1200px",
            height: "60vh",
            marginLeft: 44,
            flex: "none",
            visibility: isLoaded ? "visible" : "hidden",
          }}
        >
          <span className={classes.previewLabel}>Delegated Admin Panel</span>
          <div
            className={classes.b2bPortalPreview}
            data-testid="basic-branding-preview-developer-portal"
          >
            <StyledEngineProvider injectFirst>
              <ThemeProvider
                theme={getNewTheme(
                  theme,
                  getStyling({
                    styling: {
                      logo_url: branding?.logoUrl,
                      logo_size: "L",
                      font_url: "",
                      colors: {
                        header: branding?.headerColor,
                        primary: branding?.primaryColor,
                      },
                    },
                    defaults: defaultStyling,
                  })
                )}
              >
                <B2BPortalPreview
                  organization={{
                    issuer_url: window.location.host,
                    name: companyName || "",
                    id: INITIATED_ORGANIZATION_ID,
                  }}
                  logoUrl={branding?.logoUrl}
                />
              </ThemeProvider>
            </StyledEngineProvider>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
          marginTop: 48,
        }}
      >
        <IconButton
          onClick={handleScrollLeft}
          icon={ArrowLeft}
          style={{ background: "#fff", borderWidth: 2, color: "black" }}
          strokeWidth={3}
        />
        <IconButton
          onClick={handleScrollRight}
          icon={ArrowRight}
          style={{ marginLeft: 8, background: "#fff", borderWidth: 2, color: "black" }}
          strokeWidth={3}
        />
      </div>
    </div>
  );
}

import {
  GrantWorkspaceRoleRequestRoleEnum,
  RevokeWorkspaceRoleRequestRoleEnum,
  ServerResponseTypeEnum,
} from "@cloudentity/acp-admin";

const organizationWorkspaceRoleOptions = [
  {
    value: GrantWorkspaceRoleRequestRoleEnum.Admin,
    name: "Workspace Admin",
    description: "Workspace admin has full access to all workspace resources",
  },
  {
    value: GrantWorkspaceRoleRequestRoleEnum.Manager,
    name: "Organization Admin",
    description:
      "Organization admin is entitled to manage all resources of the organization via B2B portal",
  },
  {
    value: GrantWorkspaceRoleRequestRoleEnum.UserManager,
    name: "User Manager",
    description: "User manager is entitled to manage users in the organization via B2B portal",
  },
  {
    value: GrantWorkspaceRoleRequestRoleEnum.Auditor,
    name: "Auditor",
    description: "Workspace auditor has read-only access to all workspace resources",
  },
  {
    value: GrantWorkspaceRoleRequestRoleEnum.Member,
    name: "Member",
    description: "",
  },
];

const regularWorkspaceRoleOptions = [
  {
    value: GrantWorkspaceRoleRequestRoleEnum.Admin,
    name: "Workspace Admin",
    description: "Workspace admin has full access to all workspace resources",
  },
  {
    value: GrantWorkspaceRoleRequestRoleEnum.Auditor,
    name: "Workspace Auditor",
    description: "Workspace auditor has read-only access to all workspace resources",
  },
  {
    value: GrantWorkspaceRoleRequestRoleEnum.Member,
    name: "Member",
    description: "",
  },
];

export const serverTypeToRoleName = (
  type?: ServerResponseTypeEnum,
  role?: GrantWorkspaceRoleRequestRoleEnum | RevokeWorkspaceRoleRequestRoleEnum
) => {
  if (type === ServerResponseTypeEnum.Organization) {
    return organizationWorkspaceRoleOptions.find(o => o.value === role)?.name;
  }

  return regularWorkspaceRoleOptions.find(o => o.value === role)?.name;
};

export const serverTypeToRoleOptions = (type?: ServerResponseTypeEnum) => {
  if (type === ServerResponseTypeEnum.Organization) {
    return organizationWorkspaceRoleOptions.filter(
      r => r.value !== GrantWorkspaceRoleRequestRoleEnum.Member
    );
  }

  return regularWorkspaceRoleOptions.filter(
    r => r.value !== GrantWorkspaceRoleRequestRoleEnum.Member
  );
};

import React, { useEffect, useState } from "react";

import Editor, { loader } from "@monaco-editor/react";
import BackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { StaticUser } from "@cloudentity/acp-admin";

import DialogFullScreen from "../../../common/components/DialogFullScreen";
import FormAccordion from "../../../common/components/FormAccordion";
import FormSection from "../../../common/components/FormSection";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import { validators } from "../../../common/utils/forms/validation";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";

loader.config({
  paths: {
    vs: "./assets/monaco/min/vs",
  },
});

interface Props {
  user: StaticUser;
  inEdit?: boolean;
  serverId: string;
  onCancel: () => void;
  onSave: (data: StaticUser) => void;
  progress: boolean;
}

export default function StaticIDPEditUser({
  user,
  inEdit,
  serverId,
  onCancel,
  onSave,
  progress,
}: Props) {
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(serverId);

  const formFactory = useFormFactory({
    id: "static-idp-edit-user",
    data: user,
    progress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps,
  });

  const [additionalAttributes, setAdditionalAttributes] = useState(
    JSON.stringify(user.additional_attributes, null, 2)
  );

  const [isSyntaxError, setSyntaxError] = useState(false);
  const handleValidate = annotations => setSyntaxError(annotations && annotations.length > 0);

  const email = formFactory.watch("email");
  const emailVerified = formFactory.watch("email_verified");
  const phoneNumber = formFactory.watch("phone_number");
  const phoneNumberVerified = formFactory.watch("phone_number_verified");

  useEffect(() => {
    if (!email && emailVerified) {
      formFactory.setValue("email_verified", false);
    }
    if (!phoneNumber && phoneNumberVerified) {
      formFactory.setValue("phone_number_verified", false);
    }
  }, [email, phoneNumber, emailVerified, phoneNumberVerified, formFactory]);

  return (
    <DialogFullScreen
      id="static-idp-edit-user-dialog"
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          {inEdit ? (
            <>
              <IconButton
                id="static-idp-edit-user-dialog-back-button"
                onClick={() => onCancel()}
                style={{ marginRight: 16 }}
                size="large"
              >
                <BackIcon />
              </IconButton>
              Edit user
            </>
          ) : (
            <>Add user</>
          )}
        </div>
      }
    >
      <FormContext.Provider value={formFactory.context}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={6}>
            <Paper style={{ padding: 32 }} variant="outlined" elevation={0}>
              <FormSection id="user-attributes-label" title="User attributes" />
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  {formFactory.createRequiredField({
                    name: "username",
                    label: "Username",
                    inputProps: {
                      autoComplete: "off",
                    },
                  })}
                </Grid>
                <Grid item xs={12} lg={6}>
                  {formFactory.createRequiredField({
                    name: "password",
                    label: "Password",
                    defaultVisibility: false,
                    toggleVisibility: true,
                    inputProps: {
                      autoComplete: "off",
                    },
                  })}
                </Grid>
                <Grid item xs={12} lg={6}>
                  {formFactory.createField({
                    name: "email",
                    label: "Email",
                    rules: {
                      validate: {
                        validEmail: validators.validEmail({ label: "Value" }),
                      },
                    },
                    inputProps: {
                      autoComplete: "off",
                    },
                  })}
                  {formFactory.createCheckBox({
                    name: "email_verified",
                    label: "Use email for MFA",
                    disabled: !email,
                  })}
                </Grid>
                <Grid item xs={12} lg={6}>
                  {formFactory.createPhoneInput({
                    name: "phone_number",
                    label: "Phone",
                    selector: "#static-idp-edit-user-phone-input",
                  })}
                  {formFactory.createCheckBox({
                    name: "phone_number_verified",
                    label: "Use phone for MFA",
                    disabled: !phoneNumber,
                  })}
                </Grid>
              </Grid>
              <FormAccordion
                title="Advanced settings"
                id="static-idp-edit-user"
                style={{ padding: 0 }}
              >
                <Typography style={{ padding: 22 }}>
                  Add additional user attributes below using standard JSON syntax
                </Typography>
                <Editor
                  defaultLanguage="json"
                  options={{
                    minimap: {
                      enabled: false,
                    },
                    scrollBeyondLastLine: false,
                    readOnly: !checkWorkspacePermissionsQuery.data?.manage_idps,
                  }}
                  height="200px"
                  value={additionalAttributes}
                  onChange={v => setAdditionalAttributes(v ?? "")}
                  wrapperProps={{
                    id: "static-idp-edit-user-editor",
                  }}
                  onValidate={handleValidate}
                />
              </FormAccordion>
              {formFactory.createFormFooter({
                onCancel,
                onSubmit: data =>
                  onSave({
                    ...data,
                    authentication_context: {
                      ...user.authentication_context,
                      name: data.username,
                    },
                    additional_attributes: JSON.parse(additionalAttributes || "{}"),
                  }),
                disabled: isSyntaxError,
              })}
            </Paper>
          </Grid>
        </Grid>
      </FormContext.Provider>
    </DialogFullScreen>
  );
}

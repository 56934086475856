import React, { useMemo, useState } from "react";

import Typography from "@mui/material/Typography";

import { Attribute, Claim } from "@cloudentity/acp-admin";

import Dialog from "../../../../common/components/Dialog";
import IconTooltip from "../../../../common/components/IconTooltip";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { validators } from "../../../../common/utils/forms/validation";
import {
  ClaimType,
  getSourcePathWithMetadataKey,
  SourcePath,
  SourceType,
  sourceTypeOptions,
} from "./ClaimUtils";

export interface OAuthClaimsCreateProps {
  options: Attribute[];
  model: {
    type: ClaimType;
  };
  workspace: string;
  existingClaims: (string | undefined)[];
  onCreate: (claims: Claim[]) => void;
  onCancel: () => void;
  progress: boolean;
  scopesNames: string[];
  scopesFetching: boolean;
}

const OAuthClaimsCreate = ({
  options,
  model,
  workspace,
  existingClaims,
  onCreate,
  onCancel,
  progress,
  scopesNames,
  scopesFetching,
}: OAuthClaimsCreateProps) => {
  const data = useMemo(
    () => ({
      source_path: options[0],
      source_type: sourceTypeOptions[0].value,
      scopes: [],
    }),
    [options]
  );

  const formFactory = useFormFactory({ id: "add-claim", data, progress });

  const [paths, setPaths] = useState(options);

  const handleCreate = data => {
    const claim = {
      authorization_server_id: workspace,
      source_type: data.source_type,
      source_path: getSourcePathWithMetadataKey(
        data.source_type,
        data.source_path,
        data.metadata_key
      ),
      name: data.name,
      description: data.description,
      scopes: data.scopes,
    };
    onCreate([{ ...claim, type: model.type }]);
  };

  return (
    <Dialog onClose={onCancel} id="add-claim-dialog" title="Add claim">
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createRequiredField({
          name: "name",
          label: "Name",
          rules: {
            validate: {
              notUniq: validators.notUniq({ label: "Name", options: existingClaims }),
            },
          },
          autoFocus: true,
        })}
        {formFactory.createField({
          name: "description",
          label: "Description",
        })}
        <SourceType
          formFactory={formFactory}
          options={sourceTypeOptions}
          authnCtxOptions={options}
          setPaths={setPaths}
        />
        <SourcePath formFactory={formFactory} options={paths} />
        <div
          id="claim-scopes-tooltip"
          style={{ marginBottom: 24, display: "flex", alignItems: "center" }}
        >
          <Typography variant="body2" style={{ fontWeight: 500 }}>
            Included in scopes
          </Typography>

          <IconTooltip
            title="This claim will be added only when the selected scopes are issued. If no scopes are issued, this claim will be added in all cases."
            style={{ marginLeft: 8 }}
          />
        </div>

        {formFactory.createAutocompleteField({
          name: "scopes",
          label: "Scopes",
          defaultValue: [],
          options: scopesNames,
          multiple: true,
          loading: scopesFetching,
          disabled: scopesFetching,
        })}

        {formFactory.createFormFooter({ onCancel, onSubmit: handleCreate, submitText: "Add" })}
      </FormContext.Provider>
    </Dialog>
  );
};

export default OAuthClaimsCreate;

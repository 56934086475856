import React from "react";

import { PoolResponse } from "@cloudentity/acp-identity";

import Dialog from "../../../../../common/components/Dialog";
import { FormContext } from "../../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../../common/utils/forms/formFactory";
import IdentityPoolThemeSelector from "./IdentityPoolThemeSelector";
import { setPoolWorkspaceId } from "./useIdentityPoolThemeSelector";

const data = {
  themeWorkspaceId: "",
  rememberThemeWorkspaceId: false,
};

interface Props {
  pool: PoolResponse;
  onCancel: () => void;
  onSubmit: (serverId: string) => void;
}

export default function IdentityPoolThemeSelectorDialog({ pool, onCancel, onSubmit }: Props) {
  const formFactory = useFormFactory({
    id: "identity-pool-theme-selector-dialog",
    data,
  });

  return (
    <Dialog id="identity-pool-theme-selector-dialog" title="Select workspace to send message">
      <FormContext.Provider value={formFactory.context}>
        <IdentityPoolThemeSelector pool={pool} formFactory={formFactory} showRememberCheckbox />
        {formFactory.createFormFooter({
          onCancel,
          submitText: "Continue",
          onSubmit: data => {
            if (data.rememberThemeWorkspaceId) {
              setPoolWorkspaceId(pool?.id || "", data.themeWorkspaceId);
              return onSubmit(data.themeWorkspaceId);
            }
            return onSubmit(data.themeWorkspaceId);
          },
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

import React, { useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import uniq from "lodash/uniq";

import { RoleResourceRoleEnum, ServerResponse } from "@cloudentity/acp-admin";
import { UserWithData } from "@cloudentity/acp-identity";

import { useListUserRoles } from "../../../services/adminRolesQuery";
import { useGetWorkspaces } from "../../../services/adminServersQuery";
import EnhancedTable from "../../common/EnhancedTable";
import ChangeAdminRole from "../../manageAccess/ChangeAdminRole";
import RevokeWorkspaceRole from "../../manageAccess/RevokeWorkspaceRole";
import { serverTypeToRoleName } from "../../manageAccess/workspaceRoleOptions";
import ServerBlock from "./ServerBlock";

const headCells = [
  { id: "workspace", label: "Workspace", align: "left" },
  { id: "roles", label: "Role", align: "left" },
  { id: "actions", label: "", align: "right", style: { width: 30 } },
];

interface Props {
  user?: UserWithData;
}

export default function WorkspaceRoleTable({ user }: Props) {
  const [menu, setMenu] = useState<{
    anchorEl: HTMLElement | null;
    open: boolean;
    data?: { server: ServerResponse; roles: string[] };
  } | null>();

  const listUserRolesQuery = useListUserRoles(
    { ipID: user?.user_pool_id!, userID: user?.id! },
    { enabled: !!user }
  );
  const workspaceIds = uniq(
    listUserRolesQuery.data?.resources
      ?.map(resource => resource.workspace_id)
      .filter((id): id is string => !!id) ?? []
  );

  const getWorkspacesQuery = useGetWorkspaces(workspaceIds);

  const groupedByWorkspace =
    getWorkspacesQuery.data?.map(workspace => {
      return {
        server: workspace,
        roles: (listUserRolesQuery.data?.resources || [])
          .filter(f => f.workspace_id === workspace.id)
          .map(f => f.role)
          .filter(f => f !== RoleResourceRoleEnum.Member),
      };
    }) ?? [];

  if (groupedByWorkspace.length === 0) {
    return null;
  }

  return (
    <div style={{ marginTop: 16 }}>
      <EnhancedTable
        id="workspace-roles"
        data={groupedByWorkspace.sort((a, b) =>
          (a.server?.name ?? "").localeCompare(b.server?.name ?? "")
        )}
        headCells={headCells}
        disablePagination
        createRow={(row, i, classes) => {
          return (
            <TableRow
              key={i}
              style={{ height: 57, position: "relative" }}
              onClick={e => {}}
              id={`workspace-roles-row-${i}`}
            >
              <TableCell id={`workspace-roles-name-${i}`}>
                <ServerBlock server={row.server} />
              </TableCell>
              <TableCell id={`workspace-roles-roles-${i}`}>
                {row.roles?.map(r => serverTypeToRoleName(row.server?.type, r) || r).join(", ")}
              </TableCell>
              <TableCell align="right" padding="none" style={{ padding: "0 5px" }}>
                <IconButton
                  id={`workspace-roles-more-button-${i}`}
                  onClick={e => {
                    e.stopPropagation();
                    setMenu({ anchorEl: e.currentTarget, data: row, open: true });
                  }}
                  aria-label="more"
                  size="large"
                >
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        }}
      />
      {menu && (
        <Menu
          anchorEl={menu.anchorEl}
          open={Boolean(menu.open)}
          onClose={() => setMenu({ ...menu, open: false })}
          keepMounted={!!menu.anchorEl}
          onClickCapture={() => setMenu({ ...menu, open: false })}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <ChangeAdminRole user={user} workspace={menu.data?.server?.id!} />
          <RevokeWorkspaceRole user={user} workspace={menu.data?.server?.id!} />
        </Menu>
      )}
    </div>
  );
}

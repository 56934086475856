import React, { useEffect, useState } from "react";

import { IdentityTabs } from "../../../common/utils/viewTabs";
import { IdpType } from "../../services/adminIDPsApi";
import IdentitiesDetailsController from "./IdentitiesDetailsController";

interface Props {
  type: IdpType;
  id: string;
  serverId: string;
  handleBack: () => void;
}

const IdentitiesDetailsControllerForDialog = ({ type, id, serverId, handleBack }: Props) => {
  const [tab, setTab] = useState(IdentityTabs.CONFIGURATION);

  useEffect(() => {
    if (
      tab === IdentityTabs.PROVISIONING &&
      ["organization", "identity_pool"].includes(type || "")
    ) {
      setTab(IdentityTabs.CONFIGURATION);
    }
  }, [tab, type]);

  return (
    <IdentitiesDetailsController
      type={type}
      id={id}
      serverId={serverId}
      tab={tab}
      handleTabChange={(tab: IdentityTabs) => setTab(tab)}
      enabledDiscoveryAlert={false}
      setEnabledDiscoveryAlert={() => {}}
      withBreadcrumb={false}
      handleBackToListView={handleBack}
    />
  );
};

export default IdentitiesDetailsControllerForDialog;

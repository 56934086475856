import React, { useMemo, useState } from "react";

import Typography from "@mui/material/Typography";

import { Policy } from "@cloudentity/acp-admin";

import Dialog from "../../../common/components/Dialog";
import FormInputLabel from "../../../common/components/FormInputLabel";
import { notifyErrorOrDefaultTo } from "../../../common/components/notifications/notificationService";
import { BRAND_NAME } from "../../../common/theme/theme";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import { validators } from "../../../common/utils/forms/validation";
import { toSlugString } from "../../../common/utils/string.utlis";
import adminPoliciesApi from "../../services/adminPoliciesApi";
import { TypeNames } from "./policies.common";

const id = "policies-create";

const initialRegoPolicyDefinition =
  "package acp.authz\n\ndefault allow = false\n\nallow {\n  false\n}\n";

export interface PoliciesCreateProps {
  existingIds: string[];
  type?: string;
  typeReadonly?: boolean;
  onCreate: (policy: Policy) => void;
  onCancel: () => void;
  workspace: string;
  title?: string;
}

export default function PoliciesCreate({
  existingIds,
  type = "api",
  typeReadonly = false,
  onCreate,
  onCancel,
  workspace,
  title = "",
}: PoliciesCreateProps) {
  const [progress, setProgress] = useState(false);

  const data = useMemo(() => ({ type, language: "cloudentity" }), [type]);
  const formFactory = useFormFactory({ id, data, progress });

  const handleChangeName = e => {
    const slugId = toSlugString(e.target.value);

    formFactory.clearErrors("id");
    formFactory.setValue("id", `${workspace}-${slugId}`);
  };

  const createPolicy = data => {
    setProgress(true);
    adminPoliciesApi
      .createPolicy({
        policy: {
          id: data.id.trim(),
          tenant_id: "default",
          server_id: workspace,
          policy_name: data.policy_name.trim(),
          type: data.type || type,
          ...(data.language === "cloudentity"
            ? {
                language: "cloudentity",
                validators: [{ name: "false", conf: undefined, recovery: undefined }],
              }
            : {
                language: "rego",
                definition: initialRegoPolicyDefinition,
                query: "allow = data.example.allow",
              }),
        },
      })
      .then(res => onCreate(res.data))
      .catch(err => {
        if (
          err.response?.status === 409 &&
          err.response?.data.error === "policy id must be unique"
        ) {
          formFactory.setError(
            "id",
            {
              message: "Policy ID with given value already exists",
            },
            { shouldFocus: true }
          );
        } else {
          notifyErrorOrDefaultTo("Error occurred while trying to create policy")(err);
        }
        setProgress(false);
      });
  };

  return (
    <Dialog onClose={onCancel} id="policies-create-dialog" title={title || "Create Policy"}>
      <FormContext.Provider value={formFactory.context}>
        {typeReadonly && (
          <div style={{ marginBottom: 32 }}>
            <FormInputLabel label="Policy type" id="policy-type-label" />
            <Typography>{TypeNames[type]}</Typography>
          </div>
        )}
        {!typeReadonly &&
          formFactory.createSelect({
            name: "type",
            label: "Policy type",
            options: Object.keys(TypeNames).map(type => ({
              value: type,
              name: TypeNames[type],
            })),
            defaultValue: type,
            rules: {
              required: "Policy type is required",
            },
          })}
        {formFactory.createRequiredField({
          name: "policy_name",
          label: "Policy name",
          onChange: handleChangeName,
        })}
        {formFactory.createRequiredField({
          name: "id",
          label: "Policy ID",
          rules: {
            validate: {
              validID: validators.validID({ label: "Policy ID" }),
              notUniq: validators.notUniq({ label: "Policy ID", options: existingIds }),
            },
          },
        })}

        {formFactory.createFormCardsField({
          label: "Policy language",
          name: "language",
          cards: [
            {
              title: BRAND_NAME,
              subtitle: "Create policies using the visual editor or with JSON or YAML",
              value: "cloudentity",
            },
            {
              title: "Rego",
              subtitle: "Create Open Policy Agent (OPA) policies using Rego",
              value: "rego",
            },
          ],
        })}

        {formFactory.createFormFooter({
          onCancel,
          onSubmit: createPolicy,
          submitText: "Create",
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

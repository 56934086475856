import React, { useEffect, useMemo } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { map, pathOr, prop } from "ramda";

import { ServerResponse } from "@cloudentity/acp-admin";

import FormAccordion from "../../../common/components/FormAccordion";
import FormSection from "../../../common/components/FormSection";
import RouteLeavingGuard from "../../../common/components/RouteLeavingGuard";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import { validators } from "../../../common/utils/forms/validation";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import CardWithIconAndTitle from "../common/CardWithIconAndTitle";
import { CommonIdpConfig } from "./CommonIdpConfig";
import CommonIdpConfigUpper from "./CommonIdpConfigUpper";
import IdentitiesDetailsFooter from "./IdentitiesDetailsFooter";
import SSOIDPSettings from "./SSOIDPSettings";
import { amrOptions, amrToResponse, getAMRLabel } from "./amrOptions";
import { IdpUiModelExternalType, providers } from "./identities.utils";

const id = "identities-configuration-custom";

interface Props {
  idp: IdpUiModelExternalType;
  server: ServerResponse | undefined;
  updateProgress?: boolean;
  inEdit?: boolean;
  onLogoEdit?: (data: any) => void;
  customSubmit?: boolean;
  onInit?: (fn: () => void) => void;
  onCancel?: () => void;
  onSubmit: (data: IdpUiModelExternalType) => void;
  onDelete?: (idp: IdpUiModelExternalType) => void;
}

export default function IdentitiesConfigurationExternal({
  idp,
  server,
  updateProgress,
  inEdit,
  onLogoEdit,
  customSubmit,
  onCancel,
  onSubmit,
  onInit,
  onDelete,
}: Props) {
  const data = useMemo(() => idp || {}, [idp]);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(server?.id);

  const formFactory = useFormFactory({
    id,
    data,
    progress: updateProgress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit,
  });

  const submitFn = (idp, data) => ({
    ...idp,
    ...amrToResponse(data),
    ...data,
    settings: { ...data.settings },
  });

  const providerMapData = providers.find(p => p.method === "external");

  useEffect(() => {
    onInit &&
      onInit(() => formFactory.handleSubmit(data => onSubmit && onSubmit(submitFn(idp, data))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serverSSOEnabled = server?.sso?.enabled ?? false;

  return (
    <FormContext.Provider value={formFactory.context}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Paper style={{ padding: 32 }}>
            {providerMapData && (
              <CardWithIconAndTitle
                img={providerMapData.icon}
                title={providerMapData.name}
                subtitle={providerMapData.description}
                id={`idp-${providerMapData.name.replace(/ /g, "-")}`}
                style={{ marginBottom: 32 }}
              />
            )}

            <CommonIdpConfigUpper
              formFactory={formFactory}
              provider={idp}
              inEdit={inEdit}
              onLogoEdit={onLogoEdit}
              onUpdate={onSubmit}
            />
            {formFactory.createRequiredField({
              name: "settings.url",
              label: "External datastore service URL",
              rules: {
                validate: {
                  validURL: validators.validURL({ label: "External datastore service URL" }),
                },
              },
            })}

            <CommonIdpConfig formFactory={formFactory} data={idp} />

            <FormSection id="ipd-credentials-subtitle" title="Credentials" />
            {formFactory.createField({
              name: `credentials.username`,
              label: "Basic Auth Username",
              optional: true,
              defaultValue: idp.credentials?.username || "",
              inputProps: {
                autoComplete: "off",
              },
            })}
            {formFactory.createField({
              name: `credentials.password`,
              label: "Basic Auth Password",
              defaultValue: idp.credentials?.password || "",
              toggleVisibility: true,
              optional: true,
              defaultVisibility: false,
              inputProps: {
                autoComplete: "off",
              },
            })}

            {inEdit && (
              <FormAccordion title="Advanced settings" id={id}>
                {formFactory.createAutocompleteField({
                  name: "static_amr",
                  label: "Authentication Method Reference",
                  helperText: "If set overwrites AMR obtained from this authentication method",
                  defaultValue: pathOr([], ["static_amr"], idp),
                  options: map(prop("value"), amrOptions),
                  getOptionLabel: getAMRLabel,
                  multiple: true,
                })}
                {serverSSOEnabled && <SSOIDPSettings formFactory={formFactory} />}
              </FormAccordion>
            )}

            <IdentitiesDetailsFooter
              customSubmit={customSubmit}
              idp={idp}
              formFactory={formFactory}
              onSubmit={data => onSubmit && onSubmit(submitFn(idp, data))}
              onCancel={onCancel}
              onDelete={onDelete}
            />
          </Paper>
        </Grid>
        {inEdit && <RouteLeavingGuard />}
      </Grid>
    </FormContext.Provider>
  );
}

import React from "react";

import Menu from "../../../../common/components/Menu";
import { useFormContext } from "../../../../common/utils/forms2/Form";

interface Props {
  menu: {
    index: number;
    anchor: HTMLButtonElement;
  } | null;
  deleteDisabled?: boolean;
  onClose: () => void;
  onRemove: (index: number) => void;
  onSelect: (index: number) => void;
}

export default function IdentitiesDetailsProvisioningMappingMenu({
  menu,
  deleteDisabled,
  onClose,
  onRemove,
  onSelect,
}: Props) {
  const { form } = useFormContext();

  if (!menu) return null;

  const name = `user.attributes_mapping[${menu.index}]`;
  const mapping: {
    source: null | string;
    allow_weak_decoding: boolean;
    target: null | string;
    update_on_sign_in: boolean;
  } = form.watch(name);

  const showUpdateOption =
    !mapping.target?.startsWith("addresses.") && !mapping.target?.startsWith("identifiers.");

  return (
    <Menu
      anchorEl={menu?.anchor}
      onClose={onClose}
      items={[
        {
          onClick: () => {
            onRemove(menu.index);
            onClose();
          },
          label: "Delete",
          disabled: deleteDisabled,
          id: "menu-item-delete",
        },
        ...((showUpdateOption &&
          (mapping.update_on_sign_in
            ? [
                {
                  onClick: () => {
                    if (menu) {
                      form.setValue(`${name}.update_on_sign_in`, false);
                      onClose();
                    }
                  },
                  label: "Disable Updated on each user sign-in",
                  id: "menu-item-update-sign-in-disable",
                },
              ]
            : [
                {
                  onClick: () => {
                    if (menu) {
                      form.setValue(`${name}.update_on_sign_in`, true);
                      onClose();
                    }
                  },
                  label: "Enable Updated on each user sign-in",
                  id: "menu-item-update-sign-in-enable",
                },
              ])) ||
          []),
        {
          onClick: () => {
            onSelect(menu.index);
            onClose();
          },
          label: "Select",
          id: "menu-item-select",
        },
      ]}
    />
  );
}

import React, { useEffect, useMemo, useState } from "react";

import { pathOr } from "ramda";

import {
  PoolPermissionsResponse,
  SupportedJSONSchema,
  UserWithDataStatusEnum,
} from "@cloudentity/acp-identity";

import { getColumnsFromStorage } from "../../../admin/components/workspaceDirectory/administrator/useAdministratorUsersColumns";
import IdentityPoolUserAddressesCell from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/list/IdentityPoolUserAddressesCell";
import IdentityPoolUserIdentifierCell from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/list/IdentityPoolUserIdentifierCell";
import StatusChip from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/list/StatusChip";
import {
  getColumnsStorageKey,
  getFormattedDate,
  getUserRolesDisplayNames,
} from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/list/utils";
import {
  fieldToTitle,
  getAllPaths,
  getRequiredPaths,
} from "../../../admin/components/workspaceDirectory/identityPools/schemas/schemas.utils";
import {
  useCheckPoolPermissions,
  useGetPool,
} from "../../../admin/services/adminIdentityPoolsQuery";
import { useGetWorkspaceSchema } from "../../../admin/services/adminIdentitySchemasQuery";
import { useCheckWorkspacePermissions } from "../../../admin/services/adminPermissionsQuery";
import {
  useListIdentityPoolRoles,
  useListWorkspaceRoles,
} from "../../../admin/services/adminRolesQuery";
import { useCheckTenantPermissions } from "../../../admin/services/adminTenantsQuery";
import { setInLocalStorage } from "../../../common/utils/localStorage.utils";

interface Props {
  workspaceID?: string;
  identityPoolID: string;
}

export const useB2BUsersColumns = ({ identityPoolID, workspaceID }: Props) => {
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const poolQuery = useGetPool(identityPoolID, { enabled: !!identityPoolID });
  const payloadSchemaQuery = useGetWorkspaceSchema(workspaceID, poolQuery.data?.payload_schema_id, {
    enabled: poolQuery.isSuccess,
  });
  const metadataSchemaQuery = useGetWorkspaceSchema(
    workspaceID,
    poolQuery.data?.metadata_schema_id,
    {
      enabled: poolQuery.isSuccess,
    }
  );
  const businessMetadataSchemaQuery = useGetWorkspaceSchema(
    workspaceID,
    poolQuery.data?.business_metadata_schema_id,
    {
      enabled: poolQuery.isSuccess,
    }
  );

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspaceID, {
    enabled: !!workspaceID,
  });
  const listWorkspaceRolesQuery = useListWorkspaceRoles(workspaceID!, {
    enabled: !!checkWorkspacePermissionsQuery.data?.read_roles && !!workspaceID,
  });
  const listIdentityPoolRolesQuery = useListIdentityPoolRoles(identityPoolID!, {
    enabled: !!checkWorkspacePermissionsQuery.data?.read_roles && !!identityPoolID,
  });

  const checkPoolPermissionsQuery = useCheckPoolPermissions(identityPoolID);

  const allPayloadMetadataColumns = useMemo(
    () =>
      getPayloadMetadataColumns(
        payloadSchemaQuery.data?.schema,
        metadataSchemaQuery.data?.schema,
        businessMetadataSchemaQuery?.data?.schema,
        getAllPaths,
        checkPoolPermissionsQuery.data
      ),
    [
      payloadSchemaQuery.data,
      metadataSchemaQuery.data,
      businessMetadataSchemaQuery.data,
      checkPoolPermissionsQuery.data,
    ]
  );

  const requiredPayloadMetadataColumns = useMemo(
    () =>
      getPayloadMetadataColumns(
        payloadSchemaQuery.data?.schema,
        metadataSchemaQuery.data?.schema,
        businessMetadataSchemaQuery?.data?.schema,
        getRequiredPaths,
        checkPoolPermissionsQuery.data
      ),
    [
      payloadSchemaQuery.data,
      metadataSchemaQuery.data,
      businessMetadataSchemaQuery.data,
      checkPoolPermissionsQuery.data,
    ]
  );

  const columnsConfiguration = useMemo(
    () =>
      [
        ...allPayloadMetadataColumns,
        ...[
          {
            id: "roles",
            header: "Roles",
            cell: cell => {
              return getUserRolesDisplayNames(
                cell.row.original,
                [],
                listWorkspaceRolesQuery.data?.subjects || [],
                listIdentityPoolRolesQuery.data?.subjects || []
              );
            },
          },
          {
            id: "identifiers",
            path: "identifiers",
            header: "Identifiers",
            accessorFn: row => row.identifiers,
            cell: cell => <IdentityPoolUserIdentifierCell user={cell.row.original} />,
            enableSorting: true,
          },
          {
            id: "addresses",
            path: "addresses",
            header: "Addresses",
            accessorFn: row => row.addresses,
            cell: cell => <IdentityPoolUserAddressesCell user={cell.row.original} />,
          },
          {
            id: "created_at",
            path: "created_at",
            header: "Created At",
            enableSorting: true,
            accessorFn: row => row.created_at,
            cell: cell => {
              const created_at = cell.row.original.created_at;
              return getFormattedDate(created_at ?? "");
            },
          },
          {
            id: "status",
            path: "status",
            header: "Status",
            enableSorting: true,
            accessorFn: row => row.status,
            cell: cell => {
              const status = cell.row.original.status;
              return (
                <StatusChip
                  status={status}
                  label={status === UserWithDataStatusEnum.New ? "Invite Sent" : undefined}
                />
              );
            },
          },
          {
            id: "status_updated_at",
            path: "status_updated_at",
            header: "Last Updated",
            enableSorting: true,
            accessorFn: row => row.status_updated_at,
            cell: cell => {
              const status_updated_at = cell.row.original.status_updated_at;
              return getFormattedDate(status_updated_at ?? "");
            },
          },
        ],
      ].filter(col =>
        !!checkWorkspacePermissionsQuery.data?.read_roles ? true : col.id !== "roles"
      ),
    [
      allPayloadMetadataColumns,
      listWorkspaceRolesQuery.data?.subjects,
      listIdentityPoolRolesQuery.data?.subjects,
      checkWorkspacePermissionsQuery.data?.read_roles,
    ]
  );

  const defaultDynamicColumnsIds: string[] = useMemo(
    () => [
      ...(requiredPayloadMetadataColumns?.slice(0, 2).map(v => v.id) ?? []),
      "identifiers",
      "roles",
      "status",
      "status_updated_at",
    ],
    [requiredPayloadMetadataColumns]
  );

  const defaultColumnsIds: string[] = useMemo(
    () =>
      defaultDynamicColumnsIds
        .filter(colId =>
          !!checkWorkspacePermissionsQuery.data?.read_roles ? true : colId !== "roles"
        )
        .map(colId => (columnsConfiguration.map(col => col.id).includes(colId) ? colId : ""))
        .filter(Boolean),
    [defaultDynamicColumnsIds, columnsConfiguration, checkWorkspacePermissionsQuery.data]
  );

  const [currentColumnsIds, setCurrentColumnsIds] = useState<string[]>([]);

  useEffect(() => {
    setCurrentColumnsIds(
      getColumnsFromStorage(
        identityPoolID,
        columnsConfiguration
          .map(({ id }) => id)
          .filter(colId =>
            !!checkWorkspacePermissionsQuery.data?.read_roles ? true : colId !== "roles"
          )
          .filter(colId =>
            columnsConfiguration.map(col => col.id).includes(colId) ? colId : false
          )
      ) || defaultColumnsIds
    );
  }, [
    identityPoolID,
    defaultColumnsIds,
    columnsConfiguration,
    checkWorkspacePermissionsQuery.data,
  ]);

  const onSetCurrentColumnsIds = (currentColumnsIds: string[]) => {
    setInLocalStorage(getColumnsStorageKey(identityPoolID), JSON.stringify(currentColumnsIds));
    setCurrentColumnsIds(currentColumnsIds);
  };

  const isResolved =
    poolQuery.isSuccess &&
    (!!checkTenantPermissionsQuery.data?.list_identity_pools
      ? payloadSchemaQuery.isSuccess &&
        metadataSchemaQuery.isSuccess &&
        (!poolQuery.data?.business_metadata_schema_id || businessMetadataSchemaQuery.isSuccess)
      : true);

  return {
    isResolved,
    columnsConfiguration,
    currentColumnsIds,
    onSetCurrentColumnsIds,
    defaultColumnsIds,
  };
};

const valueToString = (path, data) => {
  const v = pathOr("", path.split("."), data);
  if (Array.isArray(v)) {
    return JSON.stringify(v, null, 2);
  }
  if (typeof v === "object" && v !== null) {
    return JSON.stringify(v, null, 2);
  }
  if (typeof v === "boolean" && v !== null) {
    return String(v);
  }
  return v;
};

const getPayloadMetadataColumns = (
  payloadSchema: SupportedJSONSchema | undefined,
  metadataSchema: SupportedJSONSchema | undefined,
  businessMetadataSchema: SupportedJSONSchema | undefined,
  pathsGetter: (schema: SupportedJSONSchema) => any[],
  poolPermissions: PoolPermissionsResponse | undefined
) => [
  ...pathsGetter(payloadSchema || {}).map(f => ({
    id: `payload.${f}`,
    path: `payload.${f}`,
    header: fieldToTitle(f),
    accessorFn: row => valueToString(f, row.payload) ?? null,
    cell: cell => valueToString(f, cell.row.original.payload) ?? null,
  })),
  ...(poolPermissions?.b2b_read_admin_metadata
    ? pathsGetter(metadataSchema || {}).map(f => ({
        id: `metadata.${f}`,
        path: `metadata.${f}`,
        header: fieldToTitle(f),
        accessorFn: row => valueToString(f, row.metadata) ?? null,
        cell: cell => valueToString(f, cell.row.original.metadata) ?? null,
      }))
    : []),
  ...(poolPermissions?.b2b_read_business_metadata
    ? pathsGetter(businessMetadataSchema || {}).map(f => ({
        id: `business_metadata.${f}`,
        path: `business_metadata.${f}`,
        header: fieldToTitle(f),
        accessorFn: row => valueToString(f, row.business_metadata) ?? null,
        cell: cell => valueToString(f, cell.row.original.business_metadata) ?? null,
      }))
    : []),
];

import React, { lazy, Suspense } from "react";
import { Routes } from "react-router";
import { Route } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { LogOut } from "react-feather";
import { makeStyles } from "tss-react/mui";

import PageContainer from "../../admin/components/common/PageContainer";
import { logout } from "../../common/auth/actions/actions";
import { useSilentAuthentication } from "../../common/auth/useSilentAuthentication";
import Logo from "../../common/components/Logo";
import Progress from "../../common/components/Progress";

const ApplicationController = lazy(() => import("./applications/ApplicationController"));
const Applications = lazy(() => import("./applications/Applications"));

const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    "& *": {
      fontFamily: `"CustomTenantFont", ${theme.typography.fontFamily} !important`,
    },
  },
  logo: {
    verticalAlign: "middle",
    height: 32,
    width: "auto",
    maxWidth: 350,
    objectFit: "contain",
  },
  appBar: {
    background: "#fff",
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    backgroundColor: theme.custom.headerBackground,
    height: 64,
  },
  content: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar as any,
  logoutButton: {
    color: "white",
    marginLeft: 20,
  },
}));

interface Props {
  preview?: boolean;
  logo?: {
    url: string;
    size: number;
  };
  isLoading?: boolean;
}

export default function AuthenticatedDeveloperAppBase({
  preview = false,
  logo,
  isLoading = false,
}: Props) {
  const { classes } = useStyles();
  useSilentAuthentication();

  return isLoading ? (
    <Progress />
  ) : (
    <div className={classes.root} style={preview ? { pointerEvents: "none" } : {}}>
      <CssBaseline />
      <PageContainer>
        <AppBar
          position="absolute"
          className={classes.appBar}
          style={preview ? { zIndex: 100 } : {}}
        >
          <Toolbar disableGutters>
            <Container style={{ display: "flex", alignItems: "center" }} maxWidth={false}>
              <Logo
                classes={{ logo: classes.logo }}
                logoUrl={logo?.url}
                style={logo?.size ? { height: logo.size } : {}}
              />
              <div style={{ flex: 1 }}></div>
              <Button
                id="logout-button"
                className={classes.logoutButton}
                onClick={() => logout("developer", { withPromptLogin: true })}
              >
                <LogOut color="white" style={{ marginRight: 12 }} />
                <Typography>Logout</Typography>
              </Button>
            </Container>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Suspense fallback={<PageContainer progress />}>
            <Routes>
              <Route path="/" element={<Applications />} />
              <Route path="/applications" element={<Applications />} />
              <Route path="/applications/:cid/:tab" element={<ApplicationController />} />
              {preview && (
                <Route path="/brand-appearance" element={<Applications preview={preview} />} />
              )}
            </Routes>
          </Suspense>
        </main>
      </PageContainer>
    </div>
  );
}

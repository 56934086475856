import React, { useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import every from "lodash/every";
import fromPairs from "lodash/fromPairs";
import groupBy from "lodash/groupBy";
import some from "lodash/some";

import { Claim } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import Dialog from "../../../common/components/Dialog";
import FormInputLabel from "../../../common/components/FormInputLabel";
import { notifySuccess } from "../../../common/components/notifications/notificationService";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import adminClaimsApi from "../../services/adminClaimsApi";
import { getClaimsQueryKey } from "../../services/adminClaimsQuery";
import { useWorkspace } from "../common/useWorkspace";

interface Props {
  idTokenClaims?: Claim[];
  accessTokenClaims?: Claim[];
  onClose: () => void;
}

export default function ChangeClaimsDialog({ idTokenClaims, accessTokenClaims, onClose }: Props) {
  const [progress, setProgress] = useState(false);
  const [workspace] = useWorkspace();

  const queryClient = useQueryClient();

  const allClaims = useMemo(
    () => [...(idTokenClaims || []), ...(accessTokenClaims || [])],
    [idTokenClaims, accessTokenClaims]
  );

  const initialData = useMemo(
    () => ({
      ...fromPairs(
        allClaims?.map((claim, index) => {
          if (claim.type === "id_token") {
            return [["id_token_claim_name_" + index], claim.name];
          }
          return [["access_token_claim_name_" + index], claim.name];
        })
      ),
    }),
    [allClaims]
  );

  const formFactory = useFormFactory({ id: "change-claims", data: initialData, progress });

  const handleSubmit = data => {
    setProgress(true);

    const promises: (() => Promise<any>)[] = [
      ...allClaims.map((claim, index) => {
        if (claim.type === "id_token") {
          return () =>
            adminClaimsApi.updateClaim({
              claim: (allClaims || [])[index]?.id!,
              updateClaimBody: {
                ...(allClaims || [])[index],
                name: data["id_token_claim_name_" + index],
              },
            });
        }

        return () =>
          adminClaimsApi.updateClaim({
            claim: (allClaims || [])[index]?.id!,
            updateClaimBody: {
              ...(allClaims || [])[index],
              name: data["access_token_claim_name_" + index],
            },
          });
      }),
    ];

    return Promise.allSettled(promises.map(p => p()))
      .then(results => {
        allClaims.forEach((claim, index) => {
          if (
            results[index].status === "rejected" &&
            (results[index] as any).reason?.response?.data?.error ===
              "claim type and name pair must be unique within server"
          ) {
            if (claim.type === "id_token") {
              formFactory.setError("id_token_claim_name_" + index, {
                message: "ID Token Claim Name is already used",
              });
            }

            if (claim.type === "access_token") {
              formFactory.setError("access_token_claim_name_" + index, {
                message: "Access Token Claim Name is already used",
              });
            }
          }
        });

        if (some(results, ["status", "fulfilled"])) {
          queryClient.invalidateQueries({ queryKey: getClaimsQueryKey(getTenantId(), workspace) });
        }

        if (some(results, ["status", "rejected"])) {
          return Promise.reject();
        }

        if (every(results, ["status", "fulfilled"])) {
          notifySuccess("Claims names updated successfully");
          setProgress(false);
          onClose();
        }
      })
      .finally(() => setProgress(false));
  };

  const grouped = groupBy(
    allClaims.map((claim, index) => ({ ...claim, _index: index })),
    "type"
  );

  return (
    <Dialog id="change-token-claim-names-dialog" title="Change Token Claim Names" onClose={onClose}>
      <FormContext.Provider value={formFactory.context}>
        {grouped["id_token"]?.length > 0 && (
          <div style={{ marginBottom: 32 }}>
            <FormInputLabel id="id-token-claim-names" label="ID Token Claim Name" />
            {grouped["id_token"].map(claim => {
              return formFactory.createRequiredField({
                name: "id_token_claim_name_" + claim._index,
                label: "ID Token Claim Name",
                hideLabel: true,
                style: { marginBottom: 16 },
              });
            })}
          </div>
        )}

        {grouped["access_token"]?.length > 0 && (
          <div style={{ marginBottom: 32 }}>
            <FormInputLabel id="access-token-claim-names" label="Access Token Claim Name" />
            {grouped["access_token"].map(claim => {
              return formFactory.createRequiredField({
                name: "access_token_claim_name_" + claim._index,
                label: "Access Token Claim Name",
                hideLabel: true,
                style: { marginBottom: 16 },
              });
            })}
          </div>
        )}

        {formFactory.createFormFooter({
          onCancel: onClose,
          onSubmit: handleSubmit,
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

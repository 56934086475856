import React, { CSSProperties } from "react";

import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { previewBasePath } from "../../../../common/api/paths";

const useStyles = makeStyles()(theme => ({
  preview: {
    border: "none",
    pointerEvents: "none",
  },
  previewUnavailableContainer: {
    background: "#F7FAFF",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "20%",
  },
  header: {
    color: theme.palette.secondary.dark,
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 12,
  },
  info: {
    marginBottom: 24,
    color: theme.palette.secondary.light,
  },
}));

interface Props {
  themeId?: string;
  selectedPath: string;
  previewPath: string;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  style?: CSSProperties;
  onLoad?: () => void;
}

export default function ThemeTemplatePreview({
  themeId,
  selectedPath,
  previewPath,
  iframeRef,
  style,
  onLoad,
}: Props) {
  const { classes } = useStyles();

  const isPreviewAvailable =
    selectedPath.startsWith("pages/") && selectedPath.endsWith("/index.tmpl");

  return (
    <>
      <div style={{ overflow: "auto", height: "calc(100% - 49px)", ...style }}>
        {!previewPath && (
          <div className={classes.previewUnavailableContainer}>
            <Typography className={classes.header}>Preview unavailable</Typography>
            <Typography className={classes.info}>
              Looks like we are unable to show the preview of the current file
            </Typography>
            <Alert severity="info">
              <Typography>
                Any modification in Shared Partial Templates affects all page template files
                referring to this template.
              </Typography>
            </Alert>
          </div>
        )}
        {(isPreviewAvailable || previewPath) && (
          <iframe
            src={`${previewBasePath}/preview?${
              themeId && themeId !== "default" ? `theme_id=${themeId}&` : ""
            }page=${previewPath.replace("pages/", "")}&preview=true`}
            width="100%"
            height="100%"
            title="preview"
            ref={iframeRef}
            className={classes.preview}
            onLoad={() => onLoad && onLoad()}
          />
        )}
      </div>
    </>
  );
}

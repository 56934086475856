import configureStore from "./configureStore";

let s = null;

export default function store(reducers) {
  if (!s) {
    s = configureStore(reducers);
  }

  return s;
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSSR = void 0;
exports.isSSR = (() => {
    const isBrowser = typeof document === "object" &&
        typeof (document === null || document === void 0 ? void 0 : document.getElementById) === "function";
    // Check for common testing framework global variables
    const isJest = typeof jest !== "undefined";
    const isMocha = typeof mocha !== "undefined";
    const isVitest = typeof __vitest_worker__ !== "undefined";
    return !isBrowser && !isJest && !isMocha && !isVitest;
})();

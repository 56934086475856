import React, { useEffect, useMemo, useState } from "react";

import RemoveIcon from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { lensPath, map, over, pathOr, prop } from "ramda";

import { ServerResponse, StaticIDP } from "@cloudentity/acp-admin";

import FormAccordion from "../../../common/components/FormAccordion";
import RouteLeavingGuard from "../../../common/components/RouteLeavingGuard";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import CardWithIconAndTitle from "../common/CardWithIconAndTitle";
import { CommonIdpConfig } from "./CommonIdpConfig";
import CommonIdpConfigUpper from "./CommonIdpConfigUpper";
import IdentitiesDetailsFooter from "./IdentitiesDetailsFooter";
import SSOIDPSettings from "./SSOIDPSettings";
import { amrOptions, amrToResponse, getAMRLabel } from "./amrOptions";
import { IdpUiModelStaticType, providers } from "./identities.utils";

const id = "identities-configuration-static";

interface Props {
  idp: IdpUiModelStaticType;
  server: ServerResponse | undefined;
  updateProgress?: boolean;
  inEdit?: boolean;
  onLogoEdit?: (data: any) => void;
  customSubmit?: boolean;
  onInit?: (fn: () => void) => void;
  onCancel?: () => void;
  onSubmit: (data: IdpUiModelStaticType) => Promise<StaticIDP>;
  onDelete?: (idp: IdpUiModelStaticType) => void;
}

export default function IdentitiesConfigurationStatic({
  idp,
  server,
  updateProgress,
  inEdit,
  onLogoEdit,
  customSubmit,
  onInit,
  onCancel,
  onSubmit,
  onDelete,
}: Props) {
  const data = useMemo(() => idp, [idp]);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(server?.id);

  const formFactory = useFormFactory({
    id,
    data,
    progress: updateProgress,
    mode: "onSubmit",
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit,
    shouldUnregister: true,
  });

  const [users, setUsers] = useState(
    (idp?.credentials as any)?.users || [
      { username: "", password: "", authentication_context: { name: "" } },
    ]
  );

  const submitFn = (idp, data) => {
    const dataWithIdp = {
      ...idp,
      ...amrToResponse(data),
      ...data,
    };

    return {
      ...dataWithIdp,
      ...over(
        lensPath(["credentials", "users"]),
        map((u: { username: string; password: string }) => ({
          ...u,
          authentication_context: { name: u.username },
        })),
        dataWithIdp
      ),
    };
  };

  const providerMapData = providers.find(p => p.method === "static");

  const addNewUser = () => {
    setUsers([...users, { username: "", password: "", authentication_context: { name: "" } }]);
  };

  const removeUser = index => {
    const list = [...users];
    list.splice(index, 1);
    setUsers(list);
  };

  useEffect(() => {
    onInit &&
      onInit(() => formFactory.handleSubmit(data => onSubmit && onSubmit(submitFn(idp, data))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serverSSOEnabled = server?.sso?.enabled ?? false;

  return (
    <FormContext.Provider value={formFactory.context}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Paper style={{ padding: 32 }}>
            {providerMapData && (
              <CardWithIconAndTitle
                img={providerMapData.icon}
                title={providerMapData.name}
                subtitle={providerMapData.description}
                id={`idp-${providerMapData.name.replace(/ /g, "-")}`}
                style={{ marginBottom: 32, width: "50%" }}
              />
            )}

            <CommonIdpConfigUpper
              formFactory={formFactory}
              provider={idp}
              inEdit={inEdit}
              onLogoEdit={onLogoEdit}
              onUpdate={onSubmit}
            />
            <CommonIdpConfig formFactory={formFactory} data={idp} />

            {!inEdit && (
              <>
                {users.map((user, index) => (
                  <Grid container key={`user-${index}`} spacing={2} wrap="nowrap">
                    <Grid item xs="auto" style={{ flex: 1 }}>
                      {formFactory.createRequiredField({
                        name: `credentials.users.${index}.username`,
                        label: "Username",
                        defaultValue: user.username,
                        hideLabel: index > 0,
                        style: { marginBottom: 24 },
                      })}
                    </Grid>
                    <Grid item xs="auto" style={{ flex: 1 }}>
                      {formFactory.createRequiredField({
                        name: `credentials.users.${index}.password`,
                        label: "Password",
                        defaultValue: user.password,
                        toggleVisibility: true,
                        defaultVisibility: false,
                        hideLabel: index > 0,
                        style: { marginBottom: 24 },
                      })}
                    </Grid>
                    {users.length > 1 && (
                      <Grid item xs={1}>
                        <IconButton
                          aria-label="delete"
                          style={{
                            ...(index === 0 ? { position: "relative", top: 40 } : {}),
                            right: 10,
                          }}
                          onClick={e => {
                            e.stopPropagation();
                            removeUser(index);
                          }}
                          id={`users-delete-button-${index}`}
                          size="large"
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                ))}
                <Button
                  id="add-new-user-button"
                  variant="text"
                  color="primary"
                  style={{ fontSize: 12, textTransform: "none" }}
                  onClick={addNewUser}
                >
                  + Add user
                </Button>
              </>
            )}

            {inEdit && (
              <FormAccordion title="Advanced settings" id={id}>
                {formFactory.createAutocompleteField({
                  name: "static_amr",
                  label: "Authentication Method Reference",
                  helperText: "If set overwrites AMR obtained from this authentication method",
                  defaultValue: pathOr([], ["static_amr"], idp),
                  options: map(prop("value"), amrOptions),
                  getOptionLabel: getAMRLabel,
                  multiple: true,
                })}
                {serverSSOEnabled && <SSOIDPSettings formFactory={formFactory} />}
              </FormAccordion>
            )}

            <IdentitiesDetailsFooter
              customSubmit={customSubmit}
              idp={idp}
              formFactory={formFactory}
              onSubmit={data => onSubmit && onSubmit(submitFn(idp, data))}
              onCancel={onCancel}
              onDelete={onDelete}
            />
          </Paper>
        </Grid>
        {inEdit && <RouteLeavingGuard />}
      </Grid>
    </FormContext.Provider>
  );
}

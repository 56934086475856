import React from "react";

import Tab from "@mui/material/Tab";
import omit from "lodash/omit";
import { drop } from "ramda";

import {
  GrantTenantRoleRequestRoleEnum,
  GrantTenantRoleRequestTypeEnum,
  IDPDiscoverySettings,
  WorkspacePermissionsResponse,
} from "@cloudentity/acp-admin";
import { IDP, IDPCredentials, IDPSettings } from "@cloudentity/acp-root";

import auth0Icon from "../../../assets/images/logos/idp/auth0-icon.svg";
import azureB2CIcon from "../../../assets/images/logos/idp/azure-b2c-icon.svg";
import azureIcon from "../../../assets/images/logos/idp/azure-icon.svg";
import awsIcon from "../../../assets/images/logos/idp/cognito-icon.svg";
import customIcon from "../../../assets/images/logos/idp/custom-icon.svg";
import entrustIcon from "../../../assets/images/logos/idp/entrust-icon.svg";
import externalIcon from "../../../assets/images/logos/idp/external-icon.svg";
import githubIcon from "../../../assets/images/logos/idp/github.svg";
import googleIcon from "../../../assets/images/logos/idp/google-icon.svg";
import identityPoolIcon from "../../../assets/images/logos/idp/identity-pool.svg";
import oktaIcon from "../../../assets/images/logos/idp/okta.svg";
import openidIcon from "../../../assets/images/logos/idp/openid-icon.svg";
import organizationIcon from "../../../assets/images/logos/idp/organization-icon.svg";
import samlIcon from "../../../assets/images/logos/idp/saml-icon.svg";
import sandboxIcon from "../../../assets/images/logos/idp/sandbox-icon.svg";
import saIcon from "../../../assets/images/logos/sa/sa_rgb_mark_blk.svg";
import { BRAND_NAME } from "../../../common/theme/theme";
import adminRolesApi from "../../services/adminRolesApi";
import { listTenantRoles } from "../../services/adminRolesQuery";
import { queryClient } from "../../services/queryClient";

export const splitAttrName = (name: string) => {
  const match = name.match(/^(access_token|id_token|user_data|saml_attributes|jit)\.(.+)$/);
  return match ? drop(1, match) : ["root", name];
};

export const splitAttrNameToSourceAndDisplayName = (attr: string) => {
  const [source, name] = splitAttrName(attr);

  switch (source) {
    case "saml_attributes": {
      return [source, name.replaceAll("\\.", ".")];
    }
    default: {
      return [source, name];
    }
  }
};

export const attrSourceAndDisplayNameToValue = (source: string, displayName: string) => {
  switch (source) {
    case "":
    case "root": {
      return displayName;
    }
    case "saml_attributes": {
      return `${source}.${displayName.replaceAll(".", "\\.")}`;
    }
    default: {
      return `${source}.${displayName}`;
    }
  }
};

export const identitiesDetailsPath = (workspace, type, id, tab) =>
  `/${workspace}/identities/${type}/${id}/${tab}`;

export const ProvidersType = {
  cloudentity: "cloudentity" as const,
  enterprise: "enterprise" as const,
  custom: "custom" as const,
};

const providerConfig = {};

export type ProviderMapperType = (typeof providers)[0];

export const providers = [
  {
    method: "cloudentity" as const,
    body: {
      method: "custom" as const,
      settings: {
        type: "cloudentity",
      },
      config: providerConfig,
    } as IdpUiModelCustomType,
    name: `${BRAND_NAME} IDP`,
    icon: saIcon,
    type: ProvidersType.cloudentity,
  },
  {
    method: "azure" as const,
    body: {
      method: "azure" as const,
      config: providerConfig,
    } as IdpUiModelAzureType,
    name: "Microsoft Azure AD",
    icon: azureIcon,
    type: ProvidersType.enterprise,
  },
  {
    method: "okta" as const,
    body: {
      method: "okta" as const,
      config: providerConfig,
    } as IdpUiModelOktaType,
    name: "Okta",
    icon: oktaIcon,
    type: ProvidersType.enterprise,
  },
  {
    method: "auth0" as const,
    body: {
      method: "auth0" as const,
      config: providerConfig,
    } as IdpUiModelAuth0Type,
    name: "Auth0",
    icon: auth0Icon,
    type: ProvidersType.enterprise,
  },
  {
    method: "google" as const,
    body: {
      method: "google" as const,
      config: providerConfig,
    } as IdpUiModelGoogleType,
    name: "Google",
    icon: googleIcon,
    type: ProvidersType.enterprise,
  },
  {
    method: "github" as const,
    body: {
      method: "github" as const,
      config: providerConfig,
    } as IdpUiModelGithubType,
    name: "GitHub",
    icon: githubIcon,
    type: ProvidersType.enterprise,
  },
  {
    method: "azureb2c" as const,
    body: {
      method: "azureb2c" as const,
      config: providerConfig,
    } as IdpUiModelAzureB2CType,
    name: "Microsoft Azure AD B2C",
    icon: azureB2CIcon,
    type: ProvidersType.enterprise,
  },
  {
    method: "cognito" as const,
    body: {
      method: "cognito" as const,
      config: providerConfig,
    } as IdpUiModelCognitoType,
    name: "Amazon Cognito",
    icon: awsIcon,
    type: ProvidersType.enterprise,
  },
  {
    method: "intelli_trust" as const,
    body: {
      method: "intelli_trust" as const,
      config: providerConfig,
    } as IdpUiModelIntelliTrustType,
    name: "Entrust",
    icon: entrustIcon,
    type: ProvidersType.enterprise,
  },
  {
    method: "oidc" as const,
    body: {
      method: "oidc" as const,
      config: providerConfig,
    } as IdpUiModelOidcType,
    name: "OpenID Connect",
    icon: openidIcon,
    type: ProvidersType.enterprise,
    description: "Configure OIDC compliant Provider",
  },
  {
    method: "saml" as const,
    body: {
      method: "saml" as const,
      config: providerConfig,
    } as IdpUiModelSamlType,
    name: "SAML",
    icon: samlIcon,
    type: ProvidersType.enterprise,
    description: "Configure SAML compliant Provider",
  },
  {
    method: "saml_v2" as const,
    body: {
      method: "saml_v2" as const,
      config: providerConfig,
    } as IdpUiModelSamlV2Type,
    name: "SAML",
    icon: samlIcon,
    type: ProvidersType.enterprise,
    isExternal: true,
    description: "Configure SAML compliant Provider",
  },
  {
    method: "static" as const,
    body: {
      method: "static" as const,
      config: providerConfig,
    } as IdpUiModelStaticType,
    name: "Sandbox IDP",
    description: "Configure a test account with mock data",
    icon: sandboxIcon,
    type: ProvidersType.custom,
  },
  {
    method: "custom" as const,
    body: {
      method: "custom" as const,
      settings: {
        type: "generic",
      },
      config: providerConfig,
    } as IdpUiModelCustomType,
    name: "Custom IDP",
    description: "Build non-OIDC or non-SAML IDP connections",
    icon: customIcon,
    type: ProvidersType.custom,
  },
  {
    method: "external" as const,
    body: {
      method: "external" as const,
      config: providerConfig,
    } as IdpUiModelExternalType,
    name: "External IDP",
    description: "Enable external datastore IDP",
    icon: externalIcon,
    type: ProvidersType.custom,
  },

  {
    method: "google_embedded" as const,
    body: {
      method: "google_embedded" as const,
      config: providerConfig,
    } as IdpUiModelGoogleType,
    name: "Google Embedded",
    icon: googleIcon,
    type: ProvidersType.enterprise,
  },
  {
    method: "github_embedded" as const,
    body: {
      method: "github_embedded" as const,
      config: providerConfig,
    } as IdpUiModelGithubType,
    name: "Github Embedded",
    icon: githubIcon,
    type: ProvidersType.enterprise,
  },
  {
    method: "identity_pool" as const,
    body: {
      method: "identity_pool" as const,
    } as IdpUiModelOtherType,
    name: "Identity Pool",
    icon: identityPoolIcon,
    type: ProvidersType.cloudentity,
    registerTitle: "Connect Identity Provider",
  },
  {
    method: "organization" as const,
    body: {
      method: "organization" as const,
    } as IdpUiModelOtherType,
    name: "Organization",
    icon: organizationIcon,
    type: ProvidersType.cloudentity,
    registerTitle: "Connect Organization",
  },
];

export const embeddedIdps = ["google_embedded", "github_embedded"];

export const getIdentityDetailsTabs = (
  idpMethod: string,
  permissions: WorkspacePermissionsResponse | undefined
) => {
  return [
    <Tab label="Configuration" value="configuration" key="configuration" id="idp-configuration" />,
    <Tab label="Attributes" value="attributes" key="attributes" id="idp-attributes" />,
    ...(idpMethod === "static"
      ? [<Tab label="Users" value="users" key="users" id="idp-users" />]
      : []),
    <Tab label="Mappings" value="mappings" key="mappings" id="idp-mappings" />,
    ...(["organization", "identity_pool"].includes(idpMethod)
      ? []
      : [
          <Tab
            label="Provisioning"
            value="provisioning"
            key="provisioning"
            id="idp-provisioning"
          />,
        ]),
    ...(permissions?.read_scripts || permissions?.read_custom_apps
      ? [<Tab label="Extensions" value="extensions" key="extensions" id="idp-extensions" />]
      : []),
  ];
};

type IdpUiModelBaseType = Omit<IDP, "display_order" | "discovery_settings" | "method"> & {
  display_order?: string | undefined;
  discovery_settings?:
    | (Omit<IDPDiscoverySettings, "domains"> & { domains: { value: string }[] })
    | undefined;
  redirectUrl?: string | undefined;
};

export type IdpUiModelAuth0Type = IdpUiModelBaseType & {
  method: "auth0";
  settings?: IDPSettings["auth0"];
  credentials?: IDPCredentials["auth0"];
};

export type IdpUiModelAzureType = IdpUiModelBaseType & {
  method: "azure";
  settings?: IDPSettings["azure"];
  credentials?: IDPCredentials["azure"];
};

export type IdpUiModelAzureB2CType = IdpUiModelBaseType & {
  method: "azureb2c";
  settings?: IDPSettings["azureb2c"];
  credentials?: IDPCredentials["azureb2c"];
};

export type IdpUiModelCognitoType = IdpUiModelBaseType & {
  method: "cognito";
  settings?: IDPSettings["cognito"];
  credentials?: IDPCredentials["cognito"];
};

export type IdpUiModelCustomType = IdpUiModelBaseType & {
  method: "custom";
  settings?: IDPSettings["custom"];
  credentials?: IDPCredentials["custom"];
};

export type IdpUiModelExternalType = IdpUiModelBaseType & {
  method: "external";
  settings?: IDPSettings["external"];
  credentials?: IDPCredentials["external"];
};

export type IdpUiModelGithubType = IdpUiModelBaseType & {
  method: "github" | "github_embedded";
  settings?: IDPSettings["github"];
  credentials?: IDPCredentials["github"];
};

export type IdpUiModelGoogleType = IdpUiModelBaseType & {
  method: "google" | "google_embedded";
  settings?: IDPSettings["google"];
  credentials?: IDPCredentials["google"];
};

export type IdpUiModelIntelliTrustType = IdpUiModelBaseType & {
  method: "intelli_trust";
  settings?: IDPSettings["intelli_trust"];
  credentials?: IDPCredentials["intelli_trust"];
};

export type IdpUiModelOidcType = IdpUiModelBaseType & {
  method: "oidc";
  settings?: IDPSettings["oidc"];
  credentials?: IDPCredentials["oidc"];
};

export type IdpUiModelOktaType = IdpUiModelBaseType & {
  method: "okta";
  settings?: IDPSettings["okta"];
  credentials?: IDPCredentials["okta"];
};

export type IdpUiModelSamlType = IdpUiModelBaseType & {
  method: "saml";
  settings?: IDPSettings["saml"];
  credentials?: IDPCredentials["saml"];
};

export type IdpUiModelSamlV2Type = IdpUiModelBaseType & {
  method: "saml_v2";
  settings?: IDPSettings["saml_v2"];
  credentials?: IDPCredentials["saml_v2"];
};

export type IdpUiModelStaticType = IdpUiModelBaseType & {
  method: "static";
  settings?: IDPSettings["_static"];
  credentials?: IDPCredentials["_static"];
};

export type IdpUiModelOtherType = IdpUiModelBaseType & {
  method: "identity_pool" | "organization";
};

export type IdpUiModelType =
  | IdpUiModelAuth0Type
  | IdpUiModelAzureType
  | IdpUiModelAzureB2CType
  | IdpUiModelCognitoType
  | IdpUiModelCustomType
  | IdpUiModelExternalType
  | IdpUiModelGithubType
  | IdpUiModelGoogleType
  | IdpUiModelIntelliTrustType
  | IdpUiModelOidcType
  | IdpUiModelOktaType
  | IdpUiModelSamlType
  | IdpUiModelSamlV2Type
  | IdpUiModelStaticType
  | IdpUiModelOtherType;

export function transformIdpToModel(idp: IDP, issuerUrl: string): IdpUiModelType {
  return {
    ...idp,
    display_order: idp.display_order?.toString(10),
    discovery_settings: {
      ...idp.discovery_settings,
      domains: idp.discovery_settings?.domains?.map(v => ({ value: v })) ?? [],
    },
    redirectUrl: idp.authorization_server_id ? `${issuerUrl}/login` : "",
    method: idp.method as any,
    settings: idp.settings as any,
    credentials: idp.credentials as any,
  };
}

export const transformIdpToAPI = (idp: IdpUiModelType) => {
  if (!idp) return idp;

  return {
    ...omit(idp, "redirectUrl"),
    discovery_settings: {
      ...idp.discovery_settings,
      domains:
        idp?.discovery_settings?.domains?.map(d => (typeof d === "string" ? d : d.value)) ?? [],
    },
    display_order:
      typeof idp.display_order === "string" ? parseInt(idp.display_order, 10) : idp.display_order,
  };
};

export type IdentityUserView = "list" | "create";

export const grantTenantMemberRoleForIDP = (wid: string, idpId: string, tenantId: string) =>
  adminRolesApi
    .grantTenantRole({
      request: {
        workspace_id: wid,
        role: GrantTenantRoleRequestRoleEnum.Member,
        idp_id: idpId,
        tenant_id: tenantId,
        type: GrantTenantRoleRequestTypeEnum.Idp,
      },
    })
    .then(() => queryClient.invalidateQueries({ queryKey: listTenantRoles() }));

export function addRedirectUrl<T extends ProviderMapperType>(provider: T, redirectUrl: string): T {
  return { ...provider, body: { ...provider.body, redirectUrl } };
}

export function addName<T extends ProviderMapperType>(provider: T): T {
  return { ...provider, body: { ...provider.body, name: provider.name } };
}

import React from "react";

import { Claim } from "@cloudentity/acp-admin";

import Dialog from "../../../../common/components/Dialog";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { validators } from "../../../../common/utils/forms/validation";

export interface OAuthVerifiedClaimsCreateProps {
  workspace: string;
  existingClaims: (string | undefined)[];
  onCreate: (claims: Claim[]) => void;
  onCancel: () => void;
  progress: boolean;
}

const OAuthVerifiedClaimsCreate = ({
  workspace,
  existingClaims,
  onCreate,
  onCancel,
  progress,
}: OAuthVerifiedClaimsCreateProps) => {
  const formFactory = useFormFactory({ id: "add-claim", progress });

  const handleCreate = data => {
    const claim = {
      authorization_server_id: workspace,
      name: data.name,
      description: data.description,
      source_path: data.name,
      type: "id_token",
      verified: true,
      source_type: "verifiedAuthnCtx",
    };
    onCreate([{ ...claim }]);
  };

  return (
    <Dialog onClose={onCancel} id="add-claim-dialog" title="Add verified claim">
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createRequiredField({
          name: "name",
          label: "Name",
          rules: {
            validate: {
              notUniq: validators.notUniq({ label: "Name", options: existingClaims }),
            },
          },
          autoFocus: true,
        })}
        {formFactory.createRequiredField({
          name: "description",
          label: "Description",
        })}
        {formFactory.createFormFooter({ onCancel, onSubmit: handleCreate, submitText: "Add" })}
      </FormContext.Provider>
    </Dialog>
  );
};

export default OAuthVerifiedClaimsCreate;

import React, { useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { GrantWorkspaceRoleRequestTypeEnum } from "@cloudentity/acp-admin";
import {
  NewUserIdentifierTypeEnum,
  NewUserPayloadStatusEnum,
  NewUserVerifiableAddressStatusEnum,
  NewUserVerifiableAddressTypeEnum,
  UserWithData,
} from "@cloudentity/acp-identity";

import { getTenantId } from "../../../common/api/paths";
import Dialog from "../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import { useFeature } from "../../../common/utils/hooks/useFeature";
import { useListIDPs } from "../../services/adminIDPsQuery";
import identityUsersApi from "../../services/adminIdentityUsersApi";
import { getUserQueryKey, listUsersQueryKey } from "../../services/adminIdentityUsersQuery";
import adminRolesApi from "../../services/adminRolesApi";
import {
  listUserRoles,
  listWorkspaceRoles,
  useListWorkspaceRoles,
} from "../../services/adminRolesQuery";
import { useGetAuthorizationServer } from "../../services/adminServersQuery";
import { useWorkspace } from "../common/useWorkspace";
import { BUILD_IN_ADMIN_POOL_ID } from "../workspaceDirectory/administrator/AdministratorManagement";
import WorkspaceRoleSelectField from "../workspaceDirectory/administrator/WorkspaceRoleSelectField";
import IdentityPoolPayloadSchemaForm from "./IdentityPoolPayloadSchemaForm";
import InviteAdminPoolSelect from "./InviteAdminPoolSelect";
import InviteAdministratorEmailField from "./InviteAdministratorEmailField";
import { UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles } from "./WorkspaceAdministratorsTable";
import { serverTypeToRoleName, serverTypeToRoleOptions } from "./workspaceRoleOptions";

interface Props {
  usersAndPoolsAndRoles: UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles[];
  onCreated: () => void;
  onCancel: () => void;
}

export default function InviteWorkspaceAdministratorDialog({
  usersAndPoolsAndRoles,
  onCreated,
  onCancel,
}: Props) {
  const [workspace] = useWorkspace();
  const isAdminWorkspaceAccessEnabled = useFeature("admin_workspace_access");

  const [progress, setProgress] = useState(false);
  const [payload, setPayload] = useState({});
  const [isPayloadValid, setIsPayloadValid] = useState(true);
  const [submitAttempt, setSubmitAttempt] = useState(false);

  const queryClient = useQueryClient();

  const listWorkspaceRolesQuery = useListWorkspaceRoles(workspace);
  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace);

  const idpsQuery = useListIDPs(getTenantId(), "admin");
  const idpsWithIdentityPoolConnected = useMemo(
    () => (idpsQuery.data?.idps || []).filter(idp => !!idp.identity_pool_id),
    [idpsQuery.data]
  );

  const initialData = useMemo(
    () => ({
      identity_pool_id: isAdminWorkspaceAccessEnabled
        ? idpsWithIdentityPoolConnected.at(0)?.identity_pool_id || null
        : BUILD_IN_ADMIN_POOL_ID,
      user: null,
      role: "",
      roles: "",
      given_name: "",
      family_name: "",
    }),
    [isAdminWorkspaceAccessEnabled, idpsWithIdentityPoolConnected]
  );

  const formFactory = useFormFactory({
    id: "manage-access-add-identity-pool-user",
    progress,
    data: initialData,
  });

  const setValue = formFactory.setValue;
  const clearErrors = formFactory.clearErrors;

  const identityPoolId = formFactory.watch("identity_pool_id");
  const selectedUser = formFactory.watch("user");

  const isNewUser = !!selectedUser && typeof selectedUser === "string";

  const handleInviteAdmin = submitData => {
    setSubmitAttempt(true);

    if (!isNewUser && !isPayloadValid) {
      return;
    }

    setProgress(true);

    const poolId = isAdminWorkspaceAccessEnabled
      ? submitData.identity_pool_id || initialData.identity_pool_id
      : BUILD_IN_ADMIN_POOL_ID;
    let createdUser: UserWithData;

    const createUserOrResolve = () => {
      if (!isNewUser) {
        return Promise.resolve(selectedUser);
      }

      const req = {
        ipID: poolId,
        newUser: {
          payload:
            poolId === BUILD_IN_ADMIN_POOL_ID
              ? {
                  given_name: submitData.given_name?.trim(),
                  family_name: submitData.family_name?.trim(),
                }
              : payload,
          metadata: {},
          status: NewUserPayloadStatusEnum.New,
          credentials: [],
          identifiers: [
            {
              identifier: submitData.user.trim(),
              type: NewUserIdentifierTypeEnum.Email,
            },
          ],
          verifiable_addresses: [
            {
              address: submitData.user.trim(),
              status: NewUserVerifiableAddressStatusEnum.Active,
              type: NewUserVerifiableAddressTypeEnum.Email,
              verified: false,
            },
          ],
        },
      };

      return identityUsersApi
        .createUser(req)
        .then(res => {
          createdUser = res.data;
          return queryClient.setQueryData(getUserQueryKey(getTenantId(), res.data.id), res.data);
        })
        .then(() =>
          queryClient.invalidateQueries({ queryKey: listUsersQueryKey(getTenantId(), poolId) })
        )
        .then(() =>
          identityUsersApi
            .sendActivationMessage({
              ipID: poolId,
              userID: createdUser.id!,
              serverId: "admin",
            })
            .then(() => {
              notifySuccess(
                <span>
                  Invitation sent to <strong>{submitData.user.trim()}</strong>
                </span>
              );
            })
        );
    };

    return createUserOrResolve()
      .then(() =>
        adminRolesApi.grantWorkspaceRole({
          wid: workspace,
          request: {
            identity_pool_id: poolId,
            identity_pool_user_id: isNewUser ? createdUser.id : submitData.user?.id,
            tenant_id: getTenantId(),
            type: GrantWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
            role: submitData.roles,
            workspace_id: "admin",
          },
        })
      )
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspaceRoles(workspace) }))
      .then(() => {
        if (isNewUser) {
          return queryClient.invalidateQueries({
            queryKey: listUserRoles(createdUser.user_pool_id, createdUser.id),
          });
        }
        return queryClient.invalidateQueries({
          queryKey: listUserRoles(submitData.user?.user_pool_id, submitData.user?.id),
        });
      })
      .then(() => {
        if (!isNewUser) {
          notifySuccess(
            <span>
              {serverTypeToRoleName(undefined, submitData.roles)} access granted to{" "}
              <strong>
                {(submitData.user?.identifiers || []).join(", ")}{" "}
                {submitData.user?.payload?.given_name} {submitData.user?.payload?.family_name}
              </strong>
            </span>
          );
        }
      })
      .then(() => onCreated())
      .catch(err => {
        if (err.response?.status === 409) {
          return formFactory.setError(
            "user",
            {
              message: "User with given email already exists",
            },
            { shouldFocus: true }
          );
        }
        notifyErrorOrDefaultTo("Error occurred when trying to grant workspace role")(err);
      })
      .finally(() => {
        setProgress(false);
      });
  };

  useEffect(() => {
    if (!!selectedUser && typeof selectedUser === "object") {
      clearErrors("given_name");
      clearErrors("family_name");
      setValue("given_name", selectedUser.payload?.given_name);
      setValue("family_name", selectedUser.payload?.family_name);
      setPayload(selectedUser.payload);
    } else if (selectedUser === null) {
      setValue("given_name", "");
      setValue("family_name", "");
      setPayload({});
    }
  }, [selectedUser, setValue, clearErrors]);

  return (
    <Dialog
      onClose={onCancel}
      id="invite-workspace-administrator-dialog"
      fullWidth
      maxWidth="sm"
      title="Invite workspace administrator"
    >
      <FormContext.Provider value={formFactory.context}>
        <div>
          <InviteAdminPoolSelect idps={idpsWithIdentityPoolConnected} formFactory={formFactory} />
          <InviteAdministratorEmailField
            formFactory={formFactory}
            poolId={identityPoolId}
            usersAndPoolsAndRoles={usersAndPoolsAndRoles}
          />
          {selectedUser && identityPoolId === BUILD_IN_ADMIN_POOL_ID && (
            <>
              {formFactory.createRequiredField({
                name: "given_name",
                label: "First name",
                disabled: !isNewUser,
                optional: false,
                autoFocus: isNewUser,
              })}
              {formFactory.createRequiredField({
                name: "family_name",
                label: "Last name",
                disabled: !isNewUser,
                optional: false,
              })}
            </>
          )}
          {selectedUser && identityPoolId !== BUILD_IN_ADMIN_POOL_ID && (
            <div style={{ marginBottom: 22 }}>
              <IdentityPoolPayloadSchemaForm
                poolId={identityPoolId}
                payload={payload}
                onChange={setPayload}
                disabled={!isNewUser}
                submitAttempt={submitAttempt}
                onValidate={result => setIsPayloadValid(result.errors.length === 0)}
              />
            </div>
          )}

          <WorkspaceRoleSelectField
            formFactory={formFactory}
            wid={workspace}
            poolId={identityPoolId}
            workspaceSubjects={listWorkspaceRolesQuery.data?.subjects}
            multiple={false}
            label="Role"
            roleOptions={serverTypeToRoleOptions(serverQuery.data?.type)}
            isInviteWorkspaceAdministrator
          />

          {formFactory.createFormFooter({
            onCancel,
            onSubmit: handleInviteAdmin,
            submitText: "Invite",
            disabled: !identityPoolId,
          })}
        </div>
      </FormContext.Provider>
    </Dialog>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import debounce from "lodash/debounce";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import { Edit } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { PoolResponse } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../common/api/paths";
import { useFormContext } from "../../../../common/utils/forms2/Form";
import AutocompleteField from "../../../../common/utils/forms/AutocompleteField";
import { useFeature } from "../../../../common/utils/hooks/useFeature";
import { useListPools, useListWorkspacePools } from "../../../services/adminIdentityPoolsQuery";
import { BUILD_IN_ADMIN_POOL_ID } from "../../workspaceDirectory/administrator/AdministratorManagement";

const useStyles = makeStyles()(theme => ({
  header: {
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    color: theme.palette.secondary.light,
    maxWidth: 480,
  },
  headerPool: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  group: {
    paddingLeft: 0,
  },
  groupLabel: {
    fontSize: 12,
    lineHeight: "18px",
    padding: "8px 12px",
    color: theme.palette.secondary.light,
    background: "#FBFCFD",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    margin: "0 8px 0 4px",
    width: 24,
    height: 24,
    fontSize: 12,
  },
}));

const getGroupByLabels = (group: string) => {
  switch (group) {
    case "tenant":
      return "Other pools";
    case "workspace":
      return "Pools from this workspace";
    default:
      return null;
  }
};

type PoolSelectorOptionType = Pick<PoolResponse, "id" | "name" | "workspace_id" | "tenant_id"> & {
  groupType: string;
};

interface Props {
  serverId: string;
}

export default function IdentitiesDetailsProvisioningPoolSelector({ serverId }: Props) {
  const { classes } = useStyles();
  const adminWorkspaceAccessEnabled = useFeature("admin_workspace_access");
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const { form } = useFormContext();
  const pool = form.watch("pool");
  const provisioning = form.watch("provisioning");

  const allPoolsQuery = useListPools({
    tid: getTenantId(),
    limit: 100,
  });

  const poolsWithSearchQuery = useListPools({
    tid: getTenantId(),
    searchPhrase: searchText,
  });

  const listAllWorkspacePoolsQuery = useListWorkspacePools({
    wid: serverId,
  });
  const listWorkspaceWithSearchPoolsQuery = useListWorkspacePools({
    wid: serverId,
    searchPhrase: searchText,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((searchText: string) => {
      setSearchText(searchText);
    }, 250),
    []
  );

  const sortPoolsByName = (pools: PoolResponse[]) => sortBy(pools, p => p.name);

  const pools = uniqBy(
    [
      ...sortPoolsByName(poolsWithSearchQuery.data?.pools || []),
      ...sortPoolsByName(allPoolsQuery.data?.pools || []),
    ],
    p => p.id
  );

  const tenantPools = pools
    .map(pool => ({ ...pool, groupType: "tenant" }))
    .filter(pool => (serverId !== "admin" ? pool.id !== BUILD_IN_ADMIN_POOL_ID : true));

  const workspacePools = uniqBy(
    [
      ...sortPoolsByName(listWorkspaceWithSearchPoolsQuery.data?.pools || []),
      ...sortPoolsByName(listAllWorkspacePoolsQuery.data?.pools || []),
    ],
    p => p.id
  ).map(pool => ({
    ...pool,
    groupType: "workspace",
  }));

  const groupedPools = [...workspacePools, ...tenantPools].filter(pool =>
    adminWorkspaceAccessEnabled ? true : pool.id !== BUILD_IN_ADMIN_POOL_ID
  );

  useEffect(() => {
    if (
      !pool &&
      serverId === "admin" &&
      groupedPools.find(pool => pool.id === BUILD_IN_ADMIN_POOL_ID)
    ) {
      const buildInAdminPool = groupedPools.find(pool => pool.id === BUILD_IN_ADMIN_POOL_ID);
      form.setValue("pool", { id: buildInAdminPool?.id, name: buildInAdminPool?.name });
    } else if (
      !pool &&
      groupedPools.length === 1 &&
      groupedPools.length &&
      provisioning === "enabled"
    ) {
      const selectedPool = groupedPools[0];
      form.setValue("pool", { id: selectedPool?.id, name: selectedPool?.name });
    }
  }, [workspacePools, provisioning, form, groupedPools, pool, serverId]);

  if (listAllWorkspacePoolsQuery.isLoading || allPoolsQuery.isLoading) {
    return null;
  }

  return (
    <>
      <Grid item xs={12} lg={6} style={{ display: groupedPools.length === 1 ? "none" : "" }}>
        <Typography variant="h5" className={classes.header}>
          Identity Pool
        </Typography>
        <Typography variant="textMD" className={classes.headerText} component="div">
          User identities who sign-in with this identity provider are persisted in this pool.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        className={classes.headerPool}
        style={{ display: groupedPools.length === 1 ? "none" : "" }}
      >
        <AutocompleteField<PoolSelectorOptionType>
          id="pool-autocomplete"
          name="pool"
          options={groupedPools.map(pool => ({
            id: pool.id,
            name: pool.name,
            workspace_id: pool.workspace_id,
            tenant_id: pool.tenant_id,
            groupType: pool.groupType,
          }))}
          aria-label="identity pool select"
          getOptionLabel={pool => (typeof pool === "string" ? pool : pool.name)}
          renderOption={(props, option) => (
            <li
              {...props}
              key={option.id}
              style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
            >
              {typeof option === "string" ? option : option.name}
              <IconButton
                onClick={e =>
                  option.groupType === "workspace"
                    ? navigate(`/${option.workspace_id}/pools/${option.id}/users`)
                    : navigate(`/identity-pools/pools/${option.id}/users`)
                }
                size="large"
              >
                <Edit size="16px" />
              </IconButton>
            </li>
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterSelectedOptions={false}
          loading={
            allPoolsQuery.isLoading ||
            poolsWithSearchQuery.isLoading ||
            listAllWorkspacePoolsQuery.isLoading ||
            listWorkspaceWithSearchPoolsQuery.isLoading
          }
          groupBy={pool => pool.groupType}
          renderGroup={params => (
            <li key={params.key}>
              <div className={classes.groupLabel}>{getGroupByLabels(params.group)}</div>
              <ul className={classes.group}>{params.children}</ul>
            </li>
          )}
          onInputChange={(_, inputValue) => {
            handleSearch(inputValue);
          }}
          onChange={(_, v) => {
            form.setValue("pool", {
              id: (v as unknown as PoolSelectorOptionType)?.id! || "",
              name: (v as unknown as PoolSelectorOptionType)?.name! || "",
            });
          }}
          afterChange={() => form.clearErrors()}
          rules={{ required: "Pool is required" }}
          disableClearable
          startAdornment={
            pool?.id ? (
              <Avatar className={classes.avatar}>{pool.name?.[0]?.toUpperCase() ?? "P"}</Avatar>
            ) : undefined
          }
          placeholder="Select pool"
        />
      </Grid>
    </>
  );
}

import React, { lazy, Suspense, useEffect, useState } from "react";
import { Mode, useForm, UseFormReturn } from "react-hook-form";

import Progress from "../../components/Progress";
import { Context } from "../forms2/Form";
import AutocompleteField, { AutocompleteFieldProps } from "./AutocompleteField";
import CheckboxField, { CheckboxFieldProps } from "./CheckboxField";
import ChipsGroupField, { ChipsGroupFieldProps } from "./ChipsGroupField";
import ColorField, { ColorFieldProps } from "./ColorField";
import CommonTextField, { CommonTextFieldProps } from "./CommonTextField";
import ComplexToggle, { ComplexToggleProps } from "./ComplexToggle";
import DurationField, { DurationFieldProps } from "./DurationField";
import EditorInput, { EditorInputProps } from "./EditorInput";
import FormCards, { FormCardsProps } from "./FormCards";
import FormFooter, { FormFooterProps } from "./FormFooter";
import NumberField, { NumberFieldProps } from "./NumberField";
import OTPInput, { OTPInputProps } from "./OTPInput";
import { PolicySelectionField } from "./PolicySelectionField";
import RadioGroupField, { RadioGroupFieldProps } from "./RadioGroupField";
import SelectField, { SelectFieldProps } from "./SelectField";
import SliderField, { SliderFieldProps } from "./SliderField";
import SwitchBlock, { SwitchBlockProps } from "./SwitchBlock";
import SwitchField, { SwitchFieldProps } from "./SwitchField";
import UploadField, { UploadFieldProps } from "./UploadField";
import XMLEditorInput, { XMLEditorInputProps } from "./XMLEditorInput";
import { validators } from "./validation";

const PhoneInput = lazy(() => import("./PhoneInput"));

export interface FormFactory extends UseFormReturn<any> {
  createRequiredField: (props: CommonTextFieldProps) => JSX.Element;
  createField: (props: CommonTextFieldProps) => JSX.Element;
  createSelect: (props: SelectFieldProps) => JSX.Element;
  createCheckBox: (props: CheckboxFieldProps) => JSX.Element;
  createSwitch: (props: SwitchFieldProps) => JSX.Element;
  createSwitchBlock: (props: SwitchBlockProps) => JSX.Element;
  createEditorField: (props: EditorInputProps) => JSX.Element;
  createXMLEditorField: (props: XMLEditorInputProps) => JSX.Element;
  createAutocompleteField: <T>(props: AutocompleteFieldProps<T>) => JSX.Element;
  createReadOnlyField: (props: CommonTextFieldProps) => JSX.Element;
  createComplexToggle: (props: Omit<ComplexToggleProps, "form">) => JSX.Element;
  createFormFooter: (props: FormFooterProps) => JSX.Element;
  createDurationField: (props: Omit<DurationFieldProps, "form">) => JSX.Element;
  createColorField: (props: Omit<ColorFieldProps, "id" | "form">) => JSX.Element;
  createChipsGroupField: (props: ChipsGroupFieldProps) => JSX.Element;
  createPolicySelection;
  createPhoneInput;
  createNumberField: (props: Omit<NumberFieldProps, "form">) => JSX.Element;
  createRadioGroupField: (props: Omit<RadioGroupFieldProps, "id" | "form">) => JSX.Element;
  createFormCardsField: (props: Omit<FormCardsProps, "form">) => JSX.Element;
  createSliderField: (props: Omit<SliderFieldProps, "id" | "form">) => JSX.Element;
  createUploadField: (props: UploadFieldProps) => JSX.Element;
  createOtpField: (props: Omit<OTPInputProps, "id" | "form">) => JSX.Element;
  setSubmitButtonDisabled: (disabled: boolean) => void;
  isDisabled: (props?: { disabled: boolean }) => boolean;
  context: Context<any>;
}

export const useFormFactory = ({
  id,
  data,
  progress,
  mode = "onTouched",
  disabled,
  noManagePermission,
  submitDisabledOnInit,
  shouldUnregister = false,
}: {
  id: string;
  data?: any;
  progress?: boolean;
  mode?: Mode;
  disabled?: boolean;
  noManagePermission?: boolean;
  submitDisabledOnInit?: boolean;
  shouldUnregister?: boolean;
}): FormFactory => {
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const form = useForm<any>({
    values: data,
    defaultValues: data,
    mode,
    shouldUnregister,
  });

  useEffect(() => {
    data && form.reset(data, { keepDefaultValues: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, form.reset]);

  useEffect(() => {
    setSubmitDisabled(submitDisabledOnInit || false);
  }, [submitDisabledOnInit]);

  const isDisabled = (props?: any) => progress || props?.disabled || disabled || noManagePermission;

  const createRequiredField = ({ rules, ...rest }: CommonTextFieldProps) => (
    <CommonTextField
      rules={{
        ...rules,
        required: `${rest.label} is required`,
        validate: {
          maxLength: validators.maxLength({ label: rest.label }),
          ...rules?.validate,
        },
      }}
      {...rest}
      disabled={isDisabled(rest)}
    />
  );

  const createField = ({ rules, ...rest }: CommonTextFieldProps) => (
    <CommonTextField
      rules={{
        ...rules,
        validate: {
          maxLength: validators.maxLength({ label: rest.label }),
          ...rules?.validate,
        },
      }}
      {...rest}
      disabled={isDisabled(rest)}
    />
  );

  const createSelect = (props: SelectFieldProps) => (
    <SelectField {...props} disabled={isDisabled(props)} />
  );

  const createEditorField = (props: EditorInputProps) => (
    <EditorInput {...props} disabled={isDisabled(props)} />
  );

  const createXMLEditorField = (props: XMLEditorInputProps) => (
    <XMLEditorInput {...props} disabled={isDisabled(props)} />
  );

  const createCheckBox = (props: CheckboxFieldProps) => (
    <CheckboxField {...props} disabled={isDisabled(props)} />
  );

  const createSwitch = (props: SwitchFieldProps) => (
    <SwitchField {...props} disabled={isDisabled(props)} />
  );

  const createSwitchBlock = (props: SwitchBlockProps) => (
    <SwitchBlock {...props} disabled={isDisabled(props)} />
  );

  const createComplexToggle = (props: Omit<ComplexToggleProps, "form">) => (
    <ComplexToggle
      id={id}
      form={form}
      {...props}
      progress={progress || props.progress}
      disabled={isDisabled(props)}
    />
  );

  const createReadOnlyField = (props: CommonTextFieldProps) => (
    <CommonTextField {...props} disabled optional={false} />
  );

  const createFormFooter = (props: FormFooterProps) => <FormFooter {...props} />;

  const createAutocompleteField = <T,>(props: AutocompleteFieldProps<T>) => (
    <AutocompleteField {...props} disabled={isDisabled(props)} />
  );

  const createDurationField = (props: Omit<DurationFieldProps, "id" | "form">) => (
    <DurationField id={id} form={form} {...props} disabled={isDisabled(props)} />
  );

  const createColorField = (props: Omit<ColorFieldProps, "id" | "form">) => (
    <ColorField id={id} form={form} {...props} disabled={isDisabled(props)} />
  );

  const createChipsGroupField = (props: ChipsGroupFieldProps) => (
    <ChipsGroupField {...props} disabled={isDisabled(props)} />
  );

  const createPolicySelection = props => (
    <PolicySelectionField id={id} form={form} {...props} disabled={isDisabled(props)} />
  );

  const createPhoneInput = props => (
    <div style={{ position: "relative", minHeight: 89 }}>
      <Suspense fallback={<Progress size={32} style={{ top: 14 }} />}>
        <PhoneInput
          id={id}
          form={form}
          validate={{
            validPhone: validators.validPhone({
              label: "Phone",
              required: props.required,
              selector: props.selector,
            }),
          }}
          {...props}
          disabled={isDisabled(props)}
        />
      </Suspense>
    </div>
  );

  const createNumberField = (props: Omit<NumberFieldProps, "id" | "form">) => (
    <NumberField id={id} form={form} {...props} disabled={isDisabled(props)} />
  );

  const createRadioGroupField = (props: Omit<RadioGroupFieldProps, "id" | "form">) => (
    <RadioGroupField id={id} form={form} {...props} disabled={isDisabled(props)} />
  );

  const createFormCardsField = (props: Omit<FormCardsProps, "id" | "form">) => (
    <FormCards id={id} form={form} {...props} disabled={isDisabled(props)} />
  );

  const createSliderField = (props: Omit<SliderFieldProps, "id" | "form">) => (
    <SliderField id={id} form={form} {...props} disabled={isDisabled(props)} />
  );

  const createUploadField = (props: UploadFieldProps) => (
    <UploadField {...props} disabled={isDisabled(props)} />
  );

  const createOtpField = (props: Omit<OTPInputProps, "id" | "form">) => (
    <OTPInput id={id} form={form} {...props} disabled={isDisabled(props)} />
  );

  return {
    ...form,
    createRequiredField,
    createField,
    createSelect,
    createCheckBox,
    createSwitch,
    createSwitchBlock,
    createEditorField,
    createXMLEditorField,
    createAutocompleteField,
    createReadOnlyField,
    createComplexToggle,
    createFormFooter,
    createDurationField,
    createColorField,
    createChipsGroupField,
    createPolicySelection,
    createPhoneInput,
    createNumberField,
    createRadioGroupField,
    createFormCardsField,
    createSliderField,
    createUploadField,
    createOtpField,
    setSubmitButtonDisabled: setSubmitDisabled,
    isDisabled: isDisabled,
    context: {
      form,
      initialValues: data,
      progress,
      disabled: progress || disabled || noManagePermission,
      submitButtonDisabled: submitDisabled,
      setSubmitButtonDisabled: setSubmitDisabled,
      id,
      noManagePermission,
    },
  };
};

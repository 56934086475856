import React from "react";

import { Claim } from "@cloudentity/acp-admin";

import Dialog from "../../../../common/components/Dialog";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { validators } from "../../../../common/utils/forms/validation";

export interface OAuthVerifiedClaimsEditProps {
  model: Claim & { metadata_key?: string };
  existingClaims: string[];
  onEdit: (claim: Claim) => void;
  onCancel: () => void;
  progress: boolean;
}

export default function OAuthVerifiedClaimsEdit({
  model,
  existingClaims,
  onEdit,
  onCancel,
  progress,
}: OAuthVerifiedClaimsEditProps) {
  const formFactory = useFormFactory({ id: "edit-claim", data: model, progress });

  const handleEdit = data => {
    const claim = {
      ...model,
      name: data.name,
      description: data.description,
    };
    onEdit(claim);
  };

  return (
    <Dialog onClose={onCancel} id="edit-claim-dialog" title="Edit verified claim">
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createRequiredField({
          name: "name",
          label: "Name",
          rules: {
            validate: {
              notUniq: validators.notUniq({
                label: "Name",
                options: existingClaims.filter(v => v !== model.name),
              }),
            },
          },
          inputProps: {
            id: "claim-name-input",
          },
        })}
        {formFactory.createRequiredField({
          name: "description",
          label: "Description",
        })}

        {formFactory.createFormFooter({ onCancel, onSubmit: handleEdit, submitText: "Update" })}
      </FormContext.Provider>
    </Dialog>
  );
}

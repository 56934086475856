import React, { useEffect } from "react";
import { useBlocker } from "react-router-dom";

import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

import { useFormContext } from "../utils/forms2/Form";
import ConfirmationDialog from "./ConfirmationDialog";

export function usePrompt(when = true) {
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      when && currentLocation.pathname !== nextLocation.pathname
  );

  return {
    promptVisible: blocker.state === "blocked",
    confirmNavigation: blocker.proceed,
    cancelNavigation: blocker.reset,
  };
}

const deepMatchObjectsContents = (
  dataObj: any,
  formDataObj: any,
  keys: string[],
  exact?: boolean
) => {
  return keys.every(key => deepMatchValues(dataObj[key], formDataObj[key], exact));
};

const deepMatchValues = (data: any, formData: any, exact?: boolean) => {
  if (Array.isArray(data) && Array.isArray(formData)) {
    if (data.length !== formData.length) {
      return false;
    }
    return data.every((_, i) => deepMatchValues(data[i], formData[i]));
  }

  if (typeof data === "object" && typeof formData === "object" && data && formData) {
    if (exact) {
      return (
        deepMatchObjectsContents(data, formData, Object.keys(formData), exact) &&
        deepMatchObjectsContents(data, formData, Object.keys(data), exact)
      );
    }
    return deepMatchObjectsContents(data, formData, Object.keys(formData), exact);
  }

  if (typeof data === "object" && isEmpty(data) && isNil(formData)) {
    return true;
  }

  if (isNil(data) && typeof formData === "object" && isEmpty(formData)) {
    return true;
  }

  return (data ?? "") === (formData ?? "");
};

export const deepMatch = (data: any, formData: any, exact?: boolean) => {
  return deepMatchObjectsContents(data, formData, Object.keys(formData), exact);
};

interface Props {
  exact?: boolean;
}

function RouteLeavingGuard({ exact }: Props) {
  const { form, initialValues, setSubmitButtonDisabled } = useFormContext();
  const formData = form.watch();

  const { promptVisible, confirmNavigation, cancelNavigation } = usePrompt(
    !deepMatch(initialValues, formData, exact)
  );

  useEffect(() => {
    setSubmitButtonDisabled(deepMatch(initialValues, formData, exact));
  }, [setSubmitButtonDisabled, initialValues, formData, exact]);

  return (
    <>
      {promptVisible && (
        <ConfirmationDialog
          title="Unsaved Changes"
          content="You have unsaved changes. Leave without saving?"
          cancelText="Dismiss"
          confirmText="Leave"
          onCancel={() => cancelNavigation && cancelNavigation()}
          onConfirm={() => confirmNavigation && confirmNavigation()}
        />
      )}
    </>
  );
}

export default RouteLeavingGuard;

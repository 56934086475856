import React, { CSSProperties } from "react";
import { Controller } from "react-hook-form";

import CardRadioGroup from "../../components/CardRadioGroup";
import { useFormContext } from "../forms2/Form";

interface Props<T> {
  id: string;
  name: string;
  cards: {
    title: string;
    description?: string;
    value: T;
    img: string;
  }[];
  afterChange?: (newValue: T) => void;
  disabled?: boolean;
  cardStyle?: CSSProperties;
}

export default function CardRadioGroupField<T extends string>({
  id,
  name,
  cards,
  afterChange,
  disabled,
  cardStyle,
}: Props<T>) {
  const { form } = useFormContext();

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field: { onChange, value } }) => (
        <CardRadioGroup
          id={id}
          value={value}
          onChange={newValue => {
            onChange(newValue);
            if (afterChange) {
              afterChange(newValue);
            }
          }}
          cards={cards}
          disabled={disabled}
          cardStyle={cardStyle}
        />
      )}
    />
  );
}

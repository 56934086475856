import React, { CSSProperties, ReactNode } from "react";

import Container from "@mui/material/Container";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import Fab from "../../../common/components/Fab";
import Progress from "../../../common/components/Progress";

export const PAGE_HEADER_HEIGHT = 110;

const useStyles = makeStyles()(theme => ({
  container: {
    width: "100%",
    height: PAGE_HEADER_HEIGHT,
    borderBottom: "1px solid #ECECEC",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    marginTop: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  breadcrumbs: {
    marginTop: 16,
    height: 16,
    color: "rgba(0, 0, 0, 0.38)",
  },
  title: {
    color: theme.palette.secondary.dark,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  subtitle: {
    marginBottom: 8,
    marginTop: 10,
    color: "rgba(0, 0, 0, 0.6)",
  },
  tabs: {
    marginBottom: 0,
    position: "absolute",
    bottom: 0,
    borderBottom: "none",
    minHeight: 34,
    "& .MuiTab-root": {
      padding: 0,
      minWidth: "unset",
      marginRight: 32,
      minHeight: 34,
    },
  },
  rightContent: {
    float: "right",
  },
}));

interface PageHeaderProps<T extends string> {
  title: string | JSX.Element;
  breadcrumb?: JSX.Element;
  tabs?: JSX.Element[];
  currentTab?: T;
  onChangeTab?: (tab: T) => void;
  progress?: boolean;
  buttonText?: string;
  buttonDisabled?: boolean;
  buttonTooltip?: string;
  customButton?: ReactNode;
  onButtonClick?: (() => void) | null;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  tabsProps?: TabsProps;
  rightContent?: JSX.Element;
  rightContentStyle?: CSSProperties;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

export default function PageHeader<T extends string>({
  title,
  breadcrumb,
  tabs,
  currentTab,
  onChangeTab = () => {},
  progress,
  buttonText = "",
  buttonDisabled,
  buttonTooltip = "",
  customButton,
  onButtonClick,
  style = {},
  containerStyle = {},
  contentStyle = {},
  tabsProps = {},
  rightContent,
  rightContentStyle = {},
  maxWidth = false,
}: PageHeaderProps<T>) {
  const { classes } = useStyles();

  return (
    <div className={classes.container} style={style}>
      <Container
        style={{ height: "100%", position: "relative", padding: "0 32px", ...containerStyle }}
        maxWidth={maxWidth}
      >
        <div className={classes.innerContainer}>
          <div className={classes.breadcrumbs}>
            <Typography variant="textMD">{breadcrumb}</Typography>
          </div>
          <div className={classes.content} style={contentStyle}>
            <Typography id="page-header-title" variant="h3" className={classes.title}>
              {title}
            </Typography>
            <div style={{ position: "relative", height: 40, flex: "0 0 auto" }}>
              {progress && <Progress size={32} top={0} />}
              {onButtonClick && !progress && (
                <Tooltip title={buttonTooltip} placement="top-end">
                  <div>
                    <Fab
                      id="page-header-action-button"
                      onClick={onButtonClick}
                      disabled={!!buttonDisabled}
                    >
                      {buttonText}
                    </Fab>
                  </div>
                </Tooltip>
              )}
              {customButton}
            </div>
          </div>
        </div>
        {tabs && (
          <Tabs
            value={currentTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, tab) => onChangeTab(tab)}
            aria-label="Header tabs"
            style={{
              ...((tabsProps && tabsProps.style) || {}),
            }}
            className={classes.tabs}
            {...tabsProps}
          >
            {tabs.map(t => t)}
          </Tabs>
        )}
        <div className={classes.rightContent} style={rightContentStyle}>
          {rightContent}
        </div>
      </Container>
    </div>
  );
}

import { applyMiddleware } from "redux";

let middleware = [];
let exp = null;

if (process.env.NODE_ENV === "development") {
  middleware = require("./middleware-dev").default;
  const composeWithDevTools = require("redux-devtools-extension").composeWithDevTools;
  const composeEnhancers = composeWithDevTools({});
  exp = () => composeEnhancers(applyMiddleware(...middleware()));
} else {
  middleware = require("./middleware-production.js").default;
  exp = () => applyMiddleware(...middleware());
}

const commonMiddleware = () => exp();

export default commonMiddleware;

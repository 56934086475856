import React, { useCallback, useMemo } from "react";
import { matchPath, useLocation, useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { compose, prop, sortBy, toLower } from "ramda";
import { ArrowUpRight, Layers, Plus } from "react-feather";
import { makeStyles } from "tss-react/mui";

import {
  ServerResponse,
  ServerResponseTypeEnum,
  WorkspaceResponseTypeEnum,
} from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import OverflowTooltip from "../../../common/components/OverflowTooltip";
import Progress from "../../../common/components/Progress";
import OrganizationsIcon from "../../../common/components/icons/OrganizationsIcon";
import { openUrlInNewTab } from "../../../common/utils/browser.utils";
import { getFromLocalStorage, setInLocalStorage } from "../../../common/utils/localStorage.utils";
import {
  useGetEnvironment,
  useGetWorkspaceEnvironment,
} from "../../services/adminEnvironmentQuery";
import {
  useGetWorkspace,
  useListUserWorkspaces,
  useListWorkspaces,
} from "../../services/adminServersQuery";
import { useCheckTenantPermissions } from "../../services/adminTenantsQuery";
import { isRouteAvailableForWorkspace } from "../AdminPanel";
import { useWorkspace } from "../common/useWorkspace";
import WorkspaceAvatar from "../nav/WorkspaceAvatar";
import { getServerTitle } from "../workspaceDirectory/server-profiles";

export const useWorkspaceSelectorStyles = makeStyles<void, "externalLink">()(
  (theme, _, classes) => ({
    root: {
      padding: 8,
      position: "relative",
    },
    list: {
      padding: 0,
      width: 300,
    },
    menuPaper: {
      left: "8px !important",
      boxShadow: theme.custom.shadows.menu,
    },
    menuItem: {
      paddingRight: 6,
      height: 56,
      "&:hover": {
        [`& .${classes.externalLink}`]: {
          visibility: "visible",
        },
      },
    },
    menuItemBottom: {
      "& svg": {
        color: theme.palette.primary.main,
      },
    },
    menuSubtitle: {
      fontWeight: 600,
      padding: "12px 16px",
    },
    menuRecentlyUsed: {
      fontWeight: 400,
      padding: "8px 16px 8px 16px",
      color: "#9EA1B7",
      fontSize: 12,
    },
    withInsetBoxShadow: {
      boxShadow: `4px 0px 0px 0px ${theme.palette.primary.main} inset`,
      backgroundColor: theme.palette.background.default,
    },
    select: {
      display: "flex",
      alignItems: "center",
      padding: "10px 10px 10px 4px",
      borderRadius: 4,
      overflow: "hidden",
      minHeight: 52,
    },
    selectable: {
      boxShadow: theme.custom.shadows.surfaceResting,
      "&:hover": {
        cursor: "pointer",
        boxShadow: theme.custom.shadows.surfaceHover,
        backgroundColor: theme.palette.background.default,
      },
    },
    avatar: {
      width: 32,
      height: 32,
      marginRight: 16,
      fontSize: 12,
    },
    externalLink: {
      visibility: "hidden",
      height: 24,
      fontSize: 10,
    },
  })
);

const recentlyUsedWorkspacesKey = "recently_used_workspaces";
export const recentlyUsedOrganizationsKey = "recently_used_organizations";

export const getRecentWorkspaces = (defaults: string[] = []) => {
  let recentWorkspaces: string[] = defaults;
  try {
    const recentWorkspacesString = getFromLocalStorage(recentlyUsedWorkspacesKey);
    recentWorkspaces = recentWorkspacesString ? JSON.parse(recentWorkspacesString) : defaults;
  } catch (e) {
    //
  }

  return recentWorkspaces;
};

export const setRecentWorkspace = (workspace: string, currentWorkspaces: string[]) => {
  setInLocalStorage(
    recentlyUsedWorkspacesKey,
    JSON.stringify([workspace, ...currentWorkspaces.filter(w => w !== workspace)].slice(0, 5))
  );
};

export const removeFromRecentWorkspaces = (workspace: string) => {
  const recentWorkspaces = getRecentWorkspaces();
  setInLocalStorage(
    recentlyUsedWorkspacesKey,
    JSON.stringify(recentWorkspaces.filter(w => w !== workspace))
  );
};

interface Props {
  onClick: () => void;
}

export default function WorkspaceSelector({ onClick }: Props) {
  const { cx, classes } = useWorkspaceSelectorStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [workspace] = useWorkspace();

  const getEnvironmentQuery = useGetEnvironment();
  const getAdminWorkspaceEnvironmentQuery = useGetWorkspaceEnvironment("admin");
  const systemWorkspaceAccess = getEnvironmentQuery.data?.system_workspace_access;
  const adminWorkspaceAccess = getAdminWorkspaceEnvironmentQuery.data?.admin_workspace_access;
  const tenantId = getTenantId();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canListWorkspaces = !!checkTenantPermissionsQuery.data?.list_workspaces;

  const listRegularWorkspaces = useListWorkspaces(
    { workspaceTypes: "regular" },
    { enabled: canListWorkspaces }
  );
  const listDeveloperWorkspaces = useListWorkspaces(
    { workspaceTypes: "developer" },
    { enabled: canListWorkspaces }
  );
  const listOrganizationWorkspaces = useListWorkspaces(
    { workspaceTypes: "organization" },
    { enabled: canListWorkspaces }
  );
  const listAdminWorkspaces = useListWorkspaces(
    { workspaceTypes: "admin" },
    { enabled: canListWorkspaces }
  );
  const listSystemWorkspaces = useListWorkspaces(
    { workspaceTypes: "system" },
    { enabled: canListWorkspaces }
  );
  const listUserWorkspaces = useListUserWorkspaces({}, { enabled: !canListWorkspaces });

  const regularWorkspaces = useMemo(
    () => listRegularWorkspaces.data?.workspaces ?? [],
    [listRegularWorkspaces.data?.workspaces]
  );
  const developerWorkspaces = useMemo(
    () => listDeveloperWorkspaces.data?.workspaces ?? [],
    [listDeveloperWorkspaces.data?.workspaces]
  );
  const organizationWorkspaces = useMemo(
    () => listOrganizationWorkspaces.data?.workspaces ?? [],
    [listOrganizationWorkspaces.data?.workspaces]
  );
  const adminWorkspaces = useMemo(
    () => listAdminWorkspaces.data?.workspaces ?? [],
    [listAdminWorkspaces.data?.workspaces]
  );
  const systemWorkspaces = useMemo(
    () => listSystemWorkspaces.data?.workspaces ?? [],
    [listSystemWorkspaces.data?.workspaces]
  );

  const userWorkspaces = useMemo(
    () => listUserWorkspaces.data?.workspaces ?? [],
    [listUserWorkspaces.data?.workspaces]
  );

  const isFetching =
    listRegularWorkspaces.isFetching ||
    listDeveloperWorkspaces.isFetching ||
    listOrganizationWorkspaces.isFetching ||
    listAdminWorkspaces.isFetching ||
    listSystemWorkspaces.isFetching ||
    listUserWorkspaces.isFetching;

  const workspaces = canListWorkspaces
    ? [
        ...regularWorkspaces,
        ...developerWorkspaces,
        ...organizationWorkspaces,
        ...adminWorkspaces,
        ...systemWorkspaces,
      ]
    : userWorkspaces;

  const getWorkspaceQuery = useGetWorkspace(workspace);
  const workspaceObj = getWorkspaceQuery.data;

  const filteredWorkspaces =
    tenantId === "system"
      ? workspaces
      : workspaces.filter(
          ({ type }) =>
            type &&
            isRouteAvailableForWorkspace(type, !!systemWorkspaceAccess, !!adminWorkspaceAccess)
        );

  const handleClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
      onClick();
    },
    [onClick]
  );

  const sortByNameCaseInsensitive = sortBy<any>(compose(toLower, prop("name") as any));
  const sortedByType = [
    ...sortByNameCaseInsensitive([
      ...(listRegularWorkspaces.data?.workspaces ?? []),
      ...(listDeveloperWorkspaces.data?.workspaces ?? []),
    ]),
    ...sortByNameCaseInsensitive(listOrganizationWorkspaces.data?.workspaces ?? []),
    ...sortByNameCaseInsensitive(listAdminWorkspaces.data?.workspaces ?? []),
    ...sortByNameCaseInsensitive(listSystemWorkspaces.data?.workspaces ?? []),
  ];

  const recentWorkspaces = getRecentWorkspaces();
  const workspacesToRender = recentWorkspaces
    .map(wid => filteredWorkspaces.find(server => server.id === wid))
    .filter(Boolean);

  const organizations = (sortedByType as ServerResponse[]).filter(
    s => s.type === ServerResponseTypeEnum.Organization
  );

  const updatePath = (previous, workspaceId) => {
    if (previous && previous !== workspaceId) {
      if (matchPath("/:workspaceId/applications/:id/:tab", location.pathname)) {
        navigate(`/${workspaceId}/applications`, { replace: true });
        return;
      }
      if (matchPath("/:workspaceId/gateways/:id/:tab", location.pathname)) {
        navigate(`/${workspaceId}/gateways`, { replace: true });
        return;
      }
      if (matchPath("/:workspaceId/services/:id/:tab", location.pathname)) {
        navigate(`/${workspaceId}/services`, { replace: true });
        return;
      }
      if (matchPath("/:workspaceId/identities/:type/:id/:tab", location.pathname)) {
        navigate(`/${workspaceId}/identities`, { replace: true });
        return;
      }
    }

    const re = new RegExp(`/${previous}/`);
    const newPath = location.pathname.replace(re, `/${workspaceId}/`);
    navigate(newPath, { replace: true });
  };

  return (
    <div id="workspace-selector" className={classes.root}>
      <div
        id="workspace-selector-current-item"
        className={cx(classes.select, classes.selectable)}
        onClick={handleClick}
      >
        {isFetching ? (
          <Progress size={20} top="calc(50% - 10px)" style={{ left: "calc(50% - 10px)" }} />
        ) : (
          <>
            <WorkspaceAvatar server={workspaceObj} size="small" />
            <Typography
              variant="body2"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                marginLeft: 12,
              }}
              id="workspace-selector-current-item-name"
            >
              {workspaceObj?.name}
            </Typography>
            <div style={{ flex: 1 }} />
            {Boolean(anchorEl) ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </>
        )}
      </div>
      <Menu
        id="workspace-selector-select"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        classes={{ list: classes.list, paper: classes.menuPaper }}
      >
        <Divider />

        <div>
          <Typography className={classes.menuRecentlyUsed}>Recent Workspaces</Typography>
          {workspacesToRender.map((w, index) =>
            w ? (
              <WorkspaceItem
                key={w.id}
                server={w}
                avatar={<WorkspaceAvatar server={w} size="small" />}
                index={index}
                selected={w.id === workspace}
                externalLink={
                  w?.type === WorkspaceResponseTypeEnum.Developer ? `${w.issuer_url || ""}/app` : ""
                }
                classes={classes}
                cx={cx}
                onClick={() => {
                  updatePath(workspace, w.id);
                  setAnchorEl(null);
                }}
              />
            ) : null
          )}
        </div>

        <Divider style={{ margin: 0 }} />

        <RouterLink
          to="/workspace-directory"
          state={{ backToWorkspace: location.pathname }}
          style={{ color: "unset" }}
        >
          <MenuItem classes={{ root: classes.menuItem }} className={classes.menuItemBottom}>
            <Layers
              className={classes.avatar}
              style={{ background: "", marginRight: 14, width: 24, height: 24 }}
            />
            <Typography id="workspace-settings" variant="body2">
              View all workspaces
            </Typography>
          </MenuItem>
        </RouterLink>

        {organizations.length > 0 && (
          <RouterLink
            to="/organization-directory/organizations"
            state={{ backToWorkspace: location.pathname }}
            style={{ color: "unset" }}
          >
            <MenuItem classes={{ root: classes.menuItem }} className={classes.menuItemBottom}>
              <OrganizationsIcon />
              <Typography id="workspace-settings" variant="body2" style={{ marginLeft: 16 }}>
                View all organizations
              </Typography>
            </MenuItem>
          </RouterLink>
        )}

        {checkTenantPermissionsQuery.data?.create_workspace && (
          <MenuItem
            classes={{ root: classes.menuItem }}
            className={classes.menuItemBottom}
            onClick={() => {
              navigate("/workspace-directory", {
                state: { goTo: "create", returnTo: workspace, backToWorkspace: location.pathname },
              });
            }}
          >
            <Plus className={classes.avatar} style={{ marginRight: 14, width: 24, height: 24 }} />
            <Typography id="workspace-create-new" variant="body2">
              Create new workspace
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export const WorkspaceItem = ({
  server,
  selected,
  avatar,
  externalLink = "",
  classes,
  cx,
  index,
  onClick,
}) => {
  return (
    <MenuItem
      value={server.id}
      classes={{ root: classes.menuItem }}
      key={server.id}
      className={cx([selected && classes.withInsetBoxShadow])}
      onClick={onClick}
    >
      {avatar}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: 12,
          overflow: "hidden",
        }}
      >
        <OverflowTooltip
          value={server.name}
          id={`workspace-settings-name-${index}`}
          typographyStyle={{ lineHeight: "18px" }}
        />

        <Typography
          variant="caption"
          style={{
            color: "#9EA1B7",
            fontSize: 10,
            fontWeight: 400,
            lineHeight: "12px",
            marginTop: 2,
          }}
        >
          {getServerTitle(server) || server.profile}
        </Typography>
      </div>
      {externalLink && (
        <div style={{ position: "absolute", right: 6 }}>
          <Button
            variant="outlined"
            size="small"
            className={classes.externalLink}
            style={{ backgroundColor: "#FBFCFD" }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              openUrlInNewTab(externalLink);
            }}
          >
            Open Portal
            <ArrowUpRight size={14} style={{ marginLeft: 4 }} />
          </Button>
        </div>
      )}
    </MenuItem>
  );
};

import React from "react";
import { useWatch } from "react-hook-form";

import { PoolResponse } from "@cloudentity/acp-identity";

import { FormFactory } from "../../../../../common/utils/forms/formFactory";
import { useIdentityPoolThemeSelector } from "./useIdentityPoolThemeSelector";

interface Props {
  pool?: PoolResponse;
  withEmptyValue?: boolean;
  showRememberCheckbox?: boolean;
  formFactory: FormFactory;
}

export default function IdentityPoolThemeSelector({
  pool,
  withEmptyValue,
  showRememberCheckbox,
  formFactory,
}: Props) {
  const { options } = useIdentityPoolThemeSelector({ pool, withEmptyValue });

  const rememberThemeServerId = useWatch({
    name: "rememberThemeWorkspaceId",
    control: formFactory.control,
  });

  return (
    <>
      {formFactory.createSelect({
        name: "themeWorkspaceId",
        label: "Workspace",
        options,
        labelProps: {
          tooltip:
            "This identity pool is connected to more than one workspace that determines graphical theme used, landing page etc. Select in context of which workspace this message must be sent.",
        },
        rules: withEmptyValue
          ? {}
          : {
              required: "Workspace is required",
            },
        optional: false,
      })}

      {showRememberCheckbox &&
        formFactory.createCheckBox({
          name: "rememberThemeWorkspaceId",
          label: "Remember workspace selection for this pool",
          helperText: rememberThemeServerId
            ? "The preference can be changed later in the pool settings."
            : "",
          style: { marginTop: -16 },
        })}
    </>
  );
}

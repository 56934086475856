import React, { useEffect, useState } from "react";

import Progress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { ChevronRight, ExternalLink, Link, Plus } from "react-feather";
import { makeStyles } from "tss-react/mui";

import {
  ServerBindingResponse,
  ServerResponse,
  WorkspaceResponse,
  WorkspaceResponseTypeEnum,
} from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import { openUrlInNewTab } from "../../../../common/utils/browser.utils";
import adminClientsApi from "../../../services/adminClientsApi";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import { useGetAuthorizationServer, useListWorkspaces } from "../../../services/adminServersQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import InMenuSearch from "../../common/InMenuSearch";
import Tooltip from "../../common/Tooltip";
import { isOpenbanking } from "../server-profiles";
import { WorkspaceTab } from "../workspace-directory.common";

export const useWorkspaceCardMenuStyles = makeStyles()(theme => ({
  root: {
    maxWidth: "100%",
    position: "relative",
  },
  cardTop: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
  },
  cardTopLeft: {
    display: "flex",
    maxWidth: "100%",
    width: "100%",
    cursor: "pointer",
  },
  card: {
    padding: 10,
    cursor: "default !important",
  },
  cardTopLeftName: {
    maxWidth: "calc(100% - 75px)",
    marginTop: 6,
    display: "flex",
    flexDirection: "column",
    "& > p": {
      color: "black",
      fontWeight: 600,
      lineHeight: "16px",
    },
    "& > span": {
      color: theme.palette.secondary.light,
    },
  },
  cardTopLeftType: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardBottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 8,
  },
  cardBottomItem: {
    display: "flex",
    alignItems: "center",
    width: "50%",
  },
  cardBottomItemInactive: {
    opacity: 0.5,
  },
  cardBottomItemIcon: {
    backgroundColor: "#F2F3F4",
    width: 18,
    height: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardBottomItemText: {
    margin: "0 8px",
    color: theme.palette.secondary.light,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardActions: {
    display: "flex",
    position: "absolute",
    right: -8,
    top: -8,
  },
  iconButton: {
    padding: 8,
    marginLeft: 2,
  },
  menu: {
    boxShadow: theme.custom.shadows.menu,
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    display: "flex",
    color: theme.palette.primary.main,
  },
  menuItemManageDevPortals: {
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
  },
  menuItemDevPortal: {
    display: "flex",
    justifyContent: "space-between",
    "& > p": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& > svg": {
      minWidth: 20,
    },
  },
  menuProgress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface Props {
  setTab: (tab: WorkspaceTab) => void;
  workspace: WorkspaceResponse;
  serversBindings: ServerBindingResponse[];
  onDelete: (id: string, name: string, type: WorkspaceResponseTypeEnum | undefined) => void;
  onBindTheme: (workspace: WorkspaceResponse) => void;
  onUnbindTheme: (workspace: WorkspaceResponse) => void;
  onSelectServer: (aid: string) => void;
  onConnectToExisting: (aid: string, rid: string) => void;
  onCreateDeveloperPortal: ({ aid }: { aid: string }) => void;
  onUnbind: ({ aid, rid }: { aid: string | undefined; rid: string }) => void;
  onBookmark: (workspace: ServerResponse) => void;
  handleClose: () => void;
  handleEdit: (id: string) => void;
  mainMenuAnchorEl: HTMLElement | null;
}
function WorkspaceCardMenu({
  setTab,
  workspace,
  serversBindings,
  onDelete,
  onBindTheme,
  onUnbindTheme,
  onConnectToExisting,
  onCreateDeveloperPortal,
  onUnbind,
  onSelectServer,
  onBookmark,
  handleClose,
  handleEdit,
  mainMenuAnchorEl,
}: Props) {
  const { classes } = useWorkspaceCardMenuStyles();
  const [devPortalsMenuAnchorEl, setDevPortalsMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [menuProgress, setMenuProgress] = useState(true);
  const [isDemoAppExists, setDemoAppExists] = useState(false);
  const [userPortalExists, setUserPortalExists] = useState(false);
  const [searchText, setSearchText] = useState<string>();

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace.id);
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const listDeveloperWorkspacesQuery = useListWorkspaces(
    {
      workspaceTypes: "developer",
      limit: 100,
      searchPhrase: searchText || undefined,
    },
    { enabled: !!checkTenantPermissionsQuery.data?.list_workspaces }
  );
  const workspaces = listDeveloperWorkspacesQuery.data?.workspaces ?? [];

  const getServerQuery = useGetAuthorizationServer(getTenantId(), workspace.id);
  const server = getServerQuery.data;

  const { id = "", name = "", number_of_child_organizations = 0 } = workspace;
  const type = "type" in workspace ? workspace.type : WorkspaceResponseTypeEnum.Organization;

  const isOPB = "profile" in workspace && isOpenbanking(workspace.profile);

  useEffect(() => {
    Promise.all([
      adminClientsApi
        .getClient({ cid: `${id}-demo` })
        .then(() => true)
        .catch(() => false),
      adminClientsApi
        .getClient({ cid: id })
        .then(() => true)
        .catch(() => false),
    ])
      .then(([res1, res2]) => {
        setDemoAppExists(res1);
        setUserPortalExists(res2);
      })
      .finally(() => {
        setMenuProgress(false);
      });
  }, [id]);

  return (
    <>
      <Menu
        id={`${id}-card-main-menu`}
        anchorEl={mainMenuAnchorEl}
        keepMounted
        open={Boolean(mainMenuAnchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.menu }}
      >
        {menuProgress ||
        getServerQuery.isLoading ||
        checkTenantPermissionsQuery.isFetching ||
        checkWorkspacePermissionsQuery.isFetching ||
        !server ? (
          <MenuItem disabled className={classes.menuProgress}>
            <Progress size={64} />
          </MenuItem>
        ) : (
          [
            <MenuItem
              id={`${id}-open-workspace`}
              onClick={() => {
                onSelectServer(id);
                handleClose();
              }}
              key="open"
            >
              Open
            </MenuItem>,
            ...(checkWorkspacePermissionsQuery.data?.update_workspace
              ? [
                  <MenuItem
                    id={`${id}-edit-workspace`}
                    onClick={() => {
                      handleEdit(id);
                      handleClose();
                    }}
                    key="edit"
                  >
                    Edit
                  </MenuItem>,
                ]
              : []),

            ...(checkWorkspacePermissionsQuery.data?.delete_workspace
              ? [
                  <Tooltip
                    title={
                      number_of_child_organizations > 0
                        ? "Cannot remove a workspace that has sub-organizations. Remove them first."
                        : undefined
                    }
                    key="delete"
                  >
                    <span>
                      <MenuItem
                        id={`${id}-delete-workspace`}
                        onClick={() => {
                          onDelete(id, name, type);
                          handleClose();
                        }}
                        disabled={number_of_child_organizations > 0}
                      >
                        Delete
                      </MenuItem>
                    </span>
                  </Tooltip>,
                ]
              : []),

            ...(!workspace.theme_id && checkTenantPermissionsQuery.data?.manage_themes
              ? [
                  <MenuItem
                    id={`${id}-bind-theme`}
                    onClick={() => {
                      onBindTheme(workspace);
                      handleClose();
                    }}
                    key="bind"
                  >
                    Bind Theme
                  </MenuItem>,
                ]
              : []),
            ...(workspace.theme_id && checkTenantPermissionsQuery.data?.manage_themes
              ? [
                  <MenuItem
                    id={`${id}-unbind-theme`}
                    onClick={() => {
                      onUnbindTheme(workspace);
                      handleClose();
                    }}
                    key="unbind"
                  >
                    Unbind Theme
                  </MenuItem>,
                ]
              : []),

            ...(checkWorkspacePermissionsQuery.data?.update_workspace
              ? [
                  <MenuItem
                    id={`${id}-quick-access`}
                    onClick={() => {
                      onBookmark(server);
                      handleClose();
                    }}
                    key="quick-access"
                  >
                    {server?.enable_quick_access
                      ? "Remove from quick access"
                      : "Add to quick access"}
                  </MenuItem>,
                ]
              : []),

            ...(checkTenantPermissionsQuery.data?.manage_server_bindings
              ? [
                  <MenuItem
                    id={`${id}-developer-portals`}
                    onClick={e => {
                      setDevPortalsMenuAnchorEl(e.currentTarget);
                    }}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    key="developer-portals"
                  >
                    <Typography variant="body2">Developer Portals</Typography>
                    <ChevronRight size="16" style={{ marginLeft: 8 }} />
                  </MenuItem>,
                ]
              : []),

            ...(isDemoAppExists || (userPortalExists && !isOPB)
              ? [
                  <Divider style={{ margin: "8px 0" }} key="visit-divider" />,
                  <MenuItem disabled style={{ fontSize: 12, fontWeight: 500 }} key="visit-title">
                    Visit
                  </MenuItem>,
                ]
              : []),

            ...(isDemoAppExists
              ? [
                  <MenuItem
                    id={`${id}-demo-application`}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() => {
                      openUrlInNewTab(`${workspace.issuer_url || ""}/demo`);
                      handleClose();
                    }}
                    key="demo-app-link"
                  >
                    Demo application <ExternalLink size="14" style={{ marginLeft: 8 }} />
                  </MenuItem>,
                ]
              : []),

            ...(userPortalExists && !isOPB
              ? [
                  <MenuItem
                    id={`${id}-user-application`}
                    onClick={() => {
                      openUrlInNewTab(`${workspace.issuer_url || ""}/app`);
                      handleClose();
                    }}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    key="user-app-link"
                  >
                    <Typography variant="body2">User Portal</Typography>
                    <ExternalLink size="14" style={{ marginLeft: 8 }} />
                  </MenuItem>,
                ]
              : []),
          ]
        )}
      </Menu>

      <Menu
        id={`${id}-card-dev-portals-menu`}
        anchorEl={devPortalsMenuAnchorEl}
        open={Boolean(devPortalsMenuAnchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        classes={{ paper: classes.menu, list: classes.menuList }}
      >
        <MenuItem
          id="dev-portals-menu-cerate-new"
          key="dev-portals-menu-cerate-new"
          className={classes.menuItem}
          onClick={() => {
            onCreateDeveloperPortal({ aid: id });
            handleClose();
          }}
        >
          <Plus size="14" style={{ marginRight: 8 }} />
          <Typography variant="body2">Create new</Typography>
        </MenuItem>
        <Divider style={{ margin: "0 0 8px" }} key="create-new-dev-portal-divider" />
        <MenuItem disabled style={{ fontSize: 10 }} key="developer-portals-title">
          Developer portals
        </MenuItem>
        <InMenuSearch key="developer-portals-search" onSearch={setSearchText} />
        <div style={{ maxHeight: 144, overflowY: "auto" }} key="developer-portals-list">
          {workspaces.length === 0 && (
            <MenuItem disabled key="developer-portals-no-portals">
              No portals found
            </MenuItem>
          )}
          {workspaces.map(portal => {
            const isBound = !!serversBindings.find(
              binding => binding.dependent === workspace.id && binding.server_id === portal.id
            );
            return (
              <MenuItem
                id={`${portal.id}-${isBound ? "disconnect" : "connect"}-portal`}
                key={portal.id}
                className={classes.menuItemDevPortal}
                onClick={() => {
                  if (isBound) {
                    onUnbind({ aid: portal.id, rid: id });
                  } else {
                    onConnectToExisting(portal.id ?? "", id);
                  }
                }}
              >
                <Typography variant="body2">{portal.name ?? ""}</Typography>
                {isBound && <Link size="14" style={{ marginLeft: 8 }} />}
              </MenuItem>
            );
          })}
        </div>
        <MenuItem
          key="developer-portals-manage"
          onClick={() => {
            setTab(WorkspaceTab.DEVELOPER_PORTALS_SEQ);
          }}
          className={classes.menuItemManageDevPortals}
        >
          <Typography variant="body2">Manage</Typography>
          <ChevronRight size="16" style={{ marginLeft: 8 }} />
        </MenuItem>
      </Menu>
    </>
  );
}

export default WorkspaceCardMenu;

import React from "react";

import FormSection from "../../../../../common/components/FormSection";
import { FormFactory } from "../../../../../common/utils/forms/formFactory";
import { validators } from "../../../../../common/utils/forms/validation";

interface Props {
  formFactory: FormFactory;
}

export default function IdentityPoolOTPSettings({ formFactory }: Props) {
  return (
    <>
      <FormSection title="Activation OTP" id="otp-activation" small />

      {formFactory.createDurationField({
        id: "otp-settings-activation-ttl",
        name: "otp_settings.activation.ttl",
        label: "Time To Live",
        description:
          "Defines the time-to-live period for One Time Passwords used for account activation. When such OTP expires, it can no longer be used to activate an account.",
        validate: {
          inRangeDuration: validators.inRangeDuration({
            label: "Time To Live",
            min: "0h0m10s",
            max: "744h0m0s",
          }),
        },
        style: { marginBottom: 16 },
      })}

      {formFactory.createNumberField({
        id: "activation-otp-length",
        name: "otp_settings.activation.length",
        title: "Length",
        description: "Determines the length for One Time Passwords used for account activation.",
        validate: {
          inRange: validators.inRange({ label: "Length", min: 6, max: 12 }),
        },
        min: 6,
        max: 12,
      })}

      <FormSection title="Reset Password OTP" id="otp-reset_password" small />

      {formFactory.createDurationField({
        id: "otp-settings-reset-password-ttl",
        name: "otp_settings.reset_password.ttl",
        label: "Time To Live",
        description:
          "Defines the time-to-live period for One Time Passwords used for resetting user's password. When such OTP expires, the user can no longer use it for resetting their passwords.",
        validate: {
          inRangeDuration: validators.inRangeDuration({
            label: "Time To Live",
            min: "0h0m10s",
            max: "744h0m0s",
          }),
        },
        style: { marginBottom: 16 },
      })}

      {formFactory.createNumberField({
        id: "reset-password-otp-length",
        name: "otp_settings.reset_password.length",
        title: "Length",
        description:
          "Determines the length for One Time Passwords used for resetting user's password.",
        validate: {
          inRange: validators.inRange({ label: "Length", min: 6, max: 12 }),
        },
        min: 6,
        max: 12,
      })}

      <FormSection title="Challenge OTP" id="otp-challenge" small />

      {formFactory.createDurationField({
        id: "otp-settings-challenge-ttl",
        name: "otp_settings.challenge.ttl",
        label: "Time To Live",
        description:
          "Defines the time-to-live period for One Time Passwords that are used for proof-of-possession challanges. When such OTP expires, it can be no longer used, for example, when providing help desk assistance, to authenticate the user, or to  verify user's address.",
        validate: {
          inRangeDuration: validators.inRangeDuration({
            label: "Time To Live",
            min: "0h0m10s",
            max: "744h0m0s",
          }),
        },
        style: { marginBottom: 16 },
      })}

      {formFactory.createNumberField({
        id: "challenge-otp-length",
        name: "otp_settings.challenge.length",
        title: "Length",
        description:
          "Determines the length for One Time Passwords used for proof-of-possession challenges.",
        validate: {
          inRange: validators.inRange({ label: "Length", min: 6, max: 12 }),
        },
        min: 6,
        max: 12,
      })}

      <FormSection title="Verify Address OTP" id="otp-verify_address" small />

      {formFactory.createDurationField({
        id: "otp-settings-verify-address-ttl",
        name: "otp_settings.verify_address.ttl",
        label: "Time To Live",
        description:
          "Defines the time-to-live period for One Time Passwords used for verifying user's address. When such OTP expires, it can no longer be used to verify address.",
        validate: {
          inRangeDuration: validators.inRangeDuration({
            label: "Time To Live",
            min: "0h0m10s",
            max: "744h0m0s",
          }),
        },
        style: { marginBottom: 16 },
      })}

      {formFactory.createNumberField({
        id: "verify-address-otp-length",
        name: "otp_settings.verify_address.length",
        title: "Length",
        description: "Determines the length for One Time Passwords used for verify address.",
        validate: {
          inRange: validators.inRange({ label: "Length", min: 6, max: 12 }),
        },
        min: 6,
        max: 12,
      })}

      <FormSection title="Authentication OTP" id="otp-authentication" small />

      {formFactory.createDurationField({
        id: "otp-settings-authentication-ttl",
        name: "otp_settings.authentication.ttl",
        label: "Time To Live",
        description:
          "Defines the time-to-live period for One Time Passwords that are used for authentication. When such OTP expires, it can no longer be used to authenticate the user.",
        validate: {
          inRangeDuration: validators.inRangeDuration({
            label: "Time To Live",
            min: "0h0m10s",
            max: "744h0m0s",
          }),
        },
        style: { marginBottom: 16 },
      })}

      {formFactory.createNumberField({
        id: "authentication-otp-length",
        name: "otp_settings.authentication.length",
        title: "Length",
        description: "Determines the length for One Time Passwords used for authentication.",
        validate: {
          inRange: validators.inRange({ label: "Length", min: 6, max: 12 }),
        },
        min: 6,
        max: 12,
      })}
    </>
  );
}

import React, { useState } from "react";

import Add from "@mui/icons-material/Add";
import { useQueryClient } from "@tanstack/react-query";
import { lensPath, pathOr, set } from "ramda";

import { Attribute, Server } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import Fab from "../../../common/components/Fab";
import { handleErrorWithNotify } from "../../../common/components/notifications/notificationService";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import adminServersApi from "../../services/adminServersApi";
import {
  getAuthorizationServerQueryKey,
  useGetAuthorizationServer,
} from "../../services/adminServersQuery";
import { useWorkspace } from "../common/useWorkspace";
import AuthNContextAddAttribute from "./AuthNContextAddAttribute";

const attrLens = lensPath(["authentication_context_settings", "attributes"]);

export default function AuthNContextCreateButton() {
  const [workspace] = useWorkspace();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);

  const [progress, setProgress] = useState(false);

  const [createDialog, setCreateDialog] = useState(false);

  const queryClient = useQueryClient();
  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace);

  const handleAdd = (attribute: Attribute) => {
    const server = set(
      attrLens,
      [
        ...(serverQuery?.data?.authentication_context_settings?.attributes ?? []),
        { ...attribute, name: attribute.name?.trim(), description: attribute.description?.trim() },
      ],
      serverQuery?.data
    );
    return updateServer(server);
  };

  const updateServer = (server: Server) => {
    setProgress(true);
    return adminServersApi
      .updateAuthorizationServer({ wid: workspace, server })
      .then(res =>
        queryClient.setQueryData(getAuthorizationServerQueryKey(getTenantId(), workspace), res.data)
      )
      .catch(handleErrorWithNotify("Error occurred while trying to update authorization server"))
      .finally(() => setProgress(false));
  };

  const attributes = pathOr(
    [],
    ["authentication_context_settings", "attributes"],
    serverQuery?.data
  );

  if (!checkWorkspacePermissionsQuery.data?.update_workspace) {
    return null;
  }

  return (
    <>
      <Fab
        id="create-authn-context-attribute-button"
        color="primary"
        size="large"
        onClick={() => setCreateDialog(true)}
      >
        <Add /> Create Attribute
      </Fab>

      {createDialog && (
        <AuthNContextAddAttribute
          attributes={attributes}
          onCreate={data => {
            handleAdd(data).then(() => {
              setCreateDialog(false);
            });
          }}
          onCancel={() => setCreateDialog(false)}
          progress={progress}
        />
      )}
    </>
  );
}

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Cloudentity API
 * Cloudentity Identity API  It is important for clients to note that most APIs return the `ETag` response header. When executing API flows which modify and retrieve a given resource, clients are expected to follow `ETag` protocols by: 1. Caching the value of an `ETag` returned by the server when retrieving the resource, and 2. Supplying the `If-Match` header with the `ETag` value when attempting to modify the resource.  A `412 Precondition Failed` response will be returned if a write request is delayed after a sufficient length of time while a previous write request was being processed.  When interacting with APIs, make sure to always supply the If-Match header from the previous ETag header response for full consistency. Otherwise, the APIs may use the cached user data, and not return what you expect!
 *
 * The version of the OpenAPI document: latest
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./api"), exports);
__exportStar(require("./configuration"), exports);

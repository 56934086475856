import React, { ReactNode, useCallback, useState } from "react";

// eslint-disable-next-line no-restricted-imports
import { SvgIconComponent } from "@mui/icons-material";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import every from "lodash/every";
import { Copy, Edit2, Eye, Icon } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { copyToClipboard } from "../../services/clipboard";
import OverflowTooltip from "../OverflowTooltip";
import Progress from "../Progress";
import ReadOnlyInputLabel from "./ReadOnlyInputLabel";

const useStyles = makeStyles()(theme => ({
  readOnlyInputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 32px",
    margin: "0 -32px 12px",
    color: theme.palette.secondary.light,
    fontSize: 14,
    letterSpacing: 0.5,
  },
  readOnlyInput: {
    width: "calc(100% - 24px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  readOnlyInputIcon: {
    width: 16,
    height: 16,
  },
  editButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 20,
    height: 20,
    color: theme.palette.primary.main,
    border: `0.5px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    marginLeft: 12,
  },
  moreOptionButton: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 12,
  },
  noData: {
    color: "#A0A3B5",
    paddingTop: 8,
    paddingBottom: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

interface Props {
  id: string;
  label: string;
  value: string | string[] | { label: string; value: string }[] | undefined;
  icon?: Icon | SvgIconComponent;
  type?: "password" | "string" | "link";
  progress?: boolean;
  whiteSpace?: "initial" | "nowrap";
  onEdit?: (() => void) | null;
  onShowMore?: (() => void) | null;
  onShowMoreLabel?: string;
  onRemove?: (() => void) | null;
  moreActions?: ReactNode;
  withMoreActionsOnEmpty?: boolean;
  showValue?: boolean;
  disabled?: boolean;
}

export default function ApplicationReadOnlyInputWithCopy({
  id,
  label,
  value,
  icon,
  type = "string",
  progress,
  onEdit,
  onShowMore,
  onShowMoreLabel,
  onRemove,
  whiteSpace = "nowrap",
  moreActions,
  withMoreActionsOnEmpty,
  showValue,
  disabled = false,
}: Props) {
  const { classes } = useStyles();
  const [isMasked, setIsMasked] = useState(type === "password");

  const handleCopyToClipboard = useCallback(
    (value, label) => () => {
      copyToClipboard(value, label);
    },
    []
  );

  const toggleVisibility = () => setIsMasked(!isMasked);

  const valuesAsArray =
    typeof value === "string"
      ? [{ label: showValue ? value : label, value }]
      : Array.isArray(value) && every(value, v => typeof v === "string")
      ? value.map(v => ({ label: label, value: v }))
      : Array.isArray(value) && value.length > 0
      ? value
      : [];

  return (
    <div style={{ position: "relative" }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ flex: 1, display: "flex", alignItems: "center", marginBottom: 4 }}>
          <ReadOnlyInputLabel id={id} label={label} icon={icon} />
          {onEdit && (
            <ButtonBase
              className={classes.editButton}
              disabled={disabled || progress}
              onClick={onEdit}
              data-testid="edit-button"
            >
              <Edit2 style={{ width: 12, height: 12 }} />
            </ButtonBase>
          )}
          {onShowMore && (
            <>
              <div style={{ flex: 1 }} />
              <ButtonBase
                className={classes.moreOptionButton}
                disabled={disabled || progress}
                onClick={onShowMore}
              >
                {onShowMoreLabel}
              </ButtonBase>
            </>
          )}
        </div>
        {onRemove && (
          <ButtonBase
            id={`read-only-input-button-remove-all-${id}`}
            color="primary"
            className={classes.moreOptionButton}
            disabled={disabled || progress}
            onClick={onRemove}
          >
            Remove all
          </ButtonBase>
        )}
      </div>

      <div style={{ minHeight: 52 }}>
        {progress && <Progress size={24} top={36} />}
        {!progress && (
          <>
            {valuesAsArray.length === 0 && (
              <div className={classes.noData}>
                {id === "client-secret" ? (
                  <>
                    <span>•••••••••••••••••••••••••</span>
                    {moreActions}
                  </>
                ) : (
                  <>
                    <div>No data</div>
                    {withMoreActionsOnEmpty ? moreActions : null}
                  </>
                )}
              </div>
            )}
            {valuesAsArray.map((v, index) => (
              <div
                key={v.value}
                className={classes.readOnlyInputContainer}
                style={{ whiteSpace: whiteSpace }}
                data-testid={`read-only-input-value-${id}-${index}`}
              >
                <div className={classes.readOnlyInput}>
                  <OverflowTooltip
                    id={`read-only-input-value-${id}`}
                    label={v.label}
                    value={isMasked ? "•••••••••••••••••••••••••" : v.value}
                    type={type}
                  />
                </div>

                {moreActions}

                {type === "password" && (
                  <IconButton
                    id={`read-only-input-button-toggle-visibility-${id}`}
                    size="small"
                    style={{ marginLeft: 12 }}
                    onClick={toggleVisibility}
                  >
                    <Eye className={classes.readOnlyInputIcon} />
                  </IconButton>
                )}

                <Tooltip placement="left" title={`Copy ${v.label ?? label} to clipboard`}>
                  <IconButton
                    id={`read-only-input-button-copy-to-clipboard-${id}`}
                    size="small"
                    style={{ marginLeft: 12 }}
                    onClick={handleCopyToClipboard(v.value, v.label ?? label)}
                  >
                    <Copy className={classes.readOnlyInputIcon} />
                  </IconButton>
                </Tooltip>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

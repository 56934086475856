import React, { useEffect, useMemo, useState } from "react";

import Typography from "@mui/material/Typography";
import { UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useQueryClient } from "@tanstack/react-query";
import { JSONSchema7 } from "json-schema";
import { makeStyles } from "tss-react/mui";

import {
  GrantWorkspaceRoleRequestRoleEnum,
  GrantWorkspaceRoleRequestTypeEnum,
  OrganizationResponse,
  ServerResponseProfileEnum,
} from "@cloudentity/acp-admin";
import {
  NewUserIdentifierTypeEnum,
  NewUserPayloadStatusEnum,
  NewUserVerifiableAddressStatusEnum,
  NewUserVerifiableAddressTypeEnum,
  Pool,
  PoolAuthenticationMechanismsEnum,
  UserWithData,
} from "@cloudentity/acp-identity";

import TemplatesSelect from "../../../b2b/directory/TemplatesSelect";
import { getTenantId } from "../../../common/api/paths";
import Dialog from "../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import { validators } from "../../../common/utils/forms/validation";
import { toSlugString } from "../../../common/utils/string.utlis";
import { useGetEnvironment } from "../../services/adminEnvironmentQuery";
import { listIDPsQueryKey } from "../../services/adminIDPsQuery";
import identityPoolsApi from "../../services/adminIdentityPoolsApi";
import identityUsersApi from "../../services/adminIdentityUsersApi";
import adminOrganizationsApi from "../../services/adminOrganizationsApi";
import {
  listOrganizationsQueryKey,
  useGetOrganization,
} from "../../services/adminOrganizationsQuery";
import adminRolesApi from "../../services/adminRolesApi";
import { listTenantRoles, listUserRoles } from "../../services/adminRolesQuery";
import { getExecutionPointsQueryKey } from "../../services/adminScriptsQuery";
import adminServersApi from "../../services/adminServersApi";
import { useGetAuthorizationServer } from "../../services/adminServersQuery";
import { useGetTenant } from "../../services/adminTenantsQuery";
import useWorkspacesSeqOrCursor from "../common/EnhancedTableAsync/useWorkspacesSeqOrCursor";
import Domains from "../settings/Domains";
import { initialColors } from "../workspaceDirectory/WorkspacesColorInput";
import SchemaForm from "../workspaceDirectory/identityPools/identityPool/users/user/SchemaForm";
import {
  getUIOrderBasedOnRequiredFields,
  mapFieldNameToTitle,
} from "../workspaceDirectory/identityPools/schemas/schemas.utils";

const useStyles = makeStyles()(theme => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  addLabel: {
    marginRight: 8,
    color: theme.palette.primary.main,
  },
}));

export interface AddOrganizationProps {
  color?: string;
  onCancel: () => void;
  onCreated?: (server: OrganizationResponse) => void;
  onSkip: (server: OrganizationResponse) => void;
  onInviteSent: (server: OrganizationResponse) => void;
  workspaceParentId?: string;
}

export default function TenantAddOrganization({
  color,
  onCancel,
  onCreated,
  onSkip,
  onInviteSent,
  workspaceParentId,
}: AddOrganizationProps) {
  const { classes } = useStyles();
  const [progress, setProgress] = useState(false);
  const [step, setStep] = useState<"create" | "invite">("create");

  const tenantId = getTenantId();
  const [newPoolId, setNewPoolId] = useState("");
  const [newOrganization, setNewOrganization] = useState<OrganizationResponse | null>(null);

  const queryClient = useQueryClient();

  const serverQuery = useGetAuthorizationServer(getTenantId(), workspaceParentId, {
    enabled: !!workspaceParentId,
  });
  const tenantQuery = useGetTenant(tenantId);
  const environmentQuery = useGetEnvironment();
  const isConsumerWorkspace = serverQuery.data?.profile === ServerResponseProfileEnum.Consumer;

  const workspacesData = useWorkspacesSeqOrCursor({
    forceMode: "seq",
    ignoreUrlParams: true,
    template: true,
  });

  const templates: { id?: string; name?: string }[] = workspacesData.totalData.filter(
    v => !!v.template
  );

  const defaultTemplateId = useMemo(
    () => environmentQuery.data?.tenant_settings?.default_template_id,
    [environmentQuery.data]
  );
  const defaultTemplate = templates.find(template => template.id === defaultTemplateId) || null;

  const data = useMemo(
    () => ({
      name: "",
      email: "",
      firstName: "",
      lastName: "",
      template: defaultTemplate,
    }),
    [defaultTemplate]
  );

  const formFactory = useFormFactory({
    id: "add-organization",
    progress,
    data,
  });

  const selectedTemplate = formFactory.watch("template");

  useEffect(() => {
    setPayload({});
  }, [selectedTemplate?.id]);

  const templateOrganizationId = selectedTemplate?.id;

  const templateOrganizationQuery = useGetOrganization(templateOrganizationId, {
    enabled: !!templateOrganizationId,
  });

  // form
  const [payload, setPayload] = useState({});

  const schemaWithMappedTitles = mapFieldNameToTitle(
    templateOrganizationQuery.data?.metadata?.schema ?? {}
  );
  const uiSchema: UiSchema = {
    "ui:order": getUIOrderBasedOnRequiredFields(schemaWithMappedTitles),
  };

  const validateMetadata = validator.validateFormData(
    payload,
    (schemaWithMappedTitles as JSONSchema7) || {}
  );
  // end

  const handleCreateIdentityPool = (responseData: OrganizationResponse) => {
    const pool: Pool = {
      name: "Users",
      tenant_id: tenantId,
      authentication_mechanisms: [PoolAuthenticationMechanismsEnum.Password],
      workspace_id: responseData.id,
      identifier_case_insensitive: true,
      metadata_schema_id: "organizations_pool_default_metadata",
      payload_schema_id: "organizations_pool_default_payload",
    };
    return identityPoolsApi.createWorkspacePool({
      wid: responseData.id ?? "",
      pool,
      withIdp: true,
    });
  };

  const handleSendActivationMessage = (user: UserWithData) => {
    identityUsersApi
      .sendActivationMessage({
        ipID: user.user_pool_id,
        userID: user.id ?? "",
        serverId: newOrganization?.id,
      })
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to send invitation message"));
  };

  const handleCreateOrganization = data => {
    let createdOrganization;
    let templateIdExists = false;
    let workspaceIdExists = false;
    let omitInvite = false;

    setProgress(true);
    const templateId = "template" in data ? data.template?.id : defaultTemplateId;

    const parentId =
      workspaceParentId || tenantQuery.data?.settings?.default_workspace_id || undefined;

    Promise.allSettled([
      templateId ? adminOrganizationsApi.getOrganization({ wid: templateId }) : Promise.resolve(),
      parentId ? adminServersApi.getWorkspace({ wid: parentId }) : Promise.resolve(),
    ])
      .then(promises => {
        if (promises[0].status === "fulfilled") {
          templateIdExists = true;
        }
        if (promises[1].status === "fulfilled") {
          workspaceIdExists = true;
        }
      })
      .then(() =>
        adminOrganizationsApi.createOrganization({
          org: {
            name: data.name.trim(),
            id: data.id.trim(),
            domains: (data.domains || []).map(d => (typeof d === "string" ? d : d.value)),
            color: color || initialColors[Math.floor(Math.random() * initialColors.length)],
            template_id: templateIdExists && templateId ? templateId : undefined,
            parent_id: workspaceIdExists ? parentId : undefined,
            metadata: { payload },
          },
        })
      )
      .then(({ data }) => {
        createdOrganization = data;
        setNewOrganization(data);
        if (templateIdExists && templateId) return;
        return handleCreateIdentityPool(data);
      })
      .then(async res => {
        if (res) {
          setStep("invite");
          setNewPoolId(res?.data.id ?? "");
        } else {
          const newOrgPoolsRes = await identityPoolsApi.listWorkspacePools({ wid: data.id });
          if (newOrgPoolsRes?.data.pools?.length === 1) {
            setNewPoolId(newOrgPoolsRes.data.pools[0].id ?? "");
            setStep("invite");
          } else {
            omitInvite = true;
          }
        }
      })
      .then(() => queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(tenantId) }))
      .then(() => queryClient.invalidateQueries({ queryKey: listOrganizationsQueryKey() }))
      .then(() => {
        if (workspaceIdExists && parentId) {
          return queryClient.invalidateQueries({
            queryKey: getExecutionPointsQueryKey(tenantId, parentId),
          });
        }
      })
      .then(() => onCreated && onCreated(createdOrganization))
      .then(() =>
        notifySuccess(
          <span>
            <strong>{data.name}</strong> organization successfully created
          </span>
        )
      )
      .catch(err => {
        if (
          err.response?.status === 409 &&
          err.response?.data.error?.includes("id must be unique")
        ) {
          formFactory.setError(
            "id",
            {
              message: "Organization ID with given value already exists",
            },
            { shouldFocus: true }
          );
        } else {
          notifyErrorOrDefaultTo("Error occurred while trying to create organization")(err);
        }
      })
      .finally(() => {
        setProgress(false);
        if (omitInvite) {
          onCancel();
        }
      });
  };

  const handleInvite = data => {
    identityUsersApi
      .createUser({
        ipID: newPoolId,
        newUser: {
          payload: {
            first_name: data.firstName.trim(),
            last_name: data.lastName.trim(),
          },
          metadata: {},
          status: NewUserPayloadStatusEnum.New,
          credentials: [],
          identifiers: [
            {
              identifier: data.email,
              type: NewUserIdentifierTypeEnum.Email,
            },
          ],
          verifiable_addresses: [
            {
              address: data.email,
              status: NewUserVerifiableAddressStatusEnum.Active,
              type: NewUserVerifiableAddressTypeEnum.Email,
              verified: false,
            },
          ],
        },
      })
      .then(res => {
        setNewPoolId("");
        return res;
      })
      .then(res => {
        adminRolesApi
          .grantWorkspaceRole({
            wid: newOrganization?.id!,
            request: {
              tenant_id: getTenantId(),
              role: GrantWorkspaceRoleRequestRoleEnum.Manager,
              type: GrantWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
              identity_pool_id: res.data.user_pool_id,
              identity_pool_user_id: res.data.id,
            },
          })
          .then(() => queryClient.invalidateQueries({ queryKey: listTenantRoles() }))
          .then(() =>
            queryClient.invalidateQueries({
              queryKey: listUserRoles(res.data.user_pool_id, res.data.id),
            })
          )
          .catch(notifyErrorOrDefaultTo("Error occurred when trying to grant tenant role"))
          .then(() => res);

        return res;
      })
      .then(({ data }) => handleSendActivationMessage(data))
      .then(() => onInviteSent(newOrganization!))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to add user"))
      .then(() =>
        notifySuccess(
          <span>
            <strong>{data.firstName.trim() + " " + data.lastName.trim()}</strong> added to{" "}
            <strong>{newOrganization?.name}</strong> as an <strong>Administrator</strong>
          </span>
        )
      );

    onCancel();
  };

  const handleChangeName = e => {
    const slugId = toSlugString(e.target.value);

    formFactory.clearErrors("id");
    formFactory.setValue("id", slugId);
  };

  return (
    <Dialog
      onClose={onCancel}
      id="add-organization-dialog"
      title={
        step === "create" ? (
          <div className={classes.header}>
            <div>
              {workspaceParentId && serverQuery.data?.name
                ? `Create new sub-organization for ${serverQuery.data?.name}`
                : "Create new organization"}
            </div>
            <Typography variant="body2">Step 1 / 2</Typography>
          </div>
        ) : (
          <div className={classes.header}>
            <div>Create new {isConsumerWorkspace ? "Organization" : "Workspace"} Admin</div>
            <Typography variant="body2">Step 2 / 2</Typography>
          </div>
        )
      }
    >
      <FormContext.Provider value={formFactory.context}>
        {step === "create" && (
          <>
            {formFactory.createRequiredField({
              name: "name",
              label: "Name",
              onChange: handleChangeName,
              autoFocus: true,
            })}
            {formFactory.createRequiredField({
              name: "id",
              label: "Organization ID",
              rules: {
                validate: {
                  validID: validators.validID({ label: "Organization ID" }),
                },
              },
              helperText: "Identifier cannot be modified after organization creation",
              tooltip:
                "Organization identifier uniquely identifies organization and is visible in the organization login page URL",
            })}

            <Domains formFactory={formFactory} />

            <TemplatesSelect
              templates={templates}
              formFactory={formFactory}
              data={workspacesData}
            />

            <SchemaForm
              formData={payload}
              setFormData={setPayload}
              schema={schemaWithMappedTitles}
              UISchema={uiSchema}
              submitAttempt={true}
              extraErrors={{}}
              resetExtraErrors={() => {}}
            />

            {formFactory.createFormFooter({
              disabled: validateMetadata.errors.length > 0,
              onCancel,
              onSubmit: data => handleCreateOrganization(data),
              submitText: "Create",
            })}
          </>
        )}
        {step === "invite" && (
          <>
            {formFactory.createRequiredField({
              name: "email",
              label: "Email",
              rules: {
                validate: {
                  validEmail: validators.validEmail({ label: "Value" }),
                },
              },
            })}
            {formFactory.createRequiredField({
              name: "firstName",
              label: "First name",
            })}
            {formFactory.createRequiredField({
              name: "lastName",
              label: "Last name",
            })}
            {formFactory.createFormFooter({
              onCancel: () => onSkip(newOrganization!),
              cancelText: "Skip",
              onSubmit: data => handleInvite(data),
              submitText: "Send Invite",
            })}
          </>
        )}
      </FormContext.Provider>
    </Dialog>
  );
}

import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import { useQueryClient } from "@tanstack/react-query";
import isURL from "validator/lib/isURL";

import adminClientsApi from "../../../admin/services/adminClientsApi";
import {
  getClientQueryKey,
  getClientSAMLMetadataQueryKey,
} from "../../../admin/services/adminClientsQuery";
import { FormContext } from "../../utils/forms2/Form";
import { useFormFactory } from "../../utils/forms/formFactory";
import { validators } from "../../utils/forms/validation";
import { EApplicationTabs } from "../../utils/viewTabs";
import DialogFullScreen from "../DialogFullScreen";
import Tabs from "../Tabs";
import {
  handleErrorWithNotify,
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../notifications/notificationService";

enum UploadMode {
  FILE = "file",
  URL = "url",
  XML = "xml",
}

const initialData = { file: "", url: "", xml: "" };

interface Props {
  clientId: string | undefined;
  onCancel: () => void;
  onTabChange: (tab: EApplicationTabs) => void;
}

export default function SamlUploadDialog({ clientId, onCancel, onTabChange }: Props) {
  const [progress, setProgress] = useState(false);
  const [mode, setMode] = useState<UploadMode>(UploadMode.XML);

  const queryClient = useQueryClient();

  const formFactory = useFormFactory({
    id: "saml-upload-dialog",
    data: initialData,
    progress,
  });

  const handleImport = (data: typeof initialData) => {
    switch (mode) {
      case UploadMode.URL:
        if (!clientId || !data.url) return Promise.reject();
        return adminClientsApi
          .importSAMLMetadataFromURL({ cid: clientId, url: data.url })
          .then(() => notifySuccess("SAML Service Provider Metadata uploaded"))
          .catch(
            notifyErrorOrDefaultTo(
              "Error occurred while trying to import SAML Service Provider Metadata"
            )
          );

      case UploadMode.FILE:
        if (!clientId || !data.file) return Promise.reject();
        return adminClientsApi
          .importSAMLMetadataFromFile({ cid: clientId, file: data.file })
          .then(() => notifySuccess("SAML Service Provider Metadata uploaded"))
          .catch(
            notifyErrorOrDefaultTo(
              "Error occurred while trying to import SAML Service Provider Metadata"
            )
          );

      case UploadMode.XML:
        if (!clientId || !data.xml) return Promise.reject();
        return adminClientsApi
          .importSAMLMetadataFromText({ cid: clientId, text: data.xml })
          .then(() => notifySuccess("SAML Service Provider Metadata uploaded"))
          .catch(
            notifyErrorOrDefaultTo(
              "Error occurred while trying to import SAML Service Provider Metadata"
            )
          );

      default:
        return Promise.reject();
    }
  };

  return (
    <DialogFullScreen onCancel={onCancel} id="saml-upload-dialog" title="Upload Metadata">
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Tabs
            value={mode}
            onChange={(_, tab) => {
              formFactory.clearErrors();
              setMode(tab);
            }}
          >
            <Tab id="xml-tab" label="Plain XML" value={UploadMode.XML} key={UploadMode.XML} />
            <Tab id="file-tab" label="File" value={UploadMode.FILE} key={UploadMode.FILE} />
            <Tab id="url-tab" label="URL" value={UploadMode.URL} key={UploadMode.URL} />
          </Tabs>
          <FormContext.Provider value={formFactory.context}>
            {mode === UploadMode.XML &&
              formFactory.createEditorField({
                name: "xml",
                editorProps: {
                  height: 500,
                  defaultLanguage: "html",
                  onValidate: undefined,
                },
                rules: {
                  required: "XML value is required",
                  validate: {
                    validXML: validators.validXML({ label: "XML" }),
                    validJson: () => true,
                  },
                },
              })}
            {mode === UploadMode.FILE &&
              formFactory.createUploadField({
                name: "file",
                title: "File",
                helperText: "Upload XML formatted file",
                validate: {
                  isXMLFile: v => v?.name?.trim().endsWith(".xml") || "Provided file is not XML",
                },
              })}
            {mode === UploadMode.URL &&
              formFactory.createRequiredField({
                label: "URL",
                name: "url",
                placeholder: "SAML metadata URL",
                rules: {
                  validate: {
                    validURL: v =>
                      isURL(v?.trim(), {
                        protocols: ["http", "https"],
                        require_protocol: true,
                        require_tld: false,
                      }) || "URL is not valid",
                  },
                },
              })}

            {formFactory.createFormFooter({
              onSubmit: data => {
                if (!data) return;
                setProgress(true);
                handleImport(data)
                  .then(() => {
                    if (clientId) {
                      queryClient.invalidateQueries({
                        queryKey: getClientSAMLMetadataQueryKey(clientId),
                      });
                      queryClient.invalidateQueries({ queryKey: getClientQueryKey(clientId) });
                    }
                    onTabChange(EApplicationTabs.SAML);
                  })
                  .catch(handleErrorWithNotify("Error occurred while trying to upload metadata"))
                  .finally(() => {
                    setProgress(false);
                    onCancel();
                  });
              },
              submitText: "Upload",
              onCancel,
            })}
          </FormContext.Provider>
        </Grid>
      </Grid>
    </DialogFullScreen>
  );
}

import React, { CSSProperties, useEffect } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { makeStyles } from "tss-react/mui";

import { useFormContext } from "../forms2/Form";

const useStyles = makeStyles()(theme => ({
  submitButton: {
    marginLeft: 16,
    fontSize: 14,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.9,
    },
  },
  cancelButton: {
    padding: "12px 24px",
  },
}));

export interface FormFooterProps {
  id?: string;
  cancelText?: string;
  submitText?: string;
  submitWithEnterKey?: boolean;
  onCancel?: () => void;
  onSubmit?: (data: any) => void;
  onSubmitButtonClick?: () => void;
  style?: CSSProperties;
  disabled?: boolean;
}

export default function FormFooter({
  id,
  cancelText,
  submitText,
  submitWithEnterKey,
  onCancel,
  onSubmit,
  onSubmitButtonClick,
  style,
  disabled,
}: FormFooterProps) {
  const { classes } = useStyles();
  const {
    form,
    progress,
    disabled: formDisabled,
    submitButtonDisabled,
    id: formId,
    noManagePermission,
  } = useFormContext();

  const htmlId = id || formId || "form-footer";

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter" && onSubmit) {
        event.preventDefault();
        form.handleSubmit(onSubmit)();
      }
    };

    if (submitWithEnterKey) {
      document.addEventListener("keydown", keyDownHandler);
    }

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [submitWithEnterKey, form, onSubmit]);

  return (
    <div style={{ textAlign: "right", ...style }}>
      {onCancel && (
        <Button
          id={`${htmlId}-cancel-button`}
          size="large"
          onClick={onCancel}
          className={classes.cancelButton}
        >
          {cancelText || "Cancel"}
        </Button>
      )}
      {onSubmit && !noManagePermission && (
        <LoadingButton
          id={`${htmlId}-confirm-button`}
          variant="contained"
          size="large"
          disabled={progress || disabled || formDisabled || submitButtonDisabled}
          onClick={e => {
            if (onSubmitButtonClick) {
              onSubmitButtonClick();
            }
            form.handleSubmit(onSubmit)(e);
          }}
          className={classes.submitButton}
          loading={progress}
        >
          {submitText || "Save"}
        </LoadingButton>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";

import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Box, Code, Feather, Info, Lock, LogOut, Mail, Settings } from "react-feather";

import { ServerResponseTypeEnum } from "@cloudentity/acp-admin";

import annotationUserIcon from "../../../assets/images/icons/selfService/annotation-user.svg";
import {
  getAdminPortalUrl,
  getAdminPortalUrlWithPoolContext,
  getServerId,
  getTenantId,
  portalMode,
} from "../../../common/api/paths";
import { logout } from "../../../common/auth/actions/actions";
import Logo from "../../../common/components/Logo";
import Switch from "../../../common/components/Switch";
import AdministratorsIcon from "../../../common/components/icons/AdministratorsIcon";
import EnforceIcon from "../../../common/components/icons/EnforceIcon";
import IdentityPoolIcon from "../../../common/components/icons/IdentityPoolIcon";
import OrganizationsIcon from "../../../common/components/icons/OrganizationsIcon";
import PermissionSystemIcon from "../../../common/components/icons/PermissionSystemIcon";
import NavbarButton from "../../../common/components/nav/NavbarButton";
import ProfileSettings from "../../../common/components/nav/ProfileSettings";
import { useTopBarStyles } from "../../../common/components/nav/utils";
import SelfServiceController from "../../../common/components/selfServiceSettings/SelfServiceController";
import { BRAND_NAME } from "../../../common/theme/theme";
import { useFeature } from "../../../common/utils/hooks/useFeature";
import { useStyling } from "../../../common/utils/hooks/useStyling";
import { useGetPublicEnvironment } from "../../../user/services/userEnvironmentQuery";
import { useGetEnvironment } from "../../services/adminEnvironmentQuery";
import {
  useCheckPoolPermissions,
  useGetPoolWithoutNotification,
} from "../../services/adminIdentityPoolsQuery";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import { useGetWorkspace } from "../../services/adminServersQuery";
import { useCheckTenantPermissions } from "../../services/adminTenantsQuery";
import { useGetUserInfo } from "../../services/oauth2Query";
import useWorkspacesSeqOrCursor from "../common/EnhancedTableAsync/useWorkspacesSeqOrCursor";
import { useWorkspace } from "../common/useWorkspace";
import {
  checkPermissionsToDisplayNavDrawer,
  isWorkspacesItemVisible,
} from "../workspaceDirectory/TenantView";
import { BUILD_IN_ADMIN_POOL_ID } from "../workspaceDirectory/administrator/AdministratorManagement";
import TopBarNotifications from "./TopBarNotifications";

interface Props {
  hideTenantSettings?: boolean;
  logoUrl?: string;
  stripped?: boolean;
  adminPanel?: boolean;
}

export default function TopBar({
  hideTenantSettings = false,
  logoUrl,
  stripped = false,
  adminPanel = false,
}: Props) {
  const theme = useTheme();
  const { cx, classes } = useTopBarStyles({ stripped });
  const navigate = useNavigate();
  const location = useLocation();
  const [workspace] = useWorkspace();
  const userinfoQuery = useGetUserInfo();

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);

  const { logo } = useStyling(theme, stripped ? getServerId() : undefined);

  const getWorkspaceQuery = useGetWorkspace(workspace, {
    enabled: !!checkWorkspacePermissionsQuery.data?.discover_workspace && !stripped,
  });
  const workspaceObj = getWorkspaceQuery.data;

  const getEnvironmentQuery = useGetEnvironment();
  const commit = getEnvironmentQuery.data?.commit;

  const getPublicEnvironmentQuery = useGetPublicEnvironment();
  const withPermissions = useFeature("with_permissions");
  const arePermissionsEnabled = useFeature("permissions");
  const isOrganizationsEnabled = useFeature("organizations");
  const isSelfServiceEnabled = !!getPublicEnvironmentQuery.data?.self_service;

  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const checkPoolPermissions = useCheckPoolPermissions(BUILD_IN_ADMIN_POOL_ID, {
    enabled: !stripped,
  });
  const buildInAdminPoolQuery = useGetPoolWithoutNotification(BUILD_IN_ADMIN_POOL_ID, {
    enabled: !!checkPoolPermissions.data?.get_identity_pool,
  });

  const listWorkspacesQuery = useWorkspacesSeqOrCursor({ enabled: !stripped });
  const workspaces = listWorkspacesQuery.totalData ?? [];

  const issuerUrl = getEnvironmentQuery.data?.admin_issuer_url;
  const adminPortalUrl = getAdminPortalUrl(issuerUrl);
  const linkToAdminPortal = getAdminPortalUrlWithPoolContext(
    adminPortalUrl,
    location.pathname,
    portalMode,
    adminPanel
      ? {
          id: workspace,
          isOrganization: workspaceObj?.type === ServerResponseTypeEnum.Organization,
        }
      : undefined
  );

  const [anchorSettings, setAnchorSettings] = useState<HTMLButtonElement | null>(null);
  const [anchorProfile, setAnchorProfile] = useState<HTMLButtonElement | null>(null);
  const [showSelfService, setShowSelfService] = useState(false);

  const handleMenuOpen = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: "settings" | "profile"
  ) => {
    if (type === "settings") {
      setAnchorSettings(e.currentTarget);
    } else if (type === "profile") {
      setAnchorProfile(e.currentTarget);
    }
  };

  const handleOpenSelfService = () => {
    setShowSelfService(true);
    setAnchorProfile(null);
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Logo
          classes={{ logo: classes.logo }}
          logoUrl={logoUrl || logo.url}
          style={logo.size ? { height: logo.size } : {}}
        />
        <div className={classes.spacing} />
        {checkWorkspacePermissionsQuery.data?.get_workspace && !stripped && !hideTenantSettings && (
          <TopBarNotifications />
        )}
        {checkPermissionsToDisplayNavDrawer(checkTenantPermissionsQuery.data || {}, workspaces) &&
          !stripped &&
          !hideTenantSettings && (
            <IconButton
              onClick={e => handleMenuOpen(e, "settings")}
              aria-label="show settings list"
              aria-haspopup="true"
              className={classes.iconButton}
              size="large"
            >
              <Settings color="white" size="20" />
            </IconButton>
          )}
        {!hideTenantSettings && (
          <NavbarButton
            onClick={e => handleMenuOpen(e, "profile")}
            email={userinfoQuery.data?.email}
            tenantId={getTenantId()}
            stripped={stripped}
          />
        )}
      </Toolbar>

      <Menu
        id="topbar-settings-menu"
        open={Boolean(anchorSettings)}
        anchorEl={anchorSettings}
        onClose={() => setAnchorSettings(null)}
        classes={{ paper: classes.menuPaper, list: classes.menuList }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Typography variant="h5" className={classes.menuItemTitle}>
          Tenant Settings
        </Typography>
        <Divider className={classes.divider} />
        {isWorkspacesItemVisible(checkTenantPermissionsQuery.data || {}, workspaces) && (
          <ListItem
            id="workspaces-button"
            button
            onClick={() =>
              navigate("/workspace-directory", {
                state: { backToWorkspace: location.pathname },
              })
            }
            className={classes.listItem}
          >
            <ListItemIcon>
              <Box />
            </ListItemIcon>
            <ListItemText
              style={{ whiteSpace: "nowrap" }}
              disableTypography
              primary={<Typography variant="body2">Workspaces</Typography>}
            />
          </ListItem>
        )}

        {isOrganizationsEnabled && (
          <ListItem
            id="organizations-button"
            button
            onClick={() =>
              navigate("/organization-directory", {
                state: { backToWorkspace: location.pathname },
              })
            }
            className={classes.listItem}
          >
            <ListItemIcon>
              <OrganizationsIcon />
            </ListItemIcon>
            <ListItemText
              style={{ whiteSpace: "nowrap" }}
              disableTypography
              primary={<Typography variant="body2">Organizations</Typography>}
            />
          </ListItem>
        )}

        {checkTenantPermissionsQuery.data?.list_identity_pools && (
          <ListItem
            id="identity-pools-button"
            button
            onClick={() =>
              navigate("/identity-pools/pools", {
                state: { backToWorkspace: location.pathname },
              })
            }
            className={classes.listItem}
          >
            <ListItemIcon>
              <IdentityPoolIcon />
            </ListItemIcon>
            <ListItemText
              style={{ whiteSpace: "nowrap" }}
              disableTypography
              primary={<Typography variant="body2">Identity Pools</Typography>}
            />
          </ListItem>
        )}

        {withPermissions &&
          arePermissionsEnabled &&
          checkTenantPermissionsQuery.data?.read_permission_systems && (
            <ListItem
              id="permissions-button"
              button
              onClick={() => navigate("/permissions")}
              className={classes.listItem}
            >
              <ListItemIcon>
                <PermissionSystemIcon />
              </ListItemIcon>
              <ListItemText
                style={{ whiteSpace: "nowrap" }}
                disableTypography
                primary={<Typography variant="body2">Permission Systems</Typography>}
              />
            </ListItem>
          )}

        {checkTenantPermissionsQuery.data?.read_mfa_methods && (
          <ListItem
            id="mfa-settings-button"
            button
            onClick={() =>
              navigate("/mfa-settings", {
                state: { backToWorkspace: location.pathname },
              })
            }
            className={classes.listItem}
          >
            <ListItemIcon>
              <Lock />
            </ListItemIcon>
            <ListItemText
              style={{ whiteSpace: "nowrap" }}
              disableTypography
              primary={<Typography variant="body2">MFA Settings</Typography>}
            />
          </ListItem>
        )}

        {checkTenantPermissionsQuery.data?.read_mfa_methods && (
          <ListItem
            id="message-providers-button"
            button
            onClick={() =>
              navigate("/message-providers/emails", {
                state: { backToWorkspace: location.pathname },
              })
            }
            className={classes.listItem}
          >
            <ListItemIcon>
              <Mail />
            </ListItemIcon>
            <ListItemText
              style={{ whiteSpace: "nowrap" }}
              disableTypography
              primary={<Typography variant="body2">Message Providers</Typography>}
            />
          </ListItem>
        )}

        {checkTenantPermissionsQuery.data?.read_brute_force_protection && (
          <ListItem
            id="brute-force-protection-button"
            button
            onClick={() =>
              navigate("/brute-force-protection", {
                state: { backToWorkspace: location.pathname },
              })
            }
            className={classes.listItem}
          >
            <ListItemIcon>
              <EnforceIcon />
            </ListItemIcon>
            <ListItemText
              style={{ whiteSpace: "nowrap" }}
              disableTypography
              primary={<Typography variant="body2">Brute-force Protection</Typography>}
            />
          </ListItem>
        )}
        {checkTenantPermissionsQuery.data?.read_themes && (
          <ListItem
            id="appearance-button"
            button
            onClick={() =>
              navigate("/appearance/branding", {
                state: { backToWorkspace: location.pathname },
              })
            }
            className={classes.listItem}
          >
            <ListItemIcon>
              <Feather />
            </ListItemIcon>
            <ListItemText
              style={{ whiteSpace: "nowrap" }}
              disableTypography
              primary={<Typography variant="body2">Appearance</Typography>}
            />
          </ListItem>
        )}

        {buildInAdminPoolQuery.isSuccess && (
          <ListItem
            id="administrators-button"
            button
            onClick={() =>
              navigate("/administrators/users", {
                state: { backToWorkspace: location.pathname },
              })
            }
            className={classes.listItem}
          >
            <ListItemIcon>
              <AdministratorsIcon />
            </ListItemIcon>
            <ListItemText
              style={{ whiteSpace: "nowrap" }}
              disableTypography
              primary={<Typography variant="body2">Account</Typography>}
            />
          </ListItem>
        )}
      </Menu>

      <Menu
        id="topbar-profile-menu"
        open={Boolean(anchorProfile)}
        anchorEl={anchorProfile}
        onClose={() => setAnchorProfile(null)}
        classes={{ paper: classes.menuPaper, list: classes.menuList }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <ProfileSettings stripped={stripped} />

        <Divider className={classes.divider} />

        {isSelfServiceEnabled && location.pathname !== "/profile" && (
          <ListItemButton
            id="self-service-button"
            className={classes.listItem}
            onClick={handleOpenSelfService}
          >
            <ListItemIcon>
              <img src={annotationUserIcon} alt="user icon" style={{ width: 24, height: 24 }} />
            </ListItemIcon>
            <ListItemText
              style={{ whiteSpace: "nowrap" }}
              disableTypography
              primary={<Typography variant="body2">User Profile</Typography>}
            />
          </ListItemButton>
        )}

        {checkTenantPermissionsQuery.data?.create_workspace &&
          linkToAdminPortal &&
          adminPortalUrl &&
          getServerId() === "admin" &&
          isOrganizationsEnabled && (
            <ListItemButton
              id="admin-b2b-button"
              className={cx(classes.listItem, classes.listItemB2B)}
            >
              <ListItemIcon>
                <OrganizationsIcon />
              </ListItemIcon>
              <ListItemText
                style={{ whiteSpace: "nowrap" }}
                disableTypography
                primary={<Typography variant="body2">Business Admin Mode</Typography>}
              />
              <ListItemIcon style={{ display: "flex", justifyContent: "flex-end" }}>
                <Switch
                  checked={portalMode === "b2b"}
                  onChange={() => {
                    window.open(linkToAdminPortal, "_self");
                  }}
                />
              </ListItemIcon>
            </ListItemButton>
          )}

        {!stripped && (
          <Link
            href="https://cloudentity.com/developers/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <ListItem id="developer-guide-button" button className={classes.listItem}>
              <ListItemIcon>
                <Code />
              </ListItemIcon>
              <ListItemText
                style={{ whiteSpace: "nowrap" }}
                disableTypography
                primary={<Typography variant="body2">Developer guide</Typography>}
              />
            </ListItem>
          </Link>
        )}
        {!stripped && (
          <Link
            href="https://cloudentity.com/contact/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <ListItem id="support-button" button className={classes.listItem}>
              <ListItemIcon>
                <Info />
              </ListItemIcon>
              <ListItemText
                style={{ whiteSpace: "nowrap" }}
                disableTypography
                primary={<Typography variant="body2">Support</Typography>}
              />
            </ListItem>
          </Link>
        )}
        <Divider className={classes.divider} />
        {!stripped && (
          <div>
            <Typography component="div" variant="textXSM" className={classes.version}>
              <>
                {BRAND_NAME} (Version {commit})
              </>
            </Typography>
            <Divider className={classes.divider} />
          </div>
        )}
        <ListItem
          id="logout-button"
          button
          onClick={() => logout("admin", { withPromptLogin: true })}
          className={classes.listItem}
        >
          <ListItemIcon>
            <LogOut />
          </ListItemIcon>
          <ListItemText
            style={{ whiteSpace: "nowrap" }}
            disableTypography
            primary={<Typography variant="body2">Logout</Typography>}
          />
        </ListItem>
      </Menu>

      {isSelfServiceEnabled && showSelfService && (
        <SelfServiceController
          config={{
            withDialog: true,
            showMetadata: true,
          }}
          onClose={() => setShowSelfService(false)}
        />
      )}
    </AppBar>
  );
}

import React, { useEffect, useState } from "react";

import IconButton from "@mui/material/IconButton";
import { Edit } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { Policy } from "@cloudentity/acp-admin";

import { useWorkspace } from "../../../admin/components/common/useWorkspace";
import { PolicySelection, Unrestricted } from "../../../admin/components/policies/PolicySelection";
import { useGetPolicy } from "../../../admin/services/adminPoliciesQuery";
import { FormContext } from "../forms2/Form";
import commonTextField from "../forms/CommonTextField";
import { useFormFactory } from "./formFactory";

const useStyles = makeStyles()(theme => ({
  primaryMainColor: {
    color: theme.palette.primary.main,
  },
}));

export const PolicySelectionField = ({ onOpen, onClose, goBackButton, ...props }) => {
  const { classes } = useStyles();
  const formFactory = useFormFactory({ id: "fake" });
  const [open, setOpen] = useState(props.open);
  const [previewOpen, setPreviewOpen] = useState(false);
  const policyId = props.form.watch(props.name);
  const [workspace] = useWorkspace();

  const getPolicyQuery = useGetPolicy(policyId, workspace, { enabled: !!policyId });
  const optionalValue = getPolicyQuery.data;
  const placeholder = Unrestricted;
  const value = optionalValue !== undefined ? optionalValue.policy_name : "";

  useEffect(() => {
    formFactory.setValue(props.name, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, props.name]);

  const propsHidden = { ...props, hideLabel: true, style: { display: "none" } };
  const propsVisible = {
    ...props,
    placeholder: placeholder,
    withProgress: props.withProgress || getPolicyQuery.isLoading,
    withEndAdornment:
      optionalValue && !props.disabled ? (
        <IconButton
          aria-label="link"
          data-testid={`policy-selection-field-presentation-edit-icon-${props.name}`}
          tabIndex={-1}
          onClick={e => {
            e.stopPropagation();
            setOpen(true);
            setPreviewOpen(true);
          }}
          size="large"
        >
          <Edit className={classes.primaryMainColor} />
        </IconButton>
      ) : undefined,
    form: formFactory,
    id: props.id + "-presentation",
    inputProps: {
      "data-testid": `policy-selection-presentation-${props.name}`,
    },
    value,
    onClick: () => {
      if (!props.disabled) {
        setOpen(true);
        onOpen && onOpen();
      }
    },
  };

  const changePolicy = (policy?: Policy, isNewPolicy?: boolean) => {
    props.form.setValue(props.name, policy?.id);

    if (!isNewPolicy) {
      innerOnClose();
    } else {
      setPreviewOpen(true);
    }
  };

  const innerOnClose = () => {
    setOpen(false);
    setPreviewOpen(false);
    onClose && onClose();
  };

  return (
    <FormContext.Provider value={formFactory.context}>
      {commonTextField(propsHidden as any)}
      {commonTextField(propsVisible as any)}
      {open && props.disabled !== true && (
        <PolicySelection
          goBackButton={goBackButton}
          workspaceID={props.workspace}
          policy={optionalValue}
          onChange={changePolicy}
          previewOpen={previewOpen}
          onPreviewClosed={() => {
            if (previewOpen) {
              innerOnClose();
            }
          }}
          onClose={() => {
            innerOnClose();
          }}
          type={props.type}
          label={props.label}
          target={props.target}
        />
      )}
    </FormContext.Provider>
  );
};

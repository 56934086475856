import React from "react";
import { useNavigate } from "react-router";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { logout } from "../../common/auth/actions/actions";
import { FormContext } from "../../common/utils/forms2/Form";
import { useFormFactory } from "../../common/utils/forms/formFactory";
import userApi from "../services/userApi";

const useStyles = makeStyles()(() => ({
  paperRoot: {
    padding: 32,
    borderRadius: 4,
  },
}));

const ChangePassword = () => {
  const { classes } = useStyles();
  const formFactory = useFormFactory({ id: "user-portal-change-password" });
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    newPassword: "",
    error: null,
  });

  const handleChange = prop => event => {
    setState({ ...state, [prop]: event.target.value });
  };

  const handleSubmit = data => {
    userApi
      .changePassword(data.current, data.new)
      .then(logout)
      .catch(e => {
        setState({ ...state, error: e.response.data });
      });
  };

  return (
    <Container style={{ marginTop: 40, position: "relative" }} maxWidth="md">
      <Paper className={classes.paperRoot}>
        <Typography
          id="change-password-header"
          variant="h5"
          style={{ marginBottom: 10, color: "rgba(0, 0, 0, 0.87)" }}
        >
          Change password
        </Typography>

        <FormContext.Provider value={formFactory.context}>
          {formFactory.createRequiredField({
            name: "current",
            label: "Current password",
            autoFocus: true,
            toggleVisibility: true,
            defaultVisibility: false,
            externalErrors:
              state.error &&
              state.error.error === "incorrect old password" &&
              "Invalid current password",
          })}

          {formFactory.createRequiredField({
            name: "new",
            label: "New password",
            onChange: handleChange("newPassword"),
            autoFocus: true,
            toggleVisibility: true,
            defaultVisibility: false,
            withPasswordStrengthMeter: true,
            externalErrors:
              state.error &&
              state.error.status_code === 422 &&
              `${state.error.error}${
                state.error.details.length > 0 ? ": " : ""
              }${state.error.details.map(v => v.message).join(" | ")}`,
          })}

          {formFactory.createRequiredField({
            name: "repeat",
            label: "Repeat password",
            autoFocus: true,
            toggleVisibility: true,
            defaultVisibility: false,
            validate: {
              equal: v => (v !== state.newPassword ? "Passwords are different" : null),
            },
          })}

          {formFactory.createFormFooter({
            onCancel: () => {
              navigate("/");
            },
            onSubmit: handleSubmit,
            submitText: "Save",
          })}
        </FormContext.Provider>
      </Paper>
    </Container>
  );
};

export default ChangePassword;

import React, { useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import isEqual from "lodash/isEqual";
import { makeStyles } from "tss-react/mui";

import { Styling, Theme } from "@cloudentity/acp-admin";

import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import FormSection from "../../../common/components/FormSection";
import RouteLeavingGuard from "../../../common/components/RouteLeavingGuard";
import { notifySuccess } from "../../../common/components/notifications/notificationService";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import { validators } from "../../../common/utils/forms/validation";
import { getDefaultStyling, getStyling } from "../../../common/utils/hooks/useStyling";
import { useCheckTenantPermissions } from "../../services/adminTenantsQuery";
import Preview from "./Preview";

const useStyles = makeStyles()(theme => ({
  paper: {
    padding: 32,
    borderRadius: 4,
    boxShadow: theme.custom.shadows?.surfaceResting,
    border: "none",
  },
}));

function FormContainer({ children }) {
  const { classes } = useStyles();

  return <Paper className={classes.paper}>{children}</Paper>;
}

interface Props {
  themeId?: string;
  themes?: Theme[];
  styling: Styling;
  onUpdate: (styling?: Styling, themeId?: string) => Promise<any>;
  defaults?: Styling;
  mode: "workspace" | "tenant";
  visibleSteps?: string[];
  hasManagePermission: boolean;
}

export default function Appearance({
  themeId,
  themes,
  styling,
  onUpdate,
  defaults,
  mode,
  visibleSteps,
  hasManagePermission,
}: Props) {
  const theme = useTheme();
  const defaultStyling = useMemo(() => getDefaultStyling(theme), [theme]);

  const [progress, setProgress] = useState(false);

  const navigate = useNavigate();
  const checkTenantPermissionQuery = useCheckTenantPermissions();

  const [resetConfirmDialog, setResetConfirmDialog] = useState(false);

  const [currentData, setCurrentData] = useState<Styling>(defaultStyling);

  const initialData = useMemo(() => {
    const stylingWithDefaults = getStyling({ styling: defaults, defaults: defaultStyling });
    const initialStyling = getStyling({ styling, defaults: stylingWithDefaults });

    setCurrentData(initialStyling);
    return {
      ...initialStyling,
      logo_url: mode === "workspace" ? styling.logo_url : initialStyling.logo_url,
      font_url: mode === "workspace" ? styling.font_url : initialStyling.font_url,
      themeId,
    };
  }, [styling, defaults, mode, themeId, defaultStyling]);

  const formFactory = useFormFactory({
    id: "custom-branding-settings",
    data: initialData,
    noManagePermission: !hasManagePermission,
    progress: progress,
  });

  const currentThemeId = useWatch({ name: "themeId", control: formFactory.control });

  function onSubmit() {
    setProgress(true);

    const stylingWithDefaults = getStyling({ styling: defaults, defaults: defaultStyling });
    const initialStyling = getStyling({ styling, defaults: stylingWithDefaults });

    onUpdate(isEqual(currentData, initialStyling) ? undefined : currentData, currentThemeId)
      .then(() => notifySuccess("Branding successfully updated"))
      .finally(() => setProgress(false));
  }

  function handleChange(property, value) {
    if (property === "font_url" || property === "logo_url" || property === "logo_size") {
      setCurrentData(currentData => ({ ...currentData, [property]: value }));
    } else {
      setCurrentData(currentData => ({
        ...currentData,
        colors: {
          ...currentData.colors,
          [property]: value.toUpperCase(),
        },
      }));
    }
  }

  function onRevertToDefaults() {
    const emptyStyling = getStyling();
    const stylingWithDefaults = getStyling({ styling: defaults, defaults: defaultStyling });
    const initialStyling = getStyling({ styling, defaults: stylingWithDefaults });

    setCurrentData(initialStyling);

    setProgress(true);
    return onUpdate(emptyStyling)
      .then(() => notifySuccess("Branding successfully updated"))
      .finally(() => setProgress(false));
  }

  const themesAsOptions = useMemo(
    () => [
      { value: "", name: "Default" },
      ...(themes || []).map(t => ({ value: t.id || "", name: t.name || t.id || "" })),
    ],
    [themes]
  );

  return (
    <FormContext.Provider value={formFactory.context}>
      <Grid container spacing={3} data-testid="basic-branding-container">
        <Grid item xs={4}>
          <FormContainer>
            {mode === "workspace" && checkTenantPermissionQuery.data?.read_themes && (
              <>
                <FormSection
                  id="custom-branding-theme-title-label"
                  title="Theme"
                  style={{ color: theme.palette.secondary.dark }}
                >
                  <Button
                    variant="text"
                    size="small"
                    style={{ textTransform: "uppercase" }}
                    onClick={() => navigate("/appearance/themes")}
                  >
                    Manage
                  </Button>
                </FormSection>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {formFactory.createSelect({
                      name: "themeId",
                      label: "Name",
                      options: themesAsOptions,
                      displayEmpty: true,
                      onChange: () => {},
                      disabled: !hasManagePermission,
                      optional: false,
                    })}
                  </Grid>
                </Grid>
              </>
            )}

            <FormSection
              id="custom-branding-settings-title-label"
              title="Branding"
              style={{ color: theme.palette.secondary.dark }}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {formFactory.createColorField({
                  name: "colors.primary",
                  label: "Primary color",
                  value: currentData.colors?.primary || "#000000",
                  onChange: value => handleChange("primary", value),
                })}
              </Grid>
              <Grid item xs={6}>
                {formFactory.createColorField({
                  name: "colors.header",
                  label: "Header color",
                  value: currentData.colors?.header || "#000000",
                  onChange: value => handleChange("header", value),
                })}
              </Grid>

              <Grid item xs={12} style={{ height: 120 }}>
                {formFactory.createField({
                  name: "logo_url",
                  label: "Logo URL",
                  onChange: e => handleChange("logo_url", e.target.value),
                  optional: false,
                  placeholder: "Your logo URL",
                  tooltip:
                    "The logo helps users identify your company. The icon for each application is imported from the application settings.",
                  rules: {
                    validate: {
                      validURL: validators.validURL({ label: "Logo URL" }),
                    },
                  },
                  style: {
                    marginBottom: 16,
                  },
                })}
              </Grid>

              <Grid item xs={12}>
                {formFactory.createChipsGroupField({
                  name: "logo_size",
                  label: "Logo size",
                  options: [
                    { label: "Small", value: "S" },
                    { label: "Medium", value: "M" },
                    { label: "Large", value: "L" },
                  ],
                  onChange: value => handleChange("logo_size", value),
                  style: { marginBottom: 16 },
                })}
              </Grid>

              <Grid item xs={12}>
                {formFactory.createField({
                  name: "font_url",
                  label: "Font URL",
                  helperText: "Supported font formats: WOFF, WOFF2, TTF.",
                  onChange: e => handleChange("font_url", e.target.value),
                  optional: false,
                  placeholder: "Your font URL",
                  rules: {
                    validate: {
                      validURL: validators.validURL({ label: "Font URL" }),
                      validURLExtension: validators.validURLExtension({
                        type: "font",
                        extensions: ["woff", "woff2", "ttf"],
                      }),
                    },
                  },
                  style: { marginBottom: 16 },
                })}
              </Grid>
            </Grid>

            {formFactory.createFormFooter({
              onSubmit,
              submitText: "Publish",
              onCancel:
                hasManagePermission && !isEqual(styling, getStyling())
                  ? () => setResetConfirmDialog(true)
                  : undefined,
              cancelText: "Reset",
              style: { marginTop: 22 },
            })}

            {resetConfirmDialog && (
              <ConfirmationDialog
                title="Reset branding settings"
                content={
                  mode === "workspace"
                    ? "You're about to reset workspace branding parameters and use tenant default branding. This cannot be undone. Reset anyway?"
                    : "You're about to reset parameters to defaults. This cannot be undone. Reset anyway?"
                }
                confirmText="Reset and Publish"
                progress={progress}
                onCancel={() => setResetConfirmDialog(false)}
                onConfirm={() => {
                  onRevertToDefaults().then(() => setResetConfirmDialog(false));
                }}
              />
            )}
            <RouteLeavingGuard />
          </FormContainer>
        </Grid>

        <Grid item xs={8}>
          <Preview
            themeId={currentThemeId ?? themeId}
            currentData={currentData}
            initialData={initialData}
            mode={mode}
            visibleSteps={visibleSteps}
          />
        </Grid>
      </Grid>
    </FormContext.Provider>
  );
}

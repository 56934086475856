import React, { useEffect, useMemo } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { lensPath, map, over, pathOr, pipe, prop } from "ramda";
import { Globe } from "react-feather";

import { ServerResponse } from "@cloudentity/acp-admin";
import { OIDCSettingsAuthenticationMethodEnum } from "@cloudentity/acp-root";

import FormAccordion from "../../../common/components/FormAccordion";
import RouteLeavingGuard from "../../../common/components/RouteLeavingGuard";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import { validators } from "../../../common/utils/forms/validation";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import CardWithIconAndTitle from "../common/CardWithIconAndTitle";
import Instructions from "../common/Instructions";
import { CommonIdpConfig } from "./CommonIdpConfig";
import CommonIdpConfigUpper from "./CommonIdpConfigUpper";
import IdentitiesDetailsFooter from "./IdentitiesDetailsFooter";
import SSOIDPSettings from "./SSOIDPSettings";
import { amrOptions, amrToResponse, getAMRLabel } from "./amrOptions";
import { IdpUiModelOidcType, providers } from "./identities.utils";

const id = "identities-configuration-oidc";

const authenticationMethodOptions: {
  value: OIDCSettingsAuthenticationMethodEnum;
  label: string;
}[] = [
  {
    value: OIDCSettingsAuthenticationMethodEnum.ClientSecret,
    label: "Client Secret",
  },
  {
    value: OIDCSettingsAuthenticationMethodEnum.PrivateKeyJwt,
    label: "Private Key JWT",
  },
];

interface Props {
  provider: IdpUiModelOidcType;
  server: ServerResponse | undefined;
  updateProgress?: boolean;
  inEdit?: boolean;
  onLogoEdit?: (data: any) => void;
  customSubmit?: boolean;
  onInit?: (fn: () => void) => void;
  onCancel?: () => void;
  onSubmit: (data: IdpUiModelOidcType) => void;
  onDelete?: (idp: IdpUiModelOidcType) => void;
}

export default function IdentitiesConfigurationOIDC({
  provider,
  server,
  updateProgress,
  inEdit,
  onLogoEdit,
  customSubmit,
  onCancel,
  onSubmit,
  onInit,
  onDelete,
}: Props) {
  const data = useMemo(
    () =>
      pipe(
        over(lensPath(["settings", "scopes"]), scopes => scopes || []),
        over(
          lensPath(["settings", "authentication_method"]),
          method => method || OIDCSettingsAuthenticationMethodEnum.ClientSecret
        )
      )(provider),
    [provider]
  );

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(server?.id);

  const formFactory = useFormFactory({
    id,
    data,
    progress: updateProgress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit,
  });

  const submitFn = (provider, data) => ({ ...provider, ...amrToResponse(data), ...data });

  const providerMapData = providers.find(p => p.method === "oidc");

  const authenticationMethod = formFactory.watch("settings.authentication_method");

  useEffect(() => {
    onInit &&
      onInit(() =>
        formFactory.handleSubmit(data => onSubmit && onSubmit(submitFn(provider, data)))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serverSSOEnabled = server?.sso?.enabled ?? false;

  return (
    <FormContext.Provider value={formFactory.context}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Paper style={{ padding: 32 }}>
            {!inEdit && providerMapData && (
              <CardWithIconAndTitle
                img={providerMapData.icon}
                title={providerMapData.name}
                id={`idp-${providerMapData.name.replace(/ /g, "-")}`}
                style={{ marginBottom: 32, width: "50%" }}
              />
            )}

            <CommonIdpConfigUpper
              formFactory={formFactory}
              provider={provider}
              inEdit={inEdit}
              onLogoEdit={onLogoEdit}
              onUpdate={onSubmit}
            />
            {formFactory.createRequiredField({
              name: "settings.issuer_url",
              label: "Issuer URL",
              defaultValue: pathOr("", ["settings", "issuer_url"], provider),
              rules: { validate: { validURL: validators.validURL({ label: "Issuer URL" }) } },
            })}
            {formFactory.createRequiredField({
              name: "settings.client_id",
              label: "Client ID",
              defaultValue: pathOr("", ["settings", "client_id"], provider),
              withCopy: true,
              inputProps: {
                autoComplete: "off",
              },
            })}

            {formFactory.createAutocompleteField({
              name: "settings.authentication_method",
              label: "Authentication Method",
              defaultValue: pathOr([], ["settings.authentication_method"], provider),
              options: authenticationMethodOptions.map(o => o.value),
              getOptionLabel: option =>
                authenticationMethodOptions.find(o => o.value === option)?.label || option,
              optional: false,
            })}

            {authenticationMethod === "client_secret" &&
              formFactory.createRequiredField({
                name: "credentials.client_secret",
                label: "Client Secret",
                defaultValue: pathOr("", ["credentials", "client_secret"], provider),
                toggleVisibility: true,
                defaultVisibility: false,
                withCopy: true,
                inputProps: {
                  autoComplete: "off",
                },
              })}
            {authenticationMethod === "private_key_jwt" && (
              <>
                {formFactory.createRequiredField({
                  name: "credentials.private_key_jwt.key",
                  label: "Client Private Key",
                  defaultValue: pathOr("", ["credentials", "key"], provider),
                  rules: {
                    validate: { maxLength: () => true },
                  },
                  minRows: 5,
                  maxRows: 5,
                  placeholder: "-----BEGIN RSA PRIVATE KEY-----\n-----END RSA PRIVATE KEY-----",
                  helperText: "PEM formatted private key used to sign client assertion",
                  multiline: true,
                  withCopy: false,
                })}

                {formFactory.createAutocompleteField({
                  name: "credentials.private_key_jwt.algorithm",
                  label: "Algorithm",
                  defaultValue: pathOr([], ["credentials.algorithm"], provider),
                  helperText: "Used to sign the client assertion",
                  options: ["RS256", "RS384", "RS512", "ES256", "ES384", "ES512"],
                  optional: false,
                })}

                {formFactory.createDurationField({
                  name: "credentials.private_key_jwt.exp",
                  label: "Expires In",
                  description: "Defines how long client assertion is valid for",
                })}
              </>
            )}

            {formFactory.createReadOnlyField({
              name: "redirectUrl",
              label: "Redirect URL",
              withCopy: true,
            })}
            <CommonIdpConfig id={id} inEdit={inEdit} formFactory={formFactory} data={provider} />

            {inEdit && (
              <FormAccordion title="Advanced settings" id={id}>
                {formFactory.createAutocompleteField({
                  name: "settings.scopes",
                  label: "Scopes",
                  options: [],
                  freeSolo: true,
                  multiple: true,
                  optional: false,
                  helperText:
                    "To add multiple values, press the 'enter' key after adding each value",
                })}
                {formFactory.createAutocompleteField({
                  name: "static_amr",
                  label: "Authentication Method Reference",
                  helperText: "If set overwrites AMR obtained from this authentication method",
                  defaultValue: pathOr([], ["static_amr"], provider),
                  options: map(prop("value"), amrOptions),
                  getOptionLabel: getAMRLabel,
                  multiple: true,
                })}
                {formFactory.createCheckBox({
                  name: "settings.get_user_info",
                  label: "Get user info",
                  style: { marginBottom: 0 },
                })}
                {formFactory.createCheckBox({
                  name: "settings.send_login_hint",
                  label: (
                    <span>
                      Send <code>login_hint</code>
                    </span>
                  ),
                  helperText: (
                    <span>
                      Sends the entered identifier as a <code>login_hint</code> to the IDP
                    </span>
                  ),
                })}
                {serverSSOEnabled && <SSOIDPSettings formFactory={formFactory} />}
              </FormAccordion>
            )}

            <IdentitiesDetailsFooter
              customSubmit={customSubmit}
              idp={provider}
              formFactory={formFactory}
              onSubmit={data => onSubmit && onSubmit(submitFn(provider, data))}
              onCancel={onCancel}
              onDelete={onDelete}
            />
          </Paper>
        </Grid>
        {inEdit && (
          <Grid item xs={12} lg={5}>
            <Paper style={{ padding: 32 }}>
              {providerMapData && (
                <CardWithIconAndTitle
                  img={providerMapData.icon}
                  title={providerMapData.name}
                  id={providerMapData.name}
                  noBorder
                  style={{ marginBottom: 12 }}
                />
              )}
              <Instructions
                title="Instructions"
                text={
                  "To configure an OIDC connection, you need to enter your application's Issuer URL, Client ID and Client Secret, all of which should be available on your IDP's configuration page."
                }
                boxTitle="How to obtain the Issuer URL?"
                boxText={
                  "Enter the URL pointing to the app's OpenID discovery endpoint. For details, refer to the IDP app's configuration page."
                }
                boxLink="https://cloudentity.com/developers/howtos/identities/oidc/"
                boxIcon={<Globe />}
              />
            </Paper>
          </Grid>
        )}
        {inEdit && <RouteLeavingGuard />}
      </Grid>
    </FormContext.Provider>
  );
}

import { UserWithData } from "@cloudentity/acp-identity";

import { FormFactory } from "../../../../common/utils/forms/formFactory";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import { getTenantRoleOptionsByPermissions, tenantRoleOptions } from "./AdministratorUserEdit";
import { useIsCurrentLoggedInUser } from "./useIsCurrentLoggedInUser";
import { useTenantRoles } from "./useTenantRoles";

interface Props {
  user?: UserWithData;
  identityPoolId: string;
  formFactory: FormFactory;
}

export default function TenantRoleSelectField({ user, identityPoolId, formFactory }: Props) {
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const { isEnabled: isRoleVisible } = useTenantRoles({ identityPoolId });

  const isCurrentLoggedInUser = useIsCurrentLoggedInUser(user, "admin");

  const role = formFactory.watch("role");
  const currentRole = tenantRoleOptions.find(option => option.value === role);

  const isDisabled =
    !(
      checkTenantPermissionsQuery.data?.manage_admin_role ||
      checkTenantPermissionsQuery.data?.manage_auditor_role
    ) || isCurrentLoggedInUser;

  return isRoleVisible
    ? formFactory.createSelect({
        name: "role",
        label: "Tenant role",
        options: isDisabled
          ? tenantRoleOptions
          : getTenantRoleOptionsByPermissions(checkTenantPermissionsQuery.data),
        disabled: isDisabled,
        optional: false,
        displayEmpty: true,
        helperText: currentRole?.description ?? "",
        placeholder: "Select",
        rules: {
          required: "Tenant role is required",
        },
        renderValue: value => {
          return (currentRole?.name ?? value) as any;
        },
      })
    : null;
}

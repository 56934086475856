import React, { Dispatch, SetStateAction, useContext } from "react";

import validator from "@rjsf/validator-ajv8";
import { useQueryClient } from "@tanstack/react-query";
import { JSONSchema7 } from "json-schema";
import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import omitBy from "lodash/omitBy";
import { makeStyles } from "tss-react/mui";

import { BaseUserWithData, SupportedJSONSchema } from "@cloudentity/acp-identity";

import { GlobalStoreContext } from "../../../admin/GlobalStore/GlobalStore";
import {
  GetUserRolesBySubjectsReturnType,
  grantAndRevokeWorkspaceRoles,
} from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/list/utils";
import IdentityPoolUserSchemasTabDeleteButton from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/user/IdentityPoolUserSchemasTabDeleteButton";
import { mapFieldNameToTitle } from "../../../admin/components/workspaceDirectory/identityPools/schemas/schemas.utils";
import adminB2BUsersApi from "../../../admin/services/adminB2BUsersApi";
import { useCheckPoolPermissions } from "../../../admin/services/adminIdentityPoolsQuery";
import identityUsersApi from "../../../admin/services/adminIdentityUsersApi";
import {
  getUserQueryKey,
  listB2BUsersQueryKey,
  listUsersQueryKey,
  useGetUserMetadata,
} from "../../../admin/services/adminIdentityUsersQuery";
import { getTenantId } from "../../../common/api/paths";
import { notifySuccess } from "../../../common/components/notifications/notificationService";
import { FormFactory } from "../../../common/utils/forms/formFactory";
import { trimStringValues } from "../../../common/utils/object.utils";

const useStyles = makeStyles()(() => ({
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

interface Props {
  user: BaseUserWithData;
  workspaceId: string;
  poolId: string;
  data: {
    payload: { [key: string]: object };
    metadata: { [key: string]: object };
    businessMetadata: { [key: string]: object };
  };
  formFactory: FormFactory;
  submitAttempt: boolean;
  workspaceRolesChanged: boolean;
  resetExtraErrors: () => void;
  setProgress: Dispatch<SetStateAction<boolean>>;
  setSubmitAttempt: Dispatch<SetStateAction<boolean>>;
  setError: (err: any) => any;
  userRolesBySubjects: GetUserRolesBySubjectsReturnType;
  onClose: () => void;
  canManageUsers: boolean;
  schemas: {
    payloadSchema: SupportedJSONSchema | null;
    metadataSchema: SupportedJSONSchema | null;
    businessMetadataSchema: SupportedJSONSchema | null;
  };
}

export default function B2BUserEditFooter({
  user,
  workspaceId,
  poolId,
  data,
  formFactory,
  submitAttempt,
  workspaceRolesChanged,
  resetExtraErrors,
  setProgress,
  setSubmitAttempt,
  setError,
  userRolesBySubjects,
  onClose,
  canManageUsers,
  schemas,
}: Props) {
  const { classes } = useStyles();
  const globalStoreContext = useContext(GlobalStoreContext);

  const userId = user.id ?? "";

  const tenantId = getTenantId();
  const queryClient = useQueryClient();
  const checkPoolPermissionsQuery = useCheckPoolPermissions(poolId);
  const getUserAdminMetadataQuery = useGetUserMetadata(poolId, userId, "admin");
  const getUserBusinessMetadataQuery = useGetUserMetadata(poolId, userId, "business");

  const payloadSchemaWithMappedTitles = mapFieldNameToTitle(schemas.payloadSchema || {});
  const validatePayload = validator.validateFormData(
    data.payload,
    (payloadSchemaWithMappedTitles as JSONSchema7) || {}
  );

  const metadataSchemaWithMappedTitles = mapFieldNameToTitle(schemas.metadataSchema || {});
  const validateMetadata = validator.validateFormData(
    data.metadata,
    (metadataSchemaWithMappedTitles as JSONSchema7) || {}
  );

  const businessMetadataSchemaWithMappedTitles = mapFieldNameToTitle(
    schemas.businessMetadataSchema || {}
  );
  const validateBusinessMetadata = validator.validateFormData(
    data.businessMetadata,
    (businessMetadataSchemaWithMappedTitles as JSONSchema7) || {}
  );

  const handleSave = formData => {
    if (user) {
      setProgress(true);
      resetExtraErrors();

      adminB2BUsersApi
        .updateB2BUser({
          ipID: poolId,
          userID: userId,
          updateUser: {
            ...user,
            payload: trimStringValues(data.payload),
            ...(canManageUsers
              ? {
                  metadata: trimStringValues(data.metadata), // TODO separate api
                  business_metadata: trimStringValues(data.businessMetadata),
                }
              : {}),
          } as any,
        })
        .then(() => {
          if (!checkPoolPermissionsQuery.data?.b2b_manage_admin_metadata) {
            return Promise.resolve({});
          }
          return identityUsersApi.setUserMetadata({
            userID: userId,
            ipID: poolId,
            metadataType: "admin",
            userMetadata: { metadata: trimStringValues(data.metadata) },
          });
        })
        .then(() => {
          if (!checkPoolPermissionsQuery.data?.b2b_manage_business_metadata) {
            return Promise.resolve({});
          }
          return identityUsersApi.setUserMetadata({
            userID: userId,
            ipID: poolId,
            metadataType: "business",
            userMetadata: { metadata: trimStringValues(data.businessMetadata) },
          });
        })
        .then(() => queryClient.invalidateQueries({ queryKey: getUserQueryKey(tenantId, userId) }))
        .then(() =>
          queryClient.invalidateQueries({ queryKey: listUsersQueryKey(tenantId, poolId) })
        )
        .then(() => setSubmitAttempt(false))
        .catch(setError);

      return grantAndRevokeWorkspaceRoles(
        queryClient,
        workspaceId,
        user,
        userRolesBySubjects,
        formData.roles
      )
        .then(() => notifySuccess("User edited successfully"))
        .finally(() => setProgress(false));
    }
  };

  const omitValuesFn = v => isEmpty(v) && !isNumber(v) && !isBoolean(v);
  const formPayloadChanged = !isEqual(
    omitBy(user?.payload || {}, omitValuesFn),
    omitBy(data.payload, omitValuesFn)
  );
  const formMetadataChanged = !isEqual(
    omitBy(getUserAdminMetadataQuery.data?.metadata || {}, omitValuesFn),
    omitBy(data.metadata, omitValuesFn)
  );
  const formBusinessMetadataChanged = !isEqual(
    omitBy(getUserBusinessMetadataQuery.data?.metadata || {}, omitValuesFn),
    omitBy(data.businessMetadata, omitValuesFn)
  );
  const changed =
    formPayloadChanged ||
    formMetadataChanged ||
    formBusinessMetadataChanged ||
    workspaceRolesChanged;

  return (
    <div className={classes.footer}>
      {canManageUsers && (
        <IdentityPoolUserSchemasTabDeleteButton
          poolId={poolId}
          user={user}
          identifiers={user.identifiers ?? []}
          onRedirect={onClose}
          onDeleteUser={(id, poolId) =>
            adminB2BUsersApi
              .deleteB2BUser({ ipID: poolId, userID: id })
              .then(() => globalStoreContext.deletedUsers.addDeletedUserId(id))
              .then(() =>
                queryClient.invalidateQueries({
                  queryKey: listB2BUsersQueryKey(getTenantId(), poolId),
                })
              )
          }
        />
      )}

      {formFactory.createFormFooter({
        disabled:
          !changed ||
          (submitAttempt &&
            validatePayload.errors.length > 0 &&
            validateMetadata.errors.length > 0 &&
            validateBusinessMetadata.errors.length > 0),
        onSubmit: data => {
          setSubmitAttempt(true);
          if (
            validatePayload.errors.length > 0 ||
            validateMetadata.errors.length > 0 ||
            validateBusinessMetadata.errors.length > 0
          ) {
            return;
          }
          handleSave(data);
        },
      })}
    </div>
  );
}

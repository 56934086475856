import React from "react";

import { PasswordPolicyStrengthEnum } from "@cloudentity/acp-identity";

import { FormFactory } from "../../../../../common/utils/forms/formFactory";
import { validators } from "../../../../../common/utils/forms/validation";

const strengthOptions: { apiValue: PasswordPolicyStrengthEnum; label: string; value: number }[] = [
  { apiValue: PasswordPolicyStrengthEnum.Any, label: "Any", value: 0 },
  { apiValue: PasswordPolicyStrengthEnum.Weak, label: "Weak", value: 25 },
  { apiValue: PasswordPolicyStrengthEnum.Fair, label: "Fair", value: 50 },
  { apiValue: PasswordPolicyStrengthEnum.Strong, label: "Strong", value: 75 },
  { apiValue: PasswordPolicyStrengthEnum.VeryStrong, label: "Very Strong", value: 100 },
];

interface Props {
  formFactory: FormFactory;
}

function IdentityPoolPasswordSettings({ formFactory }: Props) {
  const passwordStrength = formFactory.watch("password_policy.strength");

  return (
    <>
      {formFactory.createSliderField({
        name: "password_policy.strength",
        title: "Strength",
        sliderProps: {
          step: 100 / (strengthOptions.length - 1),
          marks: strengthOptions,
          size: "small",
          value: strengthOptions.find(mark => mark.apiValue === passwordStrength)?.value ?? 0,
          onChange: (_, value) => {
            const newValue = strengthOptions.find(mark => mark.value === value)?.apiValue;
            if (newValue) {
              formFactory.setValue("password_policy.strength", newValue);
            }
          },
        },
        tooltip: (
          <>
            <p>
              <b>Weak</b> - very guessable: protection from throttled online attacks
              <br />
              (guesses &lt; 10^6)
            </p>
            <p>
              <b>Fair</b> - somewhat guessable: protection from unthrottled online attacks
              <br />
              (guesses &lt; 10^8)
            </p>
            <p>
              <b>Strong</b> - safely unguessable: moderate protection from offline slow-hash
              <br />
              scenario (guesses &lt; 10^10)
            </p>
            <p>
              <b>Very Strong</b> - strong protection from offline slow-hash scenario
              <br />
              (guesses &gt;= 10^10)
            </p>
          </>
        ),
      })}

      {formFactory.createNumberField({
        id: "password-policy-capital-letters",
        name: "password_policy.capital_letters",
        title: "Capital Letters",
        description: "Number of capital letters required in password",
        validate: {
          inRange: validators.inRange({ label: "Number", min: 0, max: 48 }),
        },
        min: 0,
        max: 48,
      })}

      {formFactory.createNumberField({
        id: "password-policy-lowercase-letters",
        name: "password_policy.lowercase_letters",
        title: "Lowercase Letters",
        description: "Number of lowercase letters required in password",
        validate: {
          inRange: validators.inRange({ label: "Number", min: 0, max: 48 }),
        },
        min: 0,
        max: 48,
      })}

      {formFactory.createNumberField({
        id: "password-policy-digits",
        name: "password_policy.digits",
        title: "Digits",
        description: "Number of digits required in password",
        validate: {
          inRange: validators.inRange({ label: "Number", min: 0, max: 48 }),
        },
        min: 0,
        max: 48,
      })}

      {formFactory.createNumberField({
        id: "password-policy-min-length",
        name: "password_policy.min_length",
        title: "Minimal Length",
        description: "Minimal length required for a password",
        validate: {
          inRange: validators.inRange({ label: "Number", min: 0, max: 48 }),
        },
        min: 0,
        max: 48,
      })}

      {formFactory.createNumberField({
        id: "password-policy-not_used_since",
        name: "password_policy.not_used_since",
        title: "Not Used Since",
        description: "Number of last passwords for which the same password cannot be repeated",
        validate: {
          inRange: validators.inRange({ label: "Number", min: 0, max: 48 }),
        },
        min: 0,
        max: 48,
      })}

      {formFactory.createNumberField({
        id: "password-policy-special_characters",
        name: "password_policy.special_characters",
        title: "Special Characters",
        description: "Number of special characters required in password",
        validate: {
          inRange: validators.inRange({ label: "Number", min: 0, max: 48 }),
        },
        min: 0,
        max: 48,
        tooltip: "~ ! @ # $ % ^ & * _ - + = ` | \\ ( ) { } [ ] : ; \" ' < > , . ? /",
      })}

      {formFactory.createNumberField({
        id: "password-policy-expires_after",
        name: "password_settings.expires_after",
        title: "Expires After",
        description: "Number of days after password expires",
        min: 0,
        style: { marginBottom: 0 },
      })}
    </>
  );
}

export default IdentityPoolPasswordSettings;

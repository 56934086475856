import React, { useMemo, useState } from "react";
import { useParams } from "react-router";

import { useQueryClient } from "@tanstack/react-query";

import { AddUserIdentifierTypeEnum } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../../../common/api/paths";
import Dialog from "../../../../../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../common/components/notifications/notificationService";
import { FormContext } from "../../../../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../../../../common/utils/forms/formFactory";
import { validators } from "../../../../../../../common/utils/forms/validation";
import adminIdentityUsersApi from "../../../../../../services/adminIdentityUsersApi";
import {
  getUserQueryKey,
  listUsersQueryKey,
} from "../../../../../../services/adminIdentityUsersQuery";

export default function AddUserIdentifierDialog({ onClose }) {
  const { id = "", userID = "" } = useParams<{ id: string; userID: string; tab: string }>();
  const tenantId = getTenantId();

  const [requestError, setRequestError] = useState("");
  const [progress, setProgress] = useState(false);

  const queryClient = useQueryClient();
  const data = useMemo(
    () => ({
      identifierType: AddUserIdentifierTypeEnum.Email,
      [AddUserIdentifierTypeEnum.Email]: "",
      [AddUserIdentifierTypeEnum.Mobile]: "",
      [AddUserIdentifierTypeEnum.Uid]: "",
      [AddUserIdentifierTypeEnum.External]: "",
    }),
    []
  );
  const formFactory = useFormFactory({ id: "add-user-identifier", data, progress });
  const identifierType = formFactory.watch("identifierType");

  const handleSubmit = data => {
    setProgress(true);
    adminIdentityUsersApi
      .addUserIdentifier({
        ipID: id,
        userID,
        newIdentifier: {
          identifier:
            data[AddUserIdentifierTypeEnum.Email] ||
            data[AddUserIdentifierTypeEnum.Mobile] ||
            data[AddUserIdentifierTypeEnum.Uid] ||
            data[AddUserIdentifierTypeEnum.External],
          type: data.identifierType,
        },
      })
      .then(() => queryClient.invalidateQueries({ queryKey: getUserQueryKey(tenantId, userID) }))
      .then(() => queryClient.invalidateQueries({ queryKey: listUsersQueryKey(tenantId, id) }))
      .then(() => onClose())
      .then(() => notifySuccess("User identifier successfully added"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to add user identifier"))
      .finally(() => setProgress(false));
  };
  return (
    <Dialog onClose={onClose} id="add-user-identifier-dialog" title="Add User Identifier">
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createRadioGroupField({
          name: "identifierType",
          values: [
            {
              key: AddUserIdentifierTypeEnum.Email,
              label: "Email",
              value: AddUserIdentifierTypeEnum.Email,
            },
            {
              key: AddUserIdentifierTypeEnum.Mobile,
              label: "Mobile",
              value: AddUserIdentifierTypeEnum.Mobile,
            },
            {
              key: AddUserIdentifierTypeEnum.Uid,
              label: "Uid",
              value: AddUserIdentifierTypeEnum.Uid,
            },
            {
              key: AddUserIdentifierTypeEnum.External,
              label: "External",
              value: AddUserIdentifierTypeEnum.External,
            },
          ],
          onChange: () => setRequestError(""),
          style: { marginBottom: 12 },
        })}
        {identifierType === AddUserIdentifierTypeEnum.Email &&
          formFactory.createRequiredField({
            name: AddUserIdentifierTypeEnum.Email,
            label: "Email",
            rules: {
              validate: {
                validEmail: validators.validEmail({ label: "Value" }),
              },
            },
            inputProps: {
              autoComplete: "off",
            },
            optional: false,
            externalErrors: requestError || null,
            onChange: () => setRequestError(""),
          })}
        {identifierType === AddUserIdentifierTypeEnum.Mobile &&
          formFactory.createPhoneInput({
            name: AddUserIdentifierTypeEnum.Mobile,
            label: "Phone",
            selector: "#add-user-identifier-phone-input",
            optional: false,
            required: true,
            externalErrors: requestError || null,
            onChange: () => setRequestError(""),
          })}

        {identifierType === AddUserIdentifierTypeEnum.Uid &&
          formFactory.createRequiredField({
            name: AddUserIdentifierTypeEnum.Uid,
            label: "Uid",
            rules: {
              validate: {
                onlyAsciiWithoutWhitespaces: validators.onlyAsciiWithoutWhitespaces({
                  label: "Uid",
                }),
              },
            },
            optional: false,
          })}
        {identifierType === AddUserIdentifierTypeEnum.External &&
          formFactory.createRequiredField({
            name: AddUserIdentifierTypeEnum.External,
            label: "External",
            rules: {
              validate: {
                onlyAsciiWithoutWhitespaces: validators.onlyAsciiWithoutWhitespaces({
                  label: "External",
                }),
              },
            },
            optional: false,
          })}

        {formFactory.createFormFooter({
          onCancel: onClose,
          onSubmit: handleSubmit,
          submitText: "Add",
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

import React, { useEffect, useMemo } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { pathOr } from "ramda";

import { ServerResponse } from "@cloudentity/acp-admin";

import FormAccordion from "../../../common/components/FormAccordion";
import RouteLeavingGuard from "../../../common/components/RouteLeavingGuard";
import { FormContext } from "../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../common/utils/forms/formFactory";
import { validators } from "../../../common/utils/forms/validation";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import CardWithIconAndTitle from "../common/CardWithIconAndTitle";
import { CommonIdpConfig } from "./CommonIdpConfig";
import CommonIdpConfigUpper from "./CommonIdpConfigUpper";
import IdentitiesDetailsFooter from "./IdentitiesDetailsFooter";
import SSOIDPSettings from "./SSOIDPSettings";
import { amrToResponse } from "./amrOptions";
import { IdpUiModelIntelliTrustType, providers } from "./identities.utils";

const id = "identities-configuration-intelli-trust";

interface Props {
  provider: IdpUiModelIntelliTrustType;
  server: ServerResponse | undefined;
  updateProgress?: boolean;
  inEdit?: boolean;
  onLogoEdit?: (data: any) => void;
  customSubmit?: boolean;
  onInit?: (fn: () => void) => void;
  onCancel?: () => void;
  onSubmit: (data: IdpUiModelIntelliTrustType) => void;
  onDelete?: (idp: IdpUiModelIntelliTrustType) => void;
}

export default function IdentitiesConfigurationIntelliTrust({
  provider,
  server,
  updateProgress,
  inEdit,
  onLogoEdit,
  customSubmit,
  onCancel,
  onSubmit,
  onInit,
  onDelete,
}: Props) {
  const data = useMemo(() => provider || {}, [provider]);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(server?.id);

  const formFactory = useFormFactory({
    id,
    data,
    progress: updateProgress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit,
  });

  const submitFn = (provider, data) => ({ ...provider, ...amrToResponse(data), ...data });

  const providerMapData = providers.find(p => p.method === "intelli_trust");

  useEffect(() => {
    onInit &&
      onInit(() =>
        formFactory.handleSubmit(data => onSubmit && onSubmit(submitFn(provider, data)))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserInfo = formFactory.watch("settings.get_user_info");
  const fetchGroups = formFactory.getValues("settings.fetch_groups");

  !getUserInfo && fetchGroups && formFactory.setValue("settings.fetch_groups", false);

  const serverSSOEnabled = server?.sso?.enabled ?? false;

  return (
    <FormContext.Provider value={formFactory.context}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Paper style={{ padding: 32 }}>
            {providerMapData && (
              <CardWithIconAndTitle
                img={providerMapData.icon}
                title={providerMapData.name}
                id={`idp-${providerMapData.name.replace(/ /g, "-")}`}
                style={{ marginBottom: 32, width: "50%" }}
              />
            )}

            <CommonIdpConfigUpper
              formFactory={formFactory}
              provider={provider}
              inEdit={inEdit}
              onLogoEdit={onLogoEdit}
              onUpdate={onSubmit}
            />
            {formFactory.createRequiredField({
              name: "settings.domain",
              label: "Domain",
              defaultValue: pathOr("", ["settings", "domain"], provider),
              rules: {
                validate: {
                  validDomain: validators.validDomain({ label: "Value" }),
                },
              },
            })}
            {formFactory.createRequiredField({
              name: "settings.client_id",
              label: "Client ID",
              defaultValue: pathOr("", ["settings", "client_id"], provider),
              withCopy: true,
              inputProps: {
                autoComplete: "off",
              },
            })}
            {formFactory.createRequiredField({
              name: "credentials.client_secret",
              label: "Client Secret",
              defaultValue: pathOr("", ["credentials", "client_secret"], provider),
              toggleVisibility: true,
              defaultVisibility: false,
              withCopy: true,
              inputProps: {
                autoComplete: "off",
              },
            })}
            {formFactory.createReadOnlyField({
              name: "redirectUrl",
              label: "Redirect URL",
              withCopy: true,
            })}
            <CommonIdpConfig formFactory={formFactory} data={provider} />

            {inEdit && (
              <FormAccordion title="Advanced settings" id={id}>
                {formFactory.createCheckBox({
                  name: "settings.get_user_info",
                  label: "Get user info",
                  style: { marginBottom: 12 },
                })}
                {formFactory.createCheckBox({
                  name: "settings.send_login_hint",
                  label: (
                    <span>
                      Send <code>login_hint</code>
                    </span>
                  ),
                  helperText: (
                    <span>
                      Sends the entered identifier as a <code>login_hint</code> to the IDP
                    </span>
                  ),
                })}
                {formFactory.createCheckBox({
                  name: "settings.fetch_groups",
                  label: "Fetch groups",
                  disabled: !getUserInfo,
                  style: { marginBottom: 12 },
                })}
                {serverSSOEnabled && <SSOIDPSettings formFactory={formFactory} />}
              </FormAccordion>
            )}

            <IdentitiesDetailsFooter
              customSubmit={customSubmit}
              idp={provider}
              formFactory={formFactory}
              onSubmit={data => onSubmit && onSubmit(submitFn(provider, data))}
              onCancel={onCancel}
              onDelete={onDelete}
            />
          </Paper>
        </Grid>
        {inEdit && <RouteLeavingGuard />}
      </Grid>
    </FormContext.Provider>
  );
}

import React, { useEffect } from "react";
import { FieldValues } from "react-hook-form";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { GrantTenantRoleRequestRoleEnum } from "@cloudentity/acp-admin";

import RouterLink from "../../../../common/components/RouterLink";
import { useFormContext } from "../../../../common/utils/forms2/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import SelectField from "../../../../common/utils/forms/SelectField";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import {
  getTenantRoleOptionsByPermissions,
  tenantRoleOptions,
} from "../../workspaceDirectory/administrator/AdministratorUserEdit";
import { IdpUiModelType } from "../identities.utils";

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: 8,
  },
  header: {
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    color: theme.palette.secondary.light,
    maxWidth: 480,
  },
}));

interface Props {
  method: IdpUiModelType;
  handleUpdate: (provisioning: "enabled" | "disabled", data: FieldValues) => void;
}

export default function ConfigureIDPDisabledJITDefaultRole({ method, handleUpdate }: Props) {
  const { classes } = useStyles();

  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const { form } = useFormContext();

  const isDisabled = !(
    checkTenantPermissionsQuery.data?.manage_admin_role ||
    checkTenantPermissionsQuery.data?.manage_auditor_role ||
    checkTenantPermissionsQuery.data?.manage_business_admin_role
  );

  const role = form.watch("idp_tenant_role");

  useEffect(() => {
    if (role === "" && method.jit?.enabled) {
      form.setValue("idp_tenant_role", GrantTenantRoleRequestRoleEnum.Member);
    }
  }, [role, method, form]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12} lg={6}>
          <Typography variant="h5" className={classes.header}>
            Admin portal access role
          </Typography>

          <Typography variant="textMD" className={classes.headerText} component="div">
            User authenticating with this provider gets this access role by default. Fine grained
            access control beyond the roles can be configured using the{" "}
            <RouterLink to="/admin/applications/admin/policies">
              admin portal access policy
            </RouterLink>
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SelectField
            name="idp_tenant_role"
            displayEmpty
            options={
              isDisabled
                ? tenantRoleOptions
                : getTenantRoleOptionsByPermissions(checkTenantPermissionsQuery.data)
            }
            disabled={isDisabled}
            rules={{
              required: "Admin portal access role is required",
            }}
          />
        </Grid>

        <FormFooter
          id="jit-form-footer"
          onSubmit={data => {
            handleUpdate("disabled", data);
          }}
          style={{ width: "100%" }}
        />
      </Grid>
    </Grid>
  );
}

import React, { useMemo, useState } from "react";

import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { useQueryClient } from "@tanstack/react-query";
import merge from "lodash/merge";

import { MFAMethod, SMSSettingsProviderEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import FormSection from "../../../../common/components/FormSection";
import RouteLeavingGuard from "../../../../common/components/RouteLeavingGuard";
import {
  handleErrorWithNotify,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { BRAND_NAME } from "../../../../common/theme/theme";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { useFeature } from "../../../../common/utils/hooks/useFeature";
import adminMfaMethodsApi from "../../../services/adminMfaMethodsApi";
import { getMfaMethodsQueryKey, useGetMfaMethods } from "../../../services/adminMfaMethodsQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import PageContent from "../../common/PageContent";
import MessageProvidersPhoneTestMessage from "./MessageProvidersPhonetestMessage";

function MessageProvidersPhone() {
  const [progress, setProgress] = useState(false);
  const tid = getTenantId();
  const isDisabledEmbeddedSMSProvider = useFeature("disable_embedded_sms_provider");

  const queryClient = useQueryClient();
  const getMfaMethodsQuery = useGetMfaMethods(tid);
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const updateMfaMethod = (newMfaMethod: MFAMethod) => {
    setProgress(true);
    adminMfaMethodsApi
      .updateMfaMethod({ mfaID: "sms", mFAMethod: newMfaMethod })
      .then(() => queryClient.invalidateQueries({ queryKey: getMfaMethodsQueryKey(tid) }))
      .then(() => notifySuccess("Phone configuration saved successfully"))
      .catch(handleErrorWithNotify("Error occurred while trying to update mfa methods"))
      .finally(() => setProgress(false));
  };

  const initialData = useMemo(() => {
    if (getMfaMethodsQuery.data) {
      return (getMfaMethodsQuery.data?.mfa_methods || []).find(v => v.id === "sms") ?? {};
    }

    return {};
  }, [getMfaMethodsQuery.data]);

  const formFactory = useFormFactory({
    id: "mfa-phone-delivery",
    data: initialData,
    noManagePermission: !checkTenantPermissionsQuery.data?.manage_mfa_methods,
    progress,
  });

  const provider = formFactory.watch("settings.sms.provider");

  return (
    <FormContext.Provider value={formFactory.context}>
      <PageContent
        progress={getMfaMethodsQuery.isLoading || checkTenantPermissionsQuery.isLoading}
        fullWidth={false}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Paper style={{ padding: 32 }}>
              <FormSection id="mfa-phone-delivery-provider-label" title="Phone configuration" />

              {formFactory.createFormCardsField({
                name: "settings.sms.provider",
                cards: [
                  {
                    title: isDisabledEmbeddedSMSProvider ? "Disabled" : BRAND_NAME,
                    subtitle: isDisabledEmbeddedSMSProvider
                      ? "Text messages are disabled"
                      : "Use our default SMS provider to send a single-use verification code",
                    value: SMSSettingsProviderEnum.Embedded,
                  },
                  {
                    title: "Twilio",
                    subtitle: "Use your Twillio account to send a single-use verification code",
                    value: SMSSettingsProviderEnum.Twilio,
                  },
                ],
              })}

              {provider === SMSSettingsProviderEnum.Twilio && (
                <>
                  {formFactory.createRequiredField({
                    name: "auth.sms.sid",
                    label: "Twilio SID",
                  })}

                  {formFactory.createRequiredField({
                    name: "auth.sms.auth_token",
                    label: "Twilio Auth Token",
                    defaultVisibility: false,
                    toggleVisibility: true,
                  })}
                </>
              )}

              {formFactory.createField({
                name: "settings.sms.custom_source",
                label: "From",
                helperText: (
                  <span>
                    <code>From</code> specifies the Twilio phone number,{" "}
                    <Link
                      href="https://www.twilio.com/docs/glossary/what-is-a-short-code"
                      target="_blank"
                      rel="noreferrer"
                    >
                      short code
                    </Link>
                    , or Messaging Service that sends this message. This must be a Twilio phone
                    number that you own, formatted with a '+' and country code, e.g.{" "}
                    <code>+16175551212</code> (
                    <Link
                      href="https://www.twilio.com/docs/glossary/what-e164"
                      target="_blank"
                      rel="noreferrer"
                    >
                      E.164
                    </Link>{" "}
                    format).
                  </span>
                ),
                style: {
                  marginBottom: 52,
                  display: provider === SMSSettingsProviderEnum.Twilio ? "block" : "none",
                },
              })}

              {formFactory.createFormFooter({
                onSubmit: data => updateMfaMethod(merge(initialData, data)),
              })}
            </Paper>
          </Grid>

          {(provider === SMSSettingsProviderEnum.Twilio || !isDisabledEmbeddedSMSProvider) && (
            <Grid item xs={12} lg={4}>
              <MessageProvidersPhoneTestMessage />
            </Grid>
          )}
        </Grid>
      </PageContent>
      <RouteLeavingGuard />
    </FormContext.Provider>
  );
}

export default MessageProvidersPhone;

import { identity, isEmpty, lensPath, mergeRight, set, view } from "ramda";

export const mapReducer = (actionsMap, initialState) => (state, action) => {
  const reduceFn = actionsMap[action.type];
  return reduceFn ? reduceFn(state || initialState, action) : state || initialState;
};

export const putData =
  (stateKey, transformFn = identity) =>
  (state, action) =>
    mergeRight(state, { [stateKey]: transformFn(action.data, state) });

export const putFromData =
  (stateKey, transformFn = identity) =>
  (state, action) =>
    mergeRight(state, { [stateKey]: transformFn(action.data, state) });

export const setData =
  (lens, transformFn = identity) =>
  (state, action) =>
    set(lens, transformFn(action.data, state), state);

export const setObjectFieldWithKeyData = root => (state, action) =>
  set(lensPath([root, action.key]), action.data, state);

export const setDataWith =
  (lens, fn, transformFn = identity) =>
  (state, action) =>
    set(lens, fn(view(lens, state), transformFn(action.data, state)), state);

export const putValue = (stateKey, value) => (state, action) =>
  mergeRight(state, { [stateKey]: value });

export const setValue = (lens, value) => (state, action) => set(lens, value, state);

export const resolve = data => Promise.resolve({ payload: data });

export const reject = err => Promise.reject({ message: err });

const runReducers = (state, action, reducer, ...rest) =>
  reducer(isEmpty(rest) ? state : runReducers(state, action, ...rest), action);

export const composeReducers =
  (...reducers) =>
  (state, action) =>
    runReducers(state, action, ...reducers);

export const startProgress = putValue("progress", true);

export const stopProgress = putValue("progress", false);

export const putValidationResult = putData("validationErrors");

export const resetValidationResults = putValue("validationErrors", {});

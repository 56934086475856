import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { ServerResponseTypeEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import Dialog from "../../../../common/components/Dialog";
import { notifyErrorOrDefaultTo } from "../../../../common/components/notifications/notificationService";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import adminOrganizationsApi from "../../../services/adminOrganizationsApi";
import { listOrganizationsQueryKey } from "../../../services/adminOrganizationsQuery";
import { getExecutionPointsQueryKey } from "../../../services/adminScriptsQuery";
import { listWorkspacesQueryKey } from "../../../services/adminServersQuery";
import { initialColors } from "../WorkspacesColorInput";
import AssignedWorkspaceField from "../administrator/AssignedWorkspaceField";
import { EMPTY_VALUE_PLACEHOLDER_SERVER } from "../server-profiles";

interface Props {
  onCancel: () => void;
  onCreated: () => void;
}

const data = { server: "" };

export default function CreateOrganizationTemplateDialog({ onCancel, onCreated }: Props) {
  const [progress, setProgress] = useState(false);
  const formFactory = useFormFactory({ id: "create-organization-template", data, progress });
  const queryClient = useQueryClient();

  const handleCreate = ({ server, name }) => {
    setProgress(true);
    adminOrganizationsApi
      .createOrganization({
        org: {
          name,
          template_id: server?.id ?? undefined,
          template: true,
          color: initialColors[Math.floor(Math.random() * initialColors.length)],
        },
      })
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
      .then(() => queryClient.invalidateQueries({ queryKey: listOrganizationsQueryKey() }))
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getExecutionPointsQueryKey(getTenantId(), server?.id),
        })
      )
      .then(() => onCreated())
      .catch(
        notifyErrorOrDefaultTo("Error occurred when trying to create an organization template")
      )
      .finally(() => {
        setProgress(false);
      });
  };

  return (
    <Dialog onClose={onCancel} id="create-organization-template-dialog" title="Create Template">
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createRequiredField({ name: "name", label: "Name", autoFocus: true })}

        <AssignedWorkspaceField
          label="Copy existing configuration"
          name="server"
          workspaceType={ServerResponseTypeEnum.Organization}
          formFactory={formFactory}
          optional
          emptyValue={{ id: EMPTY_VALUE_PLACEHOLDER_SERVER, name: "Not selected" }}
        />

        {formFactory.createFormFooter({
          onCancel,
          onSubmit: handleCreate,
          submitText: "Create",
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

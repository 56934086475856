import React, { useState } from "react";

import { BaseUserWithData, UserWithData } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../../../../common/api/paths";
import Dialog from "../../../../../../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../../common/components/notifications/notificationService";
import { FormContext } from "../../../../../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../../../../../common/utils/forms/formFactory";
import identityOTPApi from "../../../../../../../services/adminIdentityOTPApi";
import { getUserQueryKey } from "../../../../../../../services/adminIdentityUsersQuery";
import { queryClient } from "../../../../../../../services/queryClient";

const data = {
  code: "",
};

interface Props {
  user: UserWithData | BaseUserWithData;
  handleClose: () => void;
}

export default function IdentityPoolUserSidePanelVerifyOTPChallengeDialog({
  user,
  handleClose,
}: Props) {
  const [progress, setProgress] = useState(false);

  const formFactory = useFormFactory({ id: "pool-user-verify-otp-challenge", data, progress });

  const handleVerifyChallengeOTP = (code: string) => {
    setProgress(true);
    identityOTPApi
      .verifyOTP({
        ipID: user?.user_pool_id!,
        userID: user?.id!,
        inspectOTP: { code },
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getUserQueryKey(getTenantId(), user?.id) })
      )
      .then(() => notifySuccess("OTP challenge verified"))
      .then(handleClose)
      .catch(err => {
        notifyErrorOrDefaultTo("Error occurred when trying to verify OTP challenge")(err);
      })
      .finally(() => {
        setProgress(false);
      });
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      id="pool-user-verify-otp-challenge"
      title="Verify OTP Challenge"
    >
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createRequiredField({
          name: "code",
          label: "Code",
        })}

        {formFactory.createFormFooter({
          onCancel: handleClose,
          onSubmit: ({ code }) => handleVerifyChallengeOTP(code),
          submitText: "Verify",
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

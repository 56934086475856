import React from "react";

import Button from "@mui/material/Button";
import { Play } from "react-feather";

import { getTenantId } from "../../../../common/api/paths";
import { openUrlInNewTab } from "../../../../common/utils/browser.utils";
import { useGetClients } from "../../../services/adminClientsQuery";
import { useGetAuthorizationServer } from "../../../services/adminServersQuery";

export default function TrySignInButton({
  serverId,
  idpHint,
  size = "large",
  variant = "outlined",
}: {
  serverId: string;
  size: "small" | "medium" | "large";
  variant: "text" | "outlined" | "contained";
  idpHint?: string;
}) {
  const getServerQuery = useGetAuthorizationServer(getTenantId(), serverId);
  const listClientsQuery = useGetClients(getTenantId(), serverId);
  const clients = listClientsQuery.data?.clients ?? [];
  const defaultApp = clients.find(
    client => client.client_id === getServerQuery.data?.settings?.default_client_id
  );
  const defaultAppLink = defaultApp?.app_url;

  const demoClient = clients.find(client => client.client_id === `${serverId}-demo`);
  const userPortal = clients.find(client => client.client_id === `${serverId}`);
  const demoLink =
    `${getServerQuery.data?.issuer_url ?? ""}/demo?auto_login=true` ||
    demoClient?.redirect_uris?.[0] ||
    "";
  const userPortalLink = `${getServerQuery.data?.issuer_url ?? ""}/app` || "";

  let link =
    (defaultApp && defaultAppLink) ||
    (demoClient && demoLink) ||
    (userPortal && userPortalLink) ||
    "";

  if (link && idpHint) {
    const url = new URL(link);
    if (!url.searchParams.has("idp_hint")) {
      url.searchParams.append("idp_hint", idpHint);
      link = url.toString();
    }
  }

  return (defaultApp && defaultAppLink) ||
    (demoClient && demoLink) ||
    (userPortal && userPortalLink) ? (
    <Button
      id="try-sign-in-button"
      variant={variant}
      size={size}
      onClick={() => {
        openUrlInNewTab(link);
      }}
    >
      <Play size={16} style={{ marginRight: 8 }} />
      Try Sign-in
    </Button>
  ) : null;
}

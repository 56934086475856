import React, { useEffect, useState } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import { AxiosResponse } from "axios";

import { SelfUserWithDataV2 } from "@cloudentity/acp-identityself";

import { logout } from "../../auth/actions/actions";
import identitySelfApi from "../../services/identitySelfApi";
import { FormContext } from "../../utils/forms2/Form";
import { useFormFactory } from "../../utils/forms/formFactory";
import Button from "../Button";
import Dialog from "../Dialog";
import { notifyErrorOrDefaultTo, notifySuccess } from "../notifications/notificationService";

const data = {
  newPassword: "",
  confirmPassword: "",
};

interface Props {
  fetchUser: (withSetter?: boolean) => Promise<AxiosResponse<SelfUserWithDataV2, any> | null>;
}

export default function SelfServiceSetPassword({ fetchUser }: Props) {
  const [dialog, setDialog] = useState(false);
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState(null);

  const formFactory = useFormFactory({
    id: "set-password",
    data,
    progress,
  });

  function handleClose() {
    setDialog(false);
  }

  const newPassword = formFactory.watch("newPassword");

  useEffect(() => {
    formFactory.trigger("confirmPassword");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword]);

  function handleChangePassword(newData: typeof data) {
    setProgress(true);
    setError(null);

    identitySelfApi
      .setPassword({ new_password: newData.newPassword })
      .then(() => fetchUser(true))
      .then(handleClose)
      .then(() => notifySuccess("New password set successfully"))
      .catch(e => {
        if (
          e.response?.status === 401 &&
          e.response?.headers?.["www-authenticate"]?.includes("insufficient_user_authentication")
        ) {
          setError(e);
          return;
        }

        return notifyErrorOrDefaultTo("Error occurred while trying to change password")(e);
      })
      .finally(() => setProgress(false));
  }

  return (
    <>
      <Button
        id="set-password"
        onClick={() => setDialog(true)}
        variant="contained"
        size="small"
        style={{ fontSize: 12 }}
      >
        Enable
      </Button>

      {dialog && (
        <Dialog onClose={handleClose} id="set-password-dialog" title="Set password">
          <Typography component="div" variant="textMD" style={{ marginBottom: 32 }}>
            Choose a strong password and don't reuse it for other accounts.
          </Typography>

          <FormContext.Provider value={formFactory.context}>
            {formFactory.createRequiredField({
              name: "newPassword",
              label: "New password",
              toggleVisibility: true,
              defaultVisibility: false,
              withPasswordStrengthMeter: true,
            })}

            {formFactory.createRequiredField({
              name: "confirmPassword",
              label: "Confirm password",
              toggleVisibility: true,
              defaultVisibility: false,
              rules: {
                validate: {
                  matchNewPassword: v =>
                    v === newPassword || "New password does not match confirm password",
                },
              },
            })}

            {error && (
              <Alert
                severity="error"
                title="Reauthentication required"
                style={{ marginBottom: 16 }}
              >
                <AlertTitle>Reauthentication required</AlertTitle>
                Reauthenticate to set password
                <Button
                  onClick={() => logout("admin", { withPromptLogin: true })}
                  variant="outlined"
                  size="small"
                  color="error"
                  style={{ marginLeft: 8 }}
                >
                  Reauthenticate
                </Button>
              </Alert>
            )}

            {formFactory.createFormFooter({
              onCancel: handleClose,
              onSubmit: handleChangePassword,
            })}
          </FormContext.Provider>
        </Dialog>
      )}
    </>
  );
}

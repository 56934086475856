import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "../App.css";
import {
  acpBasePath,
  getServerId,
  getTenantId,
  pathPrefix,
  portalMode,
  portalType,
} from "../common/api/paths";
import Callback from "../common/components/Callback";
import ErrorBoundary from "../common/components/ErrorBoundary";
import PrivateRoute from "../common/components/PrivateRoute";
import Progress from "../common/components/Progress";
import NotificationsProvider from "../common/components/notifications/NotificationsProvider";
import Notifier from "../common/components/notifications/Notifier";
import { setAuthorizationServerId, setTenantId } from "../common/reducers/global";
import getStore from "../common/store";
import { BRAND_NAME, theme } from "../common/theme/theme";
import { useStyling } from "../common/utils/hooks/useStyling";
import AuthenticatedUserAppBase from "../user/components/AuthenticatedUserAppBase";
import { GlobalStoreProvider } from "./GlobalStore/GlobalStore";
import AuthenticatedAdminAppBase from "./components/AuthenticatedAdminAppBase";
import AuthenticatedB2BAppBase from "./components/AuthenticatedB2BAppBase";
import reducers from "./reducers";
import { queryClient } from "./services/queryClient";

const tenantId = getTenantId();
const authorizationServerId = getServerId();

const store = getStore(reducers);
const basename = `${acpBasePath}${pathPrefix}`;

store.dispatch(setTenantId(tenantId));
store.dispatch(setAuthorizationServerId(authorizationServerId));

const AdminApp = () => {
  const name =
    portalMode === "b2b"
      ? `${BRAND_NAME} Delegated Organizations Admin Portal`
      : `${BRAND_NAME} - Admin Portal`;

  useEffect(() => {
    if (process.env.NODE_ENV === "development" && !window.location.pathname.startsWith(basename)) {
      window.location.replace(basename);
    }
  }, []);

  const { customTheme, isLoading, logo } = useStyling(
    theme,
    portalType === "admin" && portalMode === "portal" ? undefined : authorizationServerId
  );

  document.title = name;
  const headDescription = document.head.querySelector("meta[name='description']") as HTMLElement;
  if (headDescription && headDescription.getAttribute("content")) {
    headDescription.setAttribute("content", name);
  }

  const appName =
    (portalType === "user" && "User portal") || (portalType === "admin" && "Admin portal") || "";

  const scopes = [
    "view_ss_profile",
    "manage_ss_profile",
    "list_clients_with_access",
    "revoke_client_access",
    "view_sessions",
    "manage_sessions",
    "openid",
    "profile",
    ...(getTenantId() === "system" ? [] : ["email"]),
  ];

  const responseType = portalType === "user" ? ["id_token", "code"] : [];

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/callback" element={<Callback appName={appName} />} />
        <Route path="/silent" element={<Callback appName={appName} silent />} />
        <Route
          path="*"
          element={
            <PrivateRoute
              tenantId={tenantId}
              authorizationServerId={authorizationServerId}
              scopes={scopes}
              responseType={responseType}
              portalType={portalType}
            />
          }
        >
          <Route
            path="*"
            element={
              <>
                {(portalType === "user" && portalMode === "portal" && (
                  <AuthenticatedUserAppBase appName={appName} logo={logo} isLoading={isLoading} />
                )) ||
                  (portalType === "admin" && portalMode === "portal" && (
                    <AuthenticatedAdminAppBase tenantId={tenantId} isLoading={isLoading} />
                  )) ||
                  (portalMode === "b2b" && <AuthenticatedB2BAppBase isLoading={isLoading} />) ||
                  null}
              </>
            }
          />
        </Route>
      </>
    ),
    { basename }
  );

  return (
    <Suspense fallback={<Progress isGlobalLoader />}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={customTheme || theme}>
          <Provider store={store}>
            <ErrorBoundary>
              <RouterProvider router={router} />
              <NotificationsProvider>
                <Notifier />
                <ReactQueryDevtools initialIsOpen={false} />
              </NotificationsProvider>
            </ErrorBoundary>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  );
};

const WithQueryClientProvider = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStoreProvider>
        <AdminApp />
      </GlobalStoreProvider>
    </QueryClientProvider>
  );
};

export default WithQueryClientProvider;

import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { getTenantId } from "../../../../common/api/paths";
import Dialog from "../../../../common/components/Dialog";
import FormInputLabel from "../../../../common/components/FormInputLabel";
import { notifyErrorOrDefaultTo } from "../../../../common/components/notifications/notificationService";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { slugify } from "./utils";

const useStyles = makeStyles()(() => ({
  prefixLabelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  prefixChangeButton: {
    fontSize: 12,
    minWidth: "unset",
    padding: "0 4px",
    marginLeft: 4,
  },
}));

const prefixRegexp = /^[a-z][a-z0-9_]{1,61}[a-z0-9]\/$/;

interface Props {
  onClose: () => void;
  onCreate: (data: any) => Promise<void>;
  systemNames: string[];
}

function PermissionCreate({ onClose, onCreate, systemNames }: Props) {
  const { classes } = useStyles();

  const [progress, setProgress] = useState(false);
  const [isPrefixEditable, setIsPrefixEditable] = useState(false);
  const formFactory = useFormFactory({ id: "permission-create", progress });
  const tenantId = getTenantId();
  const name = formFactory.watch("name");

  useEffect(() => {
    formFactory.setValue("prefix", slugify(`${tenantId}_${name}`) + "/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <Dialog id="permission-create-dialog" title="Create Permission System" onClose={onClose}>
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createRequiredField({
          name: "name",
          label: "Name",
          autoFocus: true,
          rules: {
            validate: {
              notUniq: v =>
                systemNames.map(s => s.toLowerCase()).indexOf(v.trim().toLowerCase()) === -1 ||
                "Permission system with a given name already exists",
            },
          },
        })}

        {formFactory.createField({
          name: "description",
          label: "Description",
        })}

        <div className={classes.prefixLabelContainer}>
          <FormInputLabel
            id="prefix-label"
            forId="permission-create-prefix-input"
            label="Prefix"
            style={{ marginBottom: 0 }}
          />
          {!isPrefixEditable && (
            <div>
              <Typography variant="textSM">Auto-generated value</Typography>
              <Button
                size="small"
                className={classes.prefixChangeButton}
                onClick={() => setIsPrefixEditable(!isPrefixEditable)}
              >
                Change
              </Button>
            </div>
          )}
        </div>
        {formFactory.createRequiredField({
          name: "prefix",
          label: "Prefix",
          hideLabel: true,
          disabled: !isPrefixEditable,
          rules: {
            validate: {
              isValid: v =>
                prefixRegexp.test(v) ||
                "Prefix must start with a letter, have maximal length of 64 characters, end with a slash, consist of only lowercase letters, numbers and underscores",
            },
          },
        })}

        {formFactory.createFormFooter({
          onCancel: onClose,
          onSubmit: data => {
            setProgress(true);
            onCreate(data)
              .catch(err => {
                if (
                  err.response?.status === 409 &&
                  err.response?.data.error === "permission system prefix must be unique"
                ) {
                  formFactory.setError(
                    "prefix",
                    {
                      message: "Permission system with a given prefix already exists",
                    },
                    { shouldFocus: true }
                  );
                } else {
                  notifyErrorOrDefaultTo("Error occurred while trying to create permission system")(
                    err
                  );
                }
              })
              .finally(() => setProgress(false));
          },
          submitText: "Create",
        })}
      </FormContext.Provider>
    </Dialog>
  );
}

export default PermissionCreate;
